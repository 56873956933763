import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getDistrictDetails,
  revertDistrictFormDetails
} from '../../redux/reducers/mastersSlice';
import { ArrowForward, Details } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Paper,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import CustomBreadcrumb from '../../components/Breadcrumb';
import CustomDataTable from '../../components/CustomDataTable';
import { createTitle, generateImageSrc } from '../../utils/utils';
import moment from 'moment';
import {
  colorLinkGrey,
  colorPrimary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import { ChevronRightIcon } from '../../utils/iconSvg';
import CustomTabLabel from '../../custom/CustomTabLabel';

const DistrictDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    tabValue: 0,
    page: 0,
    size: 10
  });

  const [formData, setFormData] = useState({
    stateId: null,
    stateCode: null,
    stateName: null,
    districtId: 0,
    districtCode: null,
    districtName: null,
    areas: null,
    schools: null,
    users: null,
    createdBy: null,
    creationDate: null,
    employee: null
  });

  const {
    districtOtherDetails,
    districtDetails,
    isDistrictDetailsLoading,
    isDistrictDetailsSuccess
  } = useSelector(state => state.masters);

  const secondUpdate = useRef(false);

  useEffect(() => {
    document.title = createTitle('District Details');

    return () => dispatch(revertDistrictFormDetails());
  }, []);

  useEffect(() => {
    let districtId = location.state?.districtId || null;

    if (districtId) {
      dispatch(
        getDistrictDetails({
          districtId: districtId,
          payload: {
            page: initialValues.page,
            size: initialValues.size,
            type: 'all'
          }
        })
      );
    }
    return () => {};
  }, [initialValues]);

  useEffect(() => {
    if (
      !secondUpdate.current &&
      districtDetails &&
      districtDetails.districtId
    ) {
      setFormData({
        stateId: districtDetails.stateId,
        stateCode: districtDetails.stateCode,
        stateName: districtDetails.stateName,
        districtId: districtDetails.districtId,
        districtCode: districtDetails.districtCode,
        districtName: districtDetails.districtName,
        areas: districtDetails.areas,
        schools: districtDetails.schools,
        users: districtDetails.users,
        createdBy: districtDetails.createdBy,
        creationDate: districtDetails.creationDate,
        employee: districtDetails?.employee
      });
      secondUpdate.current = true;
    }
  }, [districtDetails]);

  const userColumns = [
    {
      uinqueKey: 'userId',
      id: 'name',
      label: 'Name',
      width: '220px',
      sortable: true,
      render: row => (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.userImageUrl ? (
              <Avatar
                src={row.userImageUrl}
                alt={row.name}
                sx={{ width: 32, height: 32, marginRight: 1 }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#c9c9c9',
                  color: 'white',
                  fontSize: '12px',
                  marginRight: '5px'
                }}
              >
                {row ? row.name?.split(' ')[0]?.charAt(0).toUpperCase() : ''}
              </Avatar>
            )}

            <Typography variant="body2" fontWeight={'600'}>
              {row.name}
            </Typography>
          </div>
        </>
      )
    },
    {
      id: 'contactNumber',
      label: 'Phone'
    },
    {
      id: 'email',
      label: 'Email'
    },
    {
      id: 'role',
      label: 'Role',
      sortable: true
    },
    {
      id: 'status',
      label: 'Status',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '3px', // p-1
              borderRadius: '1.25rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 90,
              color: row.status ? '#749E35' : '#D62828'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'state',
      label: 'State'
    },
    {
      id: 'district',
      label: 'District'
    },
    {
      id: 'area',
      label: 'Area'
    },
    {
      id: 'school',
      label: 'School'
    }
  ];

  const employeeColumns = [
    {
      uinqueKey: 'userId',
      id: 'name',
      label: 'Name',
      width: '220px',
      sortable: true,
      render: row => (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.userImageUrl ? (
              <Avatar
                src={row.userImageUrl}
                alt={row.name}
                sx={{ width: 32, height: 32, marginRight: 1 }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#c9c9c9',
                  color: 'white',
                  fontSize: '12px',
                  marginRight: '5px'
                }}
              >
                {row ? row.name?.split(' ')[0]?.charAt(0).toUpperCase() : ''}
              </Avatar>
            )}

            <Typography variant="body2" fontWeight={'600'}>
              {row.name}
            </Typography>
          </div>
        </>
      )
    },
    {
      id: 'contactNumber',
      label: 'Phone'
    },
    {
      id: 'email',
      label: 'Email'
    },
    {
      id: 'role',
      label: 'Role',
      sortable: true
    },
    {
      id: 'status',
      label: 'Status',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '3px', // p-1
              borderRadius: '1.25rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 90,
              color: row.status ? '#749E35' : '#D62828'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    }
  ];

  const schoolColumns = [
    {
      uinqueKey: 'schoolId',
      id: 'schoolName',
      label: 'School',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.schoolName}
          </Typography>
        );
      }
    },
    {
      id: 'area',
      label: 'Area',
      sortable: true
    },
    {
      id: 'districtName',
      label: 'District ',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.districtCode ? `(${row.districtCode})` : ''} {row.districtName}
          </Typography>
        );
      }
    },
    {
      id: 'stateName',
      label: 'State ',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.stateCode ? `(${row.stateCode})` : ''} {row.stateName}
          </Typography>
        );
      }
    },
    {
      id: 'users',
      label: 'Users',
      sortable: true
    },
    {
      id: 'status',
      label: 'Status',
      width: '150px',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '3px',
              borderRadius: '1.25rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 90,
              color: row.status ? '#749E35' : '#D62828'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'createdBy',
      label: 'Created By'
    },
    {
      id: 'creationDate',
      label: 'Created On',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'}>
                {row.creationDate
                  ? moment(row.creationDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    }
  ];

  const areaColumns = [
    {
      uinqueKey: 'pinId',
      id: 'area',
      label: 'Area',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.area}
          </Typography>
        );
      }
    },
    {
      id: 'pinCode',
      label: 'Pincode ',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.pinCode}
          </Typography>
        );
      }
    },
    {
      id: 'schools',
      label: 'Schools'
    },
    {
      id: 'users',
      label: 'Users'
    }
  ];

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={colorTextGrey} />
              </SvgIcon>
            }
          >
            <Link
              color="inherit"
              href={'Districts'}
              onClick={event => {
                event.preventDefault();
                navigate('/district');
              }}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                fontWeight={'700'}
                color={colorLinkGrey}
                fontSize={'24px'}
              >
                Districts
              </Typography>
            </Link>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
                color: colorTextBlack
              }}
            >
              {formData.districtName || ''}
            </Typography>
          </Breadcrumbs>
          {formData?.districtName !== null && (
            <Typography marginBottom={2}>
              <span
                style={{
                  fontSize: '14px',
                  color: colorLinkGrey,
                  fontWeight: '600'
                }}
              >
                {`Created by ${formData.createdBy} on ${moment(formData.creationDate).format('DD/MM/YY hh:mm A')}`}
              </span>
            </Typography>
          )}{' '}
        </Box>

        {/* <Tooltip placement="top" title="Click to edit record">
          <Button
            sx={{
              borderColor: colorPrimary,
              backgroundColor: 'transparent',
              borderRadius: '10px',
              borderWidth: '2px',
              color: colorTextBlack
            }}
            variant="outlined"
            onClick={() =>
              navigate('edit-district', {
                state: {
                  districtId: location.state?.districtId
                }
              })
            }
            size="small"
            // startIcon={<ArrowForward />}
          >
            Edit this record
          </Button>
        </Tooltip> */}
      </Box>
      {/* <CustomBreadcrumb path={`${location.pathname}`} /> */}

      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        borderRadius={'8px'}
        sx={{ paddingX: '14px', pt: 1 }}
      >
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tabs
            value={initialValues.tabValue}
            onChange={(val, newValue) =>
              setInitialValues({
                ...initialValues,
                tabValue: newValue,
                page: 0
              })
            }
            TabIndicatorProps={{
              sx: {
                height: '4px',
                borderRadius: '4px'
              }
            }}
          >
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 0 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 0
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              icon={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.25 10.7148C2.86148 11.3273 2 12.1809 2 13.125C2 14.989 5.35786 16.5 9.5 16.5C13.6421 16.5 17 14.989 17 13.125C17 12.1809 16.1385 11.3273 14.75 10.7148M14 6C14 9.04778 10.625 10.5 9.5 12.75C8.375 10.5 5 9.04778 5 6C5 3.51472 7.01472 1.5 9.5 1.5C11.9853 1.5 14 3.51472 14 6ZM10.25 6C10.25 6.41421 9.91421 6.75 9.5 6.75C9.08579 6.75 8.75 6.41421 8.75 6C8.75 5.58579 9.08579 5.25 9.5 5.25C9.91421 5.25 10.25 5.58579 10.25 6Z"
                    stroke={
                      initialValues.tabValue === 0
                        ? colorTextBlack
                        : colorTextGrey
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              label={
                <CustomTabLabel
                  title="Total Areas"
                  count={
                    (formData?.areas || 0) < 10
                      ? `0${formData?.areas || 0}`
                      : formData?.areas || 0
                  }
                  selected={initialValues.tabValue === 0}
                />
              }
              // label={`All Areas ${
              //   formData?.areas ? `(${formData?.areas})` : `(0)`
              // }`}
            />
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 1 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 1
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              icon={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.25 8.25H13.85C14.6901 8.25 15.1101 8.25 15.431 8.41349C15.7132 8.5573 15.9427 8.78677 16.0865 9.06901C16.25 9.38988 16.25 9.80992 16.25 10.65V15.75M10.25 15.75V4.65C10.25 3.80992 10.25 3.38988 10.0865 3.06901C9.9427 2.78677 9.71323 2.5573 9.43099 2.41349C9.11012 2.25 8.69008 2.25 7.85 2.25H5.15C4.30992 2.25 3.88988 2.25 3.56901 2.41349C3.28677 2.5573 3.0573 2.78677 2.91349 3.06901C2.75 3.38988 2.75 3.80992 2.75 4.65V15.75M17 15.75H2M5.375 5.25H7.625M5.375 8.25H7.625M5.375 11.25H7.625"
                    stroke={
                      initialValues.tabValue === 1
                        ? colorTextBlack
                        : colorTextGrey
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              label={
                <CustomTabLabel
                  title="Total Schools"
                  count={
                    (formData?.schools || 0) < 10
                      ? `0${formData?.schools || 0}`
                      : formData?.schools || 0
                  }
                  selected={initialValues.tabValue === 1}
                />
              }
              // label={`All Schools ${
              //   formData?.schools ? `(${formData?.schools})` : `(0)`
              // }`}
            />
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 2 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 2
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              icon={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 15.75V14.25C17 12.8521 16.0439 11.6775 14.75 11.3445M12.125 2.46807C13.2244 2.91311 14 3.99098 14 5.25C14 6.50902 13.2244 7.58689 12.125 8.03193M13.25 15.75C13.25 14.3522 13.25 13.6533 13.0216 13.1019C12.7172 12.3669 12.1331 11.7828 11.3981 11.4784C10.8467 11.25 10.1478 11.25 8.75 11.25H6.5C5.10218 11.25 4.40326 11.25 3.85195 11.4784C3.11687 11.7828 2.53284 12.3669 2.22836 13.1019C2 13.6533 2 14.3522 2 15.75M10.625 5.25C10.625 6.90685 9.28185 8.25 7.625 8.25C5.96815 8.25 4.625 6.90685 4.625 5.25C4.625 3.59315 5.96815 2.25 7.625 2.25C9.28185 2.25 10.625 3.59315 10.625 5.25Z"
                    stroke={
                      initialValues.tabValue === 2
                        ? colorTextBlack
                        : colorTextGrey
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              label={
                <CustomTabLabel
                  title="Total Users"
                  count={
                    (formData?.users || 0) < 10
                      ? `0${formData?.users || 0}`
                      : formData?.users || 0
                  }
                  selected={initialValues.tabValue === 2}
                />
              }
              // label={`All Users ${
              //   formData?.users ? `(${formData?.users})` : `(0)`
              // }`}
            />
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 3 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 3
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              icon={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.50002 16.3631C3.95195 16.5 4.56237 16.5 5.6 16.5H13.4C14.4376 16.5 15.048 16.5 15.5 16.3631M3.50002 16.3631C3.40312 16.3337 3.3135 16.2981 3.22852 16.2548C2.80516 16.039 2.46095 15.6948 2.24524 15.2715C2 14.7902 2 14.1601 2 12.9V5.1C2 3.83988 2 3.20982 2.24524 2.72852C2.46095 2.30516 2.80516 1.96095 3.22852 1.74524C3.70982 1.5 4.33988 1.5 5.6 1.5H13.4C14.6601 1.5 15.2902 1.5 15.7715 1.74524C16.1948 1.96095 16.539 2.30516 16.7548 2.72852C17 3.20982 17 3.83988 17 5.1V12.9C17 14.1601 17 14.7902 16.7548 15.2715C16.539 15.6948 16.1948 16.039 15.7715 16.2548C15.6865 16.2981 15.5969 16.3337 15.5 16.3631M3.50002 16.3631C3.50026 15.7561 3.5039 15.4349 3.55764 15.1647C3.79436 13.9747 4.72466 13.0444 5.91473 12.8076C6.20453 12.75 6.55302 12.75 7.25 12.75H11.75C12.447 12.75 12.7955 12.75 13.0853 12.8076C14.2753 13.0444 15.2056 13.9747 15.4424 15.1647C15.4961 15.4349 15.4997 15.7561 15.5 16.3631M12.5 7.125C12.5 8.78185 11.1569 10.125 9.5 10.125C7.84315 10.125 6.5 8.78185 6.5 7.125C6.5 5.46815 7.84315 4.125 9.5 4.125C11.1569 4.125 12.5 5.46815 12.5 7.125Z"
                    stroke={
                      initialValues.tabValue === 3
                        ? colorTextBlack
                        : colorTextGrey
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              label={
                <CustomTabLabel
                  title="Employee list"
                  count={
                    (formData?.employee || 0) < 10
                      ? `0${formData?.employee || 0}`
                      : formData?.employee || 0
                  }
                  selected={initialValues.tabValue === 3}
                />
              }
              // label={`All Users ${
              //   formData?.users ? `(${formData?.users})` : `(0)`
              // }`}
            />
          </Tabs>
        </Grid>
        <Grid sx={{ padding: '18px 0px' }} width={'100%'}>
          {isDistrictDetailsLoading && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}

          {!isDistrictDetailsLoading && isDistrictDetailsSuccess && (
            <CustomDataTable
              allowHover={false}
              allowSelection={false}
              columns={
                initialValues.tabValue === 0
                  ? areaColumns
                  : initialValues.tabValue === 1
                    ? schoolColumns
                    : initialValues.tabValue === 2
                      ? userColumns
                      : initialValues.tabValue === 3
                        ? employeeColumns
                        : []
              }
              defaultOrderBy={'createdDate'}
              data={
                initialValues.tabValue === 0
                  ? districtOtherDetails['dataListOfAreas'] &&
                    districtOtherDetails['dataListOfAreas'].length > 0
                    ? districtOtherDetails['dataListOfAreas']
                    : []
                  : initialValues.tabValue === 1
                    ? districtOtherDetails['dataListOfSchools'] &&
                      districtOtherDetails['dataListOfSchools'].length > 0
                      ? districtOtherDetails['dataListOfSchools']
                      : []
                    : initialValues.tabValue === 3
                      ? districtOtherDetails['dataListOfEmployees'] &&
                        districtOtherDetails['dataListOfEmployees'].length > 0
                        ? districtOtherDetails['dataListOfEmployees']
                        : []
                      : initialValues.tabValue === 2
                        ? districtOtherDetails['dataListOfUsers'] &&
                          districtOtherDetails['dataListOfUsers'].length > 0
                          ? districtOtherDetails['dataListOfUsers']
                          : []
                        : initialValues.tabValue === 3
                          ? districtOtherDetails['dataListOfEmployees'] &&
                            districtOtherDetails['dataListOfEmployees'].length >
                              0
                            ? districtOtherDetails['dataListOfEmployees']
                            : []
                          : []
              }
              onPageChange={val => {
                setInitialValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitialValues({ ...initialValues, size: val });
              }}
              defaultPage={initialValues.page}
              defaultRowsPerPage={initialValues.size}
              totalCount={
                initialValues.tabValue === 0
                  ? districtDetails.areas || 0
                  : initialValues.tabValue === 1
                    ? districtDetails.schools || 0
                    : initialValues.tabValue === 2
                      ? districtDetails.users || 0
                      : initialValues.tabValue === 3
                        ? districtDetails.employee || 0
                        : 0
              }
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default DistrictDetails;
