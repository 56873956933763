const tokenKey = 'x-auth-token';
import Cookies from 'js-cookie';

const ACTIVE_MENU = 'ACTIVE_MENU';
const OPEN_MENU = 'OPEN_MENU';
const BASIC_DETAILS_OBJ = 'basicDetails';

export const setToken = token => {
  // const expirationTime = new Date();
  // expirationTime.setTime(expirationTime.getTime() + 60 * 60 * 1000);
  try {
    if (!token || typeof token !== 'string') {
      throw new Error('Invalid token format');
    }
    Cookies.set(tokenKey, token, {
      // secure: process.env.NODE_ENV === 'production',
      // expires: expirationTime,
      // httpOnly: true,
    });
  } catch (error) {
    throw Error(`Failed to set token: ${error}`);
  }
};

export const getToken = () => {
  try {
    const token = Cookies.get(tokenKey);
    return token ? token : null;
  } catch (error) {
    throw Error(`Failed to get token: ${error}`);
  }
};

export const clearToken = () => {
  try {
    Cookies.remove(tokenKey);
  } catch (error) {
    throw Error(`Failed clear the token: ${error}`);
  }
};

export const setAuthData = authData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(tokenKey, JSON.stringify(authData));
    }
  } catch (error) {
    throw Error(`Failed to set authdata: ${error}`);
  }
};
export const getAuthData = () => {
  try {
    if (sessionStorage[tokenKey]) {
      return JSON.parse(sessionStorage.getItem(tokenKey));
    }
  } catch (error) {
    throw Error(`Failed to get authdata: ${error}`);
  }
};
export const clearAuthData = () => {
  try {
    if (sessionStorage[tokenKey]) {
      return sessionStorage.removeItem(tokenKey);
    }
  } catch (error) {
    throw Error(`Failed to remove authdata: ${error}`);
  }
};

export const setMenuData = menuData => {
  try {
    if (localStorage) {
      localStorage.setItem('menus', JSON.stringify(menuData));
    }
  } catch (error) {
    throw Error(`Failed to set menus data: ${error}`);
  }
};
export const getMenuData = () => {
  try {
    if (localStorage['menus']) {
      return JSON.parse(localStorage.getItem('menus'));
    }
  } catch (error) {
    throw Error(`Failed to get menus data: ${error}`);
  }
};
export const clearMenuData = () => {
  try {
    if (localStorage['menus']) {
      return localStorage.removeItem('menus');
    }
  } catch (error) {
    throw Error(`Failed to remove menus data: ${error}`);
  }
};

export const setMenuArray = menuArray => {
  try {
    if (localStorage) {
      const pageNamesArray = [];

      for (const category in menuArray) {
        if (menuArray[category] && menuArray[category].length > 0) {
          menuArray[category].forEach(page => {
            pageNamesArray.push(page.pageName);
          });
        } else {
          pageNamesArray.push(category);
        }
      }
      localStorage.setItem('menusArray', JSON.stringify(pageNamesArray));
    }
  } catch (error) {
    throw new Error(`Failed to set menus data: ${error}`);
  }
};

export const getMenuArray = () => {
  try {
    if (localStorage['menusArray']) {
      return JSON.parse(localStorage.getItem('menusArray'));
    }
  } catch (error) {
    throw Error(`Failed to get menus data: ${error}`);
  }
};
export const clearMenuArray = () => {
  try {
    if (localStorage['menusArray']) {
      return localStorage.removeItem('menusArray');
    }
  } catch (error) {
    throw Error(`Failed to remove menus data: ${error}`);
  }
};

export const setActiveMenuData = menuData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(ACTIVE_MENU, menuData);
    }
  } catch (error) {
    throw Error(`Failed to set ACTIVE_MENU: ${error}`);
  }
};
export const getActiveMenuData = () => {
  try {
    if (sessionStorage[ACTIVE_MENU]) {
      return sessionStorage.getItem(ACTIVE_MENU);
    }
  } catch (error) {
    throw Error(`Failed to get ACTIVE_MENU: ${error}`);
  }
};

export const clearActiveMenuData = () => {
  try {
    sessionStorage.removeItem(ACTIVE_MENU);
  } catch (error) {
    throw Error(`Failed clear the token: ${error}`);
  }
};

export const setOpenMenuData = menuData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(
        OPEN_MENU,
        JSON.stringify(menuData ? menuData : {})
      );
    }
  } catch (error) {
    throw Error(`Failed to set OPEN_MENU: ${error}`);
  }
};
export const getOpenMenuData = () => {
  try {
    if (sessionStorage[OPEN_MENU]) {
      return JSON.parse(sessionStorage.getItem(OPEN_MENU));
    }
  } catch (error) {
    throw Error(`Failed to get OPEN_MENU: ${error}`);
  }
};

export const clearOpenMenuData = () => {
  try {
    sessionStorage.removeItem(OPEN_MENU);
  } catch (error) {
    throw Error(`Failed clear the token: ${error}`);
  }
};

export const setBasicDetails = (profileImg, name) => {
  try {
    let basicDetailsObj = JSON.stringify({
      profileImg: profileImg,
      name: name
    });
    sessionStorage.setItem('basicDetails', basicDetailsObj);
  } catch (error) {
    throw Error(`Failed to set basic details: ${error}`);
  }
};

export const getBasicDetails = () => {
  try {
    if (sessionStorage[BASIC_DETAILS_OBJ]) {
      return sessionStorage.getItem(BASIC_DETAILS_OBJ);
    }
  } catch (error) {
    throw Error(`Failed to get basic details: ${error}`);
  }
};

export const clearBasicDetails = () => {
  try {
    sessionStorage.removeItem(BASIC_DETAILS_OBJ);
  } catch (error) {
    throw Error(`Failed clear the token: ${error}`);
  }
};
