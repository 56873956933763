import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';

const initial = {
  isRubricsLoading: false,
  isRubricsSuccess: false,
  isRubricsFailed: false,
  rubricsData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    rubrics: []
  },
  rubricsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isActivateDeactivateRubricsLoading: false,
  isActivateDeactivateRubricsSuccess: false,
  isActivateDeactivateRubricsFailed: false,
  activateDeactivateRubricsResponse: '',
  activateDeactivateRubricsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAssignRubricLoading: false,
  isAssignRubricSuccess: false,
  isAssignRubricFailed: false,
  assignRubricResponse: '',
  assignRubricErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isReAssignRubricLoading: false,
  isReAssignRubricSuccess: false,
  isReAssignRubricFailed: false,
  reAssignRubricResponse: '',
  reAssignRubricErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeleteRubricsLoading: false,
  isDeleteRubricsSuccess: false,
  isDeleteRubricsFailed: false,
  deleteRubricsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDomainsLoading: false,
  isDomainsSuccess: false,
  isDomainsFail: false,
  domains: [],
  domainsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isTagsLoading: false,
  isTagsSuccess: false,
  isTagsFail: false,
  tags: [],
  tagsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isIndicatorsLoading: false,
  isIndicatorsSuccess: false,
  isIndicatorsFail: false,
  indicatorsList: [],
  indicatorsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateRubricLoading: false,
  isCreateUpdateRubricSuccess: false,
  isCreateUpdateRubricFail: false,
  createUpdateRubricResponse: null,
  createUpdateRubricErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  rubricsByIdLoading: false,
  rubricsByIdSuccess: false,
  rubricsByIdFailed: false,
  rubricsByIdData: null,
  rubricsByIdDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getRubrics = createAsyncThunk(
  'rubrics/getRubrics',
  async (rubricsPayload, { rejectWithValue }) => {
    try {
      let response;
      if (rubricsPayload?.search == '' || rubricsPayload?.search == null) {
        response = await api.post(`rubrics/all`, rubricsPayload);
      } else {
        response = await api.post(
          `rubrics/search?searchCriteria=${rubricsPayload?.search}`,
          rubricsPayload
        );
      }
      // console.log('rubrics all', response?.data?.payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getAllDomains = createAsyncThunk(
  'rubrics/getAllDomains',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.get('metadata/domains');
      return response.data?.payload || initial.domains;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getAllTags = createAsyncThunk(
  'rubrics/getAllTags',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.get('metadata/tags');
      return response.data?.payload || initial.domains;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getAllIndicators = createAsyncThunk(
  'rubrics/getAllIndicators',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.get(
        'rubrics/indicatorsByNameSearch?searchCriteria='
      );
      return response.data?.payload || initial.domains;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const activateDeactivateRubrics = createAsyncThunk(
  'rubrics/activateDeactivateRubrics',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`rubrics/activate`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteRubrics = createAsyncThunk(
  'rubrics/deleteRubrics',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.delete(`rubrics`, {
        data: payload
      });
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUpdateRubric = createAsyncThunk(
  'rubrics/createUpdateRubric',
  async ({ rubricId, payload }, { rejectWithValue }) => {
    try {
      let response;

      if (rubricId) {
        response = await api.put(`rubrics/${rubricId}`, payload);
      } else {
        response = await api.post(`rubrics`, payload);
      }

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const rubricsDataById = createAsyncThunk(
  'rubrics/rubricsDataById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`rubrics/${id}`);
      return response.data?.payload?.dataList || initial.rubricsByIdData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const assignRubric = createAsyncThunk(
  'rubrics/assignRubric',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`rubrics/assign`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const reAssignRubric = createAsyncThunk(
  'rubrics/reAssignRubric',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`rubrics/reassign`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getRubricsCount = createAsyncThunk(
  'rubrics/getRubricsCount',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.post(`rubrics/all`, {
        type: 'all',
        page: 0,
        size: 10
      });

      // console.log('rubrics all', response?.data?.payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const revertRubricPageData = createAction('REVERT_RUBRIC_PAGE_DATA');
export const revertRubricFormData = createAction('REVERT_RUBRIC_FORM_DATA');
export const revertRubricDetailData = createAction('REVERT_RUBRIC_DETAIL_DATA');

export const rubricSlice = createSlice({
  name: 'rubrics',
  initialState: initial,
  extraReducers: builder => {
    builder
      .addCase(revertRubricFormData, state => {
        state.isDomainsLoading = false;
        state.isDomainsSuccess = false;
        state.isDomainsFail = false;
        state.domains = initial.domains;
        state.domainsErrorContainer = initial.domainsErrorContainer;

        state.isTagsLoading = false;
        state.isTagsSuccess = false;
        state.isTagsFail = false;
        state.tags = initial.tags;
        state.tagsErrorContainer = initial.tagsErrorContainer;

        state.isCreateUpdateRubricLoading = false;
        state.isCreateUpdateRubricSuccess = false;
        state.isCreateUpdateRubricFail = false;
        state.createUpdateRubricResponse = null;
        state.createUpdateRubricErrorContainer =
          initial.createUpdateRubricErrorContainer;

        state.rubricsByIdData = initial.rubricsByIdData;
      })
      .addCase(revertRubricPageData, state => {
        state.isDeleteRubricsLoading = false;
        state.isDeleteRubricsSuccess = false;
        state.isDeleteRubricsFailed = false;
        state.isRubricsLoading = false;
        state.isRubricsFailed = false;
        state.isRubricsSuccess = false;
        state.isActivateDeactivateRubricsLoading = false;
        state.isActivateDeactivateRubricsSuccess = false;
        state.isActivateDeactivateRubricsFailed = false;
        state.activateDeactivateRubricsResponse = '';
        state.activateDeactivateRubricsResponse = {
          ...initial.activateDeactivateRubricsErrorContainer
        };
      })
      .addCase(revertRubricDetailData, state => {
        state.isAssignRubricLoading = false;
        state.isAssignRubricFailed = false;
        state.isAssignRubricSuccess = false;
        state.assignRubricResponse = '';
        state.isReAssignRubricFailed = false;
        state.isReAssignRubricLoading = false;
        state.isReAssignRubricSuccess = false;
        state.reAssignRubricResponse = '';
      })

      .addCase(getRubrics.pending, state => {
        state.isRubricsLoading = true;
        state.isRubricsSuccess = false;
        state.isRubricsFailed = false;
        state.rubricsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getRubrics.fulfilled, (state, action) => {
        state.isRubricsLoading = false;
        state.isRubricsSuccess = true;
        state.isRubricsFailed = false;
        state.rubricsData = {
          ...state.rubricsData,
          rubrics: action.payload?.dataList || [],
          totalCount: action.payload?.totalCount || 0
        };
      })
      .addCase(getRubrics.rejected, (state, action) => {
        state.isRubricsLoading = false;
        state.isRubricsSuccess = false;
        state.isRubricsFailed = true;
        state.rubrics = initial.rubricsData;
        state.rubricsErrorContainer = {
          ...state.rubricsErrorContainer,
          ...action.payload
        };
      })

      .addCase(activateDeactivateRubrics.pending, state => {
        state.isActivateDeactivateRubricsLoading = true;
        state.isActivateDeactivateRubricsSuccess = false;
        state.isActivateDeactivateRubricsFailed = false;
        state.activateDeactivateRubricsResponse = '';
        state.activateDeactivateRubricsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(activateDeactivateRubrics.fulfilled, (state, action) => {
        state.isActivateDeactivateRubricsLoading = false;
        state.isActivateDeactivateRubricsSuccess = true;
        state.isActivateDeactivateRubricsFailed = false;
        state.activateDeactivateRubricsResponse = action.payload?.message;
      })
      .addCase(activateDeactivateRubrics.rejected, (state, action) => {
        state.isActivateDeactivateRubricsLoading = false;
        state.isActivateDeactivateRubricsSuccess = false;
        state.isActivateDeactivateRubricsFailed = true;
        state.activateDeactivateRubricsErrorContainer = {
          ...state.activateDeactivateRubricsErrorContainer,
          ...action.payload
        };
      })

      .addCase(assignRubric.pending, state => {
        state.isAssignRubricLoading = true;
        state.isAssignRubricSuccess = false;
        state.isAssignRubricFailed = false;
        state.assignRubricResponse = '';
        state.assignRubricErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(assignRubric.fulfilled, (state, action) => {
        state.isAssignRubricLoading = false;
        state.isAssignRubricSuccess = true;
        state.isAssignRubricFailed = false;
        state.assignRubricResponse = action.payload?.message;
      })
      .addCase(assignRubric.rejected, (state, action) => {
        state.isAssignRubricLoading = false;
        state.isAssignRubricSuccess = false;
        state.isAssignRubricFailed = true;
        state.assignRubricErrorContainer = {
          ...state.assignRubricErrorContainer,
          ...action.payload
        };
      })

      .addCase(reAssignRubric.pending, state => {
        state.isReAssignRubricLoading = true;
        state.isReAssignRubricSuccess = false;
        state.isReAssignRubricFailed = false;
        state.reAssignRubricResponse = '';
        state.reAssignRubricErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(reAssignRubric.fulfilled, (state, action) => {
        state.isReAssignRubricLoading = false;
        state.isReAssignRubricSuccess = true;
        state.isReAssignRubricFailed = false;
        state.reAssignRubricResponse = action.payload?.message;
      })
      .addCase(reAssignRubric.rejected, (state, action) => {
        state.isReAssignRubricLoading = false;
        state.isReAssignRubricSuccess = false;
        state.isReAssignRubricFailed = true;
        state.reAssignRubricErrorContainer = {
          ...state.reAssignRubricErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteRubrics.pending, state => {
        state.isDeleteRubricsLoading = true;
        state.isDeleteRubricsSuccess = false;
        state.isDeleteRubricsFailed = false;
        state.deleteRubricsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteRubrics.fulfilled, state => {
        state.isDeleteRubricsLoading = false;
        state.isDeleteRubricsSuccess = true;
        state.isDeleteRubricsFailed = false;
      })
      .addCase(deleteRubrics.rejected, (state, action) => {
        state.isDeleteRubricsLoading = false;
        state.isDeleteRubricsSuccess = false;
        state.isDeleteRubricsFailed = true;
        state.deleteRubricsErrorContainer = {
          ...state.deleteRubricsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getAllDomains.pending, state => {
        state.isDomainsLoading = true;
        state.isDomainsSuccess = false;
        state.isDomainsFail = false;
        state.domains = initial.domains;
      })
      .addCase(getAllDomains.fulfilled, (state, action) => {
        state.isMenuDataLoading = false;
        state.isDomainsSuccess = true;
        state.isDomainsFail = false;
        state.domains = action.payload;
      })
      .addCase(getAllDomains.rejected, (state, action) => {
        state.isDomainsLoading = false;
        state.isDomainsFail = true;
        state.isDomainsSuccess = false;
        state.domainsErrorContainer = {
          ...state.domainsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getAllTags.pending, state => {
        state.isTagsLoading = true;
        state.isTagsSuccess = false;
        state.isTagsFail = false;
        state.tags = initial.domains;
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.isTagsLoading = false;
        state.isTagsSuccess = true;
        state.isTagsFail = false;
        state.tags = action.payload;
      })
      .addCase(getAllTags.rejected, (state, action) => {
        state.isTagsLoading = false;
        state.isTagsFail = true;
        state.isTagsSuccess = false;
        state.tagsErrorContainer = {
          ...state.tagsErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateRubric.pending, state => {
        state.isCreateUpdateRubricLoading = true;
        state.isCreateUpdateRubricSuccess = false;
        state.isCreateUpdateRubricFail = false;
        state.createUpdateRubricErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateRubric.fulfilled, (state, action) => {
        state.isCreateUpdateRubricLoading = false;
        state.isCreateUpdateRubricSuccess = true;
        state.isCreateUpdateRubricFail = false;
        state.createUpdateRubricResponse = action.payload?.message;
      })
      .addCase(createUpdateRubric.rejected, (state, action) => {
        state.isCreateUpdateRubricLoading = false;
        state.isCreateUpdateRubricSuccess = false;
        state.isCreateUpdateRubricFail = true;
        state.createUpdateRubricErrorContainer = {
          ...state.createUpdateRubricErrorContainer,
          ...action.payload
        };
      })

      .addCase(rubricsDataById.pending, state => {
        state.rubricsByIdLoading = true;
        state.rubricsByIdSuccess = false;
        state.rubricsByIdFailed = false;
        state.rubricsByIdData = initial.rubricsByIdData;
      })
      .addCase(rubricsDataById.fulfilled, (state, action) => {
        state.rubricsByIdLoading = false;
        state.rubricsByIdSuccess = true;
        state.rubricsByIdFailed = false;
        state.rubricsByIdData = action.payload;
      })
      .addCase(rubricsDataById.rejected, (state, action) => {
        state.rubricsByIdLoading = false;
        state.rubricsByIdFailed = true;
        state.rubricsByIdDataErrorContainer = {
          ...state.rubricsByIdDataErrorContainer,
          ...action.payload
        };
      })

      .addCase(getAllIndicators.pending, state => {
        state.isIndicatorsLoading = true;
        state.isIndicatorsSuccess = false;
        state.isIndicatorsFail = false;
        state.indicatorsList = initial.indicatorsList;
      })
      .addCase(getAllIndicators.fulfilled, (state, action) => {
        state.isIndicatorsLoading = false;
        state.isIndicatorsSuccess = true;
        state.isIndicatorsFail = false;
        state.indicatorsList = action.payload?.dataList || [];
      })
      .addCase(getAllIndicators.rejected, (state, action) => {
        state.isIndicatorsLoading = false;
        state.isIndicatorsSuccess = false;
        state.isIndicatorsFail = true;
        state.indicatorsList = [];
        state.indicatorsErrorContainer = {
          ...state.indicatorsErrorContainer,
          ...action.payload
        };
      })
      .addCase(getRubricsCount.pending, state => {
        state.rubricsData.allCount = 0;
        state.rubricsData.activeCount = 0;
        state.rubricsData.inActiveCount = 0;
      })
      .addCase(getRubricsCount.fulfilled, (state, action) => {
        // console.log(action.payload, 'payload');
        state.rubricsData = {
          ...state.rubricsData,
          allCount: action.payload?.allCount,
          activeCount: action.payload?.activeCount,
          inActiveCount: action.payload?.inActiveCount
        };
      })
      .addCase(getRubricsCount.rejected, (state, action) => {
        state.rubricsData.allCount = 0;
        state.rubricsData.activeCount = 0;
        state.rubricsData.inActiveCount = 0;
      });
  }
});

export default rubricSlice.reducer;
