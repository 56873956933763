import { Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import UserAndRoleAnalytics from '../containers/productanalytics/UserAndRoleAnalytics';
import LocationAnalytics from '../containers/productanalytics/LocationAnalytics';
import FlowsAndFormAnalytics from '../containers/productanalytics/FlowsAndFormAnalytics';
import TeacherObservationAnalytics from '../containers/productanalytics/TeacherObservationAnalytics';
import { useDispatch } from 'react-redux';
import {
  getMetaDataArea,
  getMetaDataDistrict,
  getMetaDataSchool,
  getMetaDataState
} from '../redux/reducers/analyticsSlice';

export const analyticsTypeHeaderContent = {
  user: 'User and Role Analytics',
  location: 'Location Analytics',
  observation: 'Teacher Observation Analytics',
  flows: 'Flow & Form Analytics'
};

const ProductAnalysis = ({ type = '' }) => {
  const dispatch = useDispatch();
  const analyticsTypeComponent = {
    user: <UserAndRoleAnalytics />,
    location: <LocationAnalytics />,
    flows: <FlowsAndFormAnalytics />,
    observation: <TeacherObservationAnalytics />
  };

  useEffect(() => {
    let mastersPayload = {
      type: 'all',
      page: 0,
      size: 0,
      search: ''
    };
    dispatch(getMetaDataState(mastersPayload));
    dispatch(getMetaDataDistrict(mastersPayload));
    dispatch(getMetaDataArea(mastersPayload));
    dispatch(getMetaDataSchool(mastersPayload));

    return () => {};
  }, []);

  return <>{analyticsTypeComponent[type]}</>;
};

export default ProductAnalysis;
