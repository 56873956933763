import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';
import { downloadFileCSVPostApi } from '../../utils/utils';

export const revertAllMyAccountDetails = createAction('REVERT_MY_ACCOUNT_DATA');

export const revertAllUserGroupsData = createAction('REVERT_USER_GROUPS_DATA');

const intial = {
  isUserGroupsListLoading: false,
  isUserGroupsListSuccess: false,
  isUserGroupsListFailed: false,
  userGroupsData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    userGroupsList: []
  },
  userGroupsListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isCreateUserGroupsLoading: false,
  isCreateUserGroupsSuccess: false,
  isCreateUserGroupsFailed: false,
  createUserGroupsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isUpdateUserGroupsLoading: false,
  isUpdateUserGroupsSuccess: false,
  isUpdateUserGroupsFailed: false,
  updateUserGroupsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUploadUserGroupsLoading: false,
  isUploadUserGroupsSuccess: false,
  isUploadUserGroupsFailed: false,
  uploadUserGroupsMessage: '',
  uploadUserGroupsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDownloadUserGroupsLoading: false,
  isDownloadUserGroupsSuccess: false,
  isDownloadUserGroupsFailed: false,
  downloadUserGroupsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isActivateUserGroupsLoading: false,
  isActivateUserGroupsSuccess: false,
  isActivateUserGroupsFailed: false,
  activateUserGroupsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeActivateUserGroupsLoading: false,
  isDeActivateUserGroupsSuccess: false,
  isDeActivateUserGroupsFailed: false,
  deactivateUserGroupsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeleteUserGroupsLoading: false,
  isDeleteUserGroupsSuccess: false,
  isDeleteUserGroupsFailed: false,
  deleteUserGroupsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  usersOfGroupData: { totalCount: 0, usersOfGroupList: [] },
  isUsersOfGroupsLoading: false,
  isUsersOfGroupsSuccess: false,
  isUsersOfGroupsFailed: false,
  usersOfGroupsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDeleteUsersOfGroupsLoading: false,
  isDeleteUsersOfGroupsSuccess: false,
  isDeleteUsersOfGroupsFailed: false,
  deleteUsersOfGroupsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getUserGroupsList = createAsyncThunk(
  'userGroups/getUserGroupsList',
  async ({ payload, search }, { rejectWithValue }) => {
    try {
      let response;

      if (search == '' || search == null) {
        response = await api.post(`userGroups/all`, payload);
      } else {
        response = await api.post(
          `userGroups/search?searchCriteria=${search}`,
          payload
        );
      }

      // return response.data?.payload || [];
      return response.data?.payload || intial.userGroupsList;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUserGroups = createAsyncThunk(
  'userGroups/createUserGroups',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.post(`userGroups`, payload);

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const updateUserGroups = createAsyncThunk(
  'userGroups/updateUserGroups',
  async ({ updatePayload }, { rejectWithValue }) => {
    try {
      const response = await api.put(`userGroups`, updatePayload);

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const activateUserGroups = createAsyncThunk(
  'userGroups/activateUserGroups',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`userGroups/activate`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deactivateUserGroups = createAsyncThunk(
  'userGroups/deactivateUserGroups',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`userGroups/activate`, payload);

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteUserGroups = createAsyncThunk(
  'userGroups/deleteUserGroups',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.delete('userGroups', { data: payload });
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteUsersOfGroup = createAsyncThunk(
  'userGroups/deleteUsersOfGroup',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put('userGroups', payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const uploadUserGroupsCSV = createAsyncThunk(
  'userGroups/uploadUserGroupsCSV',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(`upload/userGroups`, payload);
      console.log(response, 'uploadUserGroupsCSV');
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadUserGroupsCSV = createAsyncThunk(
  'userGroups/downloadUserGroupsData',
  async ({ fileName, body }, { rejectWithValue }) => {
    try {
      return await downloadFileCSVPostApi({
        fileName: fileName,
        apiPath: 'download/userGroups',
        body: body
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getUsersOfGroup = createAsyncThunk(
  'userGroups/getUsersOfGroup',
  async ({ groupId, payload }, { rejectWithValue }) => {
    try {
      const response = await api.post(`userGroups/${groupId}`, payload);

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const reverUserGroupFormData = createAction(
  'REVERT_USER_GROUP_FORM_DATA'
);

export const getUserGroupsCount = createAsyncThunk(
  'userGroups/getUserGroupsCount',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.post(`userGroups/all`, {
        type: 'all',
        page: 0,
        size: 10
      });

      // return response.data?.payload || [];
      return response.data?.payload || intial.userGroupsList;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const userGroupsSlice = createSlice({
  name: 'userGroups',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(revertAllUserGroupsData, state => {
        state.isUserGroupsListLoading = false;
        state.isUserGroupsListSuccess = false;
        state.isUserGroupsListFailed = false;
        state.userGroupsListErrorContainer =
          intial.userGroupsListErrorContainer;

        state.isCreateUserGroupsLoading = false;
        state.isCreateUserGroupsSuccess = false;
        state.isCreateUserGroupsFailed = false;
        state.createUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isUpdateUserGroupsLoading = false;
        state.isUpdateUserGroupsSuccess = false;
        state.isUpdateUserGroupsFailed = false;
        state.createUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isActivateUserGroupsLoading = false;
        state.isActivateUserGroupsSuccess = false;
        state.isActivateUserGroupsFailed = false;
        state.activateUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isDeActivateUserGroupsLoading = false;
        state.isDeActivateUserGroupsSuccess = false;
        state.isDeActivateUserGroupsFailed = false;
        state.deactivateUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isDeleteUserGroupsLoading = false;
        state.isDeleteUserGroupsSuccess = false;
        state.isDeleteUserGroupsFailed = false;
        state.deleteUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isUploadUserGroupsLoading = false;
        state.isUploadUserGroupsSuccess = false;
        state.isUploadUserGroupsFailed = false;
        state.uploadUserGroupsMessage = '';
        state.uploadUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isDownloadUserGroupsLoading = false;
        state.isDownloadUserGroupsSuccess = false;
        state.isDownloadUserGroupsFailed = false;
        state.downloadUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isDeleteUsersOfGroupsLoading = false;
        state.isDeleteUsersOfGroupsSuccess = false;
        state.isDeleteUsersOfGroupsFailed = false;
        state.deleteUsersOfGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(reverUserGroupFormData, state => {
        state.isUsersOfGroupsLoading = false;
        state.isUsersOfGroupsSuccess = false;
        state.isUsersOfGroupsFailed = false;
        state.usersOfGroupData = intial.usersOfGroupData;
        state.usersOfGroupsErrorContainer = intial.usersOfGroupsErrorContainer;
      })
      .addCase(getUserGroupsList.pending, state => {
        state.isUserGroupsListLoading = true;
        state.isUserGroupsListSuccess = false;
        state.isUserGroupsListFailed = false;
        state.userGroupsListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getUserGroupsList.fulfilled, (state, action) => {
        state.isUserGroupsListLoading = false;
        state.isUserGroupsListSuccess = true;
        state.userGroupsData = {
          ...state.userGroupsData,
          userGroupsList: action.payload.dataList,
          totalCount: action.payload.totalCount
        };
      })
      .addCase(getUserGroupsList.rejected, (state, action) => {
        state.isUserGroupsListLoading = false;
        state.isUserGroupsListFailed = true;
        state.userGroupsList = [];
        state.userGroupsListErrorContainer = {
          ...state.errorContainer,
          ...action.payload
        };
      })

      .addCase(createUserGroups.pending, state => {
        state.isCreateUserGroupsLoading = true;
        state.isCreateUserGroupsSuccess = false;
        state.isCreateUserGroupsFailed = false;
        state.createUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUserGroups.fulfilled, state => {
        state.isCreateUserGroupsLoading = false;
        state.isCreateUserGroupsSuccess = true;
        state.isCreateUserGroupsFailed = false;
      })
      .addCase(createUserGroups.rejected, (state, action) => {
        console.log(action.payload, 'asdasda');
        state.isCreateUserGroupsLoading = false;
        state.isCreateUserGroupsSuccess = false;
        state.isCreateUserGroupsFailed = true;
        state.createUserGroupsErrorContainer = {
          ...state.createUserGroupsErrorContainer,
          ...action.payload
        };
      })

      .addCase(updateUserGroups.pending, state => {
        state.isUpdateUserGroupsLoading = true;
        state.isUpdateUserGroupsSuccess = false;
        state.isUpdateUserGroupsFailed = false;
        state.createUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(updateUserGroups.fulfilled, state => {
        state.isUpdateUserGroupsLoading = false;
        state.isUpdateUserGroupsSuccess = true;
        state.isUpdateUserGroupsFailed = false;
      })
      .addCase(updateUserGroups.rejected, (state, action) => {
        state.isUpdateUserGroupsLoading = false;
        state.isUpdateUserGroupsSuccess = false;
        state.isUpdateUserGroupsFailed = true;
        state.updateUserGroupsErrorContainer = {
          ...state.updateUserGroupsErrorContainer,
          ...action.payload
        };
      })

      .addCase(activateUserGroups.pending, state => {
        state.isActivateUserGroupsLoading = true;
        state.isActivateUserGroupsSuccess = false;
        state.isActivateUserGroupsFailed = false;
        state.activateUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(activateUserGroups.fulfilled, state => {
        state.isActivateUserGroupsLoading = false;
        state.isActivateUserGroupsFailed = false;
        state.isActivateUserGroupsSuccess = true;
      })
      .addCase(activateUserGroups.rejected, (state, action) => {
        state.isActivateUserGroupsLoading = false;
        state.isActivateUserGroupsSuccess = false;
        state.isActivateUserGroupsFailed = true;
        state.activateUserGroupsErrorContainer = {
          ...state.activateUserGroupsErrorContainer,
          ...action.payload
        };
      })

      .addCase(deactivateUserGroups.pending, state => {
        state.isDeActivateUserGroupsLoading = true;
        state.isDeActivateUserGroupsSuccess = false;
        state.isDeActivateUserGroupsFailed = false;
        state.deactivateUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deactivateUserGroups.fulfilled, state => {
        state.isDeActivateUserGroupsLoading = false;
        state.isDeActivateUserGroupsFailed = false;
        state.isDeActivateUserGroupsSuccess = true;
      })
      .addCase(deactivateUserGroups.rejected, (state, action) => {
        state.isDeActivateUserGroupsLoading = false;
        state.isDeActivateUserGroupsSuccess = false;
        state.isDeActivateUserGroupsFailed = true;
        state.deactivateUserGroupsErrorContainer = {
          ...state.deactivateUserErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteUserGroups.pending, state => {
        state.isDeleteUserGroupsLoading = true;
        state.isDeleteUserGroupsSuccess = false;
        state.isDeleteUserGroupsFailed = false;
        state.deleteUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteUserGroups.fulfilled, state => {
        state.isDeleteUserGroupsLoading = false;
        state.isDeleteUserGroupsFailed = false;
        state.isDeleteUserGroupsSuccess = true;
      })
      .addCase(deleteUserGroups.rejected, (state, action) => {
        state.isDeleteUserGroupsLoading = false;
        state.isDeleteUserGroupsSuccess = false;
        state.isDeleteUserGroupsFailed = true;
        state.deleteUserGroupsErrorContainer = {
          ...state.deleteUserGroupsErrorContainer,
          ...action.payload
        };
      })

      .addCase(uploadUserGroupsCSV.pending, state => {
        state.isUploadUserGroupsLoading = true;
        state.isUploadUserGroupsSuccess = false;
        state.isUploadUserGroupsFailed = false;
        state.uploadUserGroupsMessage = null;
        state.uploadUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(uploadUserGroupsCSV.fulfilled, (state, action) => {
        state.isUploadUserGroupsLoading = false;
        state.isUploadUserGroupsFailed = false;
        state.uploadUserGroupsMessage = action.payload?.message;
        state.isUploadUserGroupsSuccess = true;
      })
      .addCase(uploadUserGroupsCSV.rejected, (state, action) => {
        state.isUploadUserGroupsLoading = false;
        state.isUploadUserGroupsSuccess = false;
        state.isUploadUserGroupsFailed = true;
        state.uploadUserGroupsErrorContainer = {
          ...state.uploadUserGroupsErrorContainer,
          ...action.payload
        };
      })

      .addCase(downloadUserGroupsCSV.pending, state => {
        state.isDownloadUserGroupsLoading = true;
        state.isDownloadUserGroupsSuccess = false;
        state.isDownloadUserGroupsFailed = false;
        state.downloadUserGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(downloadUserGroupsCSV.fulfilled, state => {
        state.isDownloadUserGroupsLoading = false;
        state.isDownloadUserGroupsFailed = false;
        state.isDownloadUserGroupsSuccess = true;
      })
      .addCase(downloadUserGroupsCSV.rejected, (state, action) => {
        state.isDownloadUserGroupsLoading = false;
        state.isDownloadUserGroupsSuccess = false;
        state.isDownloadUserGroupsFailed = true;
        state.downloadUserGroupsErrorContainer = {
          ...state.downloadUserGroupsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getUsersOfGroup.pending, state => {
        state.isUsersOfGroupsLoading = true;
        state.isUsersOfGroupsSuccess = false;
        state.isUsersOfGroupsFailed = false;
        state.usersOfGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(getUsersOfGroup.fulfilled, (state, action) => {
        state.isUsersOfGroupsLoading = false;
        state.isUsersOfGroupsFailed = false;
        state.isUsersOfGroupsSuccess = true;
        state.usersOfGroupData = {
          totalCount: action.payload.totalCount,
          usersOfGroupList: action.payload.dataList
        };
      })
      .addCase(getUsersOfGroup.rejected, (state, action) => {
        state.isUsersOfGroupsLoading = false;
        state.isUsersOfGroupsSuccess = false;
        state.isUsersOfGroupsFailed = true;
        state.usersOfGroupsErrorContainer = {
          ...state.usersOfGroupsErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteUsersOfGroup.pending, state => {
        state.isDeleteUsersOfGroupsLoading = true;
        state.isDeleteUsersOfGroupsSuccess = false;
        state.isDeleteUsersOfGroupsFailed = false;
        state.deleteUsersOfGroupsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteUsersOfGroup.fulfilled, state => {
        state.isDeleteUsersOfGroupsLoading = false;
        state.isDeleteUsersOfGroupsFailed = false;
        state.isDeleteUsersOfGroupsSuccess = true;
        // state.usersOfGroupData = {
        //   totalCount: action.payload.totalCount,
        //   usersOfGroupList: action.payload.dataList
        // };
      })
      .addCase(deleteUsersOfGroup.rejected, (state, action) => {
        state.isDeleteUsersOfGroupsLoading = false;
        state.isDeleteUsersOfGroupsSuccess = false;
        state.isDeleteUsersOfGroupsFailed = true;
        state.deleteUsersOfGroupsErrorContainer = {
          ...state.deleteUsersOfGroupsErrorContainer,
          ...action.payload
        };
      })
      .addCase(getUserGroupsCount.pending, state => {
        state.userGroupsData.allCount = 0;
        state.userGroupsData.activeCount = 0;
        state.userGroupsData.inActiveCount = 0;
      })
      .addCase(getUserGroupsCount.fulfilled, (state, action) => {
        // console.log(action.payload, 'payload');
        state.userGroupsData = {
          ...state.userGroupsData,
          allCount: action.payload?.allCount,
          activeCount: action.payload?.activeCount,
          inActiveCount: action.payload?.inActiveCount
        };
      })
      .addCase(getUserGroupsCount.rejected, (state, action) => {
        state.userGroupsData.allCount = 0;
        state.userGroupsData.activeCount = 0;
        state.userGroupsData.inActiveCount = 0;
      });
  }
});

export default userGroupsSlice.reducer;
