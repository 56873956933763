import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Paper,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../components/Breadcrumb';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAreaDetails,
  revertAreaFormDetails
} from '../../redux/reducers/mastersSlice';
import { ArrowForward } from '@mui/icons-material';
import {
  colorLinkGrey,
  colorPrimary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import CustomDataTable from '../../components/CustomDataTable';
import { createTitle, generateImageSrc } from '../../utils/utils';
import moment from 'moment';
import { ChevronRightIcon } from '../../utils/iconSvg';
import CustomTabLabel from '../../custom/CustomTabLabel';

const AreaDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initialValues, setInitalValues] = useState({
    tabValue: 0,
    page: 0,
    size: 10
  });
  const [formData, setFormData] = useState({
    pinId: null,
    area: null,
    stateId: null,
    stateCode: null,
    stateName: null,
    districtId: 0,
    districtCode: null,
    districtName: null,
    areas: null,
    schools: null,
    users: null,
    createdBy: null,
    creationDate: null
  });

  const {
    areaOtherDetails,
    areaDetails,
    isAreaDetailsLoading,
    isAreaDetailsSuccess
  } = useSelector(state => state.masters);

  const secondUpdate = useRef(false);

  useEffect(() => {
    document.title = createTitle('Area Details');

    return () => dispatch(revertAreaFormDetails());
  }, []);

  useEffect(() => {
    let pinId = location.state?.pinId || null;

    if (pinId) {
      dispatch(
        getAreaDetails({
          pinId: pinId,
          payload: {
            page: initialValues.page,
            size: initialValues.size,
            type: 'all'
          }
        })
      );
    }
    return () => {};
  }, [initialValues]);

  useEffect(() => {
    if (!secondUpdate.current && areaDetails && areaDetails.pinId) {
      setFormData({
        pinId: areaDetails.pinId,
        area: areaDetails.area,
        stateId: areaDetails.stateId,
        stateCode: areaDetails.stateCode,
        stateName: areaDetails.stateName,
        districtId: areaDetails.districtId,
        districtCode: areaDetails.districtCode,
        districtName: areaDetails.districtName,
        areas: areaDetails.areas,
        schools: areaDetails.schools,
        users: areaDetails.users,
        createdBy: areaDetails.createdBy,
        creationDate: areaDetails.creationDate
      });
      secondUpdate.current = true;
    }
  }, [areaDetails]);

  const userColumns = [
    {
      uinqueKey: 'userId',
      id: 'name',
      label: 'Name',
      width: '220px',
      sortable: true,
      render: row => (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.userImageUrl ? (
              <Avatar
                src={row.userImageUrl}
                alt={row.name}
                sx={{ width: 32, height: 32, marginRight: 1 }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#c9c9c9',
                  color: 'white',
                  fontSize: '12px',
                  marginRight: '5px'
                }}
              >
                {row ? row.name?.split(' ')[0]?.charAt(0).toUpperCase() : ''}
              </Avatar>
            )}

            <Typography variant="body2" fontWeight={'600'}>
              {row.name}
            </Typography>
          </div>
        </>
      )
    },
    {
      id: 'contactNumber',
      label: 'Phone'
    },
    {
      id: 'email',
      label: 'Email'
    },
    {
      id: 'role',
      label: 'Role',
      sortable: true
    },
    {
      id: 'status',
      label: 'Status',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '3px', // p-1
              borderRadius: '1.25rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 90,
              color: row.status ? '#749E35' : '#D62828'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'state',
      label: 'State'
    },
    {
      id: 'district',
      label: 'District'
    },
    {
      id: 'area',
      label: 'Area'
    },
    {
      id: 'school',
      label: 'School'
    }
  ];

  const schoolsColumns = [
    {
      uinqueKey: 'schoolId',
      id: 'schoolName',
      label: 'School',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.schoolName}
          </Typography>
        );
      }
    },
    {
      id: 'area',
      label: 'Area',
      sortable: true
    },
    {
      id: 'districtName',
      label: 'District ',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.districtCode ? `(${row.districtCode})` : ''} {row.districtName}
          </Typography>
        );
      }
    },
    {
      id: 'stateName',
      label: 'State ',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.stateCode ? `(${row.stateCode})` : ''} {row.stateName}
          </Typography>
        );
      }
    },
    {
      id: 'users',
      label: 'Users',
      sortable: true
    },
    {
      id: 'status',
      label: 'Status',
      width: '150px',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: row.status ? '#749E35' : '#D62828',
              width: row.status ? '62px' : '70px',
              height: '22px'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'createdBy',
      label: 'Created By'
    },
    {
      id: 'creationDate',
      label: 'Created On',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'}>
                {row.creationDate
                  ? moment(row.creationDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    }
  ];

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={colorTextGrey} />
              </SvgIcon>
            }
          >
            <Link
              color="inherit"
              href={'area'}
              onClick={event => {
                event.preventDefault();
                navigate('/area');
              }}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                fontWeight={'700'}
                color={colorLinkGrey}
                fontSize={'24px'}
              >
                Area
              </Typography>
            </Link>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
                color: colorTextBlack
              }}
            >
              {formData.area || ''}
            </Typography>
          </Breadcrumbs>

          {formData?.area !== null && (
            <Typography marginBottom={2}>
              <span
                style={{
                  fontSize: '14px',
                  color: colorLinkGrey,
                  fontWeight: '600'
                }}
              >
                {`Created by ${formData.createdBy} on ${moment(formData.creationDate).format('DD/MM/YY hh:mm A')}`}
              </span>
            </Typography>
          )}
        </Box>
        {/* <Tooltip placement="top" title="Click to edit record">
          <Button
            onClick={() =>
              navigate('edit-area', { state: { pinId: location.state.pinId } })
            }
            size="small"
            // startIcon={<ArrowForward />}
            sx={{
              borderColor: colorPrimary,
              backgroundColor: 'transparent',
              borderRadius: '10px',
              borderWidth: '2px',
              color: colorTextBlack
            }}
            variant="outlined"
          >
            Edit this record
          </Button>
        </Tooltip> */}
      </Box>
      {/* <CustomBreadcrumb path={`${location.pathname}`} /> */}

      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        sx={{ paddingX: '14px', pt: 1 }}
        borderRadius={'8px'}
      >
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tabs
            value={initialValues.tabValue}
            onChange={(val, newValue) =>
              setInitalValues({ ...initialValues, tabValue: newValue, page: 0 })
            }
            TabIndicatorProps={{
              sx: {
                height: '4px',
                borderRadius: '4px'
              }
            }}
          >
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                fontSize: '16px',
                fontWeight: initialValues.tabValue === 0 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 0
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="All Schools"
                  count={
                    (formData?.schools || 0) < 10
                      ? `0${formData.schools || 0}`
                      : formData.schools || 0
                  }
                  selected={initialValues.tabValue === 0}
                />
              }
              // label={`All Schools ${
              //   formData?.schools ? `(${formData?.schools})` : `(0)`
              // }`}
            />
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                fontSize: '16px',
                fontWeight: initialValues.tabValue === 1 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 1
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="All Employees"
                  count={
                    (formData?.users || 0) < 10
                      ? `0${formData?.users || 0}`
                      : formData.users || 0
                  }
                  selected={initialValues.tabValue === 1}
                />
              }
              // label={`All Employees ${
              //   formData?.users ? `(${formData?.users})` : `(0)`
              // }`}
            />
          </Tabs>
        </Grid>
        <Grid sx={{ padding: '18px 0px' }} width={'100%'}>
          {isAreaDetailsLoading && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}

          {!isAreaDetailsLoading && isAreaDetailsSuccess && (
            <CustomDataTable
              allowHover={false}
              allowSelection={false}
              columns={
                initialValues.tabValue === 0
                  ? schoolsColumns
                  : initialValues.tabValue === 1
                    ? userColumns
                    : []
              }
              defaultOrderBy={'createdDate'}
              data={
                initialValues.tabValue === 0
                  ? areaOtherDetails['dataListOfSchools'].length > 0
                    ? areaOtherDetails['dataListOfSchools']
                    : []
                  : initialValues.tabValue === 1
                    ? areaOtherDetails['dataListOfEmployees'].length > 0
                      ? areaOtherDetails['dataListOfEmployees']
                      : []
                    : []
              }
              onPageChange={val => {
                setInitalValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitalValues({ ...initialValues, size: val });
              }}
              defaultPage={initialValues.page}
              defaultRowsPerPage={initialValues.size}
              totalCount={
                initialValues.tabValue === 0
                  ? areaDetails.schools
                  : initialValues.tabValue === 1
                    ? areaDetails.users
                    : 0
              }
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AreaDetails;
