import {
  alpha,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  styled,
  SvgIcon,
  Typography
} from '@mui/material';
import {
  IMG_USER_GROUP_ANALYTICS_ICON,
  IMG_ROLE_ANALYTICS_ICON,
  IMG_USER_ANALYTICS_ICON
} from '../../utils/imageUrls';
import { Chart } from 'react-google-charts';
import {
  colorPrimary,
  colorSecondary,
  colorTextBlack
} from '../../config/theme';
import { useEffect, useRef, useState } from 'react';
import {
  Calendar01Icon,
  Copy05Icon,
  Expand05Icon,
  FileDownload03Icon,
  FilterLinesIcon,
  ShareThreeDotsIcon,
  User01Icon,
  XClose
} from '../../utils/iconSvg';
import {
  getUserAnalytics,
  getUserAndRoleAnalytics,
  getUserAndRoleAnalyticsCount,
  revertUserAndRoleAnalytics
} from '../../redux/reducers/analyticsSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createTitle, isArrayNotEmpty } from '../../utils/utils';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { analyticsTypeHeaderContent } from '../../pages/ProductAnalysis';
import SnackBarBox from '../../components/SnackBarBox';
import { ref } from 'yup';

export const TileCountComponent = ({ count, description, icon }) => {
  return (
    <Grid
      item
      sx={{
        padding: '12px 15px',
        border: '1px solid #E4E7EB',
        borderRadius: '8px',
        width: '288px',
        display: 'flex',
        mr: 2,
        alignItems: 'center'
      }}
      // lg={3}
      // md={12}
      // sm={12}
      mb={1}
    >
      <Grid item mr={1}>
        <Avatar src={icon} sx={{ height: 48, width: 48 }} variant="rounded" />
      </Grid>
      <Grid item>
        <Typography fontSize={'32px'} lineHeight={'36px'} fontWeight={'700'}>
          {count}
        </Typography>
        <Typography fontWeight={'500'} fontSize={'14px'} color={'#4E565F'}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const Legend = ({ series = [] }) => {
  return (
    <Grid display={'flex'} spacing={1} justifyContent={'end'} width={'100%'}>
      {series.map((item, index) => (
        <Grid item key={index} sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{
              width: '10px',
              height: '10px',
              backgroundColor: item.color,
              marginRight: '8px'
            }}
          >
            <></>
          </Avatar>
          <Typography
            sx={{ fontWeight: '600', color: '#4E565F', fontSize: '14px' }}
          >
            {item.name}
          </Typography>
          {index < series.length - 1 && (
            <Box
              component="span"
              sx={{
                marginX: '8px',
                height: '16px',
                width: '1px',
                backgroundColor: '#E4E7EB',
                display: 'inline-block'
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .MuiSelect-icon': {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1)
  },

  '& .MuiMenuItem-root': {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1.5)
    },
    '&:active': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      )
    }
  }
}));

export function prepareChartOptionsAndData(payload) {
  const data = payload.slice(1); // Remove header row

  const months = data.map(item => item[0]);
  const userCounts = data.map(item => item[1]);
  const roleCounts = data.map(item => item[2]);
  const userGroupCounts = data.map(item => item[3]);

  const maxUserCount = Math.max(...userCounts);
  const maxRoleCount = Math.max(...roleCounts);
  const maxUserGroupCount = Math.max(...userGroupCounts);

  const maxCount = Math.max(maxUserCount, maxRoleCount, maxUserGroupCount);

  const ticks = [];
  for (let i = 0; i <= maxCount; i += 10) {
    ticks.push(i);
  }
  return ticks;
}

export const styledMenuItem = {
  border: '1px solid #E4E7EB',
  fontWeight: 700,
  fontSize: '14px',
  background: 'inherit',
  marginX: 1,
  borderRadius: 3,
  marginY: 1,
  justifyContent: 'center'
};

const UserAndRoleAnalytics = () => {
  const dispatch = useDispatch();
  const [char1, setChart1] = useState(null);
  const [chart2, setChart2] = useState(null);
  const [tempDateFilter, setTempDateFilter] = useState({
    dateType: null,
    startDate: null,
    endDate: null
  });
  const [copySuccess, setCopySuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState({ anchor: null, id: null });
  const [chart, setChart] = useState('');
  const [open, setOpen] = useState(null);
  const [chart1Payload, setChart1Payload] = useState({
    userStatusType: -999,
    roleStatusType: -999,
    userGroupStatusType: null,
    stateId: 'all',
    districtId: 'all',
    area: 'all',
    dateType: 'all',
    startDate: null,
    endDate: null
  });
  const [chart2Payload, setChart2Payload] = useState({
    userStatusType: -999,
    dateType: 'all',
    startDate: null,
    endDate: null
  });
  const [openCalenderModal, setOpenCalendarModal] = useState({
    startDate: false,
    endDate: false
  });

  const {
    isUserAndRoleAnalyticsLoading,
    userAndRoleAnalyticsData,
    userAnalyticsData,
    metaDataState,
    metaDataDistrict,
    metaDataArea
  } = useSelector(state => state.analytics);

  useEffect(() => {
    document.title = createTitle('User & Role Analytics');

    dispatch(getUserAndRoleAnalyticsCount());
    return () => {
      dispatch(revertUserAndRoleAnalytics());
    };
  }, []);

  useEffect(() => {
    let payload = {
      ...chart1Payload,
      userStatusType:
        chart1Payload.userStatusType == -999
          ? 'all'
          : chart1Payload.userStatusType,
      roleStatusType:
        chart1Payload.roleStatusType == -999
          ? 'all'
          : chart1Payload.roleStatusType,
      stateId: chart1Payload.stateId == 'all' ? null : chart1Payload.stateId,
      districtId:
        chart1Payload.districtId == 'all' ? null : chart1Payload.districtId,
      area: chart1Payload.area == 'all' ? null : chart1Payload.area,
      dateType: chart1Payload.dateType,
      startDate: chart1Payload.startDate
        ? dayjs(chart1Payload.startDate).format('YYYY-MM-DD')
        : null,
      endDate: chart1Payload.endDate
        ? dayjs(chart1Payload.endDate).format('YYYY-MM-DD')
        : null
    };
    dispatch(getUserAndRoleAnalytics(payload));
  }, [chart1Payload]);

  useEffect(() => {
    let payload = {
      // userStatusType: chart2Payload.userStatusType,
      userStatusType:
        chart2Payload.userStatusType == -999
          ? 'all'
          : chart2Payload.userStatusType,
      dateType: chart2Payload.dateType,
      startDate: chart2Payload.startDate
        ? dayjs(chart2Payload.startDate).format('YYYY-MM-DD')
        : null,
      endDate: chart2Payload.endDate
        ? dayjs(chart2Payload.endDate).format('YYYY-MM-DD')
        : null
    };

    dispatch(getUserAnalytics(payload));
  }, [chart2Payload]);

  const handleClickOpen = id => {
    setChart(id);
  };

  const handleClose = () => {
    setChart('');
  };
  const handleOpenCalendar = type => {
    setOpenCalendarModal({ ...openCalenderModal, [type]: true });
  };

  const handleCloseCalendar = type => {
    setOpenCalendarModal({ ...openCalenderModal, [type]: false });
  };

  const handleShareClick = (event, id) => {
    setAnchorEl({ anchor: event.currentTarget, id: id });
  };

  const handleShareClose = () => {
    setAnchorEl({ anchor: null, id: null });
    setTimeout(() => {
      setCopySuccess(false);
    }, 4000);
  };

  const handleCopyLink = id => {
    let ids = {
      1: 'userAndRoleChart',
      2: 'usersChart'
    };
    let shareLink = `${process.env.REACT_APP_PORTAL_URL}share-charts?chartType=${ids[id]}`;

    navigator.clipboard.writeText(shareLink);
    setCopySuccess(true);
    handleShareClose();
  };

  const options = {
    hAxis: {
      title: 'Date',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      }
    },
    vAxis: {
      title: 'Count',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      },
      viewWindow: {
        min: 0
      },
      ticks: prepareChartOptionsAndData(userAndRoleAnalyticsData.analyticsData),
      gridlines: {
        count: 10,
        color: '#F5F7FA',
        units: {
          ones: { count: 0 }
        }
      }
    },
    curveType: 'function',
    series: {
      0: { color: colorPrimary, curveType: 'function' },
      1: { color: '#2F68C4', curveType: 'function' },
      2: { color: '#749E35', curveType: 'function' }
    },
    legend: { position: 'none' }
  };

  const handleDownload = ({ id = null }) => {
    if (id) {
      const chartWrapper = (id === 1 && char1) || (id === 2 && chart2);
      const imageURI = chartWrapper.getChart().getImageURI();
      const link = document.createElement('a');
      link.href = imageURI;
      link.download = 'chart.png';
      link.click();
    }
  };

  const DropdownCalendar = () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6.66634H2M10.6667 1.33301V3.99967M5.33333 1.33301V3.99967M5.2 14.6663H10.8C11.9201 14.6663 12.4802 14.6663 12.908 14.4484C13.2843 14.2566 13.5903 13.9506 13.782 13.5743C14 13.1465 14 12.5864 14 11.4663V5.86634C14 4.74624 14 4.18618 13.782 3.75836C13.5903 3.38204 13.2843 3.07607 12.908 2.88433C12.4802 2.66634 11.9201 2.66634 10.8 2.66634H5.2C4.0799 2.66634 3.51984 2.66634 3.09202 2.88433C2.71569 3.07607 2.40973 3.38204 2.21799 3.75836C2 4.18618 2 4.74624 2 5.86634V11.4663C2 12.5864 2 13.1465 2.21799 13.5743C2.40973 13.9506 2.71569 14.2566 3.09202 14.4484C3.51984 14.6663 4.0799 14.6663 5.2 14.6663Z"
        stroke="#1F2933"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <Typography mb={2} fontSize={'32px'} fontWeight={'700'}>
        {analyticsTypeHeaderContent['user']}
      </Typography>
      <Grid
        container
        component={Paper}
        elevation={0}
        sx={{ borderRadius: '8px', padding: 2, display: 'flex', width: '100%' }}
      >
        <TileCountComponent
          icon={IMG_ROLE_ANALYTICS_ICON}
          count={userAndRoleAnalyticsData.totalRole}
          description={'Total Roles Created'}
        />
        <TileCountComponent
          icon={IMG_USER_ANALYTICS_ICON}
          count={userAndRoleAnalyticsData.totalUser}
          description={'Total Users'}
        />
        <TileCountComponent
          icon={IMG_USER_GROUP_ANALYTICS_ICON}
          count={userAndRoleAnalyticsData.totalGroup}
          description={'Total User Groups'}
        />
      </Grid>
      <Grid
        mt={2}
        container
        display={'flex'}
        width={'100%'}
        flexDirection={'column'}
        sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
      >
        <Grid item sx={12} display={'flex'} mb={1}>
          <FormControl fullWidth sx={{ marginRight: 1 }}>
            <Select
              placeholder="Role status"
              size="small"
              value={chart1Payload.roleStatusType}
              onChange={e =>
                setChart1Payload({
                  ...chart1Payload,
                  roleStatusType: e.target.value
                })
              }
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
              renderValue={val => {
                return val === -999 ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ABB4BD'
                    }}
                  >
                    <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                      <User01Icon color="#ABB4BD" />
                    </SvgIcon>
                    Role status
                  </Typography>
                ) : val === true ? (
                  'Active Roles'
                ) : val === false ? (
                  'Inactive Roles'
                ) : (
                  'All Roles'
                );
              }}
            >
              <MenuItem value={'all'}>All Roles</MenuItem>
              <MenuItem value={true}>Active Roles</MenuItem>
              <MenuItem value={false}>Inactive Roles</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginRight: 1 }}>
            <Select
              placeholder="User status"
              size="small"
              value={chart1Payload.userStatusType}
              onChange={e =>
                setChart1Payload({
                  ...chart1Payload,
                  userStatusType: e.target.value
                })
              }
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
              renderValue={val => {
                return val === -999 ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ABB4BD'
                    }}
                  >
                    <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                      <User01Icon color="#ABB4BD" />
                    </SvgIcon>
                    User status
                  </Typography>
                ) : val === true ? (
                  'Active Users'
                ) : val === false ? (
                  'Inactive Users'
                ) : (
                  'All Users'
                );
              }}
            >
              <MenuItem value={'all'}>All Users</MenuItem>
              <MenuItem value={true}>Active Users</MenuItem>
              <MenuItem value={false}>Inactive Users</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginRight: 1 }}>
            <Select
              size="small"
              value={chart1Payload.stateId}
              onChange={e => {
                setChart1Payload({
                  ...chart1Payload,
                  stateId: e.target.value,
                  districtId: 'all',
                  area: 'all'
                });
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
              renderValue={(val, val1) => {
                let value =
                  isArrayNotEmpty(metaDataState) &&
                  metaDataState.find((row, key) => row.stateId === val);

                return val === 'all' ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ABB4BD'
                    }}
                  >
                    Select state
                  </Typography>
                ) : (
                  value?.stateName
                );
              }}
            >
              {isArrayNotEmpty(metaDataState) &&
                metaDataState.map((row, key) => {
                  return (
                    <MenuItem
                      key={key}
                      value={row.stateId}
                      sx={{
                        ':hover': {
                          backgroundColor: colorSecondary
                        }
                      }}
                    >
                      {row?.stateName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>{' '}
          <FormControl fullWidth sx={{ marginRight: 1 }}>
            <Select
              size="small"
              value={chart1Payload.districtId}
              onChange={e =>
                setChart1Payload({
                  ...chart1Payload,
                  districtId: e.target.value,
                  area: 'all'
                })
              }
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
              renderValue={val => {
                let value =
                  isArrayNotEmpty(metaDataDistrict) &&
                  metaDataDistrict.find((row, key) => row.districtId === val);

                return val === 'all' ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ABB4BD'
                    }}
                  >
                    Select district
                  </Typography>
                ) : (
                  value?.districtName
                );
              }}
            >
              {isArrayNotEmpty(metaDataDistrict) &&
                metaDataDistrict
                  .filter(cc => cc.stateId === chart1Payload.stateId)
                  .map((row, key) => {
                    return (
                      <MenuItem
                        key={key}
                        value={row?.districtId}
                        sx={{
                          ':hover': {
                            backgroundColor: colorSecondary
                          }
                        }}
                      >
                        {row?.districtName}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>{' '}
          <FormControl fullWidth sx={{ marginRight: 1 }}>
            <Select
              size="small"
              value={chart1Payload.area}
              onChange={e =>
                setChart1Payload({
                  ...chart1Payload,
                  area: e.target.value
                })
              }
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
              renderValue={val => {
                let value =
                  isArrayNotEmpty(metaDataArea) &&
                  metaDataArea.find((row, key) => row.pinId === val);

                return val == 'all' ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ABB4BD'
                    }}
                  >
                    Select area
                  </Typography>
                ) : (
                  value?.area
                );
              }}
            >
              {isArrayNotEmpty(metaDataArea) &&
                metaDataArea
                  .filter(
                    cc =>
                      cc.stateId === chart1Payload.stateId &&
                      cc.districtId === chart1Payload.districtId
                  )
                  .map((row, key) => {
                    return (
                      <MenuItem
                        key={key}
                        value={row?.pinId}
                        sx={{
                          ':hover': {
                            backgroundColor: colorSecondary
                          }
                        }}
                      >
                        {row?.area}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth size="small">
            <StyledSelect
              IconComponent={Calendar01Icon}
              size="small"
              open={open == 1}
              value={
                open == 1 ? tempDateFilter.dateType : chart1Payload.dateType
              }
              onOpen={(e, va) => {
                setOpen(1);
                setTempDateFilter({
                  dateType: chart1Payload.dateType,
                  startDate: chart1Payload.startDate,
                  endDate: chart1Payload.endDate
                });
              }}
              onChange={e => {
                setTempDateFilter({
                  ...tempDateFilter,
                  dateType: e.target.value,
                  startDate: null,
                  endDate: null
                });
              }}
              renderValue={value => {
                let data = open === 1 ? tempDateFilter : chart1Payload;
                if (data.dateType == 'all') {
                  return (
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#ABB4BD'
                      }}
                    >
                      <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                        <FilterLinesIcon color="#ABB4BD" />
                      </SvgIcon>
                      Select date
                    </Typography>
                  );
                }
                switch (data.dateType) {
                  case 'selected_date':
                    return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                  case 'Week':
                    return 'Last Week';
                  case 'Month':
                    return 'This Month';
                  case 'past_3_months':
                    return 'Past 3 months';
                  case 'Past_1_Year':
                    return 'Past 1 year';
                  default:
                    return;
                }
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
            >
              <MenuItem
                sx={{ ...styledMenuItem, paddingX: 0, border: 'none' }}
                value="selected_date"
                disableRipple
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box display={'flex'} gap={1}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      sx={{ width: '175px' }}
                    >
                      <Typography
                        sx={{ fontSize: '14px', fontWeight: 600, mb: 0.5 }}
                      >
                        Start Date
                      </Typography>
                      <DatePicker
                        value={dayjs(tempDateFilter.startDate)}
                        onOpen={() => handleOpenCalendar('startDate')}
                        onClose={() => handleCloseCalendar('startDate')}
                        open={openCalenderModal.startDate}
                        format="DD/MM/YYYY"
                        slotProps={{
                          textField: {
                            size: 'small',
                            placeholder: 'Start Date',
                            error: false,
                            InputProps: {
                              sx: { color: colorTextBlack },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleOpenCalendar('startDate')
                                    }
                                  >
                                    <SvgIcon
                                      sx={{
                                        width: '16px',
                                        height: '16px',
                                        ml: 1
                                      }}
                                    >
                                      <Calendar01Icon />
                                    </SvgIcon>
                                  </IconButton>
                                </InputAdornment>
                              )
                            },
                            sx: {
                              paddingRight: 0 // Remove extra padding from the hidden icon
                            }
                          }
                        }}
                        onChange={date =>
                          setTempDateFilter({
                            ...tempDateFilter,
                            dateType: 'selected_date',
                            startDate: date,
                            endDate: date
                          })
                        }
                        components={{ OpenPickerIcon: () => null }}
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      size="small"
                      sx={{ width: '175px' }}
                    >
                      <Typography
                        sx={{ fontSize: '14px', fontWeight: 600, mb: 0.5 }}
                      >
                        End Date
                      </Typography>
                      <DatePicker
                        minDate={dayjs(tempDateFilter.startDate)}
                        // label="End Date"
                        value={dayjs(tempDateFilter.endDate)}
                        open={openCalenderModal.endDate}
                        onOpen={() => handleOpenCalendar('endDate')}
                        onClose={() => handleCloseCalendar('endDate')}
                        format="DD/MM/YYYY"
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: false,
                            InputProps: {
                              sx: { color: colorTextBlack },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleOpenCalendar('endDate')
                                    }
                                  >
                                    <SvgIcon
                                      sx={{
                                        width: '16px',
                                        height: '16px',
                                        ml: 1
                                      }}
                                    >
                                      <Calendar01Icon />
                                    </SvgIcon>
                                  </IconButton>
                                </InputAdornment>
                              )
                            },
                            sx: {
                              paddingRight: 0 // Remove extra padding from the hidden icon
                            }
                          }
                        }}
                        onChange={date => {
                          setTempDateFilter({
                            ...tempDateFilter,
                            dateType: 'selected_date',
                            endDate: date
                          });
                        }}
                        components={{ OpenPickerIcon: () => null }}
                        // components={{
                        //   OpenPickerIcon: props => (
                        //     <IconButton size="small" {...props}>
                        //       <Calendar01Icon />
                        //     </IconButton>
                        //   )
                        // }}
                      />
                    </FormControl>
                  </Box>
                </LocalizationProvider>
              </MenuItem>

              <MenuItem sx={styledMenuItem} value="Week" disableRipple>
                Last Week
              </MenuItem>
              <MenuItem sx={styledMenuItem} value="Month" disableRipple>
                This Month
              </MenuItem>
              <MenuItem sx={styledMenuItem} value="past_3_months" disableRipple>
                Past 3 month
              </MenuItem>
              <MenuItem sx={styledMenuItem} value="Past_1_Year" disableRipple>
                Past 1 year
              </MenuItem>
              <MenuItem
                // disabled={
                //   !(tempDateFilter.dateType || chart1Payload.dateFilter)
                // }
                sx={{
                  border: '1px solid #E4E7EB',
                  backgroundColor: colorPrimary,
                  marginX: 1,
                  borderRadius: 3,
                  fontWeight: 700,
                  fontSize: '14px',
                  justifyContent: 'center',
                  marginY: 1,
                  '&:hover': {
                    background: colorPrimary
                  }
                }}
                onClick={() => {
                  if (tempDateFilter.dateType) {
                    setChart1Payload({
                      ...chart1Payload,
                      dateType: tempDateFilter.dateType,
                      startDate: tempDateFilter.startDate,
                      endDate: tempDateFilter.endDate
                    });
                  }
                  setTempDateFilter({
                    dateType: null,
                    endDate: null,
                    startDate: null
                  });
                  setOpen(null);
                }}
              >
                Apply
              </MenuItem>
              <MenuItem
                // disabled={
                //   !(tempDateFilter.dateType || chart1Payload.dateFilter)
                // }
                sx={{
                  border: '1px solid #E4E7EB',
                  backgroundColor: colorPrimary,
                  marginX: 1,
                  borderRadius: 3,
                  fontWeight: 700,
                  fontSize: '14px',
                  justifyContent: 'center',
                  marginY: 1,
                  '&:hover': {
                    background: colorPrimary
                  }
                }}
                onClick={() => {
                  setChart1Payload({
                    ...chart1Payload,
                    dateType: 'all',
                    startDate: null,
                    endDate: null
                  });
                  setTempDateFilter({
                    startDate: null,
                    dateType: null,
                    endDate: null
                  });
                  setOpen(null);
                }}
              >
                Close
              </MenuItem>
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
          <Legend
            series={
              userAndRoleAnalyticsData.analyticsData.length > 0
                ? userAndRoleAnalyticsData.analyticsData[0]
                    .slice(1)
                    .map((name, index) => ({
                      name,
                      color: options.series[index].color
                    }))
                : []
            }
          />
          <Grid display={'flex'} ml={5}>
            <IconButton onClick={() => handleClickOpen(1)} sx={{ mx: 0.5 }}>
              <SvgIcon sx={{ height: 20, width: 20 }}>
                <Expand05Icon />
              </SvgIcon>
            </IconButton>
            <IconButton
              onClick={() => {
                handleDownload({ id: 1 });
              }}
              sx={{ mr: 0.5 }}
            >
              <SvgIcon sx={{ height: 20, width: 20 }}>
                <FileDownload03Icon />
              </SvgIcon>
            </IconButton>
            <IconButton onClick={e => handleShareClick(e, 1)}>
              <SvgIcon sx={{ height: 20, width: 20 }}>
                <ShareThreeDotsIcon />
              </SvgIcon>
            </IconButton>{' '}
          </Grid>

          <Menu
            anchorEl={anchorEl.anchor}
            open={Boolean(anchorEl.id == 1)}
            onClose={handleShareClose}
            onClick={handleShareClose}
          >
            <MenuItem onClick={() => handleCopyLink(1)}>
              <SvgIcon fontSize="small" sx={{ marginRight: 2 }}>
                <Copy05Icon />
              </SvgIcon>{' '}
              Copy link
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={12}>
          {isUserAndRoleAnalyticsLoading && (
            <Skeleton height={300} width={'100%'} />
          )}
          {!isUserAndRoleAnalyticsLoading && (
            <Chart
              height={'300px'}
              width={'100%'}
              chartType="LineChart"
              data={userAndRoleAnalyticsData.analyticsData}
              options={options}
              getChartWrapper={val => {
                setChart1(val);
              }}
            />
          )}{' '}
        </Grid>
      </Grid>
      <Grid
        mt={2}
        container
        display={'flex'}
        width={'100%'}
        flexDirection={'column'}
        sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white  ' }}
      >
        <Grid item sx={12} display={'flex'} mb={1}>
          <FormControl sx={{ marginRight: 1 }}>
            <Select
              placeholder="User status"
              size="small"
              value={chart2Payload.userStatusType}
              onChange={e =>
                setChart2Payload({
                  ...chart2Payload,
                  userStatusType: e.target.value
                })
              }
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                },
                minWidth: 200
              }}
              renderValue={val => {
                return val === -999 ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ABB4BD'
                    }}
                  >
                    <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                      <User01Icon color="#ABB4BD" />
                    </SvgIcon>
                    User status
                  </Typography>
                ) : val === true ? (
                  'Active Users'
                ) : val === false ? (
                  'Inactive Users'
                ) : (
                  'All Users'
                );
              }}
            >
              <MenuItem value={'all'}>All Users</MenuItem>
              <MenuItem value={true}>Active Users</MenuItem>
              <MenuItem value={false}>Inactive Users</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 200 }} variant="outlined" size="small">
            <StyledSelect
              IconComponent={Calendar01Icon}
              size="small"
              open={open === 2}
              value={
                open === 2 ? tempDateFilter.dateType : chart2Payload.dateType
              }
              onOpen={(e, va) => {
                setOpen(2);
                setTempDateFilter({
                  dateType: chart2Payload.dateType,
                  startDate: chart2Payload.startDate,
                  endDate: chart2Payload.endDate
                });
              }}
              onChange={e => {
                setTempDateFilter({
                  ...tempDateFilter,
                  dateType: e.target.value,
                  startDate: null,
                  endDate: null
                });
              }}
              renderValue={value => {
                let data = open === 2 ? tempDateFilter : chart2Payload;

                if (data.dateType == 'all') {
                  return (
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#ABB4BD'
                      }}
                    >
                      <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                        <FilterLinesIcon color="#ABB4BD" />
                      </SvgIcon>
                      Select date
                    </Typography>
                  );
                }
                switch (data.dateType) {
                  case 'selected_date':
                    return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                  case 'Week':
                    return 'Last Week';
                  case 'Month':
                    return 'This Month';
                  case 'past_3_months':
                    return 'Past 3 months';
                  case 'Past_1_Year':
                    return 'Past 1 year';
                  default:
                    return;
                }
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
            >
              <MenuItem
                sx={{ ...styledMenuItem, paddingX: 0, border: 'none' }}
                value="selected_date"
                disableRipple
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box display={'flex'} margin={'0px 6px'}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      sx={{ width: '185px' }}
                    >
                      <Typography
                        sx={{ fontSize: '14px', fontWeight: 600, mb: 0.5 }}
                      >
                        Start Date
                      </Typography>
                      <DatePicker
                        // label="Start Date"
                        sx={{ marginRight: 1 }}
                        onOpen={() => handleOpenCalendar('startDate')}
                        onClose={() => handleCloseCalendar('startDate')}
                        open={openCalenderModal.startDate}
                        value={dayjs(tempDateFilter.startDate)}
                        format="DD/MM/YYYY"
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: false,
                            InputProps: {
                              sx: { color: colorTextBlack },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleOpenCalendar('startDate')
                                    }
                                  >
                                    <Calendar01Icon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                          }
                        }}
                        onChange={date =>
                          setTempDateFilter({
                            ...tempDateFilter,
                            dateType: 'selected_date',
                            startDate: date,
                            endDate: date
                          })
                        }
                        components={{ OpenPickerIcon: () => null }}
                        // components={{
                        //   OpenPickerIcon: props => (
                        //     <IconButton size="small" {...props}>
                        //       <Calendar01Icon />
                        //     </IconButton>
                        //   )
                        // }}
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      // fullWidth
                      size="small"
                      sx={{ width: '185px' }}
                    >
                      <Typography
                        sx={{ fontSize: '14px', fontWeight: 600, mb: 0.5 }}
                      >
                        End Date
                      </Typography>
                      <DatePicker
                        minDate={dayjs(tempDateFilter.startDate)}
                        // label="End Date"
                        value={dayjs(tempDateFilter.endDate)}
                        open={openCalenderModal.endDate}
                        onOpen={() => handleOpenCalendar('endDate')}
                        onClose={() => handleCloseCalendar('endDate')}
                        format="DD/MM/YYYY"
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: false,
                            InputProps: {
                              sx: { color: colorTextBlack },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleOpenCalendar('endDate')
                                    }
                                  >
                                    <Calendar01Icon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                          }
                        }}
                        onChange={date => {
                          setTempDateFilter({
                            ...tempDateFilter,
                            dateType: 'selected_date',
                            endDate: date
                          });
                        }}
                        components={{ OpenPickerIcon: () => null }}
                        // components={{
                        //   OpenPickerIcon: props => (
                        //     <IconButton size="small" {...props}>
                        //       <Calendar01Icon />
                        //     </IconButton>
                        //   )
                        // }}
                      />
                    </FormControl>
                  </Box>
                </LocalizationProvider>
              </MenuItem>

              <MenuItem sx={styledMenuItem} value="Week" disableRipple>
                Last Week
              </MenuItem>
              <MenuItem sx={styledMenuItem} value="Month" disableRipple>
                This Month
              </MenuItem>
              <MenuItem sx={styledMenuItem} value="past_3_months" disableRipple>
                Past 3 month
              </MenuItem>
              <MenuItem sx={styledMenuItem} value="Past_1_Year" disableRipple>
                Past 1 year
              </MenuItem>
              <MenuItem
                sx={{
                  border: '1px solid #E4E7EB',
                  backgroundColor: colorPrimary,
                  marginX: 1,
                  borderRadius: 3,
                  fontWeight: 700,
                  justifyContent: 'center',
                  marginY: 1,
                  '&:hover': {
                    background: colorPrimary
                  }
                }}
                onClick={() => {
                  if (tempDateFilter.dateType) {
                    setChart2Payload({
                      ...chart2Payload,
                      dateType: tempDateFilter.dateType,
                      startDate: tempDateFilter.startDate,
                      endDate: tempDateFilter.endDate
                    });
                  }
                  setTempDateFilter({
                    dateType: null,
                    endDate: null,
                    startDate: null
                  });
                  setOpen(null);
                }}
              >
                Apply
              </MenuItem>
              <MenuItem
                sx={{
                  border: '1px solid #E4E7EB',
                  backgroundColor: colorPrimary,
                  marginX: 1,
                  borderRadius: 3,
                  fontWeight: 700,
                  justifyContent: 'center',
                  marginY: 1,
                  '&:hover': {
                    background: colorPrimary
                  }
                }}
                onClick={() => {
                  setChart2Payload({
                    ...chart2Payload,
                    dateType: 'all',
                    startDate: null,
                    endDate: null
                  });
                  setTempDateFilter({
                    startDate: null,
                    dateType: null,
                    endDate: null
                  });
                  setOpen(null);
                }}
              >
                Close
              </MenuItem>
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
          <IconButton onClick={() => handleClickOpen(2)} sx={{ mx: 0.5 }}>
            <SvgIcon sx={{ height: 20, width: 20 }}>
              <Expand05Icon />
            </SvgIcon>
          </IconButton>
          <IconButton
            onClick={() => {
              handleDownload({ id: 2 });
            }}
            sx={{ mr: 0.5 }}
          >
            <SvgIcon sx={{ height: 20, width: 20 }}>
              <FileDownload03Icon />
            </SvgIcon>
          </IconButton>
          <IconButton onClick={e => handleShareClick(e, 2)}>
            <SvgIcon sx={{ height: 20, width: 20 }}>
              <ShareThreeDotsIcon />
            </SvgIcon>
          </IconButton>{' '}
          <Menu
            anchorEl={anchorEl.anchor}
            open={Boolean(anchorEl.id == 2)}
            onClose={handleShareClose}
            onClick={handleShareClose}
          >
            <MenuItem onClick={() => handleCopyLink(2)}>
              <SvgIcon fontSize="small" sx={{ marginRight: 2 }}>
                <Copy05Icon />
              </SvgIcon>{' '}
              Copy link
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={12}>
          <Chart
            height={'300px'}
            width={'100%'}
            chartType="LineChart"
            data={userAnalyticsData.analyticsData}
            options={options}
            getChartWrapper={val => {
              setChart2(val);
            }}
          />{' '}
        </Grid>
      </Grid>
      <Dialog
        sx={{ borderRadius: '8px' }}
        open={chart !== ''}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ borderRadius: 10 }}>
          <Box textAlign={'end'}>
            <IconButton size="small" onClick={handleClose}>
              <SvgIcon sx={{ height: 20, width: 20 }}>
                <XClose />
              </SvgIcon>
            </IconButton>
            <Legend
              series={
                chart === 1
                  ? userAndRoleAnalyticsData.analyticsData.length > 0
                    ? userAndRoleAnalyticsData.analyticsData[0]
                        .slice(1)
                        .map((name, index) => ({
                          name,
                          color: options.series[index].color
                        }))
                    : []
                  : userAnalyticsData.analyticsData.length > 0
                    ? userAnalyticsData.analyticsData[0]
                        .slice(1)
                        .map((name, index) => ({
                          name,
                          color: options.series[index].color
                        }))
                    : []
              }
            />
          </Box>
          {chart == 1 && (
            <Chart
              height={'60vh'}
              width={'100%'}
              chartType="LineChart"
              data={userAndRoleAnalyticsData.analyticsData}
              options={options}
            />
          )}
          {chart == 2 && (
            <Chart
              height={'60vh'}
              width={'100%'}
              chartType="LineChart"
              data={userAnalyticsData.analyticsData}
              options={options}
            />
          )}
        </DialogContent>
      </Dialog>{' '}
      {copySuccess && <SnackBarBox message="Link copied!" type={'success'} />}{' '}
    </>
  );
};

export default UserAndRoleAnalytics;
