import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';
import { downloadFileCSVPostApi } from '../../utils/utils';
import { resetPassword, setAuthDetailsByLocalStorage } from './authSlice';

export const revertAllUsersData = createAction('REVERT_USERS_DATA');

const intial = {
  isCreateUserLoading: false,
  isCreateUserSuccess: false,
  isCreateUserFailed: false,
  createUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetUserLoading: false,
  isGetUserSuccess: false,
  isGetUserFailed: false,
  userDetails: null,
  getUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetUserListLoading: false,
  isGetUserListSuccess: false,
  isGetUserListFailed: false,
  usersData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    userList: []
  },
  userListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUploadProfilePicLoading: false,
  isUploadProfilePicSuccess: false,
  isUploadProfilePicFailed: false,
  uploadProfilePicErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeleteProfilePicLoading: false,
  isDeleteProfilePicSuccess: false,
  isDeleteProfilePicFailed: false,
  DeleteProfilePicErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUpadteUserDataLoading: false,
  isUpadteUserDataSuccess: false,
  isUpadteUserDataFailed: false,
  UpadteUserDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isActivateUserLoading: false,
  isActivateUserSuccess: false,
  isActivateUserFailed: false,
  activateUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeActivateUserLoading: false,
  isDeActivateUserSuccess: false,
  isDeActivateUserFailed: false,
  deactivateUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeleteUserLoading: false,
  isDeleteUserSuccess: false,
  isDeleteUserFailed: false,
  deleteUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDownloadUserLoading: false,
  isDownloadUserSuccess: false,
  isDownloadUserFailed: false,
  downloadUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isResetPasswordLoading: false,
  isResetPasswordSuccess: false,
  isResetPasswordFailed: false,
  resetPasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUploadUsersLoading: false,
  isUploadUsersSuccess: false,
  isUploadUsersFailed: false,
  uploadUsersMessage: '',
  uploadUsersErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getUserDetails = createAsyncThunk(
  'users/getUserDetails',
  async (userId, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await api.get(`users/${userId}`);
      let { auth } = getState();
      // if (auth.authData && auth.authData.userId === userId) {
      //   dispatch(setAuthDetailsByLocalStorage(response.data?.payload || null));
      // }
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getUserList = createAsyncThunk(
  'users/getUserList',
  async ({ payload, search }, { rejectWithValue }) => {
    try {
      //
      let response;

      if (search == '' || search == null) {
        response = await api.post(`users/all`, payload);
      } else {
        response = await api.post(
          `users/search?searchCriteria=${search}`,
          payload
        );
      }
      return response.data?.payload || intial.usersData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const profilePicUpload = createAsyncThunk(
  'users/uploadProfilePhoto',
  async ({ data, userId, file }, { rejectWithValue }) => {
    try {
      await multipartApi.put(`/users/photo/${userId}`, data);
    } catch (error) {
      if (error?.response && error?.response?.status === 500) {
        return rejectWithValue({
          error: false,
          errorMessage: 'Error caught while uploading profile picture.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      } else if (error?.response && error?.response?.status === 400) {
        return rejectWithValue({
          error: false,
          errorMessage: `Error while uploading profile picture.`,
          description: 'Not Found',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const profilePicDelete = createAsyncThunk(
  'users/deleteProfilePhoto',
  async ({ userId, loggedinUserId }, { rejectWithValue }) => {
    try {
      const response = await api.delete(
        `users/photo/${userId}?loggedInUserName=${loggedinUserId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  'users/updateUserDetails',
  async (
    { updatePayload, userId, profilePic, isMyAccount },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const response = await api.put(
        `users/${userId}?myAccount=${isMyAccount}`,
        updatePayload
      );
      // if (response.data?.status === 200) {
      if (profilePic) {
        const formData = new FormData();
        formData.append('profilePhoto', profilePic);
        formData.append(
          'loggedInUserName',
          getState().auth?.authData?.userName
        );
        await dispatch(
          profilePicUpload({
            data: formData,
            userId: userId
          })
        );
      } else if (profilePic === null) {
        await dispatch(
          profilePicDelete({
            userId: userId,
            loggedinUserId: getState().auth?.authData?.userName
          })
        );
      }
      // }
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(`users`, payload);

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const activateUsers = createAsyncThunk(
  'users/activateUsers',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`users/activate`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deactivateUsers = createAsyncThunk(
  'users/deactivateUsers',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`users/activate`, payload);

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteUsers = createAsyncThunk(
  'users/deleteUsers',
  async (payload, { rejectWithValue, getState }) => {
    try {
      let userName = getState().auth?.authData?.userName;
      const response = await api.delete(`users?loggedInUserName=${userName}`, {
        data: {
          ids: payload,
          loggedInUserName: userName
        }
      });
      console.log(response, 'response delete');
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const uploadUsers = createAsyncThunk(
  'users/uploadUsers',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(`upload/users`, payload);
      console.log(response, 'arare');
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadUsersCSV = createAsyncThunk(
  'users/downloadUsersData',
  async ({ fileName, body }, { rejectWithValue }) => {
    try {
      return await downloadFileCSVPostApi({
        fileName: fileName,
        apiPath: 'download/users',
        body: body
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const revertUserPageData = createAction('REVERT_USER_PAGE_DATA');

export const getUserCount = createAsyncThunk(
  'users/getUserCount',
  async (_, { rejectWithValue }) => {
    try {
      //
      let response = await api.post(`users/all`, {
        type: 'all',
        page: 0,
        size: 10
      });

      return response.data?.payload || intial.usersData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(revertUserPageData, state => {
        state.isActivateUserSuccess = false;
        state.isDeActivateUserSuccess = false;
        state.isDeleteUserSuccess = false;
        state.isDownloadUserSuccess = false;

        state.isDeActivateUserFailed = false;
        state.isDeleteUserFailed = false;
        state.isActivateUserFailed = false;
        state.isDownloadUserFailed = false;

        state.activateUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.deactivateUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.deleteUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.downloadUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isUploadUsersLoading = false;
        state.isUploadUsersSuccess = false;
        state.isUploadUsersFailed = false;
        state.uploadUsersMessage = '';
        state.uploadUsersErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(revertAllUsersData, state => {
        state.isUploadProfilePicLoading = false;
        state.isUploadProfilePicSuccess = false;
        state.isUploadProfilePicFailed = false;
        state.uploadProfilePicErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isDeleteProfilePicLoading = false;
        state.isDeleteProfilePicSuccess = false;
        state.isDeleteProfilePicFailed = false;
        state.DeleteProfilePicErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isCreateUserLoading = false;
        state.isCreateUserSuccess = false;
        state.isCreateUserFailed = false;
        state.createUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.userDetails = null;
        state.isUpadteUserDataLoading = false;
        state.isUpadteUserDataSuccess = false;
        state.isUpadteUserDataFailed = false;
        state.UpadteUserDataErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isResetPasswordLoading = false;
        state.isResetPasswordSuccess = false;
        state.isResetPasswordFailed = false;

        state.resetPasswordErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(getUserDetails.pending, state => {
        state.isGetUserLoading = true;
        state.isGetUserSuccess = false;
        state.isGetUserFailed = false;
        state.getUserErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.isGetUserLoading = false;
        state.isGetUserSuccess = true;
        state.userDetails = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.isGetUserLoading = false;
        state.isGetUserFailed = true;
        state.getUserErrorContainer = {
          ...state.errorContainer,
          ...action.payload
        };
      })

      .addCase(getUserList.pending, state => {
        state.isGetUserListLoading = true;
        state.isGetUserListSuccess = false;
        state.isGetUserListFailed = false;
        state.userListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.isGetUserListLoading = false;
        state.isGetUserListSuccess = true;
        state.usersData = {
          ...state.usersData,
          userList: action.payload?.dataList,
          totalCount: action.payload?.totalCount
        };
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.isGetUserListLoading = false;
        state.isGetUserListFailed = true;
        state.usersData = intial.usersData;
        state.userListErrorContainer = {
          ...state.userListErrorContainer,
          ...action.payload
        };
      })

      .addCase(profilePicUpload.pending, state => {
        state.isUploadProfilePicLoading = true;
        state.isUploadProfilePicSuccess = false;
        state.isUploadProfilePicFailed = false;
        state.uploadProfilePicErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(profilePicUpload.fulfilled, state => {
        state.isUploadProfilePicLoading = false;
        state.isUploadProfilePicFailed = false;
        state.isUploadProfilePicSuccess = true;
      })
      .addCase(profilePicUpload.rejected, (state, action) => {
        state.isUploadProfilePicLoading = false;
        state.isUploadProfilePicSuccess = false;
        state.isUploadProfilePicFailed = true;
        state.uploadProfilePicErrorContainer = {
          ...state.uploadProfilePicErrorContainer,
          ...action.payload
        };
      })

      .addCase(profilePicDelete.pending, state => {
        state.isDeleteProfilePicLoading = true;
        state.isDeleteProfilePicSuccess = false;
        state.isDeleteProfilePicFailed = false;
        state.DeleteProfilePicErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(profilePicDelete.fulfilled, state => {
        state.isDeleteProfilePicLoading = false;
        state.isDeleteProfilePicFailed = false;
        state.isDeleteProfilePicSuccess = true;
      })
      .addCase(profilePicDelete.rejected, (state, action) => {
        state.isDeleteProfilePicLoading = false;
        state.isDeleteProfilePicSuccess = false;
        state.isDeleteProfilePicFailed = true;
        state.DeleteProfilePicErrorContainer = {
          ...state.DeleteProfilePicErrorContainer,
          ...action.payload
        };
      })

      .addCase(updateUserDetails.pending, state => {
        state.isUpadteUserDataLoading = true;
        state.isUpadteUserDataSuccess = false;
        state.isUpadteUserDataFailed = false;
        state.UpadteUserDataErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(updateUserDetails.fulfilled, state => {
        state.isUpadteUserDataLoading = false;
        state.isUpadteUserDataFailed = false;
        state.isUpadteUserDataSuccess = true;
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.isUpadteUserDataLoading = false;
        state.isUpadteUserDataSuccess = false;
        state.isUpadteUserDataFailed = true;
        state.UpadteUserDataErrorContainer = {
          ...state.UpadteUserDataErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUser.pending, state => {
        state.isCreateUserLoading = true;
        state.isCreateUserSuccess = false;
        state.isCreateUserFailed = false;
        state.createUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUser.fulfilled, state => {
        state.isCreateUserLoading = false;
        state.isCreateUserFailed = false;
        state.isCreateUserSuccess = true;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isCreateUserLoading = false;
        state.isCreateUserSuccess = false;
        state.isCreateUserFailed = true;
        state.createUserErrorContainer = {
          ...state.createUserErrorContainer,
          ...action.payload
        };
      })

      .addCase(activateUsers.pending, state => {
        state.isActivateUserLoading = true;
        state.isActivateUserSuccess = false;
        state.isActivateUserFailed = false;
        state.activateUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(activateUsers.fulfilled, state => {
        state.isActivateUserLoading = false;
        state.isActivateUserFailed = false;
        state.isActivateUserSuccess = true;
      })
      .addCase(activateUsers.rejected, (state, action) => {
        state.isActivateUserLoading = false;
        state.isActivateUserSuccess = false;
        state.isActivateUserFailed = true;
        state.activateUserErrorContainer = {
          ...state.activateUserErrorContainer,
          ...action.payload
        };
      })

      .addCase(deactivateUsers.pending, state => {
        state.isDeActivateUserLoading = true;
        state.isDeActivateUserSuccess = false;
        state.isDeActivateUserFailed = false;
        state.deactivateUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deactivateUsers.fulfilled, state => {
        state.isDeActivateUserLoading = false;
        state.isDeActivateUserFailed = false;
        state.isDeActivateUserSuccess = true;
      })
      .addCase(deactivateUsers.rejected, (state, action) => {
        state.isDeActivateUserLoading = false;
        state.isDeActivateUserSuccess = false;
        state.isDeActivateUserFailed = true;
        state.deactivateUserErrorContainer = {
          ...state.deactivateUserErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteUsers.pending, state => {
        state.isDeleteUserLoading = true;
        state.isDeleteUserSuccess = false;
        state.isDeleteUserFailed = false;
        state.deleteUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteUsers.fulfilled, state => {
        state.isDeleteUserLoading = false;
        state.isDeleteUserFailed = false;
        state.isDeleteUserSuccess = true;
      })
      .addCase(deleteUsers.rejected, (state, action) => {
        state.isDeleteUserLoading = false;
        state.isDeleteUserSuccess = false;
        state.isDeleteUserFailed = true;
        state.deleteUserErrorContainer = {
          ...state.deleteUserErrorContainer,
          ...action.payload
        };
      })

      .addCase(downloadUsersCSV.pending, state => {
        state.isDownloadUserLoading = true;
        state.isDownloadUserSuccess = false;
        state.isDownloadUserFailed = false;
        state.downloadUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(downloadUsersCSV.fulfilled, state => {
        state.isDownloadUserLoading = false;
        state.isDownloadUserFailed = false;
        state.isDownloadUserSuccess = true;
      })
      .addCase(downloadUsersCSV.rejected, (state, action) => {
        state.isDownloadUserLoading = false;
        state.isDownloadUserSuccess = false;
        state.isDownloadUserFailed = true;
        state.downloadUserErrorContainer = {
          ...state.downloadUserErrorContainer,
          ...action.payload
        };
      })

      .addCase(uploadUsers.pending, state => {
        state.isUploadUsersLoading = true;
        state.isUploadUsersSuccess = false;
        state.isUploadUsersFailed = false;
        state.uploadUsersMessage = null;
        state.downloadUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(uploadUsers.fulfilled, (state, action) => {
        state.isUploadUsersLoading = false;
        state.isUploadUsersFailed = false;
        state.uploadUsersMessage = action.payload?.message;
        state.isUploadUsersSuccess = true;
      })
      .addCase(uploadUsers.rejected, (state, action) => {
        state.isUploadUsersLoading = false;
        state.isUploadUsersSuccess = false;
        state.isUploadUsersFailed = true;
        state.uploadUsersErrorContainer = {
          ...state.uploadUsersErrorContainer,
          ...action.payload
        };
      })

      .addCase(resetPassword.pending, state => {
        state.isResetPasswordLoading = true;
        state.isResetPasswordSuccess = false;
        state.isResetPasswordFailed = false;
        state.resetPasswordErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(resetPassword.fulfilled, state => {
        state.isResetPasswordLoading = false;
        state.isResetPasswordSuccess = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isResetPasswordLoading = false;
        state.isResetPasswordFailed = true;
        state.resetPasswordErrorContainer = {
          ...state.resetPasswordErrorContainer,
          ...action.payload
        };
      })

      .addCase(getUserCount.pending, state => {
        state.usersData.allCount = 0;
        state.usersData.activeCount = 0;
        state.usersData.inActiveCount = 0;
      })
      .addCase(getUserCount.fulfilled, (state, action) => {
        state.usersData = {
          ...state.usersData,
          allCount: action.payload?.allCount,
          activeCount: action.payload?.activeCount,
          inActiveCount: action.payload?.inActiveCount
        };
      })
      .addCase(getUserCount.rejected, (state, action) => {
        state.usersData.allCount = 0;
        state.usersData.activeCount = 0;
        state.usersData.inActiveCount = 0;
      });
  }
});

export default usersSlice.reducer;
