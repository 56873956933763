import { api } from '../config/api';
import { setBasicDetails } from '../config/cookie';

export const getBasicDetails = async userId => {
  const response = await api.get(`users/${userId}`);
  if (!response.data.error) {
    setBasicDetails(
      response?.data?.payload?.userImageUrl,
      response?.data?.payload?.name
    );
    return response.data.payload;
  } else {
    return {};
  }
};
