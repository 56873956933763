import { Button, Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function BackButton({ text = 'Back', path }) {
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(path);
    return;
  };
  return (
    <Tooltip placement="top" title="Click to go back">
      <Button
        onClick={handleBackButton}
        size="small"
        startIcon={<ArrowBackIcon />}
      >
        {text}
      </Button>
    </Tooltip>
  );
}

export default BackButton;
