import { Typography, Grid, Paper, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  colorContainedHoverButton,
  colorPrimary,
  colorTextBlack
} from '../config/theme';

const Usage = () => {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);

    const timeout = setTimeout(() => {
      window.open('https://analytics.google.com/analytics/web', '_blank');
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Grid>
      <Typography fontSize={32} fontWeight="700" display={'flex'}>
        Usage Analytics
      </Typography>

      <Grid container elevation={0} padding={1} component={Paper}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            textAlign: 'center'
          }}
          py={20}
        >
          <Grid>
            <Typography variant="h6">
              You are being redirected to Google Analytics login page, please
              wait...
            </Typography>
            <Grid display={'flex'} gap={1} mt={3}>
              <Typography variant="h6">
                In case, if you {`haven't`} been redirected in
              </Typography>
              <Typography variant="h6" color={colorPrimary}>
                {countdown} seconds,
              </Typography>
              <Typography variant="h6">
                {' '}
                please click on the Continue button.
              </Typography>
            </Grid>
          </Grid>

          <Grid mt={6}>
            <Typography
              fontWeight={'700'}
              fontSize={'14px'}
              sx={{
                position: 'relative',
                display: 'inline-block',
                backgroundColor: colorContainedHoverButton,
                color: 'black',
                padding: '10px 20px',
                borderRadius: '5px',
                transition: '0.2s ease',
                '&:hover': {
                  backgroundColor: colorPrimary,
                  color: 'black'
                },
                '& a': {
                  color: 'inherit',
                  textDecoration: 'none',
                  transition: 'color 0.2s ease'
                },
                '&:hover a': {
                  color: colorTextBlack
                }
              }}
            >
              <a
                href="https://analytics.google.com/analytics/web"
                target="_blank"
                rel="noreferrer"
              >
                Continue
              </a>
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Usage;
