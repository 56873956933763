import { useDispatch, useSelector } from 'react-redux';
import {
  getUserList,
  revertAllUsersData
} from '../../redux/reducers/usersSlice';
import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  InputBase,
  Skeleton,
  Typography,
  styled
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { generateImageSrc, handleError } from '../../utils/utils';
import CustomDataTable from '../../components/CustomDataTable';
import { LoadingButton } from '@mui/lab';
import { colorMidGray, colorPrimary } from '../../config/theme';
import { isEmptyArray } from 'formik';
import SnackBarBox from '../../components/SnackBarBox';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import {
  createUserGroups,
  revertAllUserGroupsData,
  updateUserGroups
} from '../../redux/reducers/userGroupsSlice';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: ''
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '0px',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const AddUserInGroupForm = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [usersPayload, setUsersPayLoad] = useState({
    page: 0,
    size: 10,
    type: 'all'
  });
  const [selecteUsers, setSelectedUsers] = useState([]);
  const [showError, setShowError] = useState(null);
  const [initialValues, setInitalValues] = useState({
    tabValue: 0,
    page: 0,
    size: 10,
    searchFilter: ''
  });

  const { isGetUserListLoading, usersData } = useSelector(state => state.users);
  const {
    isCreateUserGroupsFailed,
    isCreateUserGroupsSuccess,
    isUpdateUserGroupsSuccess,
    isUpdateUserGroupsFailed,
    isCreateUserGroupsLoading,
    createUserGroupsErrorContainer,
    isUpdateUserGroupsLoading
  } = useSelector(state => state.userGroups);
  const { authData } = useSelector(state => state.auth);

  const columns = [
    {
      uinqueKey: 'userId',
      id: 'name',
      label: 'Name',
      width: '220px',
      sortable: true,
      sticky: true,
      render: row => (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.userImageUrl ? (
              <Avatar
                src={row.userImageUrl}
                alt={row.name}
                sx={{ width: 32, height: 32, marginRight: 1 }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#c9c9c9',
                  color: 'white',
                  fontSize: '12px',
                  marginRight: '5px'
                }}
              >
                {row ? row.name?.split(' ')[0]?.charAt(0).toUpperCase() : ''}
              </Avatar>
            )}

            <Typography variant="body2" fontWeight={'600'}>
              {row.name}
            </Typography>
          </div>
        </>
      )
    },
    {
      id: 'contactNumber',
      label: 'Phone',
      sticky: true
    },
    {
      id: 'email',
      label: 'Email'
    },
    {
      id: 'role',
      label: 'Role',
      sortable: true
    },
    {
      id: 'userType',
      label: 'Type',
      sortable: true
    },
    {
      id: 'status',
      label: 'Status',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: row.status ? '#749E35' : '#D62828',
              width: row.status ? '62px' : '70px',
              height: '22px'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'state',
      label: 'State'
    },
    {
      id: 'district',
      label: 'District'
    },
    {
      id: 'area',
      label: 'Area'
    },
    {
      id: 'school',
      label: 'School'
    }
  ];

  useEffect(() => {
    dispatch(
      getUserList({
        payload: {
          type: 'all',
          page: initialValues.page,
          size: initialValues.size
        },
        search: initialValues.searchFilter
      })
    );
    return () => dispatch(revertAllUserGroupsData());
  }, [initialValues]);

  useEffect(() => {
    dispatch(getUserList({ payload: usersPayload }));
  }, [usersPayload]);

  const debouncedSearch = _.debounce(
    term => setInitalValues({ ...initialValues, searchFilter: term }),
    300
  );

  const handleSearch = e => {
    const term = e.target.value;
    if (term.length >= 3 || term.length === 0) {
      debouncedSearch(term);
    }
  };

  const handleSave = () => {
    let errorMessage = '';

    if (props.formValues != true) {
      if (props.groupName === '' || props.groupName === null) {
        errorMessage = 'Please enter a group name.';
      }

      if (isEmptyArray(selecteUsers)) {
        errorMessage = 'Please select users to add in group.';
      }

      let updatePayload = {
        userGroupId: props.formValues.userGroupId,
        groupName: props.groupName || props.formValues.groupName,
        userIdsToAdd: [...selecteUsers],
        userIdsToRemove: [],
        loggedInUserName: authData.userName
      };
      dispatch(
        updateUserGroups({
          updatePayload: updatePayload
        })
      );
    } else {
      if (props.groupName === '' || props.groupName === null) {
        errorMessage = 'Please enter a group name.';
      }

      if (isEmptyArray(selecteUsers)) {
        errorMessage = 'Please select users to add in group.';
      }

      let createPayload = {
        userIds: [...selecteUsers],
        groupName: props.groupName,
        loggedInUserName: authData.userName
      };

      dispatch(createUserGroups({ payload: createPayload }));
    }
    setShowError(errorMessage);
    setTimeout(() => {
      setShowError(null);
    }, 3000);
  };

  useEffect(() => {
    if (isCreateUserGroupsSuccess || isUpdateUserGroupsSuccess) {
      setTimeout(() => {
        dispatch(revertAllUserGroupsData());
        navigate('/user-groups');
      }, 2000);
    }
  }, [isCreateUserGroupsSuccess, isUpdateUserGroupsSuccess]);

  return (
    <>
      <Grid sx={{ padding: '10px' }} width={'100%'}>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography display={'flex'} alignItems={'center'} mb={1}>
            Total{' '}
            <Typography fontWeight="600" margin={'0px 5px'}>
              {selecteUsers.length}
            </Typography>{' '}
            user[s] will be added in group.
          </Typography>

          <Search>
            <SearchIconWrapper>
              {/* <SearchIcon sx={{ color: colorMidGray }} /> */}
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 17.5L14.375 13.875M16.3333 9.16667C16.3333 12.8486 13.3486 15.8333 9.66667 15.8333C5.98477 15.8333 3 12.8486 3 9.16667C3 5.48477 5.98477 2.5 9.66667 2.5C13.3486 2.5 16.3333 5.48477 16.3333 9.16667Z"
                  stroke="#ABB4BD"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SearchIconWrapper>
            <StyledInputBase
              onChange={handleSearch}
              placeholder="Search user here…"
            />
          </Search>
        </Grid>

        {(isGetUserListLoading ||
          isCreateUserGroupsLoading ||
          isUpdateUserGroupsLoading) && (
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={400}
            animation="wave"
          />
        )}

        {!isGetUserListLoading &&
          !isCreateUserGroupsLoading &&
          !isUpdateUserGroupsLoading && (
            <CustomDataTable
              allowSelection={true}
              columns={columns}
              data={
                usersData['userList'].length > 0
                  ? usersData['userList'].filter(
                      xx => !_.includes(props.existingUsers, xx.userId)
                    )
                  : []
              }
              onPageChange={val => {
                setUsersPayLoad({ ...usersPayload, page: val });
              }}
              onRowsPerPageChange={val => {
                setUsersPayLoad({ ...usersPayload, size: val });
              }}
              defaultPage={usersPayload.page}
              onSelectionChange={val => {
                setSelectedUsers(val);
              }}
              selectedRows={selecteUsers}
              defaultRowsPerPage={usersPayload.size}
              totalCount={usersData['totalCount']}
            />
          )}
      </Grid>
      <Divider orientation="horizontal" />
      <Box bgcolor={'white'} padding={2} borderRadius={2}>
        <Grid display={'flex'} gap={2}>
          <LoadingButton
            variant="outlined"
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: 'black',
              borderWidth: '2px',
              height: '42px',
              width: '106px',
              '&:hover': {
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px'
              }
            }}
            loading={
              isGetUserListLoading ||
              isCreateUserGroupsLoading ||
              isUpdateUserGroupsLoading
            }
            // variant="outlined"
            onClick={() => {
              props.onBackClick();
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={
              isGetUserListLoading ||
              isCreateUserGroupsLoading ||
              isUpdateUserGroupsLoading
            }
            sx={{ width: '94px', height: '42px', boxShadow: 'none' }}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </LoadingButton>
        </Grid>
      </Box>

      {showError && <SnackBarBox type={'error'} message={showError} />}

      {!isCreateUserGroupsLoading && isCreateUserGroupsSuccess && (
        <SnackBarBox
          type="success"
          message={'User group created successfully. '}
        />
      )}

      {!isUpdateUserGroupsLoading && isUpdateUserGroupsSuccess && (
        <SnackBarBox
          type="success"
          message={'User group updated successfully. '}
        />
      )}

      {!isCreateUserGroupsLoading && isCreateUserGroupsFailed && (
        <SnackBarBox
          type="error"
          message={handleError(createUserGroupsErrorContainer?.errorMessage)}
        />
      )}

      {!isUpdateUserGroupsLoading && isUpdateUserGroupsFailed && (
        <SnackBarBox
          type="error"
          message={handleError(createUserGroupsErrorContainer?.errorMessage)}
        />
      )}
    </>
  );
};

export default AddUserInGroupForm;
