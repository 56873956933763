import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../components/Breadcrumb';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteClassroom,
  getSchoolDetails,
  revertClassroomPageData,
  revertSchoolFormData
} from '../../redux/reducers/mastersSlice';
import { Add, ArrowForward, MoreVert } from '@mui/icons-material';
import CustomDataTable from '../../components/CustomDataTable';
import {
  createTitle,
  generateImageSrc,
  handleError,
  isArrayNotEmpty
} from '../../utils/utils';
import moment from 'moment';
import ConfirmBox from '../../components/ConfirmBox';
import SnackBarBox from '../../components/SnackBarBox';
import { ChevronRightIcon, DeleteIcon, TrashIcon } from '../../utils/iconSvg';
import {
  colorLinkGrey,
  colorPrimary,
  colorTextBlack,
  colorTextGrey,
  colorWhite
} from '../../config/theme';
import { Container } from '../../utils/styledLayout';
import CustomTabLabel from '../../custom/CustomTabLabel';

const SchoolDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    schoolId: null,
    schoolName: null,
    pinId: null,
    area: null,
    pinCode: null,
    districtId: null,
    districtCode: null,
    districtName: null,
    stateId: null,
    stateCode: null,
    stateName: null,
    status: null,
    users: null,
    createdBy: null,
    creationDate: null
  });
  const secondUpdate = useRef(false);
  const [showError, setShowError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);
  const [confirmation, setConfimation] = useState(null);
  const [initialValues, setInitalValues] = useState({
    tabValue: 0,
    page: 0,
    size: 10
  });
  const {
    schoolOtherDetails,
    schoolDetails,
    isSchoolDetailsLoading,
    isSchoolDetailsSuccess,
    isDeleteClassroomSuccess,
    isDeleteClassroomLoading,
    isDeleteClassroomFailed,
    deleteClassroomErrorContainer
  } = useSelector(state => state.masters);
  const { authData } = useSelector(state => state.auth);

  useEffect(() => {
    // console.log(location.state,"location.state")
    let schooldId = location.state.schoolId || null;

    dispatch(
      getSchoolDetails({
        schoolId: schooldId,
        payload: {
          page: initialValues.page,
          size: initialValues.size,
          type: 'all'
        }
      })
    );
    return () => {};
  }, [initialValues]);

  useEffect(() => {
    document.title = createTitle('School Details');

    return () => dispatch(revertSchoolFormData());
  }, []);

  useEffect(() => {
    return () => dispatch(revertClassroomPageData());
  }, []);

  useEffect(() => {
    if (!secondUpdate.current && schoolDetails && schoolDetails.schoolId) {
      setFormData({
        schoolId: schoolDetails.schoolId,
        schoolName: schoolDetails.schoolName,
        pinId: schoolDetails.pinId,
        area: schoolDetails.area,
        pinCode: schoolDetails.pinCode,
        districtId: schoolDetails.districtId,
        districtCode: schoolDetails.districtCode,
        districtName: schoolDetails.districtName,
        stateId: schoolDetails.stateId,
        stateCode: schoolDetails.stateCode,
        stateName: schoolDetails.stateName,
        status: schoolDetails.status,
        users: schoolDetails.users,
        createdBy: schoolDetails.createdBy,
        creationDate: schoolDetails.creationDate
      });
      secondUpdate.current = true;
    }
  }, [schoolDetails]);

  useEffect(() => {
    if (isDeleteClassroomSuccess) {
      return handleReset();
    }
  }, [isDeleteClassroomSuccess]);

  const handleMenuActions = action => {
    setShowError(null);
    if (action === 'Delete') {
      handleDeleteClassroom();
    }
    // else if (action === 'De-Activate') {
    //   handleDeActivateArea();
    // } else if (action === 'Activate') {
    //   handleActivateArea();
    // }
  };

  const handleDeleteClassroom = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(schoolOtherDetails?.dataListOfClassrooms)) {
      if (isArrayNotEmpty(selectedClassrooms)) {
        setConfimation(true);
      } else {
        errorMessage = 'Please select classroom[s] to delete.';
      }
    }
    setShowError(errorMessage);
  };

  const deleteUser = () => {
    let deletePayload = {
      ids: [...selectedClassrooms],
      loggedInUserName: authData?.userName
    };
    dispatch(deleteClassroom(deletePayload));
  };

  const handleReset = () => {
    setSelectedClassrooms([]);
    setShowError(null);
    setConfimation(null);
    setInitalValues({ tabValue: 0, page: 0, size: 10 });
    setAnchorEl(null);
  };

  const userColumns = [
    {
      uinqueKey: 'userId',
      id: 'name',
      label: 'Name',
      width: '220px',
      sortable: true,
      render: row => (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.userImageUrl ? (
              <Avatar
                src={row.userImageUrl}
                alt={row.name}
                sx={{ width: 32, height: 32, marginRight: 1 }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#c9c9c9',
                  color: 'white',
                  fontSize: '12px',
                  marginRight: '5px'
                }}
              >
                {row ? row.name?.split(' ')[0]?.charAt(0).toUpperCase() : ''}
              </Avatar>
            )}

            <Typography variant="body2" fontWeight={'600'}>
              {row.name}
            </Typography>
          </div>
        </>
      )
    },
    {
      id: 'contactNumber',
      label: 'Phone'
    },
    {
      id: 'email',
      label: 'Email'
    },
    {
      id: 'role',
      label: 'Role',
      sortable: true
    },
    {
      id: 'status',
      label: 'Status',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: row.status ? '#749E35' : '#D62828',
              width: row.status ? '62px' : '70px',
              height: '22px'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'state',
      label: 'State'
    },
    {
      id: 'district',
      label: 'District'
    },
    {
      id: 'area',
      label: 'Area'
    },
    {
      id: 'school',
      label: 'School'
    }
  ];

  const classroomColumns = [
    {
      uinqueKey: 'classroomId',
      id: 'grade',
      label: 'Grade',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.grade}
          </Typography>
        );
      }
    },
    {
      id: 'division',
      label: 'Division',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.division}
          </Typography>
        );
      }
    },
    {
      id: 'teacherName',
      label: 'Teacher Name'
    },
    {
      id: 'createdBy',
      label: 'Created By'
    },
    {
      id: 'createdDate',
      label: 'Created On',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'}>
                {row.createdDate
                  ? moment(row.createdDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    }
  ];

  const handleOnRowClick = row => {
    if (initialValues.tabValue === 0) {
      return navigate(`edit-class`, {
        state: {
          classroomId: row.classroomId,
          schoolId: row.schoolId,
          division: row?.division
        }
      });
    }
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={colorTextGrey} />
              </SvgIcon>
            }
          >
            <Link
              color="inherit"
              href={'schools'}
              onClick={event => {
                event.preventDefault();
                navigate('/schools');
              }}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                fontWeight={'700'}
                color={colorLinkGrey}
                fontSize={'24px'}
              >
                Schools
              </Typography>
            </Link>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
                color: colorTextBlack
              }}
            >
              {formData.schoolName || ''}
            </Typography>
          </Breadcrumbs>
          {formData?.schoolName !== null && (
            <Typography marginBottom={2}>
              <span
                style={{
                  fontSize: '14px',
                  color: colorLinkGrey,
                  fontWeight: '600'
                }}
              >
                {`Created by ${formData.createdBy} on ${moment(formData.creationDate).format('DD/MM/YY hh:mm A')}`}
              </span>
            </Typography>
          )}{' '}
        </Box>
        <Box>
          {initialValues.tabValue === 0 && (
            <Tooltip placement="top" title="Click to Add classroom">
              <Button
                sx={{
                  marginRight: 2,
                  boxShadow: 'none'
                }}
                variant="contained"
                onClick={() => {
                  navigate('/schools/school-details/add-new-class', {
                    state: {
                      schoolId: schoolDetails.schoolId,
                      schoolName: schoolDetails.schoolName
                    }
                  });
                }}
              >
                <SvgIcon
                  fontSize="small"
                  sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_11072_29482)">
                      <path
                        d="M1.91732 12.0766C1.94795 11.801 1.96326 11.6632 2.00497 11.5343C2.04197 11.42 2.09425 11.3113 2.16038 11.211C2.23493 11.0979 2.33299 10.9999 2.52911 10.8037L11.3333 1.99955C12.0697 1.26317 13.2636 1.26317 14 1.99955C14.7364 2.73593 14.7364 3.92984 14 4.66622L5.19578 13.4704C4.99966 13.6665 4.9016 13.7646 4.78855 13.8391C4.68826 13.9053 4.57949 13.9575 4.46519 13.9945C4.33636 14.0362 4.19853 14.0516 3.92287 14.0822L1.66663 14.3329L1.91732 12.0766Z"
                        stroke="#1F2933"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_11072_29482">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SvgIcon>{' '}
                Create new classroom
              </Button>
            </Tooltip>
          )}
          {/* <Tooltip placement="top" title="Click to edit record">
            <Button
              sx={{
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px',
                color: colorTextBlack
              }}
              variant="outlined"
              onClick={() =>
                navigate('edit-school', {
                  state: { schoolId: location.state.schoolId }
                })
              }
              size="small"
              // startIcon={<ArrowForward />}
            >
              Edit this record
            </Button>
          </Tooltip> */}
        </Box>
      </Box>

      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        borderRadius={'8px'}
        sx={{ paddingX: '14px', pt: 1 }}
      >
        <Container sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={initialValues.tabValue}
            onChange={(val, newValue) => {
              setInitalValues({
                ...initialValues,
                tabValue: newValue,
                page: 0
              });
              setSelectedClassrooms([]);
            }}
            TabIndicatorProps={{
              sx: {
                height: '4px',
                borderRadius: '4px'
              }
            }}
            aria-label="school details tabs"
          >
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 0 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 0
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              icon={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.75 7.25H13.35C14.1901 7.25 14.6101 7.25 14.931 7.41349C15.2132 7.5573 15.4427 7.78677 15.5865 8.06901C15.75 8.38988 15.75 8.80992 15.75 9.65V14.75M9.75 14.75V3.65C9.75 2.80992 9.75 2.38988 9.58651 2.06901C9.4427 1.78677 9.21323 1.5573 8.93099 1.41349C8.61012 1.25 8.19008 1.25 7.35 1.25H4.65C3.80992 1.25 3.38988 1.25 3.06901 1.41349C2.78677 1.5573 2.5573 1.78677 2.41349 2.06901C2.25 2.38988 2.25 2.80992 2.25 3.65V14.75M16.5 14.75H1.5M4.875 4.25H7.125M4.875 7.25H7.125M4.875 10.25H7.125"
                    stroke={
                      initialValues.tabValue === 0
                        ? colorTextBlack
                        : colorTextGrey
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              // label={`Total Classrooms ${
              //   schoolDetails?.classrooms
              //     ? `(${schoolDetails?.classrooms})`
              //     : `(0)`
              // }`}
              label={
                <CustomTabLabel
                  title="Total Classrooms"
                  count={
                    (schoolDetails?.classrooms || 0) < 10
                      ? `0${schoolDetails?.classrooms || 0}`
                      : schoolDetails?.classrooms
                  }
                  selected={initialValues.tabValue === 0}
                />
              }
            />
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                fontSize: '16px',
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 1 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 1
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              icon={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.25 14.75V10.25M12 12.5H16.5M9 10.25H6C4.60218 10.25 3.90326 10.25 3.35195 10.4784C2.61687 10.7828 2.03284 11.3669 1.72836 12.1019C1.5 12.6533 1.5 13.3522 1.5 14.75M11.625 1.46807C12.7244 1.91311 13.5 2.99098 13.5 4.25C13.5 5.50902 12.7244 6.58689 11.625 7.03193M10.125 4.25C10.125 5.90685 8.78185 7.25 7.125 7.25C5.46815 7.25 4.125 5.90685 4.125 4.25C4.125 2.59315 5.46815 1.25 7.125 1.25C8.78185 1.25 10.125 2.59315 10.125 4.25Z"
                    stroke={
                      initialValues.tabValue === 1
                        ? colorTextBlack
                        : colorTextGrey
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              // label={`Total Users ${
              //   formData?.users ? `(${formData?.users})` : `(0)`
              // }`}
              label={
                <CustomTabLabel
                  title="Total Users"
                  count={
                    (formData?.users || 0) < 10
                      ? `0${formData?.users || 0}`
                      : formData?.users || 0
                  }
                  selected={initialValues.tabValue === 1}
                />
              }
            />
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                fontSize: '16px',
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 2 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 2
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              icon={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.25 10.25H6C4.60218 10.25 3.90326 10.25 3.35195 10.4784C2.61687 10.7828 2.03284 11.3669 1.72836 12.1019C1.5 12.6533 1.5 13.3522 1.5 14.75M11.625 1.46807C12.7244 1.91311 13.5 2.99098 13.5 4.25M8.9999 15.125L10.5187 14.8213C10.6511 14.7948 10.7174 14.7815 10.7791 14.7573C10.8339 14.7358 10.886 14.7079 10.9343 14.6743C10.9887 14.6363 11.0364 14.5886 11.1319 14.4931L16.125 9.50004C16.5393 9.08581 16.5393 8.4142 16.125 7.99998C15.7108 7.58577 15.0392 7.58578 14.625 8L9.63189 12.9931C9.53641 13.0886 9.48866 13.1363 9.45073 13.1907C9.41705 13.239 9.38918 13.2911 9.36768 13.3459C9.34347 13.4076 9.33022 13.4738 9.30373 13.6062L8.9999 15.125ZM10.125 4.25C10.125 5.90685 8.78185 7.25 7.125 7.25C5.46815 7.25 4.125 5.90685 4.125 4.25C4.125 2.59315 5.46815 1.25 7.125 1.25C8.78185 1.25 10.125 2.59315 10.125 4.25Z"
                    stroke={
                      initialValues.tabValue === 2
                        ? colorTextBlack
                        : colorTextGrey
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              // label={`Employee List ${
              //   schoolDetails?.employees
              //     ? `(${schoolDetails?.employees})`
              //     : `(0)`
              // }`}

              label={
                <CustomTabLabel
                  title="Employee List"
                  count={
                    (schoolDetails?.employees || 0) < 10
                      ? `0${schoolDetails?.employees || 0}`
                      : schoolDetails?.employees
                  }
                  selected={initialValues.tabValue === 2}
                />
              }
            />
          </Tabs>
          {initialValues.tabValue === 0 && (
            <div style={{ display: 'flex', margin: 'auto 10px' }}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                  setAnchorEl(null);
                  setShowError(null);
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    width: '20ch',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {['Delete'].map(option => (
                  <MenuItem
                    key={option}
                    disabled={
                      (initialValues.type === 1 && option === 'Activate') ||
                      (initialValues.type === 2 && option === 'De-Activate')
                    }
                    onClick={() => {
                      handleMenuActions(option);
                    }}
                  >
                    <ListItemIcon>
                      {option === 'Delete' ? (
                        <>
                          <SvgIcon sx={{ height: 20, width: 20 }}>
                            <TrashIcon color="red" />
                          </SvgIcon>
                        </>
                      ) : (
                        // option === 'De-Activate' ? (
                        //   <SvgIcon
                        //     sx={{ height: 20, width: 20, color: 'orangered' }}
                        //   >
                        //     <NotInterested />
                        //   </SvgIcon>
                        // ) : option === 'Activate' ? (
                        //   <SvgIcon sx={{ height: 20, width: 20, color: 'green' }}>
                        //     <CheckCircleOutline />
                        //   </SvgIcon>
                        // ) :
                        ''
                      )}
                    </ListItemIcon>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </Container>
        <Grid sx={{ padding: '18px 0px' }} width={'100%'}>
          {isSchoolDetailsLoading && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}

          {!isSchoolDetailsLoading && isSchoolDetailsSuccess && (
            <CustomDataTable
              allowSelection={initialValues.tabValue === 0 ? true : false}
              columns={
                initialValues.tabValue === 0
                  ? classroomColumns
                  : initialValues.tabValue === 1 || initialValues.tabValue === 2
                    ? userColumns
                    : []
              }
              defaultOrderBy={'createdDate'}
              data={
                initialValues.tabValue === 0
                  ? schoolOtherDetails['dataListOfClassrooms'] &&
                    schoolOtherDetails['dataListOfClassrooms'].length > 0
                    ? schoolOtherDetails['dataListOfClassrooms']
                    : []
                  : initialValues.tabValue === 1
                    ? schoolOtherDetails['dataListOfUsers'].length > 0
                      ? schoolOtherDetails['dataListOfUsers']
                      : []
                    : initialValues.tabValue === 2
                      ? schoolOtherDetails['dataListOfEmployees'].length > 0
                        ? schoolOtherDetails['dataListOfEmployees']
                        : []
                      : []
              }
              onPageChange={val => {
                setInitalValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitalValues({ ...initialValues, size: val });
              }}
              selectedRows={[...selectedClassrooms]}
              onSelectionChange={val => {
                setSelectedClassrooms(val);
              }}
              defaultPage={initialValues.page}
              defaultRowsPerPage={initialValues.size}
              totalCount={
                initialValues.tabValue === 0
                  ? schoolDetails?.classrooms
                    ? schoolDetails?.classrooms
                    : 0
                  : initialValues.tabValue === 1
                    ? schoolDetails.users
                      ? schoolDetails.users
                      : 0
                    : initialValues.tabValue === 2
                      ? schoolDetails.employees
                        ? schoolDetails.employees
                        : 0
                      : 0
              }
              onRowClick={row => handleOnRowClick(row)}
            />
          )}
        </Grid>
        <ConfirmBox
          isOpen={confirmation}
          title="Delete Classroom!"
          subtitle={`${selectedClassrooms.length} classroom[s] are selected, Are you sure you want to delete. `}
          handleClose={() => setConfimation(null)}
          handleSubmit={() => {
            deleteUser();
          }}
        />
      </Grid>
      {showError && <SnackBarBox type={'error'} message={showError} />}
      {!isDeleteClassroomLoading && isDeleteClassroomSuccess && (
        <SnackBarBox
          type="success"
          message={'Classroom[s] deleted successfully. '}
        />
      )}
      {!isDeleteClassroomLoading && isDeleteClassroomFailed && (
        <SnackBarBox
          type="error"
          message={handleError(deleteClassroomErrorContainer?.errorMessage)}
        />
      )}
    </Box>
  );
};

export default SchoolDetails;
