import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  Skeleton,
  SvgIcon,
  TextField,
  Typography,
  Link,
  InputLabel,
  InputAdornment
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import {
  createUpdateArea,
  getAreaDetails,
  getDistrict,
  getState,
  revertAreaFormDetails
} from '../../redux/reducers/mastersSlice';
import {
  createTitle,
  enterOnlyNumbers,
  handleError,
  isArrayNotEmpty
} from '../../utils/utils';
import SnackBarBox from '../../components/SnackBarBox';
import CustomBreadcrumb from '../../components/Breadcrumb';
import { ChevronRightIcon, SearchMdIcon } from '../../utils/iconSvg';

const AddArea = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { authData } = useSelector(state => state.auth);

  const {
    stateData,
    districtData,
    isStateFailed,
    isDistrictFailed,
    areaDetails,
    isAreaDetailsSuccess,
    isCreateUpdateAreaLoading,
    isCreateUpdateAreaSuccess,
    isCreateUpdateAreaFailed,
    createUpdateAreaErrorContainer,
    createUpdateAreaResponse
  } = useSelector(state => state.masters);

  // console.log('districtByStateData', districtByStateData);

  useEffect(() => {
    let mastersPayload = {
      type: 'all',
      page: 0,
      size: 0
    };
    dispatch(getState(mastersPayload));
    dispatch(getDistrict(mastersPayload));
    // if (formik.values.stateId) {
    //   dispatch(getDistrictByState(mastersPayload, formik.values.stateId.id));
    // }
    document.title = createTitle(
      `${location.pathname === '/area/area-details/edit-area' ? 'Edit' : 'Add'} Area`
    );

    if (
      location.pathname === '/area/area-details/edit-area' &&
      location.state
    ) {
      let areaId = location.state.pinId || null;
      dispatch(
        getAreaDetails({
          pinId: areaId,
          payload: {
            page: 0,
            size: 1,
            type: 'all'
          }
        })
      );
    }

    return () => dispatch(revertAreaFormDetails());
  }, []);

  // Formik form control
  const formik = useFormik({
    initialValues: {
      state: null,
      district: null,
      area: null,
      pinCode: null
    },
    validationSchema: yup.object().shape({
      state: yup
        .object()
        .shape({
          id: yup.string().required()
        })
        .required('State is required'),
      district: yup
        .object()
        .shape({
          id: yup.string().required()
        })
        .required('District is required'),
      pinCode: yup
        .string()
        .test(
          'is-email-or-phone',
          'Pincode must contain only numbers',
          value => {
            return enterOnlyNumbers(value);
          }
        )
        .required('Pincode is required'),
      area: yup.string().required('Area name is required')
    }),
    onSubmit: values => {
      setLoading(true);

      let submitPayload = {
        stateId: values.state.id,
        districtId: values.district.id,
        area: values.area,
        pinCode: values.pinCode,
        loggedInUserName: authData?.userName
      };

      dispatch(
        createUpdateArea({
          payload: submitPayload,
          pinId: areaDetails?.pinId
        })
      );
    }
  });

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (areaDetails?.pinId) {
        formik.setValues({
          state: {
            id: areaDetails.stateId,
            label: areaDetails.stateName
          },
          district: {
            id: areaDetails.districtId,
            label: areaDetails.districtName
          },
          area: areaDetails?.area || '',
          pinCode: areaDetails?.pinCode || ''
        });

        setLoading(false);
      } else {
        setLoading(false);
      }
    }, 1200);
  }, [isAreaDetailsSuccess]);

  function getOptionLabel(option) {
    if (!option) {
      return ''; // or any default label you want to return for null options
    }

    if (option.label !== null) {
      return option.label;
    }

    if (option.id !== null) {
      return option.id;
    }

    return ''; // or any default label you want to return for null label and id
  }

  const handleFormValue = (e, field) => {
    formik.setFieldValue(field, e.target.value);
  };

  useEffect(() => {
    if (isCreateUpdateAreaSuccess) {
      setTimeout(() => {
        dispatch(revertAreaFormDetails());
        navigate('/area');
      }, 2000);
    }

    if (isCreateUpdateAreaFailed) {
      setLoading(false);
    }
  }, [isCreateUpdateAreaSuccess, isCreateUpdateAreaFailed]);

  const areAllRequiredFieldsFilled = () => {
    return (
      formik.values.state &&
      formik.values.district &&
      formik.values.pinCode &&
      formik.values.area
    );
  };

  const isSaveDisabled = !formik.isValid || !areAllRequiredFieldsFilled();

  return (
    <Box>
      <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <SvgIcon sx={{ height: 24, width: 24 }}>
              <ChevronRightIcon color={colorTextGrey} />
            </SvgIcon>
          }
          sx={{ marginBottom: 2 }}
        >
          <Link
            color="inherit"
            href={'area'}
            onClick={event => {
              event.preventDefault();
              navigate(
                location.pathname === '/area/area-details/edit-area'
                  ? '/area/area-details'
                  : '/area',
                {
                  state: { ...location.state }
                }
              );
            }}
            sx={{ textDecoration: 'none' }}
          >
            <Typography
              fontWeight={'700'}
              color={colorLinkGrey}
              fontSize={'24px'}
            >
              {areaDetails?.area || 'Area'}
            </Typography>
          </Link>
          <Typography
            sx={{ fontSize: '32px', fontWeight: '700', color: colorTextBlack }}
          >
            {`${location.pathname === '/area/area-details/edit-area' ? 'Edit' : 'Add new'} area`}
          </Typography>
        </Breadcrumbs>
      </Typography>

      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}
      {!loading && (
        <>
          <Box
            bgcolor={'white'}
            padding={2}
            borderRadius={2}
            marginBottom={2}
            sx={{ width: '100%' }}
            // sx={{
            //   width: {
            //     lg: '60%',
            //     md: '100%'
            //   }
            // }}
          >
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid>
                <Grid sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <Grid item sm={12} sx={{ width: '280px' }}>
                    <InputLabel
                      required
                      sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    >
                      State
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        size="small"
                        value={formik.values.state}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            'state',
                            newValue ? newValue : null
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === (value ? value.id : null)
                        }
                        getOptionLabel={getOptionLabel}
                        options={
                          isArrayNotEmpty(stateData.state)
                            ? stateData.state.map(c => {
                                return { label: c.stateName, id: c.stateId };
                              })
                            : []
                        }
                        sx={{
                          width: '100%'
                        }}
                        clearIcon={false}
                        renderInput={params => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span
                                    style={{
                                      padding: '0.2rem 0px 5px 5px',
                                      display: 'inline-flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <SearchMdIcon
                                      color={colorTextGrey}
                                      height="16px"
                                      width="16px"
                                    />
                                  </span>
                                </InputAdornment>
                              )
                            }}
                            placeholder="Select state "
                            error={formik.touched.state && formik.errors.state}
                            helperText={
                              formik.touched.state && formik.errors.state
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} sx={{ width: '280px' }}>
                    <InputLabel
                      required
                      sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    >
                      District
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        size="small"
                        value={formik.values.district}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            'district',
                            newValue ? newValue : null
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === (value ? value.id : null)
                        }
                        getOptionLabel={getOptionLabel}
                        options={
                          isArrayNotEmpty(districtData.district)
                            ? districtData.district
                                .filter(
                                  cc => cc.stateId === formik.values.state?.id
                                )
                                .map(c => {
                                  return {
                                    label: c.districtName,
                                    id: c.districtId
                                  };
                                })
                            : []
                        }
                        sx={{ width: '100%' }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span
                                    style={{
                                      padding: '0.2rem 0px 5px 5px',
                                      display: 'inline-flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <SearchMdIcon
                                      color={colorTextGrey}
                                      height="16px"
                                      width="16px"
                                    />
                                  </span>
                                </InputAdornment>
                              )
                            }}
                            placeholder="Select district "
                            error={
                              formik.touched.district && formik.errors.district
                            }
                            helperText={
                              formik.touched.district && formik.errors.district
                            }
                          />
                        )}
                        clearIcon={false}
                        ListboxProps={{
                          sx: {
                            '& .MuiAutocomplete-option:hover': {
                              backgroundColor: colorSecondary
                            }
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', gap: 2 }}>
                  <Grid item sm={12} sx={{ width: '280px' }}>
                    <InputLabel
                      required
                      sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    >
                      Pincode
                    </InputLabel>
                    <TextField
                      placeholder="Enter pincode"
                      name="pinCode"
                      size="small"
                      fullWidth
                      inputProps={{ maxLength: 6 }}
                      value={formik.values.pinCode}
                      onChange={e => handleFormValue(e, 'pinCode')}
                      error={formik.touched.pinCode && formik.errors.pinCode}
                      InputLabelProps={{
                        shrink: formik.values.pinCode !== ''
                      }}
                      helperText={
                        formik.touched.pinCode && formik.errors.pinCode
                      }
                    />
                  </Grid>
                  <Grid item sm={12} sx={{ width: '280px' }}>
                    <InputLabel
                      required
                      sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    >
                      Area Name
                    </InputLabel>
                    <TextField
                      placeholder="Enter area name"
                      name="area"
                      fullWidth
                      size="small"
                      inputProps={{ maxLength: 64 }}
                      value={formik.values.area}
                      onChange={formik.handleChange}
                      error={formik.touched.area && Boolean(formik.errors.area)}
                      InputLabelProps={{
                        shrink: formik.values.area !== ''
                      }}
                      helperText={formik.touched.area && formik.errors.area}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Grid
            bgcolor={'white'}
            padding={2}
            borderRadius={2}
            marginTop={2}
            display={'flex'}
            gap={2}
            sx={{ width: '100%' }}
            // sx={{
            //   width: {
            //     lg: '60%',
            //     md: '100%'
            //   }
            // }}
          >
            <Button
              // variant="outlined"
              onClick={() => {
                navigate('/area');
              }}
              // style={{ marginRight: 24 }}
              variant="outlined"
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              onClick={() => formik.handleSubmit()}
              disabled={isSaveDisabled}
              variant="contained"
              sx={{
                width: '94px',
                height: '42px',
                boxShadow: 'none'
              }}
            >
              Save
            </LoadingButton>
          </Grid>
        </>
      )}
      {isStateFailed && (
        <SnackBarBox
          type={'error'}
          message={`Failed to fetch data for:- State Master `}
        />
      )}

      {isDistrictFailed && (
        <SnackBarBox
          type={'error'}
          message={`Failed to fetch data for:- District Master `}
        />
      )}

      {!isCreateUpdateAreaLoading && isCreateUpdateAreaSuccess && (
        <SnackBarBox
          type="success"
          message={createUpdateAreaResponse || 'Action performed successfully'}
        />
      )}

      {!isCreateUpdateAreaLoading && isCreateUpdateAreaFailed && (
        <SnackBarBox
          type="error"
          message={handleError(createUpdateAreaErrorContainer?.errorMessage)}
        />
      )}
    </Box>
  );
};

export default AddArea;
