import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getFlowsAndFormsAnalytics,
  getMetaDataArea,
  getMetaDataDistrict,
  getMetaDataSchool,
  getMetaDataState,
  getUserAnalytics,
  getUserAndRoleAnalytics
} from '../../redux/reducers/analyticsSlice';
import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  SvgIcon,
  Typography
} from '@mui/material';
import {
  Calendar01Icon,
  Expand05Icon,
  FileDownload03Icon,
  FilterLinesIcon,
  User01Icon,
  XClose
} from '../../utils/iconSvg';
import { isArrayNotEmpty } from '../../utils/utils';
import { useSelector } from 'react-redux';
import {
  colorPrimary,
  colorSecondary,
  colorTextBlack
} from '../../config/theme';
import {
  Legend,
  prepareChartOptionsAndData,
  styledMenuItem,
  StyledSelect
} from '../productanalytics/UserAndRoleAnalytics';
import dayjs from 'dayjs';
import { HighlightOff } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Chart from 'react-google-charts';
import { useLocation } from 'react-router-dom';

const SelectStyled = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '&:hover  .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8
  }
};
const ShareFlowsAndFormsAnalytics = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let chartType = new URLSearchParams(location.search).get('chartType');

  const [tempDateFilter, setTempDateFilter] = useState({
    dateType: null,
    startDate: null,
    endDate: null
  });
  const [chart, setChart] = useState('');
  const [open, setOpen] = useState(null);
  const [chart1Payload, setChart1Payload] = useState({
    dateType: 'all',
    startDate: null,
    endDate: null
  });
  const [chart1Wrapper, setChart1Wrapper] = useState(null);

  const {
    isFlowsAndFormsAnalyticsLoading,
    flowsAndFormsAnalyticsData,
    flowsAndFormsAnalyticsDetailData
  } = useSelector(state => state.analytics);

  useEffect(() => {
    let mastersPayload = {
      type: 'all',
      page: 0,
      size: 0,
      search: ''
    };
    dispatch(getMetaDataState(mastersPayload));
    dispatch(getMetaDataDistrict(mastersPayload));
    dispatch(getMetaDataArea(mastersPayload));
    dispatch(getMetaDataSchool(mastersPayload));
  }, []);

  useEffect(() => {
    if (chartType == 'flowsAndFormsChart') {
      let payload = {
        dateType:
          chart1Payload.dateType === 'all' ? null : chart1Payload.dateType,
        startDate: chart1Payload.startDate
          ? dayjs(chart1Payload.startDate).format('YYYY-MM-DD')
          : null,
        endDate: chart1Payload.endDate
          ? dayjs(chart1Payload.endDate).format('YYYY-MM-DD')
          : null
      };
      dispatch(getFlowsAndFormsAnalytics(payload));
    }
  }, [chart1Payload]);

  const handleClickOpen = id => {
    setChart(id);
  };

  const handleClose = () => {
    setChart('');
  };
  const options = {
    hAxis: {
      title: 'Date',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      }
    },
    vAxis: {
      title: 'Count',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      },
      viewWindow: {
        min: 0
      },
      gridlines: {
        count: 10,
        color: '#F5F7FA',
        units: {
          ones: { count: 0 }
        }
      }
    },
    curveType: 'function',
    series: {
      0: { color: colorPrimary, curveType: 'function' },
      1: { color: '#2F68C4', curveType: 'function' },
      2: { color: '#749E35', curveType: 'function' }
    },
    legend: { position: 'none' }
  };

  const handleDownload = ({ id = null }) => {
    if (id) {
      const chartWrapper = id === 1 && chart1Wrapper;
      const imageURI = chartWrapper.getChart().getImageURI();
      const link = document.createElement('a');
      link.href = imageURI;
      link.download = 'chart.png';
      link.click();
    }
  };

  return (
    <Grid item>
      <Typography mb={2} variant="h5" fontWeight={'600'}>
        Flow & Form Analytics
      </Typography>
      {chartType == 'flowsAndFormsChart' && (
        <Grid
          mt={2}
          container
          display={'flex'}
          width={'100%'}
          flexDirection={'column'}
          sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
        >
          <Grid item display={'flex'} mb={1}>
            <Grid
              container
              display={'flex'}
              mb={1}
              justifyContent={'space-between'}
            >
              <Grid xs={12} lg={3} sm={12} md={6} mr={1} mb={1} width={'100%'}>
                <FormControl
                  variant="outlined"
                  sx={{ width: '296px' }}
                  size="small"
                >
                  <StyledSelect
                    size="small"
                    IconComponent={Calendar01Icon}
                    open={open == 1}
                    value={
                      open == 1
                        ? tempDateFilter.dateType
                        : chart1Payload.dateType
                    }
                    onOpen={(e, va) => {
                      setOpen(1);
                      setTempDateFilter({
                        dateType: chart1Payload.dateType,
                        startDate: chart1Payload.startDate,
                        endDate: chart1Payload.endDate
                      });
                    }}
                    onChange={e => {
                      setTempDateFilter({
                        ...tempDateFilter,
                        dateType: e.target.value,
                        startDate: null,
                        endDate: null
                      });
                    }}
                    renderValue={value => {
                      let data = open === 1 ? tempDateFilter : chart1Payload;
                      if (data.dateType === 'all') {
                        return (
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#ABB4BD'
                            }}
                          >
                            <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                              <FilterLinesIcon color="#ABB4BD" />
                            </SvgIcon>
                            Select date
                          </Typography>
                        );
                      }
                      switch (data.dateType) {
                        case 'selected_date':
                          return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                        case 'Week':
                          return 'Last Week';
                        case 'Month':
                          return 'This Month';
                        case 'past_3_months':
                          return 'Past 3 months';
                        case 'Past_1_Year':
                          return 'Past 1 Year';
                        default:
                          return;
                      }
                    }}
                    // endAdornment={
                    //   chart1Payload.dateType ? (
                    //     <IconButton
                    //       sx={{ marginRight: '10px' }}
                    //       aria-label="clear"
                    //       size="small"
                    //       onClick={() => {
                    //         setChart1Payload({
                    //           ...chart1Payload,
                    //           dateType: 'Month',
                    //           startDate: null,
                    //           endDate: null
                    //         });
                    //         setTempDateFilter({
                    //           startDate: null,
                    //           dateType: null,
                    //           endDate: null
                    //         });
                    //       }}
                    //       edge="end"
                    //     >
                    //       <HighlightOff sx={{ color: '#ABB4BD' }} />
                    //     </IconButton>
                    //   ) : null
                    // }
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&:hover  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8
                      }
                    }}
                  >
                    <MenuItem
                      sx={{ ...styledMenuItem, paddingX: 0, border: 'none' }}
                      value="selected_date"
                      disableRipple
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display={'flex'} margin={'0px 6px'}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <DatePicker
                              name="Start-Date"
                              sx={{ marginRight: 1 }}
                              value={dayjs(tempDateFilter.startDate)}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack }
                                  }
                                }
                              }}
                              onChange={date =>
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  startDate: date,
                                  endDate: date
                                })
                              }
                              components={{
                                OpenPickerIcon: props => (
                                  <IconButton size="small" {...props}>
                                    <Calendar01Icon />
                                  </IconButton>
                                )
                              }}
                            />
                          </FormControl>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <DatePicker
                              minDate={dayjs(tempDateFilter.startDate)}
                              label="End Date"
                              value={dayjs(tempDateFilter.endDate)}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack }
                                  }
                                }
                              }}
                              onChange={date => {
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  endDate: date
                                });
                              }}
                              components={{
                                OpenPickerIcon: props => (
                                  <IconButton size="small" {...props}>
                                    <Calendar01Icon />
                                  </IconButton>
                                )
                              }}
                            />
                          </FormControl>
                        </Box>
                      </LocalizationProvider>
                    </MenuItem>

                    <MenuItem sx={styledMenuItem} value="Week" disableRipple>
                      Last Week
                    </MenuItem>
                    <MenuItem sx={styledMenuItem} value="Month" disableRipple>
                      This Month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="past_3_months"
                      disableRipple
                    >
                      Past 3 month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="Past_1_Year"
                      disableRipple
                    >
                      Past 1 year
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        if (tempDateFilter.dateType) {
                          setChart1Payload({
                            ...chart1Payload,
                            dateType: tempDateFilter.dateType,
                            startDate: tempDateFilter.startDate,
                            endDate: tempDateFilter.endDate
                          });
                        }
                        setTempDateFilter({
                          dateType: null,
                          endDate: null,
                          startDate: null
                        });
                        setOpen(null);
                      }}
                    >
                      Apply
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        setChart1Payload({
                          ...chart1Payload,
                          dateType: 'all',
                          startDate: null,
                          endDate: null
                        });
                        setTempDateFilter({
                          startDate: null,
                          dateType: null,
                          endDate: null
                        });
                        setOpen(null);
                      }}
                    >
                      Close
                    </MenuItem>
                  </StyledSelect>
                </FormControl>{' '}
              </Grid>
              <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
                <IconButton onClick={() => handleClickOpen(1)} sx={{ mx: 0.5 }}>
                  <SvgIcon sx={{ height: 18, width: 18 }}>
                    <Expand05Icon />
                  </SvgIcon>
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleDownload({ id: 1 });
                  }}
                  sx={{ mr: 0.5 }}
                >
                  <SvgIcon sx={{ height: 18, width: 18 }}>
                    <FileDownload03Icon />
                  </SvgIcon>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Legend
            series={
              flowsAndFormsAnalyticsData.analyticsData.length > 0
                ? flowsAndFormsAnalyticsData.analyticsData[0]
                    .slice(1)
                    .map((name, index) => ({
                      name,
                      color: options.series[index].color
                    }))
                : []
            }
          />

          <Grid item xs={12} mb={2}>
            {isFlowsAndFormsAnalyticsLoading && (
              <Skeleton height={300} width={'100%'} />
            )}
            {!isFlowsAndFormsAnalyticsLoading && (
              <Chart
                height={'300px'}
                width={'100%'}
                chartType="LineChart"
                data={flowsAndFormsAnalyticsData.analyticsData}
                options={options}
                getChartWrapper={val => {
                  setChart1Wrapper(val);
                }}
              />
            )}{' '}
          </Grid>
        </Grid>
      )}

      <Dialog
        sx={{ borderRadius: '8px' }}
        open={chart !== ''}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ borderRadius: 10 }}>
          <Box textAlign={'end'}>
            <IconButton size="small" onClick={handleClose}>
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <XClose />
              </SvgIcon>
            </IconButton>
            <Legend
              series={
                flowsAndFormsAnalyticsData.analyticsData.length > 0
                  ? flowsAndFormsAnalyticsData.analyticsData[0]
                      .slice(1)
                      .map((name, index) => ({
                        name,
                        color: options.series[index].color
                      }))
                  : []
              }
            />
          </Box>
          {chart == 1 && (
            <Chart
              height={'300px'}
              width={'100%'}
              chartType="LineChart"
              data={flowsAndFormsAnalyticsData.analyticsData}
              options={options}
            />
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ShareFlowsAndFormsAnalytics;
