import { Box, Divider, Rating, Typography } from '@mui/material';
import { colorRatingStarOrange } from '../../../config/theme';
import { IMG_TEACHER_DASHBOARD } from '../../../utils/imageUrls';
import { Container } from '../../../utils/styledLayout';
import { useSelector } from 'react-redux';

function TeacherDetails() {
  const { authData, teacherDashboardCountData } = useSelector(
    state => state.auth
  );

  // const getTriangleWithCount = ({ type = 'constant', value = 0 }) => {
  //   console.log(value, 'value');
  //   if (type === 'plus') {
  //     return (
  //       <Box
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           color: 'green',
  //           marginLeft: '8px'
  //         }}
  //       >
  //         <ArrowDropUp />
  //         <span>{value?.toFixed(1)}</span>
  //       </Box>
  //     );
  //   }
  //   if (type === 'minus') {
  //     return (
  //       <Box
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           color: 'red',
  //           marginLeft: '8px'
  //         }}
  //       >
  //         <ArrowDropDown />
  //         <span>{value?.toFixed(1)}</span>
  //       </Box>
  //     );
  //   }
  //   return null; // Rating did not change
  // };
  // console.log(teacherDashboardCountData, 'teacherDashboardCountData');

  return (
    <Container mb={2}>
      <Box bgcolor={'#FBE5B2'} padding={2} borderRadius={2} width={'100%'}>
        <Box style={{ display: 'flex' }}>
          <Box style={{ width: '50%', textAlign: 'left' }}>
            <Box>
              <Typography sx={{ fontWeight: 700, fontSize: '32px' }}>
                Hello {authData?.name?.split(' ')[0] || ''}!
              </Typography>
            </Box>
            <Box style={{ color: '#4E565F' }}>
              <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                {teacherDashboardCountData?.schoolName}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              sx={{ height: '32px', mt: 1 }}
            >
              <Rating
                name="simple-controlled"
                precision={0.5}
                size="large"
                sx={{ color: colorRatingStarOrange }}
                value={teacherDashboardCountData?.averageRating}
                readOnly
              />
              <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
              <Typography sx={{ fontSize: '32px', fontWeight: '700' }}>
                {teacherDashboardCountData?.averageRating}/5
              </Typography>

              {/* <Typography variant="caption" alignSelf={'flex-start'}>
                {' '}
                {getTriangleWithCount({
                  type: teacherDashboardCountData?.pointStatus,
                  value: teacherDashboardCountData?.progressPoint
                })}
              </Typography> */}
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography sx={{ fontSize: '12px', fontWeight: 500, mt: 1 }}>
                {' '}
                (from {teacherDashboardCountData?.forMe} ratings){' '}
              </Typography>
            </Box>
          </Box>
          <Box style={{ width: '50%', textAlign: 'right' }}>
            <img src={IMG_TEACHER_DASHBOARD} alt="Teacher Dashboard Image" />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default TeacherDetails;
