import { styled } from '@mui/material';
import { IMG_SPLASH_LOGO } from '../utils/imageUrls';

const CenteredItem = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
    // [theme.breakpoints.up('md')]: {
    //   margin: 'auto',
    //   marginBottom: '1rem'
    // },
    // [theme.breakpoints.down('md')]: {
    //   margin: '2rem auto'
    // }
  };
});

function CenteredLogo() {
  return (
    <CenteredItem>
      <img src={IMG_SPLASH_LOGO} alt="login_logo" />
    </CenteredItem>
  );
}

export default CenteredLogo;
