import { useMemo } from 'react';
import moment from 'moment';

const useDateFormatter = () => {
  const formatDateZone = date => moment(date).format('D MMMM h:mm a');
  const formatTimeZone = time => moment(time).format('h:mm a');
  const formatZoneDateAndTime = date => {
    const options = {
      weekday: 'short',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return { formatDateZone, formatZoneDateAndTime, formatTimeZone };
};

export default useDateFormatter;
