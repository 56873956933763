import {
  Button,
  Grid,
  Box,
  Avatar,
  LinearProgress,
  Divider,
  Typography,
  Icon,
  SvgIcon
} from '@mui/material';
import {
  IMG_CONTINUE_LEARNING_COURSE,
  IMG_COURSES_ICON,
  IMG_RECOMMENDED_COURSE_1,
  IMG_RECOMMENDED_COURSE_2,
  IMG_CLOCK_ICON,
  IMG_FORWARD_ARROW_ICON
} from '../../../utils/imageUrls';
import {
  colorLightYellow,
  colorPrimary,
  colorPureWhite,
  colorRatingStarOrange,
  colorTextBlack,
  colorTextGrey
} from '../../../config/theme';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const CustomTextLink = ({
  onClick,
  title = '',
  fontWeight = '',
  href,
  target
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <a
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        position: 'relative'
      }}
      onClick={onClick}
      href={href}
      target={target}
    >
      {' '}
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative'
        }}
      >
        <Typography
          fontWeight={fontWeight || '500'}
          sx={{
            fontSize: '14px',
            color: isHovered ? colorPrimary : colorTextBlack
          }}
        >
          {title}
        </Typography>{' '}
        <SvgIcon
          sx={{
            height: '16px',
            width: '16px',
            ml: 0.2
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.83301 8.53874H13.1663M13.1663 8.53874L8.49967 3.87207M13.1663 8.53874L8.49967 13.2054"
              stroke={isHovered ? colorPrimary : colorTextBlack}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </SvgIcon>{' '}
        <span
          style={{
            position: 'absolute',
            bottom: '-2px',
            left: 0,
            width: '100%',
            height: '1px',
            backgroundColor: isHovered ? colorPrimary : colorTextBlack
          }}
        />
      </span>
    </a>
  );
};

function Courses() {
  const theme = useTheme();
  const navigate = useNavigate();
  const courses = [
    {
      continueLearning: [
        {
          title: 'Preparing Lesson Plans',
          completedPercentage: 40,
          minutesLeft: '25',
          image: IMG_CONTINUE_LEARNING_COURSE
        }
      ],
      recommendedCourses: [
        {
          title: 'Creating safe spaces',
          minutesLeft: '20',
          completedPercentage: 0,
          image: IMG_RECOMMENDED_COURSE_1
        },
        {
          title: 'Student discipline',
          minutesLeft: '20',
          completedPercentage: 0,
          image: IMG_RECOMMENDED_COURSE_2
        }
      ]
    }
  ];

  const viewCourses = (courseType, course, id) => {
    return (
      <Grid
        key={id}
        sx={{
          [theme.breakpoints.up('xl')]: {
            display: 'flex'
          },
          mb: 1.3
        }}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box style={{ position: 'relative' }}>
          {/* Flag for status */}
          {typeof course?.completedPercentage !== 'undefined' && (
            <Box
              sx={{
                position: 'absolute',
                top: 10,
                left: 10,
                backgroundColor:
                  course?.completedPercentage > 0
                    ? colorRatingStarOrange
                    : '#2F68C4',
                padding: '4px 8px',
                borderRadius: '4px',
                zIndex: 10
              }}
            >
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: '800',
                  color: colorPureWhite,
                  letterSpacing: '0.5px'
                }}
              >
                {course?.completedPercentage > 0 ? 'In progress' : 'New'}
              </Typography>
            </Box>
          )}

          <Avatar
            variant="rounded"
            src={course?.image}
            alt="Continue Learning Course Image"
            sx={{
              height: { lg: '146px', md: '100px' },
              width: { lg: '195px', md: '100px' }
            }}
          />
        </Box>

        <Box style={{ width: '100%', padding: '3%' }}>
          <Typography style={{ fontSize: '16px', fontWeight: 600 }}>
            {course?.title}
          </Typography>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              margin: '5px 0px'
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {courseType === 'continueLearning' && (
                <Typography
                  sx={{ color: '#EA7804', fontSize: '10px', fontWeight: 700 }}
                >
                  {course?.completedPercentage} % completed
                </Typography>
              )}
            </Box>
            <Box
              style={{
                color: '#ABB4BD',
                fontSize: '10px',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img
                src={IMG_CLOCK_ICON}
                alt="Clock Icon Image"
                style={{ marginRight: '5px', height: '15px', width: '15px' }}
              />
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: '600',
                  color: colorTextGrey
                }}
              >
                {' '}
                {course?.minutesLeft} min left
              </Typography>
            </Box>
          </Box>

          <LinearProgress
            variant="determinate"
            sx={{
              borderRadius: 5,
              backgroundColor: '#E4E7EB',
              '& .MuiLinearProgress-bar': {
                borderRadius: 5,
                backgroundColor: '#EA7804'
              }
            }}
            value={course?.completedPercentage}
          />

          <Box
            style={{
              color: '#1F2933',
              fontWeight: 700,
              fontSize: '14px',
              marginTop: '10px'
            }}
          >
            <CustomTextLink
              onClick={() => {}}
              fontWeight="700"
              title={
                courseType === 'continueLearning' ? 'Continue' : 'Enroll Now'
              }
            />
            {/* {courseType === 'continueLearning' ? (
              <span style={{ textDecoration: 'underline' }}>
                Continue
                <img
                  src={IMG_FORWARD_ARROW_ICON}
                  alt="Forward Arrow Icon Image"
                />
              </span>
            ) : (
              <span style={{ textDecoration: 'underline' }}>
                Enroll Now{' '}
                <img
                  src={IMG_FORWARD_ARROW_ICON}
                  alt="Forward Arrow Icon Image"
                />
              </span>
            )} */}
          </Box>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid sx={{ backgroundColor: 'white', padding: 1.6, borderRadius: '8px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          mt: 1
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <img src={IMG_COURSES_ICON} alt="Icon Image" />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '20px',
              lineHeight: '20px',
              ml: 1
            }}
          >
            Courses
          </Typography>
        </Box>
        <Button
          variant="outlined"
          sx={{
            borderColor: colorPrimary,
            borderRadius: '10px',
            width: '106px',
            height: '36px',
            color: 'black',
            borderWidth: '2px',
            fontWeight: '700',
            fontSize: '12px',
            '&:hover': {
              borderColor: colorPrimary,
              backgroundColor: 'transparent',
              borderRadius: '10px',
              borderWidth: '2px'
            }
          }}
          onClick={() => {
            navigate('/courses');
          }}
        >
          All Courses
        </Button>
      </Box>

      <Box sx={{ marginY: 2 }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
          Continue learning
        </Typography>
      </Box>
      {courses?.map(item => {
        return item?.continueLearning?.map((continueLearningItem, id) => {
          return viewCourses('continueLearning', continueLearningItem, id);
        });
      })}
      <Divider orientation="horizontal" sx={{ marginY: 2 }} />
      <Box sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
          {' '}
          Recommended courses
        </Typography>
      </Box>
      {courses?.map(item => {
        return item?.recommendedCourses?.map((recommendedCoursesItem, id) => {
          return viewCourses('recommended', recommendedCoursesItem, id);
        });
      })}
    </Grid>
  );
}

export default Courses;
