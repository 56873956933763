import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  Skeleton,
  SvgIcon,
  TextField,
  Typography,
  Link,
  InputLabel,
  InputAdornment
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import SnackBarBox from '../../components/SnackBarBox';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import {
  createUpdateClassroom,
  getClassroomDetails,
  getGradeList,
  getTeacherList,
  revertClassroomFormData
} from '../../redux/reducers/mastersSlice';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import { ChevronRightIcon, SearchMdIcon } from '../../utils/iconSvg';

function AddClassroom() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { authData } = useSelector(state => state.auth);
  const {
    gradeList,
    teacherList,
    createUpdateClassroomErrorContainer,
    isCreateUpdateClassroomFailed,
    isCreateUpdateClassroomSuccess,
    isCreateUpdateClassroomLoading,
    isCreateUpdateClassroomResponse,
    isClassroomDetailsSuccess,
    classroomDetails
  } = useSelector(state => state.masters);

  useEffect(() => {
    document.title = createTitle(
      `${location.pathname === '/schools/edit-class' ? 'Edit' : 'Add'} Classroom`
    );

    dispatch(getGradeList());
    dispatch(getTeacherList());
    let classroomId = location.state?.classroomId || null;

    if (
      location.pathname === '/schools/school-details/edit-class' &&
      location.state
    ) {
      dispatch(getClassroomDetails(classroomId));
    }
    return () => dispatch(revertClassroomFormData());
  }, []);

  useEffect(() => {
    if (isCreateUpdateClassroomSuccess) {
      setTimeout(() => {
        dispatch(revertClassroomFormData());
        navigate('/schools');
      }, 2000);
    }

    if (isCreateUpdateClassroomFailed) {
      setLoading(false);
    }
  }, [isCreateUpdateClassroomSuccess, isCreateUpdateClassroomFailed]);

  useEffect(() => {
    setTimeout(() => {
      if (classroomDetails.classroomId) {
        formik.setValues({
          gradeId: {
            id: classroomDetails.gradeId,
            label: classroomDetails.grade
          },
          division: classroomDetails.division,
          teacherId: {
            id: classroomDetails.teacherId,
            label: classroomDetails.teacherName
          }
        });

        setLoading(false);
      } else {
        setLoading(false);
      }
    }, 1200);
  }, [isClassroomDetailsSuccess]);

  function getOptionLabel(option) {
    if (!option) {
      return '';
    }

    if (option.label !== null) {
      return option.label;
    }

    if (option.id !== null) {
      return option.id;
    }

    return '';
  }

  const formik = useFormik({
    initialValues: {
      gradeId: '',
      division: '',
      teacherId: ''
    },
    validationSchema: yup.object().shape({
      gradeId: yup
        .object()
        .shape({
          id: yup.string().required(),
          label: yup.string()
        })
        .required('Grade is required'),
      division: yup.string().required('Division is required'),
      teacherId: yup
        .object()
        .shape({
          id: yup.string().required(),
          label: yup.string()
        })
        .required('Teacher is required')
    }),
    onSubmit: values => {
      setLoading(true);
      let submitPayload = {
        schoolId: location.state.schoolId,
        gradeId: values.gradeId.id,
        division: values.division,
        teacherId: values.teacherId.id,
        loggedInUserName: authData?.userName
      };
      dispatch(
        createUpdateClassroom({
          payload: submitPayload,
          classroomId: location.state?.classroomId || null
        })
      );
    }
  });

  const areAllRequiredFieldsFilled = () => {
    return (
      formik.values.teacherId && formik.values.gradeId && formik.values.division
    );
  };

  const isSaveDisabled = !formik.isValid || !areAllRequiredFieldsFilled();

  return (
    <>
      <Box>
        <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={colorTextGrey} />
              </SvgIcon>
            }
            sx={{ marginBottom: 2 }}
          >
            <Link
              color="inherit"
              href={'edit-class'}
              onClick={event => {
                event.preventDefault();
                navigate('/schools/school-details', {
                  state: { ...location.state }
                });
              }}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                fontWeight={'700'}
                color={colorLinkGrey}
                fontSize={'24px'}
              >
                {location.pathname !== '/schools/school-details/edit-class'
                  ? location.state?.schoolName
                  : location.state?.division}
              </Typography>
            </Link>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
                color: colorTextBlack
              }}
            >
              {`${location.pathname === '/schools/school-details/edit-class' ? 'Edit' : 'Add new'} classroom`}
            </Typography>
          </Breadcrumbs>
        </Typography>

        {loading && (
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={400}
            animation="wave"
          />
        )}
        {!loading && (
          <form autoComplete="off">
            <Box
              bgcolor={'white'}
              padding={2}
              borderRadius={2}
              marginBottom={2}
            >
              <Grid container spacing={3}>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Grade
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Autocomplete
                      size="small"
                      value={formik.values.gradeId}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          'gradeId',
                          newValue ? newValue : null
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === (value ? value.id : null)
                      }
                      getOptionLabel={getOptionLabel}
                      options={
                        isArrayNotEmpty(gradeList)
                          ? gradeList.map(c => {
                              return {
                                label: c.gradeName,
                                id: c.gradeId
                              };
                            })
                          : []
                      }
                      sx={{ width: '100%' }}
                      clearIcon={false}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            error={
                              formik.touched.gradeId && formik.errors.gradeId
                            }
                            helperText={
                              formik.touched.gradeId && formik.errors.gradeId
                            }
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span
                                    style={{
                                      padding: '0.2rem 0px 5px 5px',
                                      display: 'inline-flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <SearchMdIcon
                                      color={colorTextGrey}
                                      height="16px"
                                      width="16px"
                                    />
                                  </span>
                                </InputAdornment>
                              )
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  {' '}
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Division
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    {/* <Autocomplete
                      size="small"
                      value={formik.values.division}
                      //   onChange={(event, newValue) => {
                      //     let tempParentRole = roleGroupsData.find(
                      //       cc => cc.id === newValue.id
                      //     );

                      //     if (isArrayNotEmpty(tempParentRole.others)) {
                      //       setParentRoleData(tempParentRole.others);
                      //     } else {
                      //       setParentRoleData([]);
                      //     }

                      //     formik.setFieldValue(
                      //       'levelId',
                      //       newValue ? newValue : null
                      //     );
                      //     formik.setFieldValue('parentRoleId', null);
                      //   }}
                      //   isOptionEqualToValue={(option, value) =>
                      //     option.id === (value ? value.id : null)
                      //   }
                      //   getOptionLabel={getOptionLabel}
                      //   options={
                      //     isArrayNotEmpty(roleGroupsData)
                      //       ? roleGroupsData.map(c => {
                      //           return {
                      //             label: c.label,
                      //             id: c.id
                      //           };
                      //         })
                      //       : []
                      //   }
                      isOptionEqualToValue={(option, value) =>
                        option.id === (value ? value.id : null)
                      }
                      getOptionLabel={getOptionLabel}
                      options={divisionOptions}
                      sx={{ width: '100%' }}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            label="Division *"
                            error={
                              formik.touched.division && formik.errors.division
                            }
                            helperText={
                              formik.touched.division && formik.errors.division
                            }
                          />
                        );
                      }}
                    /> */}

                    <TextField
                      // label="Division*"
                      name="division"
                      fullWidth
                      size="small"
                      inputProps={{ maxLength: 64 }}
                      value={formik.values.division}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.division &&
                        Boolean(formik.errors.division)
                      }
                      InputLabelProps={{
                        shrink: formik.values.division !== ''
                      }}
                      helperText={
                        formik.touched.division && formik.errors.division
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Teacher
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Autocomplete
                      size="small"
                      value={formik.values.teacherId}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          'teacherId',
                          newValue ? newValue : null
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === (value ? value.id : null)
                      }
                      getOptionLabel={getOptionLabel}
                      options={
                        isArrayNotEmpty(teacherList?.dataList)
                          ? teacherList.dataList.map(c => {
                              return {
                                label: c.teacherName,
                                id: c.teacherId
                              };
                            })
                          : []
                      }
                      sx={{ width: '100%' }}
                      clearIcon={false}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            placeholder="Select teacher"
                            error={
                              formik.touched.teacherId &&
                              formik.errors.teacherId
                            }
                            helperText={
                              formik.touched.teacherId &&
                              formik.errors.teacherId
                            }
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span
                                    style={{
                                      padding: '0.2rem 0px 5px 5px',
                                      display: 'inline-flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <SearchMdIcon
                                      color={colorTextGrey}
                                      height="16px"
                                      width="16px"
                                    />
                                  </span>
                                </InputAdornment>
                              )
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Box>
      <Grid
        bgcolor={'white'}
        padding={2}
        borderRadius={2}
        marginTop={2}
        display={'flex'}
        gap={2}
      >
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/schools');
          }}
          // style={{ marginRight: 24 }}
          sx={{
            borderColor: colorPrimary,
            borderRadius: '10px',
            color: colorTextBlack,
            borderWidth: '2px',
            height: '42px',
            width: '106px',
            '&:hover': {
              borderColor: colorPrimary,
              backgroundColor: 'transparent',
              borderRadius: '10px',
              borderWidth: '2px'
            }
          }}
        >
          Back
        </Button>
        <LoadingButton
          loading={loading}
          disabled={isSaveDisabled}
          onClick={() => formik.handleSubmit()}
          variant="contained"
          sx={{
            width: '94px',
            height: '42px',
            boxShadow: 'none'
          }}
          // style={{ marginRight: 24 }}
          // sx={{ px: 6 }}
        >
          Save
        </LoadingButton>
      </Grid>

      {!isCreateUpdateClassroomLoading && isCreateUpdateClassroomFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            createUpdateClassroomErrorContainer?.errorMessage
          )}
        />
      )}

      {!isCreateUpdateClassroomLoading && isCreateUpdateClassroomSuccess && (
        <SnackBarBox
          type="success"
          message={
            isCreateUpdateClassroomResponse || 'Action performed successfully'
          }
        />
      )}
    </>
  );
}

export default AddClassroom;
