import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  clearMenuData,
  setActiveMenuData,
  setMenuArray,
  setMenuData,
  setOpenMenuData
} from '../../config/cookie';
import { api } from '../../config/api';
export const revertAllLayoutFields = createAction('REVERT_ALL_LAYOUT_FIELDS');
export const setLayoutTitle = createAction('SET_LAYOUT_TITLE');
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');
export const clearLayoutState = createAction('CLEAR_LAYOUT_STATE');
export const setSideBarMenu = createAction('SET_SIDEBAR_MENU');
export const setIsMenuOpen = createAction('SET_OPEN_MENU');
export const clearNotification = createAction('CLEAR_NOTIFACTION_LIST');
export const setOpenedModuleId = createAction('SET_OPEN_MODULE_ID');
export const setMenusDataByLocalStorage = createAction('SET_MENU_DATA');
export const sidebarToggle = createAction('SIDEBAR_TOOGLE');

const intial = {
  title: '',
  backwardLink: '',
  backwardLinkText: '',
  forwardLink: '',
  modules: null,
  isModulesLoading: false,
  isModulesSuccess: false,
  isModulesError: false,
  modulesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  sidebarOpen: true,
  activeMenuId: null,
  expandedMenus: {},
  isNotificationsLoading: false,
  isNotificationsSucess: false,
  isNotificationsError: false,
  notifications: {
    today: [],
    week: [],
    month: [],
    yesterday: [],
    todayCount: 0,
    yesterdayCount: 0,
    weekCount: 0,
    monthCount: 0,
    isNewNotification: false
  },
  notificationErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getSidebarMenu = createAsyncThunk(
  'menu/Modules',
  async (credentials, { rejectWithValue }) => {
    try {
      return credentials;
    } catch (error) {
      // console.log(error, 'rere');
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getNotificaitonsList = createAsyncThunk(
  'layout/NotificationList',
  async (user_Id, { rejectWithValue, getState, dispatch }) => {
    try {
      let userId = user_Id ? user_Id : getState().auth.authData.userId;
      let payload = {
        page: 0,
        size: '0',
        type: 'all'
      };

      let response = await api.post(
        `notification/allUserNotification/${userId}?isVisible=web`,
        payload
      );
      dispatch(updateFirebaseFlag({ flag: true }));
      return response.data?.payload?.dataList || intial.notifications;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const readAllNotification = createAsyncThunk(
  'layout/readAllNotification',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      let userId = getState().auth.authData.userId;
      let response = await api.get(`notification/readAll/${userId}`);

      dispatch(getNotificaitonsList());
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const readNotification = createAsyncThunk(
  'layout/readNotification',
  async (userNotificationId, { rejectWithValue, getState, dispatch }) => {
    try {
      await api.get(
        `notification/read?userNotificationId=${userNotificationId}`
      );

      // dispatch(updateFirebaseFlag({ flag: true }));
      dispatch(getNotificaitonsList());
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const sendNotificationOnEventCreation = createAsyncThunk(
  'layout/sendNotificationOnEventCreation',
  async (payload, { rejectWithValue }) => {
    try {
      await api.post(`notification/newEvent`, payload);
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const updateFirebaseFlag = createAsyncThunk(
  'layout/updateFirebaseFlag',
  async ({ flag }, { rejectWithValue, getState }) => {
    try {
      let response;
      let userId = getState().auth.authData.userId;
      if (flag) {
        response = await api.put(`notification/updateUser?userId=${userId}`);
      }

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: intial,
  reducers: {
    setActiveModule: (state, action) => {
      state.activeMenuId = action.payload;
      setActiveMenuData(action.payload);
    },
    toggleExpandMenu: (state, action) => {
      state.expandedMenus = action.payload;
      setOpenMenuData(action.payload);
    }
  },
  extraReducers: builder => {
    builder.addCase(revertAllLayoutFields, () => {
      return intial;
    });
    builder
      .addCase(setLayoutTitle, (state, action) => {
        state.title = action.payload?.title ? action.payload?.title : '';
      })
      .addCase(logoutAndClearToken, () => {
        return intial;
      })
      .addCase(sidebarToggle, (state, action) => {
        state.sidebarOpen = action.payload;
      })

      .addCase(getSidebarMenu.pending, state => {
        state.isModulesLoading = true;
        state.isModulesError = false;
      })
      .addCase(getSidebarMenu.fulfilled, (state, action) => {
        clearMenuData();
        state.isModulesLoading = false;
        state.isModulesSuccess = true;
        state.modules = action.payload;
        setMenuData(action.payload);
        setMenuArray(action.payload);
      })
      .addCase(getSidebarMenu.rejected, (state, action) => {
        state.isModulesLoading = false;
        state.isModulesError = true;
        state.modulesErrorContainer = {
          ...state.modulesErrorContainer,
          ...action.payload
        };
      })
      .addCase(setMenusDataByLocalStorage, (state, action) => {
        state.modules = action.payload;
      })

      .addCase(getNotificaitonsList.pending, state => {
        state.isNotificationsLoading = true;
        state.isNotificationsSucess = false;
        state.isNotificationsError = false;
      })
      .addCase(getNotificaitonsList.fulfilled, (state, action) => {
        state.isNotificationsLoading = false;
        state.isNotificationsSucess = true;
        state.isNotificationsError = false;
        state.notifications = {
          today: action.payload['today'] || [],
          week: action.payload['week'] || [],
          month: action.payload['month'] || [],
          yesterday: action.payload['yesterday'] || [],
          todayCount: action.payload['todayCount'] || 0,
          yesterdayCount: action.payload['yesterdayCount'] || 0,
          weekCount: action.payload['weekCount'] || 0,
          monthCount: action.payload['monthCount'] || 0,
          isNewNotification: action.payload['isNewNotification'] || false
        };
      })
      .addCase(getNotificaitonsList.rejected, (state, action) => {
        state.isNotificationsLoading = false;
        state.isNotificationsSucess = false;
        state.isNotificationsError = true;
        state.notifications = intial.notifications;
        state.notificationErrorContainer = {
          ...state.notificationErrorContainer,
          ...action.payload
        };
      });
  }
});

export const { setActiveModule, toggleExpandMenu } = layoutSlice.actions;
export default layoutSlice.reducer;
