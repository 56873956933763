import { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import CustomPagination from './CustomTablePagination';
import { colorSecondary, colorTextGrey } from '../config/theme';

const EnhancedTableHead = ({
  columns,
  onSelectAllClick,
  numSelected,
  rowCount,
  allowSelection,
  individualAvgRating
}) => {
  const [cc, setcc] = useState(0);

  useEffect(() => {
    setcc(individualAvgRating);
  }, [individualAvgRating]);

  return (
    <TableHead>
      <TableRow>
        {allowSelection && (
          <TableCell sx={{ width: 10, padding: '0px 12px' }}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all items'
              }}
            />
          </TableCell>
        )}
        {columns.map(column => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sx={{
              width: column.width || 'auto',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: column.width || '100px',
              fontWeight: '600'
            }}
          >
            {column.label === 'Average rating' ? (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{ color: colorTextGrey }}
                  fontSize={'14px'}
                  fontWeight={'700'}
                >
                  {column.label}
                </Typography>
                <Box sx={{ display: 'flex', mt: 1 }}>
                  <Typography sx={{}} variant="body2" fontWeight={'600'}>
                    {individualAvgRating
                      ? parseFloat(individualAvgRating).toFixed(1)
                      : '0.0'}
                  </Typography>
                  <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />

                  <Rating size="small" precision={0.5} readOnly value={cc} />
                </Box>
              </Box>
            ) : (
              <Typography
                sx={{ color: colorTextGrey }}
                fontSize={'14px'}
                fontWeight={'700'}
              >
                {column.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const CustomTable = ({
  columns,
  data,
  onPageChange,
  onRowsPerPageChange,
  defaultPage = 0,
  defaultRowsPerPage = 10,
  selectedRows,
  onSelectionChange,
  totalCount,
  allowSelection,
  individualAvgRating,
  allowPagination = true,
  hideEmptyRows = false,
  onRowClick = () => {},
  allowHover = true
}) => {
  const [page, setPage] = useState(defaultPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(selectedRows || []);
  }, [selectedRows]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = data.map(item => item[columns[0].uinqueKey]);
      setSelected(newSelected);
      onSelectionChange && onSelectionChange(newSelected);
      return;
    }
    setSelected([]);
    onSelectionChange && onSelectionChange([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (onPageChange) onPageChange(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (onRowsPerPageChange) onRowsPerPageChange(newRowsPerPage);
  };

  const visibleRows = data;
  // const visibleRows = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    onSelectionChange && onSelectionChange(newSelected);
  };

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size={'small'}>
          {data.length > 0 && (
            <EnhancedTableHead
              columns={columns}
              individualAvgRating={individualAvgRating}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              allowSelection={allowSelection}
              rowCount={data.length}
            />
          )}
          <TableBody>
            {data.length > 0 ? (
              visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row[columns[0].uinqueKey]);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover={allowHover}
                    tabIndex={-1}
                    key={row[columns[0].id]} // assuming uniqueKey is `id` for simplicity
                    sx={{
                      height: '54px',
                      cursor: allowHover ? 'pointer' : 'default',
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)'
                      },
                      ...(allowHover && {
                        '.MuiTableRow-hover&:hover': {
                          backgroundColor: colorSecondary
                        }
                      })
                    }}
                  >
                    {allowSelection && (
                      <TableCell sx={{ padding: '0px 12px' }}>
                        <Checkbox
                          onClick={event => {
                            handleClick(event, row[columns[0].uinqueKey]);
                          }}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.map(column => (
                      <TableCell
                        onClick={() => onRowClick(row, index)}
                        key={column.id}
                        align={column.numeric ? 'right' : 'left'}
                        sx={{
                          width: column.width || 'auto',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          minWidth: column.width || '100px',
                          fontWeight: '600',
                          borderColor: '#E4E7EB'
                        }}
                      >
                        {column.render
                          ? column.render(row, index)
                          : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: 'center', border: 'none' }}
                >
                  <Typography variant="body1" sx={{ p: 2, pb: 4 }}>
                    No data found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!hideEmptyRows && (
              <TableRow
                style={{ height: 33 * (rowsPerPage - visibleRows.length) }}
              >
                <TableCell colSpan={columns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {allowPagination && data.length > 0 && (
        <>
          <Divider orientation="horizontal" />

          <CustomPagination
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            paginationLabelMode={true}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default CustomTable;
