import React, { useEffect, useState } from 'react';
import {
  Box,
  DialogTitle,
  Grid,
  Radio,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Button,
  TextField,
  IconButton,
  Checkbox,
  Skeleton,
  Autocomplete,
  Chip,
  DialogContent,
  styled
} from '@mui/material';
import { BootstrapDialog } from '../../../utils/styledLayout';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  getScheduleReminderDateForSchedule,
  getUsersAndGroupsForSchedule,
  revertFormScheduleReminder,
  sendReminderUserForSchedule,
  sendReminderUserGroupForSchedule,
  sendScheduleReminderDateForSchedule
} from '../../../redux/reducers/flowsSlice';
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../../config/theme';
import {
  Search,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  ContentCopy,
  Delete,
  Download,
  DragIndicator,
  East,
  SaveOutlined,
  RadioButtonUnchecked,
  RadioButtonChecked
} from '@mui/icons-material';
import {
  createTitle,
  enterOnlyNumbers,
  handleError,
  isArrayNotEmpty
} from '../../../utils/utils';
import SnackBarBox from '../../../components/SnackBarBox';
import {
  AlarmClockIcon,
  Calendar01Icon,
  CircleCheckIcon
} from '../../../utils/iconSvg';
import moment from 'moment';

const CustomCard = ({
  radioValue = '',
  label = '',
  onChange,
  checkedValue,
  scheduleDate,
  handleDateChange
}) => {
  const CustomRadioButtonUncheckedIcon = styled(RadioButtonUnchecked)({
    fontSize: '20px' // Change this to the desired size
  });

  const CustomRadioButtonCheckedIcon = styled(RadioButtonChecked)({
    fontSize: '20px' // Change this to the desired size
  });

  return (
    <Box mb={0.5}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography fontWeight={'700'} fontSize="16px" mt={2}>
            {label}
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: 'right' }}>
          <FormControlLabel
            control={
              <Radio
                value={radioValue}
                checked={checkedValue === radioValue}
                onChange={onChange}
                icon={<CustomRadioButtonUncheckedIcon />}
                checkedIcon={<CustomRadioButtonCheckedIcon />}
                sx={{ padding: 0, height: '20px', width: '20px' }}
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'flex-end',
                  color: 'primary'
                }}
              />
            }
            label=""
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const SendReminder = ({ open, onClose, formId, flowId }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [userGroupReminder, setUserGroupReminder] = useState([]);
  const { authData } = useSelector(state => state.auth);
  const [userIDSelectionResError, setUserIDSelectionResError] = useState({
    error: false,
    errorMessage: ''
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    usersAndGroupsList,
    scheduleReminderDateForm,
    scheduleReminderDateFormLoading,
    scheduleReminderDateFormSuccess,
    scheduleReminderUserFormLoading,
    scheduleReminderUserFormSuccess,
    scheduleReminderUserFormFailed,
    scheduleReminderUserForm,
    scheduleReminderUserFormErrorContainer,
    scheduleReminderUserGroupFormLoading,
    scheduleReminderUserGroupFormSuccess,
    scheduleReminderUserGroupFormFailed,
    scheduleReminderUserGroupForm,
    scheduleReminderUserGroupFormErrorContainer,
    sendScheduleReminderDateFormLoading,
    sendScheduleReminderDateFormSuccess,
    sendScheduleReminderDateFormFailed,
    sendScheduleReminderDateForm,
    sendScheduleReminderDateFormErrorContainer
  } = useSelector(state => state.flow);

  const formik = useFormik({
    initialValues: {
      selectedOption: '',
      scheduleDate: null
    },
    onSubmit: values => {
      const formattedDate = dayjs(values.scheduleDate).format('YYYYMMDD');

      setLoading(true);
      if (selectedOption === '1') {
        dispatch(
          sendReminderUserForSchedule({
            flowId: flowId,
            formId: formId,
            userName: authData?.userName
          })
        );
      } else if (selectedOption === '3') {
        if (userGroupReminder.length === 0) {
          setUserIDSelectionResError({
            error: true,
            errorMessage: `At least one user group should be selected`
          });
          setLoading(false);
          return;
        }
        setUserIDSelectionResError({
          error: false,
          errorMessage: ''
        });
        const userGroupIds = userGroupReminder
          .map(group => group.userGroupId)
          .join(',');
        dispatch(
          sendReminderUserGroupForSchedule({
            formId: formId,
            flowId: flowId,
            userGroupIds,
            userName: authData?.userName
          })
        );
      } else if (selectedOption === '2') {
        if (
          !formik.values.scheduleDate ||
          isNaN(formik.values.scheduleDate.valueOf())
        ) {
          setUserIDSelectionResError({
            error: true,
            errorMessage: `Please select scheduleReminder Date`
          });
          setLoading(false);
          return;
        }
        const dateString = scheduleDate;
        const date = new Date(dateString);
        const formattedDate = values.scheduleDate
          ? dayjs(values.scheduleDate).format('YYYYMMDD')
          : null; //date.toISOString().slice(0, 10).replace(/-/g, '');
        dispatch(
          sendScheduleReminderDateForSchedule({
            flowId: formId,
            scheduleDate: formattedDate,
            userName: authData?.userName
          })
        );
      } else {
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (userIDSelectionResError.error) {
      const timeoutId = setTimeout(() => {
        setUserIDSelectionResError({
          error: false,
          errorMessage: ''
        });
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [userIDSelectionResError]);

  useEffect(() => {
    if (!scheduleReminderUserFormLoading && scheduleReminderUserFormSuccess) {
      setTimeout(() => {
        onClose();
        dispatch(revertFormScheduleReminder());
      }, 1500);
      return;
    }
    if (!scheduleReminderUserFormLoading && scheduleReminderUserFormFailed) {
      setTimeout(() => {
        setLoading(false);
        dispatch(revertFormScheduleReminder());
        return;
      }, 1500);
      return;
    }
    if (
      !scheduleReminderUserGroupFormLoading &&
      scheduleReminderUserGroupFormSuccess
    ) {
      setTimeout(() => {
        onClose();
        dispatch(revertFormScheduleReminder());
      }, 1500);
      return;
    }
    if (scheduleReminderUserGroupFormFailed) {
      setLoading(false);
      dispatch(revertFormScheduleReminder());
      return;
    }
    if (
      !sendScheduleReminderDateFormLoading &&
      sendScheduleReminderDateFormSuccess
    ) {
      setTimeout(() => {
        onClose();
        dispatch(revertFormScheduleReminder());
      }, 1500);
      return;
    }

    if (
      !sendScheduleReminderDateFormLoading &&
      sendScheduleReminderDateFormFailed
    ) {
      setLoading(false);
      setTimeout(() => {
        dispatch(revertFormScheduleReminder());
      }, 1500);
      return;
    }
    // return () => dispatch(revertFormScheduleReminder());
  }, [
    scheduleReminderUserFormSuccess,
    scheduleReminderUserGroupFormSuccess,
    scheduleReminderUserFormFailed,
    scheduleReminderUserGroupFormFailed,
    sendScheduleReminderDateFormLoading,
    sendScheduleReminderDateFormSuccess,
    sendScheduleReminderDateFormFailed
  ]);

  useEffect(() => {
    if (selectedOption === '1' || selectedOption === '3') {
      dispatch(
        getUsersAndGroupsForSchedule({ formId: formId, flowId: flowId })
      );
    }
  }, [selectedOption, formId, dispatch]);

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleDateChange = date => {
    setScheduleDate(date);
    formik.setFieldValue('scheduleDate', date);
  };

  useEffect(() => {
    if (selectedOption === '3' && usersAndGroupsList?.UserGroups?.length > 0) {
      setUserGroupReminder(
        usersAndGroupsList.UserGroups.map(group => ({
          userGroupId: group.userGroupId,
          groupName: group.groupName
        }))
      );
    }
  }, [selectedOption, usersAndGroupsList]);

  useEffect(() => {
    dispatch(getScheduleReminderDateForSchedule(formId));
  }, []);

  useEffect(() => {
    if (!scheduleReminderDateFormLoading && scheduleReminderDateFormSuccess) {
      const apiDate = scheduleReminderDateForm?.message;
      const formattedDate = dayjs(apiDate, 'YYYYMMDD').toDate();
      setScheduleDate(dayjs(formattedDate));
      formik.setFieldValue('scheduleDate', dayjs(formattedDate));
    }
  }, [scheduleReminderDateForm]);

  return (
    <Grid container>
      <BootstrapDialog
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '604px',
            maxWidth: '604px',
            borderRadius: '8px',
            padding: '40px'
          }
        }}
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 0,
            backgroundColor: 'white',
            fontWeight: '700',
            color: 'black',
            fontSize: '20px'
          }}
          id="customized-dialog-title"
        >
          Send reminder
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            overflowX: 'hidden',
            '&.MuiDialogContent-root': {
              padding: 0,
              overflowX: 'hidden'
            }
          }}
        >
          {loading && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}
          {!loading && (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <RadioGroup
                size="large"
                fullWidth
                style={{ width: '33rem', p: 1, marginLeft: '5px' }}
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <CustomCard
                  radioValue="1"
                  label="1. Send Reminder to all pending users to submit their responses"
                  onChange={handleOptionChange}
                  checkedValue={selectedOption}
                />
                {selectedOption === '1' &&
                  usersAndGroupsList?.Users?.length > 0 && (
                    <Box
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        width: '70%',
                        // marginLeft: '5%',
                        padding: '10px'
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: '600',
                          color: 'black',
                          marginBottom: '10px'
                        }}
                      >
                        List of pending users
                      </Typography>
                      <Box
                        sx={{
                          //   width: '60%',
                          overflowY: 'auto',
                          maxHeight: 'calc(25vh - 70px)',
                          '&::-webkit-scrollbar': {
                            width: '0.2em'
                          },
                          '&::-webkit-scrollbar-track': {
                            background: 'transparent'
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: 'rgba(0, 0, 0, 0.3)'
                          }
                        }}
                      >
                        {usersAndGroupsList.Users.map(user => (
                          <Typography
                            key={user.userId}
                            variant="body2"
                            fontWeight={'400'}
                          >
                            {user.name}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                <CustomCard
                  radioValue="2"
                  label="2. Schedule Reminder"
                  onChange={handleOptionChange}
                  checkedValue={selectedOption}
                />
                {selectedOption === '2' && (
                  <Box
                    style={{
                      width: '100%'
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        components={{
                          OpenPickerIcon: props => (
                            <IconButton size="small" {...props}>
                              <Calendar01Icon />
                            </IconButton>
                          )
                        }}
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: false,
                            InputProps: {
                              sx: { color: colorTextBlack }
                            }
                          }
                        }}
                        sx={{ width: '460px' }}
                        disablePast={true}
                        value={formik.values.scheduleDate}
                        onChange={handleDateChange}
                        renderInput={params => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ width: '460px' }}
                            error={
                              formik.touched.scheduleDate &&
                              Boolean(formik.errors.scheduleDate)
                            }
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  onClick={() =>
                                    formik.setFieldValue('scheduleDate', null)
                                  }
                                  edge="end"
                                >
                                  <CloseIcon />
                                </IconButton>
                              )
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                )}
                <CustomCard
                  radioValue="3"
                  label="3. Form Assignment Reminder"
                  onChange={handleOptionChange}
                  checkedValue={selectedOption}
                />
                {selectedOption === '3' && (
                  <Grid item>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        limitTags={2}
                        multiple
                        size="small"
                        onChange={(event, newValue) => {
                          setUserGroupReminder(newValue);
                        }}
                        disableCloseOnSelect
                        getOptionLabel={option => option.groupName}
                        isOptionEqualToValue={(option, value) =>
                          option.groupName === (value ? value.groupName : null)
                        }
                        options={(usersAndGroupsList?.UserGroups || []).map(
                          group => ({
                            userGroupId: group.userGroupId,
                            groupName: group.groupName
                          })
                        )}
                        value={userGroupReminder}
                        sx={{ width: '460px' }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlank fontSize="small" />}
                              checkedIcon={<CheckBox fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.groupName}
                          </li>
                        )}
                        renderInput={params => (
                          <TextField
                            name={`formId`}
                            {...params}
                            placeholder={
                              userGroupReminder.length > 0
                                ? ''
                                : 'Select user groups'
                            }
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.5 14L12.1667 11.6667M13.8333 7.66667C13.8333 10.7963 11.2963 13.3333 8.16667 13.3333C5.03705 13.3333 2.5 10.7963 2.5 7.66667C2.5 4.53705 5.03705 2 8.16667 2C11.2963 2 13.8333 4.53705 13.8333 7.66667Z"
                                      stroke={colorTextGrey}
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>

                                  {params.InputProps.startAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                        ListboxProps={{
                          sx: {
                            '& .MuiAutocomplete-option:hover': {
                              backgroundColor: colorSecondary
                            }
                          }
                        }}
                      />
                    </FormControl>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      overflowY="auto"
                      maxHeight="200px"
                      sx={{ width: '80%' }}
                      mt={0.3}
                    >
                      {userGroupReminder &&
                        userGroupReminder.map(group => {
                          return (
                            <Typography
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: 1,
                                mr: 1,
                                fontWeight: '600',
                                padding: '2px 6px',
                                background: '#FCEBC5',
                                border: `1px solid ${colorPrimary}`,
                                borderRadius: '6px'
                              }}
                              key={group.userGroupId}
                              variant="caption"
                            >
                              {group?.groupName}
                              <IconButton
                                onClick={() => {
                                  setUserGroupReminder([
                                    ...userGroupReminder.filter(
                                      (_, i) => i !== group.userGroupId
                                    )
                                  ]);
                                }}
                                sx={{
                                  padding: 0,
                                  ml: 2
                                }}
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            </Typography>
                          );
                        })}
                    </Box>
                  </Grid>
                )}
              </RadioGroup>
            </form>
          )}

          <Box mt={2} container display={'flex'} elevation={0}>
            <Grid display={'flex'} gap={2}>
              <Button
                sx={{
                  borderColor: colorPrimary,
                  borderRadius: '10px',
                  color: 'black',
                  borderWidth: '2px',
                  height: '42px',
                  width: '106px',
                  '&:hover': {
                    borderColor: colorPrimary,
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    borderWidth: '2px'
                  }
                }}
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                loading={loading}
                sx={{
                  borderColor: colorPrimary,
                  borderRadius: '10px',
                  color: 'black',
                  borderWidth: '2px',
                  height: '42px',
                  width: '106px'
                }}
                disabled={!selectedOption}
                // type="submit"
                onClick={() => {
                  formik.handleSubmit();
                  // setShowModal(true);
                }}
              >
                Send
              </Button>
            </Grid>
          </Box>
        </DialogContent>
      </BootstrapDialog>

      {/* {!scheduleReminderUserFormLoading && scheduleReminderUserFormSuccess && (
        <SnackBarBox
          type="success"
          message={
            scheduleReminderUserForm?.message || 'Action performed successfully'
          }
        />
      )} */}

      {!scheduleReminderUserFormLoading && scheduleReminderUserFormFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            scheduleReminderUserFormErrorContainer?.errorMessage
          )}
        />
      )}
      {/* {!scheduleReminderUserGroupFormLoading &&
        scheduleReminderUserGroupFormSuccess && (
          <SnackBarBox
            type="success"
            message={
              scheduleReminderUserGroupForm?.message ||
              'Action performed successfully'
            }
          />
        )} */}
      {!scheduleReminderUserGroupFormLoading &&
        scheduleReminderUserGroupFormFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              scheduleReminderUserGroupFormErrorContainer?.errorMessage
            )}
          />
        )}
      {/* {!sendScheduleReminderDateFormLoading &&
        sendScheduleReminderDateFormSuccess && (
          <SnackBarBox
            type="success"
            message={
              sendScheduleReminderDateForm?.message ||
              'Action performed successfully'
            }
          />
        )} */}
      {!sendScheduleReminderDateFormLoading &&
        sendScheduleReminderDateFormFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              sendScheduleReminderDateFormErrorContainer?.errorMessage
            )}
          />
        )}
      {userIDSelectionResError.error && (
        <SnackBarBox
          type="error"
          message={handleError(userIDSelectionResError.errorMessage)}
        />
      )}
    </Grid>
  );
};

SendReminder.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  flowId: PropTypes.string
};

export default SendReminder;
