import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App, WebFlagProvider } from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import '@fontsource/lato';
import ErrorBoundary from './components/Errorboundary';
import ReactGA from 'react-ga';

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID);
root.render(
  <React.Fragment>
    <Provider store={store}>
      <ErrorBoundary>
        <WebFlagProvider>
          <App />
        </WebFlagProvider>
      </ErrorBoundary>
    </Provider>
  </React.Fragment>
);

reportWebVitals();
