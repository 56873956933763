import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import _ from 'lodash';
import {
  Add,
  ArrowForward,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
  Close,
  HighlightOff
} from '@mui/icons-material';
import { AssignFormIcon } from '../../utils/imageUrls';
import CustomBreadcrumb from '../../components/Breadcrumb';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Avatar,
  Breadcrumbs,
  SvgIcon,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {
  colorLightYellow,
  colorLinkGrey,
  colorPrimary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import {
  assignFlow,
  getFormsListByFlowId,
  revertUserAndUserGroupAssignment
} from '../../redux/reducers/flowsSlice';
import { useSelector } from 'react-redux';
import CustomDataTable from '../../components/CustomDataTable';
import SnackBarBox from '../../components/SnackBarBox';
import { LoadingButton } from '@mui/lab';
import { getUserList } from '../../redux/reducers/usersSlice';
import { getUserGroupsList } from '../../redux/reducers/userGroupsSlice';
import FormAssignModal from './components/FormAssignModal';
import UserAndUserGroupAssingmentModal from './components/UserAndUserGroupAssingmentModal';
import { setActiveModule } from '../../redux/reducers/layoutSlice';
import { ChevronRightIcon, File02Icon } from '../../utils/iconSvg';
import CustomPagination from '../../components/CustomTablePagination';
import { ListView } from '../productanalytics/FlowsAndFormAnalytics';
import { styledMenuItem, StyledSelect } from '../../pages/ObservationReports';
import CustomTabLabel from '../../custom/CustomTabLabel';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// const FormListingDraggableCard = ({ title, description, onCardClick }) => {
//   return (
//     <div
//       style={{
//         padding: '10px',
//         display: 'flex',
//         border: '1px solid #E4E7EB',
//         borderRadius: '4px',
//         alignItems: 'center',
//         marginTop: '5px',
//         justifyContent: 'space-between'
//       }}
//     >
//       <div
//         style={{
//           display: 'flex',
//           alignItems: 'center'
//         }}
//       >
//         <DragIndicator
//           sx={{
//             color: '#E4E7EB',
//             marginRight: 1
//           }}
//         />

//         <span
//           onClick={() => {
//             onCardClick();
//           }}
//           style={{
//             color: '#4E565F',
//             cursor: 'pointer'
//           }}
//         >
//           {title}
//         </span>
//       </div>
//       <div>{description}</div>
//     </div>
//   );
// };

const FlowDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [payloadData, setPayloadData] = useState({
    tab: 0,
    size: 10,
    page: 0,
    sortBy: ''
  });
  const SORTING_DATA = [
    { label: 'Form Name (A to Z)', value: 'AscForm' },
    { label: 'Form Name (Z to A)', value: 'DescForm' },
    { label: 'Response Count (Low to High)', value: 'AscResp' },
    { label: 'Response Count (High to Low)', value: 'DescResp' }
  ];
  const [selectedAssigns, setSelectedAssigns] = useState({
    user: [],
    isUserSelected: false,
    userGroups: [],
    isUserGroupSelected: false
  });
  const [flowAssignModal, setFlowAssignModal] = useState('');
  const [formAssignModal, setFormAssignModal] = useState(false);
  const [sortByValue, setSortByValue] = useState(null);
  const [localFormList, setLocalFormList] = useState([]);
  const [flowDetailData, setFlowDetailData] = useState({
    flowId: '',
    flowName: '',
    status: false,
    createdBy: '',
    createdDate: ''
  });
  const {
    formListByFlowIdLoading,
    formListByFlowIdFail,
    formList,
    formListByFlowIdErrorContainer,
    isAssignFormToFlowLoading,
    isAssignFormToFlowSuccess,
    assignFormToFlowResponse,
    isAssignFlowLoading,
    isAssignFlowSuccess,
    assignFlowResponse
  } = useSelector(state => state.flow);

  useEffect(() => {
    if (formList?.list) {
      setLocalFormList(formList.list);
    }
  }, [formList]);

  useEffect(() => {
    document.title = createTitle(`Flow Details`);
    dispatch(setActiveModule('Evaluation Forms'));
    if (location.state && location.state?.flowId) {
      setFlowDetailData({
        flowId: location.state?.flowId,
        flowName: location.state?.flowName,
        status: location.state?.status,
        createdBy: location.state?.createdBy,
        createdDate: location.state?.createdDate
      });
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state?.flowId) {
      dispatch(
        getFormsListByFlowId({
          flowId: location.state?.flowId,
          payload: {
            page: payloadData.page,
            size: payloadData.size,
            type: 'all'
          },
          sortByValue: sortByValue
        })
      );
    }

    return () => {};
  }, [
    payloadData.page,
    payloadData.size,
    isAssignFormToFlowSuccess === true,
    sortByValue
  ]);

  const columns = [
    {
      uinqueKey: 'formId',
      id: 'formName',
      label: 'Form Name',
      width: '85%',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.formName}
          </Typography>
        );
      }
    },
    {
      id: 'responses',
      label: 'Response',
      render: row => (
        <a>
          {' '}
          <Typography variant="body2" display={'flex'}>
            {row.responses} Responses
          </Typography>
        </a>
      )
    }
  ];

  const handleOnRowClick = row => {
    return navigate('edit-form', {
      state: { ...location.state, formId: row.formId }
    });
  };

  const handleOnDragEnd = result => {
    if (!result.destination) return;

    const reorderedItems = Array.from(localFormList);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setLocalFormList(reorderedItems);
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        {_.includes([null, undefined, ''], flowDetailData?.flowName) ? (
          <Typography fontSize={24} fontWeight="medium" marginBottom={1}>
            Flow Details
          </Typography>
        ) : (
          <Typography
            fontSize={24}
            display={'flex'}
            fontWeight="medium"
            marginBottom={1}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <SvgIcon sx={{ height: 24, width: 24 }}>
                  <ChevronRightIcon color={colorTextGrey} />
                </SvgIcon>
              }
            >
              <Link
                color="inherit"
                href={'flow-detail'}
                onClick={event => {
                  event.preventDefault();
                  navigate('/flows');
                }}
                sx={{ textDecoration: 'none' }}
              >
                <Typography
                  fontWeight={'700'}
                  color={colorLinkGrey}
                  fontSize={'24px'}
                >
                  Evaluation Flows
                </Typography>
              </Link>
              <Typography
                sx={{
                  fontSize: '32px',
                  fontWeight: '700',
                  color: colorTextBlack
                }}
              >
                {flowDetailData?.flowName || ''}
              </Typography>
            </Breadcrumbs>
            <span
              style={{
                fontSize: '12px',
                color: colorLinkGrey,
                marginLeft: 5,
                alignContent: 'end',
                paddingBottom: '8px'
              }}
            >
              <div
                style={{
                  fontWeight: '600',
                  fontSize: 12,
                  backgroundColor: flowDetailData.status
                    ? '#EBF9D9'
                    : '#FDF0E3',
                  padding: '4px 8px 4px 8px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: flowDetailData.status ? '#749E35' : '#D62828',
                  width: flowDetailData.status ? '62px' : '70px',
                  height: '22px'
                }}
              >
                <Avatar
                  sx={{
                    width: 8,
                    height: 8,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    backgroundColor:
                      flowDetailData.status === true ? '#749E35' : '#D62828',
                    color: 'white',
                    fontSize: '12px',
                    marginRight: '5px'
                  }}
                >
                  <></>
                </Avatar>
                {flowDetailData.status === true ? 'Active' : 'InActive'}
              </div>
            </span>
          </Typography>
        )}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Tooltip placement="top" title="Click to assign flow">
            <Button
              onClick={() => {
                dispatch(revertUserAndUserGroupAssignment());
                setFlowAssignModal('flow');
              }}
              variant="outlined"
              sx={{
                fontWeight: '700',
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: 'black',
                borderWidth: '2px'
              }}
            >
              <SvgIcon sx={{ marginRight: 0.6, height: '16px', width: '16px' }}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3332 8.33301V4.53301C13.3332 3.4129 13.3332 2.85285 13.1152 2.42503C12.9234 2.0487 12.6175 1.74274 12.2412 1.55099C11.8133 1.33301 11.2533 1.33301 10.1332 1.33301H5.8665C4.7464 1.33301 4.18635 1.33301 3.75852 1.55099C3.3822 1.74274 3.07624 2.0487 2.88449 2.42503C2.6665 2.85285 2.6665 3.4129 2.6665 4.53301V11.4663C2.6665 12.5864 2.6665 13.1465 2.88449 13.5743C3.07624 13.9506 3.3822 14.2566 3.75852 14.4484C4.18635 14.6663 4.7464 14.6663 5.8665 14.6663H7.99984M9.33317 7.33301H5.33317M6.6665 9.99967H5.33317M10.6665 4.66634H5.33317M9.6665 12.6663L10.9998 13.9997L13.9998 10.9997"
                    stroke={colorTextBlack}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SvgIcon>
              Assign Flow
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Click to create new form">
            <Button
              onClick={() => {
                navigate('/flows/flows-details/create-new-form', {
                  state: {
                    flowId: location.state?.flowId,
                    flowName: location.state?.flowName,
                    status: location.state?.status,
                    createdBy: location.state?.createdBy,
                    createdDate: location.state?.createdDate
                  }
                });
              }}
              variant="contained"
              sx={{
                fontWeight: '700',
                marginRight: 2,
                boxShadow: 'none'
              }}
            >
              <SvgIcon
                fontSize="small"
                sx={{ marginRight: 0.8, height: '16px', width: '16px' }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.91732 12.0771C1.94795 11.8015 1.96326 11.6636 2.00497 11.5348C2.04197 11.4205 2.09425 11.3117 2.16038 11.2114C2.23493 11.0984 2.33299 11.0003 2.52911 10.8042L11.3333 2.00004C12.0697 1.26366 13.2636 1.26366 14 2.00004C14.7364 2.73642 14.7364 3.93033 14 4.66671L5.19578 13.4709C4.99966 13.667 4.9016 13.7651 4.78855 13.8396C4.68826 13.9058 4.57949 13.958 4.46519 13.995C4.33636 14.0367 4.19853 14.0521 3.92287 14.0827L1.66663 14.3334L1.91732 12.0771Z"
                    stroke={colorTextBlack}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SvgIcon>{' '}
              Create new form
            </Button>
          </Tooltip>

          {/* <Tooltip placement="top" title="Click to edit record">
            <Button
              onClick={() =>
                navigate('edit-flow', {
                  state: { ...location.state }
                })
              }
              startIcon={<ArrowForward />}
            >
              Edit this record
            </Button>
          </Tooltip> */}
        </Box>
      </Box>
      {/* <CustomBreadcrumb path={`${location.pathname}`} /> */}

      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        borderRadius={'8px'}
        sx={{ paddingX: '14px', pt: 1 }}
      >
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tabs
            value={payloadData.tab}
            onChange={(val, newValue) =>
              setPayloadData({ ...payloadData, tab: newValue, page: 0 })
            }
            TabIndicatorProps={{
              sx: {
                height: '4px',
                borderRadius: '4px'
              }
            }}
          >
            <Tab
              icon={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 9H6M12 12.375H6M12 5.625H6M15 5.1V12.9C15 14.1601 15 14.7902 14.7548 15.2715C14.539 15.6948 14.1948 16.039 13.7715 16.2548C13.2902 16.5 12.6601 16.5 11.4 16.5H6.6C5.33988 16.5 4.70982 16.5 4.22852 16.2548C3.80516 16.039 3.46095 15.6948 3.24524 15.2715C3 14.7902 3 14.1601 3 12.9V5.1C3 3.83988 3 3.20982 3.24524 2.72852C3.46095 2.30516 3.80516 1.96095 4.22852 1.74524C4.70982 1.5 5.33988 1.5 6.6 1.5H11.4C12.6601 1.5 13.2902 1.5 13.7715 1.74524C14.1948 1.96095 14.539 2.30516 14.7548 2.72852C15 3.20982 15 3.83988 15 5.1Z"
                    stroke={
                      payloadData.tab === 0 ? colorTextBlack : colorTextGrey
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: payloadData.tab === 0 ? '700' : '600',
                '&.Mui-selected': {
                  color: payloadData.tab === 0 ? colorTextBlack : colorTextGrey
                }
              }}
              iconPosition="start"
              // label={`Form List`}
              label={
                <CustomTabLabel
                  title="Form List"
                  count={
                    formList?.totalCount < 10
                      ? `0${formList?.totalCount}`
                      : formList?.totalCount
                  }
                  selected={payloadData.tab === 0}
                />
              }
            />
          </Tabs>
        </Grid>
        <Box display={'flex'} justifyContent="end" mt={1}>
          <FormControl variant="outlined" size="small" sx={{ marginRight: 2 }}>
            <StyledSelect
              size="small"
              sx={{ minWidth: '179px', height: '38px' }}
              placeholder="Sort by"
              value={sortByValue}
              onChange={e => {
                setSortByValue(e.target.value);
              }}
              displayEmpty
              renderValue={selected => {
                if (!selected) {
                  return (
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#ABB4BD'
                      }}
                    >
                      <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.3333 2.66675V13.3334M11.3333 13.3334L8.66667 10.6667M11.3333 13.3334L14 10.6667M4.66667 13.3334V2.66675M4.66667 2.66675L2 5.33341M4.66667 2.66675L7.33333 5.33341"
                            stroke="#ABB4BD"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </SvgIcon>
                      Sort by
                    </Typography>
                  );
                }
                return (
                  <Typography
                    sx={{
                      color: colorTextBlack,
                      fontWeight: '600'
                    }}
                  >
                    {SORTING_DATA.find(cc => cc.value === selected).label}
                  </Typography>
                );
              }}
              IconComponent={() => null}
              // endAdornment={
              //   filterOptions.ratingFrom ? (
              //     <IconButton
              //       sx={{ marginRight: '10px' }}
              //       aria-label="clear"
              //       size="small"
              //       onClick={() => {
              //         // setFilterOptions({
              //         //   ...filterOptions,
              //         //   ratingFrom: ''
              //         // })
              //       }}
              //       edge="end"
              //     >
              //       <HighlightOff sx={{ color: colorTextBlack }} />
              //     </IconButton>
              //   ) : null
              // }
            >
              {SORTING_DATA.map((cc, key) => (
                <MenuItem
                  sx={styledMenuItem}
                  key={key}
                  value={cc.value}
                  disableRipple
                >
                  {cc.label}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <Button
            onClick={() => {
              setFormAssignModal(!formAssignModal);
            }}
            variant="outlined"
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: 'black',
              borderWidth: '2px',
              height: '38px',
              width: '107px',
              padding: '5px',
              fontWeight: '700',
              '&:hover': {
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px'
              }
            }}
          >
            <SvgIcon
              fontSize="small"
              sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                  stroke="#1F2933"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>{' '}
            Add Form
          </Button>
        </Box>
        <Grid sx={{ padding: '10px 0px' }} width={'100%'}>
          {formListByFlowIdLoading && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}

          {/* {isArrayNotEmpty(formList?.list) &&
            formList?.list.map((cc, idx) => (
              <ListView
                key={idx}
                flowId={cc.flowId}
                // formId={cc.formId}
                responseCount={cc.responses}
                name={cc.formName}
                onClick={() => handleOnRowClick(cc)}
              />
            ))} */}
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="formLists">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {localFormList.map((cc, idx) => (
                    <Draggable
                      key={cc.flowId}
                      draggableId={`formList-${idx}`}
                      index={idx}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ ...provided.draggableProps.style }}
                        >
                          <ListView
                            key={idx}
                            id={`formlist-${idx}`}
                            name={cc.formName}
                            flowId={cc.flowId}
                            responseCount={cc.responses}
                            provided={provided}
                            onClick={() => handleOnRowClick(cc)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <CustomPagination
            count={formList?.totalCount}
            page={payloadData.page}
            rowsPerPage={payloadData.size}
            onPageChange={(_, val) =>
              setPayloadData({ ...payloadData, page: val })
            }
          />

          {/* {!formListByFlowIdLoading && (
            <CustomDataTable
              allowSelection={false}
              columns={columns}
              defaultOrderBy={'formId'}
              data={formList ? formList.list : []}
              onPageChange={val => {
                setPayloadData({ ...payloadData, page: val });
              }}
              onRowsPerPageChange={val => {
                setPayloadData({ ...payloadData, size: val });
              }}
              defaultPage={payloadData.page}
              defaultRowsPerPage={payloadData.size}
              totalCount={formList.totalCount}
              onRowClick={row => handleOnRowClick(row)}
            />
          )} */}

          {/* <DragDropContext onDragEnd={e => onDragEnd(e)}>
            <Droppable droppableId="options">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Draggable key={1} draggableId={`${1}-${1}`} index={1}>
                    {provided => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <FormListingDraggableCard
                          onCardClick={() => {}}
                          title={'School observation (optional)'}
                          description={'10 Responses'}
                        />
                      </div>
                    )}
                  </Draggable>
                </div>
              )}
            </Droppable>
          </DragDropContext> */}
          {/* <TablePagination
            component="div"
            count={100}
            rowsPerPage={10}
            page={0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Grid>
      </Grid>
      {!formListByFlowIdLoading && formListByFlowIdFail && (
        <SnackBarBox
          type="error"
          message={handleError(formListByFlowIdErrorContainer?.errorMessage)}
        />
      )}

      <Dialog
        open={_.includes(['flow', 'form'], flowAssignModal) ? true : false}
        onClose={() => setFlowAssignModal('')}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '520px',
            borderRadius: '8px'
          }
        }}
      >
        <Typography paddingX={3} pt={3} fontSize={'18px'} fontWeight={'bold'}>
          Assign flow
        </Typography>

        <DialogContent sx={{ paddingX: 3 }}>
          <UserAndUserGroupAssingmentModal
            flowId={location.state?.flowId}
            onCancelClick={() => {
              setFlowAssignModal('');
            }}
            type="flow"
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={formAssignModal}
        onClose={() => setFormAssignModal(!formAssignModal)}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '519px',
            maxWidth: '519px',
            borderRadius: '8px'
          }
        }}
      >
        <Typography paddingX={3} pt={3} fontSize={'20px'} fontWeight={'700'}>
          Select the form
        </Typography>

        <DialogContent width={1 / 3} sx={{ minWidth: 400 }}>
          <FormAssignModal
            flowId={location.state?.flowId}
            onCancelClick={() => {
              setFormAssignModal(false);
            }}
            type="form"
          />
        </DialogContent>
      </Dialog>
      {!isAssignFlowLoading && isAssignFlowSuccess && (
        <SnackBarBox type={'success'} message={assignFlowResponse} />
      )}

      {!isAssignFormToFlowLoading && isAssignFormToFlowSuccess && (
        <SnackBarBox type={'success'} message={assignFormToFlowResponse} />
      )}
    </Box>
  );
};

export default FlowDetails;
