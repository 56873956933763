import {
  Alert,
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  Rating,
  Select,
  Skeleton,
  Snackbar,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@mui/material';
import {
  Add,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  ContentCopy,
  Delete,
  Download,
  DragIndicator,
  East,
  SaveOutlined
} from '@mui/icons-material';
import {
  FLOWS_FORMS_QUESTION_TYPE_SELECTOR,
  formsFileType,
  formsFileTypeIcon,
  iconMapFlows,
  uploadFileType
} from '../../../utils/constant';
import React, { useEffect, useRef, useState } from 'react';
import {
  colorDisableGrey,
  colorFieldError,
  colorLightYellow,
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../../config/theme';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { isArrayNotEmpty } from '../../../utils/utils';
import _, { at } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BootstrapDialog, IOSSwitch } from '../../../utils/styledLayout';
import {
  ChevronRightIcon,
  Copy05Icon,
  TrashIcon
} from '../../../utils/iconSvg';
import { LimitOptions } from '../AddEditForm';

const PreviewForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState();

  const { formLayout, listOfAttachments } = location.state;
  const { indicatorsList } = useSelector(state => state.rubric);

  function getOptionLabel(option) {
    if (!option) {
      return '';
    }

    if (option.label !== null) {
      return option.label;
    }

    if (option.id !== null) {
      return option.id;
    }

    return '';
  }
  const handleSelectChange = event => {
    setSelectedValue(event.target.value);
  };

  const onDragEnd = (result, sectionKey, questionKey, questionObj) => {
    if (!result.destination) {
      return;
    }
    const newOptions = Array.from(questionObj.optionTexts);
    const [removed] = newOptions.splice(result.source.index, 1);
    newOptions.splice(result.destination.index, 0, removed);
  };

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} mb={1}>
        <Grid>
          <Typography mb={1} fontSize={32} fontWeight="700">
            {/* {`Preview - ${formLayout.formName}`} */}
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <SvgIcon sx={{ height: 24, width: 24 }}>
                  <ChevronRightIcon color={colorTextGrey} />
                </SvgIcon>
              }
              sx={{ marginBottom: 2 }}
            >
              <Link
                color="inherit"
                href={'flows'}
                onClick={event => {
                  event.preventDefault();
                  navigate(-1);
                }}
                sx={{ textDecoration: 'none' }}
              >
                <Typography
                  fontWeight={'700'}
                  color={colorLinkGrey}
                  fontSize={'24px'}
                >
                  {formLayout?.flowName}
                </Typography>
              </Link>
              <Typography
                sx={{
                  fontSize: '32px',
                  fontWeight: '700',
                  color: colorTextBlack
                }}
              >
                {formLayout?.formName}
              </Typography>
            </Breadcrumbs>
          </Typography>
        </Grid>
        {/* <Box>
          <Button
            variant="contained"
            sx={{ width: 150 }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box> */}
      </Box>
      <Grid sx={{ padding: '24px 0px' }} width={'100%'}>
        <Box
          sx={{ borderTop: `5px solid ${colorPrimary}` }}
          bgcolor={'white'}
          padding={2}
          borderRadius={2}
          marginBottom={2}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                name="formTitle"
                fullWidth
                placeholder="Form Title"
                defaultValue="Untitled Form"
                size="small"
                variant="standard"
                value={formLayout.formName}
                InputProps={{
                  style: { fontWeight: 700, maxLength: 64 },
                  readOnly: true
                }}
              />
              <TextField
                sx={{ marginTop: 2 }}
                name="formDescription"
                fullWidth
                placeholder="Form description (optional)"
                size="small"
                variant="standard"
                value={formLayout.formDescription}
                inputProps={{ maxLength: 200, readOnly: true }}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" fullWidth size="small">
                <Typography
                  fontWeight="700"
                  component="section"
                  color={'inherit'}
                >
                  {listOfAttachments.length > 0 && 'Attachments'}
                </Typography>
                <Box sx={{ width: '50%' }}>
                  {listOfAttachments.map((attach, key) => {
                    return (
                      <Box
                        key={key}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '6px 10px',
                          border: '1px solid',
                          borderRadius: '7px',
                          marginBottom: '5px'
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            mr: 1,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {formsFileTypeIcon[attach.type]}
                          <a>
                            <Typography
                              variant="body2"
                              // color="text.secondary"
                              fontWeight={'500'}
                              sx={{ marginLeft: 1 }}
                            >
                              {attach.title}
                            </Typography>
                          </a>
                        </Box>
                        {/* <Box sx={{ minWidth: 35, display: 'flex' }}>
                            <IconButton size="small">
                              <Close />
                            </IconButton>
                          </Box> */}
                      </Box>
                    );
                  })}
                </Box>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {/* <DragDropContext>
        {/* onDragEnd={onSectionDragEnd} */}
      {/* <Droppable droppableId="sections">
          {provided => ( */}
      {/* <div {...provided.droppableProps} ref={provided.innerRef}> */}
      <div>
        {formLayout.sectionRequestList
          .sort((a, b) => a.sectionOrder - b.sectionOrder)
          .map((section, sectionKey) => {
            return (
              // <Draggable key={sectionKey} draggableId={`section-${sectionKey}`} index={sectionKey}>
              // {provided => (
              <Box
                // id={`section-${sectionKey}`}
                key={sectionKey}
                // ref={provided.innerRef}
                // {...provided.draggableProps}
                // {...provided.dragHandleProps}
                margin={'4px 6px'}
              >
                <Typography
                  fontWeight="600"
                  fontSize={'20px'}
                  readOnly
                  color={
                    colorPrimary
                    //   sectionKey === selectedSection
                    //     ? colorPrimary
                    //     : 'inherit'
                  }
                  sx={{ marginBottom: 1 }}
                >
                  Section {sectionKey + 1} of{' '}
                  {formLayout.sectionRequestList.length}
                </Typography>
                <Box
                  sx={{
                    borderTop: `5px solid ${colorPrimary}`
                  }}
                  bgcolor={'white'}
                  padding={2}
                  borderRadius={2}
                  marginBottom={2}
                >
                  <Grid container spacing={3}>
                    <Grid item sm={12}>
                      <TextField
                        name="sectionTitle"
                        fullWidth
                        readOnly
                        placeholder="Section Title"
                        // defaultValue=""
                        value={section.sectionName}
                        size="small"
                        variant="standard"
                        InputProps={{
                          style: {
                            fontWeight: 700,
                            maxLength: 64
                          },
                          readOnly: true
                        }}
                      />
                      <TextField
                        sx={{ marginTop: 2 }}
                        name="sectionDescription"
                        fullWidth
                        placeholder="Section description (optional)"
                        size="small"
                        variant="standard"
                        readOnly
                        inputProps={{
                          maxLength: 200,
                          readOnly: true
                        }}
                        value={section.sectionDescription}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {section.questionRequestList.map((questionObj, key) => {
                  let questionType = FLOWS_FORMS_QUESTION_TYPE_SELECTOR.find(
                    qtyp => qtyp.id === questionObj.questionOptionId
                  );

                  let options = LimitOptions();
                  return (
                    <Box
                      key={key}
                      bgcolor={'white'}
                      padding={2}
                      borderRadius={2}
                      marginBottom={2}
                    >
                      <Grid
                        container
                        justifyContent={'space-between'}
                        alignItems={'end'}
                      >
                        <Grid item lg={6} md={12}>
                          <TextField
                            name="question"
                            fullWidth
                            sx={{
                              '& .MuiInputBase-input': {
                                color: colorTextBlack,
                                fontWeight: '600',
                                fontSize: '16px',
                                lineHeight: '20px'
                              }
                            }}
                            value={questionObj.questionText}
                            placeholder="Write your question here"
                            size="small"
                            variant="standard"
                            inputProps={{ maxLength: 200 }}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <span
                                  style={{
                                    padding: '0.5rem',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    fontWeight: 600
                                  }}
                                >
                                  {key + 1}{' '}
                                  <East
                                    fontSize="small"
                                    sx={{
                                      marginLeft: '4px'
                                    }}
                                  />
                                </span>
                              )
                            }}
                          />
                          <TextField
                            name="description"
                            fullWidth
                            placeholder="Description (optional)"
                            size="small"
                            value={questionObj.questionDescription}
                            variant="standard"
                            sx={{ marginTop: 2 }}
                            inputProps={{
                              readOnly: true,
                              maxLength: 200,
                              color: 'gray'
                            }}
                          />
                        </Grid>
                        {/* <Grid item lg={6} md={12} display={'flex'} justifyContent={'end'}>
                                    <Grid lg={8}>
                                      <Autocomplete
                                        size="small"
                                        value={questionType}
                                        clearIcon={false}
                                        readOnly
                                        isOptionEqualToValue={(option, value) => option.id === (value ? value.id : null)}
                                        getOptionLabel={getOptionLabel}
                                        options={FLOWS_FORMS_QUESTION_TYPE_SELECTOR}
                                        sx={{ width: '100%' }}
                                        // renderOption={(props, option) => {
                                        //   return (
                                        //     <ListItem {...props}>
                                        //       <ListItemIcon
                                        //         sx={{ minWidth: 40 }}
                                        //       >
                                        //         {iconMapFlows[option.id]}
                                        //       </ListItemIcon>
                                        //       <ListItemText>
                                        //         <Typography>
                                        //           {option.label}
                                        //         </Typography>
                                        //       </ListItemText>
                                        //     </ListItem>
                                        //   );
                                        // }}
                                        renderInput={params => {
                                          const selectedOption = params.inputProps.value
                                            ? FLOWS_FORMS_QUESTION_TYPE_SELECTOR.find(
                                                option => option.label === params.inputProps.value
                                              )
                                            : null;

                                          return (
                                            <TextField
                                              {...params}
                                              placeholder="Select question type"
                                              variant="outlined"
                                              InputProps={{
                                                ...params.InputProps,
                                                readOnly: true
                                                // startAdornment:
                                                //   selectedOption ? (
                                                //     <ListItemIcon
                                                //       style={{
                                                //         minWidth: 0
                                                //       }}
                                                //     >
                                                //       {
                                                //         iconMapFlows[
                                                //           selectedOption.id
                                                //         ]
                                                //       }
                                                //     </ListItemIcon>
                                                //   ) : null
                                              }}
                                            />
                                          );
                                        }}
                                        ListboxProps={{
                                          sx: {
                                            '& .MuiAutocomplete-option:hover': {
                                              backgroundColor: colorSecondary
                                            }
                                          }
                                        }}
                                      />
                                      <Box display={'flex'}>
                                        <FormControlLabel
                                          sx={{
                                            marginLeft: 0,
                                            marginRight: 2
                                          }}
                                          control={<IOSSwitch />}
                                          label={'Required'}
                                          readOnly
                                          checked={questionObj.isRequired === 1}
                                          labelPlacement="start"
                                        />
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <IconButton sx={{ marginRight: 1 }}>
                                          <Copy05Icon />
                                        </IconButton>
                                        <IconButton>
                                          <TrashIcon />
                                        </IconButton>
                                      </Box>
                                    </Grid>
                                  </Grid> */}
                        {/* <Grid>
                                 
                                    {questionType.id === 4 && (
                                      <Tooltip placement="top" title="Click to add new indicator">
                                        <Button
                                          sx={{
                                            height: 30,
                                            mt: 1,
                                            color: 'black',
                                            borderBottom: '1px solid black',
                                            borderRadius: 0
                                          }}
                                          variant="text"
                                          size="small"
                                          startIcon={<Add />}
                                        >
                                          Add Indicator
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </Grid> */}

                        <Grid item lg={12}>
                          {(questionType.id === 4 || questionType.id === 5) && (
                            <>
                              <TextField
                                placeholder={`${
                                  questionType.id === 4
                                    ? 'Short answer'
                                    : questionType.id === 5
                                      ? 'Long answer'
                                      : ''
                                }`}
                                size="small"
                                disabled
                                variant="standard"
                                sx={{ marginTop: 2 }}
                                InputProps={{
                                  readOnly: true
                                }}
                              />
                            </>
                          )}
                          {questionType.id === 1 && (
                            <FormControl
                              variant="outlined"
                              size="small"
                              style={{
                                width: '274px',
                                height: '42px',
                                marginTop: '12px',
                                cursor: 'default'
                              }}
                            >
                              <Select
                                value={'all'}
                                // onChange={handleSelectChange}
                                placeholder="Select option"
                                readOnly
                                style={{
                                  border: `1px solid ${colorTextGrey}`,
                                  // boxSizing: 'border-box',
                                  outline: 'none'
                                }}
                                renderValue={() => (
                                  <Typography
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: '#ABB4BD'
                                    }}
                                  >
                                    Select option
                                  </Typography>
                                )}
                              >
                                <MenuItem
                                  value={'all'}
                                  sx={{
                                    '&:hover': {
                                      backgroundColor: colorSecondary,
                                      cursor: 'default'
                                    }
                                  }}
                                >
                                  Select option
                                </MenuItem>
                                {/* {questionObj.optionTexts.map(
                                  (option, index) => (
                                    <MenuItem
                                      key={index}
                                      value={option}
                                      sx={{
                                        '&:hover': {
                                          backgroundColor: colorSecondary,
                                          cursor: 'default'
                                        }
                                      }}
                                    >
                                      {option}
                                    </MenuItem>
                                  )
                                )} */}
                              </Select>
                            </FormControl>
                          )}

                          {_.includes([2, 3], questionType.id) && (
                            <div style={{ position: 'relative' }}>
                              {/* <DragDropContext onDragEnd={e => onDragEnd(e, sectionKey, key, questionObj)}>
                                          <Droppable droppableId="options">
                                            {provided => ( */}
                              {/* <div {...provided.droppableProps} ref={provided.innerRef}> */}
                              <div>
                                <div>
                                  {questionType.id === 2 ||
                                  questionType.id === 3
                                    ? isArrayNotEmpty(
                                        questionObj.optionTexts
                                      ) &&
                                      questionObj.optionTexts.map(
                                        (optinList, optionIdx) => {
                                          return (
                                            // <Draggable
                                            //   key={optionIdx}
                                            //   draggableId={`${optinList}-${optionIdx}`}
                                            //   index={optionIdx}
                                            //   draggable={false}
                                            // >
                                            //   {provided => (
                                            // <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <div
                                              key={optionIdx}
                                              style={{
                                                padding: '8px 16px',
                                                width: '207px',
                                                height: '34px',
                                                display: 'flex',
                                                border: `1px solid ${colorDisableGrey}`,
                                                borderRadius: '8px',
                                                alignItems: 'center',
                                                marginTop: '8px',
                                                justifyContent: 'space-between',
                                                pointerEvents: 'none'
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  pointerEvents: 'none'
                                                }}
                                              >
                                                {/* Optional DragIndicator component */}
                                                {/* <DragIndicator
                                                style={{
                                                  color: '#E4E7EB',
                                                  marginRight: 1,
                                                  pointerEvents: 'none'
                                                }}
                                                draggable={false}
                                              /> */}

                                                {questionType.id === 3 ? (
                                                  <input
                                                    type="radio"
                                                    style={{
                                                      border: `1px solid ${colorTextGrey}`,
                                                      boxSizing: 'border-box',
                                                      marginRight: 10
                                                    }}
                                                    // disabled
                                                  />
                                                ) : questionType.id === 2 ? (
                                                  <input
                                                    type="checkbox"
                                                    style={{
                                                      width: 14,
                                                      height: 14,
                                                      appearance: 'none',
                                                      borderRadius: '4px',
                                                      border: `1px solid ${colorTextGrey}`,
                                                      boxSizing: 'border-box',
                                                      marginRight: 10
                                                    }}
                                                  />
                                                ) : null}
                                                <Typography
                                                  sx={{
                                                    color: colorLinkGrey,
                                                    fontWeight: 500,
                                                    fontSize: '14px'
                                                  }}
                                                >
                                                  {optinList}
                                                </Typography>
                                              </div>
                                              {/* <div>
                                                <IconButton
                                                  sx={{
                                                    padding: 0
                                                  }}
                                                >
                                                  <Close />
                                                </IconButton>
                                              </div> */}
                                            </div>
                                            // </div>
                                            // )}
                                            // </Draggable>
                                          );
                                        }
                                      )
                                    : null}
                                </div>
                              </div>
                              {/* //     )}
                                        //   </Droppable>
                                        // </DragDropContext> */}
                              {/* <div style={{ display: 'flex' }}>
                                            <TextField
                                              variant="standard"
                                              size="small"
                                              placeholder="Add option"
                                              sx={{ marginTop: 2 }}
                                              value={
                                                questionObj
                                                  ? questionObj.tempOption
                                                  : ''
                                              }
                                            />
                                            <IconButton sx={{}}>
                                              {add_circle_icon()}
                                            </IconButton>
                                          </div> */}
                            </div>
                          )}

                          {questionType.id === 6 && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disabled
                                format="DD-MM-YYYY"
                                sx={{ marginTop: 2 }}
                                slotProps={{
                                  textField: {
                                    size: 'small',
                                    variant: 'standard',
                                    placeholder: 'Month/Day/Year',
                                    InputProps: {
                                      sx: { color: colorTextBlack }
                                    }
                                  }
                                }}
                                components={{
                                  OpenPickerIcon: props => (
                                    <IconButton size="small" {...props}>
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M14 6.66634H2M10.6667 1.33301V3.99967M5.33333 1.33301V3.99967M5.2 14.6663H10.8C11.9201 14.6663 12.4802 14.6663 12.908 14.4484C13.2843 14.2566 13.5903 13.9506 13.782 13.5743C14 13.1465 14 12.5864 14 11.4663V5.86634C14 4.74624 14 4.18618 13.782 3.75836C13.5903 3.38204 13.2843 3.07607 12.908 2.88433C12.4802 2.66634 11.9201 2.66634 10.8 2.66634H5.2C4.0799 2.66634 3.51984 2.66634 3.09202 2.88433C2.71569 3.07607 2.40973 3.38204 2.21799 3.75836C2 4.18618 2 4.74624 2 5.86634V11.4663C2 12.5864 2 13.1465 2.21799 13.5743C2.40973 13.9506 2.71569 14.2566 3.09202 14.4484C3.51984 14.6663 4.0799 14.6663 5.2 14.6663Z"
                                          stroke={colorTextGrey}
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </IconButton>
                                  )
                                }}
                              />
                            </LocalizationProvider>
                          )}

                          {questionType.id === 7 && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                disabled
                                sx={{ marginTop: 2 }}
                                slotProps={{
                                  textField: {
                                    size: 'small',
                                    variant: 'standard',
                                    placeholder: 'Time'
                                  }
                                }}
                                components={{
                                  OpenPickerIcon: props => (
                                    <IconButton size="small" {...props}>
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clipPath="url(#clip0_5467_42319)">
                                          <path
                                            d="M7.99967 3.99967V7.99967L10.6663 9.33301M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967Z"
                                            stroke={colorTextGrey}
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_5467_42319">
                                            <rect
                                              width="16"
                                              height="16"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </IconButton>
                                  )
                                }}
                              />
                            </LocalizationProvider>
                          )}

                          {questionType.id === 8 && (
                            <Grid
                              container
                              alignItems="center"
                              gap={4}
                              sx={{ marginTop: 2 }}
                            >
                              <Grid item>
                                <Typography
                                  sx={{
                                    fontWeight: '600',
                                    fontSize: '14px'
                                  }}
                                >
                                  {questionObj.lowerLimitLabel || 'Label 1'}
                                </Typography>
                              </Grid>

                              <Grid item sx={{ display: 'flex', gap: 2 }}>
                                {options
                                  .filter(
                                    ({ value }) =>
                                      value >= questionObj.lowerLimit &&
                                      value <= questionObj.upperLimit
                                  )
                                  .map(({ value, label }) => (
                                    <Grid
                                      container
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={3}
                                      alignItems="center"
                                      key={value}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: '600',
                                          fontSize: '14px'
                                        }}
                                      >
                                        {label}
                                      </Typography>
                                      <Radio
                                        value={value}
                                        disabled
                                        sx={{
                                          height: '14px',
                                          width: '14px'
                                        }}
                                      />
                                    </Grid>
                                  ))}
                              </Grid>

                              <Grid item>
                                <Typography
                                  sx={{
                                    fontWeight: '600',
                                    fontSize: '14px'
                                  }}
                                >
                                  {questionObj.upperLimitLabel || 'Label 2'}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>

                        {questionObj?.indicatorIds &&
                          isArrayNotEmpty(questionObj?.indicatorIds) && (
                            <Grid item lg={12} mt={2}>
                              {/* <Typography variant="subtitle1" fontWeight={'bold'}>
                              Indicators
                            </Typography> */}
                              <Box display={'flex'} flexDirection={'column'}>
                                {isArrayNotEmpty(questionObj?.indicatorIds) &&
                                  questionObj?.indicatorIds.map(
                                    (indicators, indiIdx) => {
                                      let tempIndObj = indicatorsList.find(
                                        cc => cc.indicatorId === indicators
                                      );
                                      return (
                                        <Box
                                          key={indiIdx}
                                          sx={{
                                            display: 'flex',
                                            gap: 2
                                          }}
                                        >
                                          <Typography
                                            fontWeight={'500'}
                                            fontsize={'14px'}
                                            sx={{
                                              color: colorTextBlack
                                            }}
                                          >
                                            {`${tempIndObj?.indicatorName} :`}
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Rating
                                              name="simple-controlled"
                                              precision={0.5}
                                              readOnly
                                              // value={row?.ratings}
                                            />
                                          </Box>
                                        </Box>
                                      );
                                      // return (
                                      //   <Typography
                                      //     sx={{
                                      //       display: 'flex',
                                      //       justifyContent: 'space-between',
                                      //       mt: 1,
                                      //       mr: 1,
                                      //       fontWeight: '600',
                                      //       padding: '5px',
                                      //       background: '#F5F7FA',
                                      //       borderRadius: '6px'
                                      //     }}
                                      //     key={indiIdx}
                                      //     variant="caption"
                                      //   >
                                      //     {tempIndObj?.indicatorName}
                                      //     <IconButton
                                      //       sx={{
                                      //         padding: 0,
                                      //         ml: 2
                                      //       }}
                                      //     >
                                      //       <Close fontSize="small" />
                                      //     </IconButton>
                                      //   </Typography>
                                      // );
                                    }
                                  )}
                              </Box>
                            </Grid>
                          )}
                      </Grid>
                    </Box>
                  );
                })}
              </Box>
              // )}
              // </Draggable>
            );
          })}
      </div>
      {/* )}
        </Droppable>
      </DragDropContext> */}
    </>
  );
};

export default PreviewForm;
