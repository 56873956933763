import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, apiNoAuth } from '../../config/api';

const initial = {
  isUserAndRoleAnalyticsLoading: false,
  isUserAndRoleAnalyticsSuccess: false,
  isUserAndRoleAnalyticsFailed: false,
  userAndRoleAnalyticsData: {
    totalUser: 0,
    totalRole: 0,
    totalGroup: 0,
    analyticsData: []
  },
  userAndRoleAnalyticsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUserAnalyticsLoading: false,
  isUserAnalyticsSuccess: false,
  isUserAnalyticsFailed: false,
  userAnalyticsData: {
    analyticsData: []
  },
  userAnalyticsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isLocationAnalyticsLoading: false,
  isLocationAnalyticsSuccess: false,
  isLocationAnalyticsFailed: false,
  locationAnalyticsData: {
    totalSchool: 0,
    totalArea: 0,
    totalDistrict: 0,
    analyticsData: []
  },
  locationAnalyticsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isFlowsAndFormsAnalyticsLoading: false,
  isFlowsAndFormsAnalyticsSuccess: false,
  isFlowsAndFormsAnalyticsFailed: false,
  flowsAndFormsAnalyticsData: {
    totalForms: 0,
    totalResponse: 0,
    totalAverageResponseTime: 0,
    analyticsData: []
  },
  flowsAndFormsAnalyticsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isFlowsAndFormsAnalyticsDetailLoading: false,
  isFlowsAndFormsAnalyticsDetailSuccess: false,
  isFlowsAndFormsAnalyticsDetailFailed: false,
  flowsAndFormsAnalyticsDetailData: null,
  flowsAndFormsAnalyticsDetailErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isTeacherObservationsAnalyticsLoading: false,
  isTeacherObservationsAnalyticsSuccess: false,
  isTeacherObservationsAnalyticsFailed: false,

  isTeacherObservationsRubricAnalyticsLoading: false,
  isTeacherObservationsRubricAnalyticsSuccess: false,
  isTeacherObservationsRubricAnalyticsFailed: false,

  isTeacherObservationsUserAnalyticsLoading: false,
  isTeacherObservationsUserAnalyticsSuccess: false,
  isTeacherObservationsUserAnalyticsFailed: false,
  teacherObservationsAnalyticsData: {
    totalObservationCount: 0,
    totalIndicatorCount: 0,
    totalObservationAverage: 0,
    rubricAnalyticsData: [],
    userAnalyticsData: []
  },
  teacherObservationsAnalyticsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMetaDataStateLoading: false,
  isMetaDataStateSuccess: false,
  isMetaDataStateFailed: false,
  metaDataState: [],
  metaDataStateErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMetaDataDistrictLoading: false,
  isMetaDataDistrictSuccess: false,
  isMetaDataDistrictFailed: false,
  metaDataDistrict: [],
  metaDataDistrictErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMetaDataAreaLoading: false,
  isMetaDataAreaSuccess: false,
  isMetaDataAreaFailed: false,
  metaDataArea: [],
  metaDataAreaErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMetaDataSchoolLoading: false,
  isMetaDataSchoolSuccess: false,
  isMetaDataSchoolFailed: false,
  metaDataSchool: [],
  metaDataSchoolErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isObservationCardDataLoading: false,
  isObservationCardDataSuccess: false,
  isObservationCardDataFailed: false,
  observationCardData: [],
  observationCardErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isObservationDetailLoading: false,
  isObservationDetailSuccess: false,
  isObservationDetailFailed: false,
  observationDetail: null,
  observationDetailErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isObservationsLoading: false,
  isObservationsSuccess: false,
  isObservationsFailed: false,
  observationData: { totalCount: 0, observation: [] },
  observationErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const revertUserAndRoleAnalytics = createAction(
  'REVERT_USER_AND_ROLE_ANALYTICS_DATA'
);

export const revertLocationAnalytics = createAction(
  'REVERT_LOCATION_ANALYTICS_DATA'
);

export const revertFlowsAndFormsAnalytics = createAction(
  'REVERT_FLOWS_AND_FORMS_ANALYTICS_DATA'
);

export const revertTeacherObservationsAnalytics = createAction(
  'REVERT_TEACHER_OBSERVATIONS_ANALYTICS_DATA'
);

export const getUserAndRoleAnalytics = createAsyncThunk(
  'analytics/getUserAndRoleAnalytics',
  async (payload, { rejectWithValue }) => {
    try {
      let response = await api.post(
        `analytics/userAndRoleCountAnalytics`,
        payload
      );
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getUserAnalytics = createAsyncThunk(
  'analytics/getUserAnalytics',
  async (payload, { rejectWithValue }) => {
    try {
      let response = await api.post(`analytics/userCountAnalytics`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getLocationAnalytics = createAsyncThunk(
  'analytics/getLocationAnalytics',
  async (payload, { rejectWithValue }) => {
    try {
      let response = await api.post(
        `analytics/countOfStateAndAreaAndSchool`,
        payload
      );
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getFlowsAndFormsAnalytics = createAsyncThunk(
  'analytics/getFlowsAndFormsAnalytics',
  async (payload, { rejectWithValue }) => {
    try {
      let response = await api.post(
        `analytics/countOfFormAndResponse`,
        payload
      );
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getFlowsAndFormsAnalyticsDetail = createAsyncThunk(
  'analytics/getFlowsAndFormsAnalyticsDetail',
  async ({ formId, flowId }, { rejectWithValue }) => {
    console.log(flowId, 'flowId');
    console.log(formId, 'formId');
    try {
      let response = await api.get(
        `analytics/formAnalytics/${formId}?flowId=${flowId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getTeacherObservationsCount = createAsyncThunk(
  'analytics/getTeacherObservationsCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/analytics/teacherObservation`);

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getTeacherObservationsRubric = createAsyncThunk(
  'analytics/getTeacherObservationsRubric',
  async (payload, { rejectWithValue }) => {
    try {
      let response = await api.post(
        `analytics/teacherObservation/rubric`,
        payload
      );
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getTeacherObservationsUserAnalytics = createAsyncThunk(
  'analytics/getTeacherObservationsUserAnalytics',
  async (payload, { rejectWithValue }) => {
    try {
      let response = await api.post(
        `analytics/teacherObservation/observation`,
        payload
      );
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getMetaDataState = createAsyncThunk(
  'analytics/getMetaDataState',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        '/analytics/metadata/states',
        payload
      );
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getMetaDataArea = createAsyncThunk(
  'analytics/getMetaDataArea',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        '/analytics/metadata/areas',
        payload
      );
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getMetaDataDistrict = createAsyncThunk(
  'analytics/getMetaDataDistrict',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        '/analytics/metadata/districts',
        payload
      );
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getMetaDataSchool = createAsyncThunk(
  'analytics/getMetaDataSchool',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        '/analytics/metadata/schools',
        payload
      );
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getObservationByUserId = createAsyncThunk(
  'analytics/getObservationByUserId',
  async (payload, { rejectWithValue }) => {
    try {
      let response = await api.post(
        `analytics/teacherObservation/observationByUserId`,
        payload
      );
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getObservationsAnalyticsDetail = createAsyncThunk(
  'analytics/getObservationsAnalyticsDetail',
  async (observationId, { rejectWithValue }) => {
    try {
      // const response = await api.get(`teachers/observation/${observationId}`);
      const response = await api.get(
        `analytics/metadata/observation/${observationId}`
      );
      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getAnalyticsObservations = createAsyncThunk(
  'observation/getAnalyticsObservations',
  async ({ payload }, { rejectWithValue, getState }) => {
    try {
      let response;
      response = await api.post(
        `analytics/metadata/observation/getAllObservation/search/${getState().auth.authData.userId}`,
        payload
      );

      return response.data.payload || initial.observationData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getUserAndRoleAnalyticsCount = createAsyncThunk(
  'analytics/getUserAndRoleAnalyticsCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`analytics/userCountAnalytics`);
      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: initial,
  extraReducers: builder => {
    builder
      .addCase(revertUserAndRoleAnalytics, state => {
        state.isUserAnalyticsLoading = initial.isUserAnalyticsLoading;
        state.isUserAnalyticsSuccess = initial.isUserAnalyticsSuccess;
        state.isUserAnalyticsFailed = initial.isUserAnalyticsFailed;
        state.userAnalyticsData = initial.userAnalyticsData;
        state.userAndRoleAnalyticsErrorContainer =
          initial.userAndRoleAnalyticsErrorContainer;

        state.isUserAndRoleAnalyticsLoading =
          initial.isUserAndRoleAnalyticsLoading;
        state.isUserAndRoleAnalyticsSuccess =
          initial.isUserAndRoleAnalyticsSuccess;
        state.isUserAndRoleAnalyticsFailed =
          initial.isUserAndRoleAnalyticsFailed;
        state.userAnalyticsErrorContainer = initial.userAnalyticsErrorContainer;
      })
      .addCase(revertLocationAnalytics, state => {
        state.isLocationAnalyticsLoading = initial.isLocationAnalyticsLoading;
        state.isLocationAnalyticsSuccess = initial.isLocationAnalyticsSuccess;
        state.isLocationAnalyticsFailed = initial.isLocationAnalyticsFailed;
        state.locationAnalyticsData = initial.locationAnalyticsData;
        state.locationAnalyticsErrorContainer =
          initial.locationAnalyticsErrorContainer;
      })

      .addCase(revertFlowsAndFormsAnalytics, state => {
        state.isFlowsAndFormsAnalyticsLoading =
          initial.isFlowsAndFormsAnalyticsLoading;
        state.isFlowsAndFormsAnalyticsSuccess =
          initial.isFlowsAndFormsAnalyticsSuccess;
        state.isFlowsAndFormsAnalyticsFailed =
          initial.isFlowsAndFormsAnalyticsFailed;
        state.flowsAndFormsAnalyticsData = initial.flowsAndFormsAnalyticsData;
        state.flowsAndFormsAnalyticsErrorContainer =
          initial.flowsAndFormsAnalyticsErrorContainer;
      })

      .addCase(revertTeacherObservationsAnalytics, state => {
        state.isTeacherObservationsAnalyticsLoading =
          initial.isTeacherObservationsAnalyticsLoading;
        state.isTeacherObservationsAnalyticsSuccess =
          initial.isTeacherObservationsAnalyticsSuccess;
        state.isTeacherObservationsAnalyticsFailed =
          initial.isTeacherObservationsAnalyticsFailed;
        state.teacherObservationsAnalyticsData =
          initial.teacherObservationsAnalyticsData;
        state.teacherObservationsAnalyticsErrorContainer =
          initial.teacherObservationsAnalyticsErrorContainer;
      })

      .addCase(getUserAndRoleAnalytics.pending, state => {
        state.isUserAndRoleAnalyticsLoading = true;
        state.isUserAndRoleAnalyticsSuccess = false;
        state.isUserAndRoleAnalyticsFailed = false;
        state.userAndRoleAnalyticsData = {
          ...state.userAndRoleAnalyticsData,
          analyticsData: []
        };
        state.userAndRoleAnalyticsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getUserAndRoleAnalytics.fulfilled, (state, action) => {
        state.isUserAndRoleAnalyticsLoading = false;
        state.isUserAndRoleAnalyticsSuccess = true;
        state.isUserAndRoleAnalyticsFailed = false;
        state.userAndRoleAnalyticsData = {
          ...state.userAndRoleAnalyticsData,
          analyticsData: action.payload?.dataList?.UserAndRole || []
        };
      })
      .addCase(getUserAndRoleAnalytics.rejected, (state, action) => {
        state.isUserAndRoleAnalyticsLoading = false;
        state.isUserAndRoleAnalyticsSuccess = false;
        state.isUserAndRoleAnalyticsFailed = true;
        state.userAndRoleAnalyticsData = { ...state, analyticsData: [] };
        state.userAndRoleAnalyticsErrorContainer = {
          ...state.userAndRoleAnalyticsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getUserAnalytics.pending, state => {
        state.isUserAnalyticsLoading = true;
        state.isUserAnalyticsSuccess = false;
        state.isUserAnalyticsFailed = false;
        state.userAnalyticsData = initial.userAnalyticsData;
        state.userAnalyticsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getUserAnalytics.fulfilled, (state, action) => {
        state.isUserAnalyticsLoading = false;
        state.isUserAnalyticsSuccess = true;
        state.isUserAnalyticsFailed = false;
        state.userAnalyticsData = {
          analyticsData: action.payload?.dataList?.userCount || []
        };
      })
      .addCase(getUserAnalytics.rejected, (state, action) => {
        state.isUserAndRoleAnalyticsLoading = false;
        state.isUserAndRoleAnalyticsSuccess = false;
        state.isUserAndRoleAnalyticsFailed = true;
        state.userAnalyticsData = initial.userAnalyticsData;
        state.userAnalyticsErrorContainer = {
          ...state.userAnalyticsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getLocationAnalytics.pending, state => {
        state.isLocationAnalyticsLoading = true;
        state.isLocationAnalyticsSuccess = false;
        state.isLocationAnalyticsFailed = false;
        state.locationAnalyticsData = initial.userAndRoleAnalyticsData;
        state.locationAnalyticsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getLocationAnalytics.fulfilled, (state, action) => {
        state.isLocationAnalyticsLoading = false;
        state.isLocationAnalyticsSuccess = true;
        state.isLocationAnalyticsFailed = false;
        state.locationAnalyticsData = {
          totalArea: action.payload?.dataList?.totalAreaCount || 0,
          totalDistrict: action.payload?.dataList?.totalDistrictCount || 0,
          totalSchool: action.payload?.dataList?.totalSchoolCount || 0,
          analyticsData: action.payload?.dataList?.locationAnalytics || []
        };
      })
      .addCase(getLocationAnalytics.rejected, (state, action) => {
        state.isLocationAnalyticsLoading = false;
        state.isLocationAnalyticsSuccess = false;
        state.isLocationAnalyticsFailed = true;
        state.locationAnalyticsData = initial.locationAnalyticsData;
        state.locationAnalyticsErrorContainer = {
          ...state.locationAnalyticsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getFlowsAndFormsAnalytics.pending, state => {
        state.isFlowsAndFormsAnalyticsLoading = true;
        state.isFlowsAndFormsAnalyticsSuccess = false;
        state.isFlowsAndFormsAnalyticsFailed = false;
        state.flowsAndFormsAnalyticsData = initial.flowsAndFormsAnalyticsData;
        state.flowsAndFormsAnalyticsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getFlowsAndFormsAnalytics.fulfilled, (state, action) => {
        state.isFlowsAndFormsAnalyticsLoading = false;
        state.isFlowsAndFormsAnalyticsSuccess = true;
        state.isFlowsAndFormsAnalyticsFailed = false;
        state.flowsAndFormsAnalyticsData = {
          totalForms:
            action.payload?.dataList?.['totalForm count']?.[0] ||
            action.payload?.dataList?.['totalFormCount']?.[0] ||
            0,
          totalResponse: action.payload?.dataList?.totalResponseCount?.[0] || 0,
          totalAverageResponseTime:
            action.payload?.dataList?.totalResponseAverageTime?.[0] || 0,
          analyticsData:
            action.payload?.dataList?.['Form Analytics'] ||
            action.payload?.dataList?.formAnalytics ||
            []
        };
      })
      .addCase(getFlowsAndFormsAnalytics.rejected, (state, action) => {
        state.isFlowsAndFormsAnalyticsLoading = false;
        state.isFlowsAndFormsAnalyticsSuccess = false;
        state.isFlowsAndFormsAnalyticsFailed = true;
        state.flowsAndFormsAnalyticsData = initial.locationAnalyticsData;
        state.flowsAndFormsAnalyticsErrorContainer = {
          ...state.flowsAndFormsAnalyticsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getFlowsAndFormsAnalyticsDetail.pending, state => {
        state.isFlowsAndFormsAnalyticsDetailLoading = true;
        state.isFlowsAndFormsAnalyticsDetailSuccess = false;
        state.isFlowsAndFormsAnalyticsDetailFailed = false;
        state.flowsAndFormsAnalyticsDetailData =
          initial.flowsAndFormsAnalyticsDetailData;
        state.flowsAndFormsAnalyticsDetailErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getFlowsAndFormsAnalyticsDetail.fulfilled, (state, action) => {
        state.isFlowsAndFormsAnalyticsDetailLoading = false;
        state.isFlowsAndFormsAnalyticsDetailSuccess = true;
        state.isFlowsAndFormsAnalyticsDetailFailed = false;
        state.flowsAndFormsAnalyticsDetailData = action.payload?.payload;
      })
      .addCase(getFlowsAndFormsAnalyticsDetail.rejected, (state, action) => {
        state.isFlowsAndFormsAnalyticsDetailLoading = false;
        state.isFlowsAndFormsAnalyticsDetailSuccess = false;
        state.isFlowsAndFormsAnalyticsDetailFailed = true;
        state.flowsAndFormsAnalyticsDetailData =
          initial.flowsAndFormsAnalyticsDetailData;
        state.flowsAndFormsAnalyticsDetailErrorContainer = {
          ...state.flowsAndFormsAnalyticsDetailErrorContainer,
          ...action.payload
        };
      })

      .addCase(getTeacherObservationsCount.pending, state => {
        state.isTeacherObservationsAnalyticsLoading = true;
        state.isTeacherObservationsAnalyticsSuccess = false;
        state.isTeacherObservationsAnalyticsFailed = false;
        state.teacherObservationsAnalyticsData =
          initial.teacherObservationsAnalyticsData;
        state.teacherObservationsAnalyticsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getTeacherObservationsCount.fulfilled, (state, action) => {
        state.isTeacherObservationsAnalyticsLoading = false;
        state.isTeacherObservationsAnalyticsSuccess = true;
        state.isTeacherObservationsAnalyticsFailed = false;
        state.teacherObservationsAnalyticsData = {
          ...state.teacherObservationsAnalyticsData,
          totalObservationAverage:
            action.payload?.dataList?.totalObservationAverage || 0,
          totalIndicatorCount:
            action.payload?.dataList?.totalIndicatorCount || 0,
          totalObservationCount:
            action.payload?.dataList?.totalObservationCount || 0
        };
      })
      .addCase(getTeacherObservationsCount.rejected, (state, action) => {
        state.isTeacherObservationsAnalyticsLoading = false;
        state.isTeacherObservationsAnalyticsSuccess = false;
        state.isTeacherObservationsAnalyticsFailed = true;
        state.teacherObservationsAnalyticsData =
          initial.teacherObservationsAnalyticsData;
        state.teacherObservationsAnalyticsErrorContainer = {
          ...state.teacherObservationsAnalyticsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getTeacherObservationsRubric.pending, state => {
        state.isTeacherObservationsRubricAnalyticsLoading = true;
        state.isTeacherObservationsRubricAnalyticsSuccess = false;
        state.isTeacherObservationsRubricAnalyticsFailed = false;
        state.teacherObservationsAnalyticsData = {
          ...state.teacherObservationsAnalyticsData,
          rubricAnalyticsData: []
        };
        state.teacherObservationsAnalyticsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getTeacherObservationsRubric.fulfilled, (state, action) => {
        state.isTeacherObservationsRubricAnalyticsLoading = false;
        state.isTeacherObservationsRubricAnalyticsSuccess = true;
        state.isTeacherObservationsRubricAnalyticsFailed = false;
        state.teacherObservationsAnalyticsData = {
          ...state.teacherObservationsAnalyticsData,
          rubricAnalyticsData: action.payload?.dataList?.indAverageRating || []
        };
      })
      .addCase(getTeacherObservationsRubric.rejected, (state, action) => {
        state.isTeacherObservationsRubricAnalyticsLoading = false;
        state.isTeacherObservationsRubricAnalyticsSuccess = false;
        state.isTeacherObservationsRubricAnalyticsFailed = true;
        state.teacherObservationsAnalyticsData = {
          ...state.teacherObservationsAnalyticsData,
          rubricAnalyticsData: []
        };
        state.teacherObservationsAnalyticsErrorContainer = {
          ...state.teacherObservationsAnalyticsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getTeacherObservationsUserAnalytics.pending, state => {
        state.isTeacherObservationsUserAnalyticsLoading = true;
        state.isTeacherObservationsUserAnalyticsSuccess = false;
        state.isTeacherObservationsUserAnalyticsFailed = false;
        state.teacherObservationsAnalyticsData = {
          ...state.teacherObservationsAnalyticsData,
          userAnalyticsData: []
        };
        state.teacherObservationsAnalyticsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(
        getTeacherObservationsUserAnalytics.fulfilled,
        (state, action) => {
          state.isTeacherObservationsUserAnalyticsLoading = false;
          state.isTeacherObservationsUserAnalyticsSuccess = true;
          state.isTeacherObservationsUserAnalyticsFailed = false;
          state.teacherObservationsAnalyticsData = {
            ...state.teacherObservationsAnalyticsData,
            userAnalyticsData:
              action.payload?.dataList?.observationAndIndicatorCount || []
          };
        }
      )
      .addCase(
        getTeacherObservationsUserAnalytics.rejected,
        (state, action) => {
          state.isTeacherObservationsUserAnalyticsLoading = false;
          state.isTeacherObservationsUserAnalyticsSuccess = false;
          state.isTeacherObservationsUserAnalyticsFailed = true;
          state.teacherObservationsAnalyticsData = {
            ...state.teacherObservationsAnalyticsData,
            rubricAnalyticsData: []
          };
          state.teacherObservationsAnalyticsErrorContainer = {
            ...state.teacherObservationsAnalyticsErrorContainer,
            ...action.payload
          };
        }
      )

      .addCase(getMetaDataState.pending, state => {
        state.isMetaDataStateLoading = true;
        state.isMetaDataStateSuccess = false;
        state.isMetaDataStateFailed = false;
        state.metaDataState = [];
        state.metaDataStateErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMetaDataState.fulfilled, (state, action) => {
        state.isMetaDataStateLoading = false;
        state.isMetaDataStateSuccess = true;
        state.isMetaDataStateFailed = false;
        state.metaDataState = action.payload?.dataList;
      })
      .addCase(getMetaDataState.rejected, (state, action) => {
        state.isMetaDataStateLoading = true;
        state.isMetaDataStateSuccess = false;
        state.isMetaDataStateFailed = false;
        state.metaDataState = [];
        state.metaDataStateErrorContainer = {
          ...state.metaDataStateErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMetaDataDistrict.pending, state => {
        state.isMetaDataDistrictLoading = true;
        state.isMetaDataDistrictSuccess = false;
        state.isMetaDataDistrictFailed = false;
        state.metaDataDistrict = [];
        state.metaDataDistrictErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMetaDataDistrict.fulfilled, (state, action) => {
        state.isMetaDataDistrictLoading = false;
        state.isMetaDataDistrictSuccess = true;
        state.isMetaDataDistrictFailed = false;
        state.metaDataDistrict = action.payload?.dataList;
      })
      .addCase(getMetaDataDistrict.rejected, (state, action) => {
        state.isMetaDataDistrictLoading = true;
        state.isMetaDataDistrictSuccess = false;
        state.isMetaDataDistrictFailed = false;
        state.metaDataDistrict = [];
        state.metaDataDistrictErrorContainer = {
          ...state.metaDataDistrictErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMetaDataArea.pending, state => {
        state.isMetaDataAreaLoading = true;
        state.isMetaDataAreaSuccess = false;
        state.isMetaDataAreaFailed = false;
        state.metaDataArea = [];
        state.metaDataAreaErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMetaDataArea.fulfilled, (state, action) => {
        state.isMetaDataAreaLoading = false;
        state.isMetaDataAreaSuccess = true;
        state.isMetaDataAreaFailed = false;
        state.metaDataArea = action.payload?.dataList;
      })
      .addCase(getMetaDataArea.rejected, (state, action) => {
        state.isMetaDataAreaLoading = true;
        state.isMetaDataAreaSuccess = false;
        state.isMetaDataAreaFailed = false;
        state.metaDataArea = [];
        state.metaDataAreaErrorContainer = {
          ...state.metaDataAreaErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMetaDataSchool.pending, state => {
        state.isMetaDataSchoolLoading = true;
        state.isMetaDataSchoolSuccess = false;
        state.isMetaDataSchoolFailed = false;
        state.metaDataSchool = [];
        state.metaDataSchoolErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMetaDataSchool.fulfilled, (state, action) => {
        state.isMetaDataSchoolLoading = false;
        state.isMetaDataSchoolSuccess = true;
        state.isMetaDataSchoolFailed = false;
        state.metaDataSchool = action.payload?.dataList;
      })
      .addCase(getMetaDataSchool.rejected, (state, action) => {
        state.isMetaDataSchoolLoading = true;
        state.isMetaDataSchoolSuccess = false;
        state.isMetaDataSchoolFailed = false;
        state.metaDataSchool = [];
        state.metaDataSchoolErrorContainer = {
          ...state.metaDataSchoolErrorContainer,
          ...action.payload
        };
      })

      .addCase(getObservationsAnalyticsDetail.pending, state => {
        state.isObservationDetailLoading = true;
        state.isObservationDetailSuccess = false;
        state.isObservationDetailFailed = false;
        state.observationDetail = null;
        state.observationDetailErrorContainer =
          initial.observationDetailErrorContainer;
      })
      .addCase(getObservationsAnalyticsDetail.fulfilled, (state, action) => {
        state.isObservationDetailLoading = false;
        state.isObservationDetailSuccess = true;
        state.isObservationDetailFailed = false;
        state.observationDetail = action.payload;
        state.observationDetailErrorContainer =
          initial.observationDetailErrorContainer;
      })
      .addCase(getObservationsAnalyticsDetail.rejected, (state, action) => {
        state.isObservationDetailLoading = false;
        state.isObservationDetailSuccess = false;
        state.isObservationDetailFailed = true;
        state.observationDetailErrorContainer = {
          ...state.observationDetailErrorContainer,
          ...action.payload
        };
      })

      .addCase(getAnalyticsObservations.pending, state => {
        state.isObservationsLoading = true;
        state.isObservationsSuccess = false;
        state.isObservationsFailed = false;
        state.observationData = initial.observationData;
        state.observationErrorContainer = initial.observationErrorContainer;
      })
      .addCase(getAnalyticsObservations.fulfilled, (state, action) => {
        state.isObservationsLoading = false;
        state.isObservationsSuccess = true;
        state.isObservationsFailed = false;
        state.observationData = {
          totalCount: action.payload.totalCount,
          observation: action.payload.dataList
        };
        state.observationErrorContainer = initial.observationErrorContainer;
      })
      .addCase(getAnalyticsObservations.rejected, (state, action) => {
        state.isObservationsLoading = false;
        state.isObservationsSuccess = false;
        state.isObservationsFailed = true;
        state.observationData = initial.observationData;
        state.observationErrorContainer = {
          ...state.observationErrorContainer,
          ...action.payload
        };
      })

      .addCase(getObservationByUserId.pending, state => {
        state.isObservationCardDataLoading = true;
        state.isObservationCardDataSuccess = false;
        state.isObservationCardDataFailed = false;
        state.observationCardData = null;
        state.observationDetailErrorContainer =
          initial.observationDetailErrorContainer;
      })
      .addCase(getObservationByUserId.fulfilled, (state, action) => {
        state.isObservationCardDataLoading = false;
        state.isObservationCardDataSuccess = true;
        state.isObservationCardDataFailed = false;
        state.observationCardData =
          action.payload?.dataList?.observationAndAverageCount || [];
        state.observationDetailErrorContainer =
          initial.observationDetailErrorContainer;
      })
      .addCase(getObservationByUserId.rejected, (state, action) => {
        state.isObservationCardDataLoading = false;
        state.isObservationCardDataSuccess = false;
        state.isObservationCardDataFailed = true;
        state.observationDetailErrorContainer = {
          ...state.observationDetailErrorContainer,
          ...action.payload
        };
      })

      .addCase(getUserAndRoleAnalyticsCount.pending, state => {
        state.userAndRoleAnalyticsData = {
          ...state.userAndRoleAnalyticsData,
          totalUser: 0,
          totalGroup: 0,
          totalRole: 0
        };
      })
      .addCase(getUserAndRoleAnalyticsCount.fulfilled, (state, action) => {
        state.userAndRoleAnalyticsData = {
          ...state.userAndRoleAnalyticsData,
          totalUser: action?.payload?.dataList['totalUserCount'],
          totalGroup: action?.payload?.dataList['totalUserGroupCount'],
          totalRole: action?.payload?.dataList['totalRoleCount']
        };
      })
      .addCase(getUserAndRoleAnalyticsCount.rejected, (state, action) => {
        state.isObservationCardDataLoading = false;
        state.isObservationCardDataSuccess = false;
        state.isObservationCardDataFailed = true;
        state.observationDetailErrorContainer = {
          ...state.observationDetailErrorContainer,
          ...action.payload
        };
      });
  }
});
export default analyticsSlice.reducer;
