import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Snackbar,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import { FormButtonBottom } from '../../utils/styledLayout';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GenericInput from '../../components/GenericInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  passwordRecovery,
  updatePassword
} from '../../redux/reducers/authSlice';
import {
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined
} from '@mui/icons-material';
import {
  enterWithNoSpaces,
  isPasswordContains,
  isValidMobileNumber,
  isValidPassword
} from '../../utils/utils';
import { useState } from 'react';
import { getAuthData, getToken } from '../../config/cookie';
import { EyeOffIcon, EyeOnIcon } from '../../utils/iconSvg';
import {
  colorFieldError,
  colorLinkGrey,
  colorPrimary,
  colorPureWhite,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import { IMG_SPLASH_LOGO } from '../../utils/imageUrls';
import { useNavigate } from 'react-router-dom';

const UpdatePasswordForm = () => {
  // constants
  const dispatch = useDispatch();
  const { authData } = useSelector(state => state.auth);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const customErrorPassword = (
    <div>
      <div>Password requires at least 8 characters long</div>
      <div>1 uppercase,</div>
      <div>1 lowercase,</div>
      <div>1 numeric character,</div>
      <div>and at least 1 special character.</div>
    </div>
  );
  // form style
  const formControl = {
    marginBottom: '0.5rem'
  };

  // form control
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .test(
          'is-valid-password',
          'Password should be atleast 8 characters and should contain uppercase,lowercase,number and special character.',
          value => isPasswordContains(value)
        )
        // .min(8, 'New Password should be of minimum 8 digit')
        // .max(16, 'New Password should be of maximum 16 digit')
        .required('New Password is required'),
      confirmPassword: yup
        .string()
        .required('Confirm Password is required')
        .oneOf(
          [yup.ref('password'), null],
          'Password and Confirm Password must match.'
        )
    }),
    onSubmit: value => {
      // console.log(value);
      let token = getToken();
      let username = getAuthData()?.userName;

      if (!token || !username) {
        // Extract token and username from the URL
        const urlParams = new URLSearchParams(window.location.search);
        token = urlParams.get('token');
        username = urlParams.get('user');
      }
      dispatch(
        updatePassword({
          username: username,
          password: value.password,
          token: token
        })
      );
    }
  });

  return (
    <>
      {/* <Box sx={{ margin: 2, width: '100%' }}>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <FormControl sx={formControl} variant="outlined" fullWidth>
            <GenericInput
              label="Password"
              name="password"
              type={!showPassword ? 'text' : 'password'}
              inputProps={{ maxLength: 16, minLength: 8 }}
              value={formik.values.password}
              onChange={e =>
                formik.setFieldValue(
                  'password',
                  enterWithNoSpaces(e.target.value)
                )
              }
              error={formik.touched.password && formik.errors.password}
              startIcon={<LockOutlined className="icon_color" />}
              endIcon={
                showPassword ? (
                  <VisibilityOffOutlined className="icon_color" />
                ) : (
                  <VisibilityOutlined className="icon_color" />
                )
              }
              onEndIconClick={() => {
                setShowPassword(!showPassword);
              }}
            />
          </FormControl>
          <FormControl sx={formControl} variant="outlined" fullWidth>
            <GenericInput
              label="Confirm Password"
              name="confirmPassword"
              type={!showConfirmPassword ? 'text' : 'password'}
              inputProps={{ maxLength: 16, minLength: 8 }}
              value={formik.values.confirmPassword}
              onChange={e =>
                formik.setFieldValue(
                  'confirmPassword',
                  enterWithNoSpaces(e.target.value)
                )
              }
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              startIcon={<LockOutlined className="icon_color" />}
              endIcon={
                showConfirmPassword ? (
                  <VisibilityOffOutlined className="icon_color" />
                ) : (
                  <VisibilityOutlined className="icon_color" />
                )
              }
              onEndIconClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            />
          </FormControl>
          <FormButtonBottom sx={formControl}>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              size="medium"
              color="primary"
              sx={{
                mt: 3,
                height: 42,
                fontSize: 18,
                fontWeight: '600'
                // textTransform: 'uppercase'
              }}
            >
              Continue
            </LoadingButton>
          </FormButtonBottom>
        </form>

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }

            setOpen(false);
          }}
          message="Please confirm the above link"
        />
      </Box> */}
      {false && (
        <Grid container>
          <Grid sx={formControl} item xs={12} sm={12} md={12} lg={12}>
            <InputLabel
              sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.8 }}
            >
              Password
            </InputLabel>
            <FormControl variant="outlined" sx={formControl} fullWidth>
              <TextField
                name="password"
                size="small"
                placeholder="Enter your password"
                type={!showPassword ? 'text' : 'password'}
                inputProps={{ maxLength: 64 }}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password}
                InputProps={{
                  style: { backgroundColor: colorPureWhite },
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          cursor: 'pointer',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        {!showPassword ? (
                          <EyeOnIcon color={colorTextBlack} />
                        ) : (
                          <EyeOffIcon color={colorTextBlack} />
                        )}
                      </span>
                    </InputAdornment>
                  ),
                  tabIndex: -1
                }}
                onChange={e =>
                  formik.setFieldValue(
                    'password',
                    enterWithNoSpaces(e.target.value)
                  )
                }
              />
              {formik.touched.password && formik.errors.password && (
                <FormHelperText sx={{ mt: 0.2 }}>
                  <SvgIcon
                    sx={{ marginRight: 0.5, height: '16px', width: '16px' }}
                  >
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.64093 5.87662V8.54329M7.64093 11.21H7.6476M6.71781 2.4711L1.23455 11.9422C0.930414 12.4675 0.778346 12.7302 0.800822 12.9457C0.820425 13.1338 0.918939 13.3046 1.07184 13.4158C1.24715 13.5433 1.55066 13.5433 2.15767 13.5433H13.1242C13.7312 13.5433 14.0347 13.5433 14.21 13.4158C14.3629 13.3046 14.4614 13.1338 14.481 12.9457C14.5035 12.7302 14.3514 12.4675 14.0473 11.9422L8.56405 2.4711C8.261 1.94766 8.10948 1.68594 7.9118 1.59803C7.73936 1.52136 7.5425 1.52136 7.37006 1.59803C7.17238 1.68594 7.02085 1.94766 6.71781 2.4711Z"
                        stroke={colorFieldError}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SvgIcon>
                  Incorrect password entered
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid sx={formControl} item xs={12} sm={12} md={12} lg={12}>
            <InputLabel
              sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.8 }}
            >
              Confirm Password
            </InputLabel>
            <FormControl variant="outlined" sx={formControl} fullWidth>
              <TextField
                name="confirmPassword"
                size="small"
                placeholder="Enter your password"
                type={!showConfirmPassword ? 'text' : 'password'}
                inputProps={{ maxLength: 64 }}
                value={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                InputProps={{
                  style: { backgroundColor: colorPureWhite },
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        style={{
                          cursor: 'pointer',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        {!showConfirmPassword ? (
                          <EyeOnIcon color={colorTextBlack} />
                        ) : (
                          <EyeOffIcon color={colorTextBlack} />
                        )}
                      </span>
                    </InputAdornment>
                  ),
                  tabIndex: -1
                }}
                onChange={e =>
                  formik.setFieldValue(
                    'confirmPassword',
                    enterWithNoSpaces(e.target.value)
                  )
                }
              />
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <FormHelperText sx={{ mt: 0.2 }}>
                    <SvgIcon
                      sx={{ marginRight: 0.5, height: '16px', width: '16px' }}
                    >
                      <svg
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.64093 5.87662V8.54329M7.64093 11.21H7.6476M6.71781 2.4711L1.23455 11.9422C0.930414 12.4675 0.778346 12.7302 0.800822 12.9457C0.820425 13.1338 0.918939 13.3046 1.07184 13.4158C1.24715 13.5433 1.55066 13.5433 2.15767 13.5433H13.1242C13.7312 13.5433 14.0347 13.5433 14.21 13.4158C14.3629 13.3046 14.4614 13.1338 14.481 12.9457C14.5035 12.7302 14.3514 12.4675 14.0473 11.9422L8.56405 2.4711C8.261 1.94766 8.10948 1.68594 7.9118 1.59803C7.73936 1.52136 7.5425 1.52136 7.37006 1.59803C7.17238 1.68594 7.02085 1.94766 6.71781 2.4711Z"
                          stroke={colorFieldError}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </SvgIcon>
                    Incorrect password entered
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          <Grid sx={formControl} item xs={12} sm={12} md={12} lg={12}>
            <LoadingButton
              fullWidth
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => formik.handleSubmit()}
              sx={{
                mt: 3,
                height: 42,
                fontSize: 18,
                fontWeight: '600'
              }}
            >
              Continue
            </LoadingButton>
          </Grid>
        </Grid>
      )}

      {true && (
        <Grid
          sx={{
            p: 2,
            height: '100vh',
            position: 'fixed',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: 8,
            backgroundColor: colorPureWhite
          }}
        >
          <Grid>
            <Grid>
              <img src={IMG_SPLASH_LOGO} alt={IMG_SPLASH_LOGO} />
            </Grid>
            <Grid
              sx={{
                p: 3,
                py: 17
              }}
            >
              <Box>
                <Typography
                  fontSize={'32px'}
                  sx={{ color: colorTextBlack }}
                  fontWeight={'700'}
                >
                  Create New Password
                </Typography>
                <Typography
                  sx={{ color: colorLinkGrey }}
                  fontSize={'16px'}
                  fontWeight={'600'}
                >
                  Your new password must be different from previous used
                  passwords.
                </Typography>
              </Box>

              <Box sx={{ width: '434px' }}>
                <Box mt={5} mb={2}>
                  <form autoComplete="off">
                    <Grid>
                      <InputLabel
                        sx={{
                          fontWeight: '700',
                          fontSize: '16px',
                          color: colorTextBlack
                        }}
                      >
                        Password
                      </InputLabel>
                      <FormControl
                        sx={formControl}
                        variant="outlined"
                        fullWidth
                      >
                        <TextField
                          name="password"
                          placeholder="Enter new password"
                          type={!showPassword ? 'text' : 'password'}
                          inputProps={{ maxLength: 16, minLength: 8 }}
                          value={formik.values.password}
                          onChange={e =>
                            formik.setFieldValue(
                              'password',
                              enterWithNoSpaces(e.target.value)
                            )
                          }
                          size="small"
                          error={
                            formik.touched.password && formik.errors.password
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  onClick={() => setShowPassword(!showPassword)}
                                  style={{
                                    cursor: 'pointer',
                                    display: 'inline-flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {!showPassword ? (
                                    <EyeOnIcon
                                      color={
                                        formik.values.password !== ''
                                          ? colorTextBlack
                                          : colorTextGrey
                                      }
                                    />
                                  ) : (
                                    <EyeOffIcon
                                      color={
                                        formik.values.password !== ''
                                          ? colorTextBlack
                                          : colorTextGrey
                                      }
                                    />
                                  )}
                                </span>
                              </InputAdornment>
                            )
                          }}
                          onEndIconClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid>
                      <InputLabel
                        sx={{
                          fontWeight: '700',
                          fontSize: '16px',
                          color: colorTextBlack
                        }}
                      >
                        Re-enter password
                      </InputLabel>
                      <FormControl
                        sx={formControl}
                        variant="outlined"
                        fullWidth
                      >
                        <TextField
                          name="confirmPassword"
                          placeholder="Re-enter new password"
                          type={!showConfirmPassword ? 'text' : 'password'}
                          inputProps={{ maxLength: 16, minLength: 8 }}
                          value={formik.values.confirmPassword}
                          onChange={e =>
                            formik.setFieldValue(
                              'confirmPassword',
                              enterWithNoSpaces(e.target.value)
                            )
                          }
                          size="small"
                          error={
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                          }
                          helperText={
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    display: 'inline-flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {!showConfirmPassword ? (
                                    <EyeOnIcon
                                      color={
                                        formik.values.confirmPassword !== ''
                                          ? colorTextBlack
                                          : colorTextGrey
                                      }
                                    />
                                  ) : (
                                    <EyeOffIcon
                                      color={
                                        formik.values.confirmPassword !== ''
                                          ? colorTextBlack
                                          : colorTextGrey
                                      }
                                    />
                                  )}
                                </span>
                              </InputAdornment>
                            )
                          }}
                          onEndIconClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </form>
                </Box>
                <Divider />
              </Box>
              <Box mt={3}>
                <Grid
                  sx={formControl}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display={'flex'}
                  gap={2}
                >
                  <Button
                    variant="outlined"
                    size="medium"
                    fullWidth
                    sx={{
                      color: colorTextBlack,
                      borderColor: colorPrimary,
                      fontSize: 14,
                      marginRight: 1,
                      width: '209px',
                      height: '42px',
                      fontWeight: '700',
                      borderWidth: '2px',
                      '&:hover': {
                        borderColor: colorPrimary,
                        backgroundColor: 'transparent',
                        borderRadius: '8px',
                        borderWidth: '2px'
                      }
                    }}
                    onClick={() => navigate('/login')}
                  >
                    Back to login
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    disabled={
                      formik.values.password == '' ||
                      formik.values.confirmPassword == ''
                    }
                    sx={{ width: '209px', height: '42px' }}
                    fullWidth
                    onClick={() => formik.handleSubmit()}
                  >
                    Reset password
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid
            sx={{
              backgroundColor: colorSecondary,
              width: '20%',
              borderRadius: '5px'
            }}
          ></Grid>
        </Grid>
      )}
    </>
  );
};

export default UpdatePasswordForm;
