import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  Rating,
  Skeleton,
  SvgIcon,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  createTitle,
  generateImageSrc,
  isArrayNotEmpty
} from '../../utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getObservationsDetail,
  revertEvidenceFormData,
  revertObservationFormData
} from '../../redux/reducers/observationSlice';
import { useSelector } from 'react-redux';
import {
  MIC_ICON,
  PDF_ICON,
  VIDEO_ICON,
  sceneroy_icon
} from '../../utils/imageUrls';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack
} from '../../config/theme';

const ObservationReportCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {
    isObservationDetailLoading,
    isObservationDetailSuccess,
    isObservationDetailFailed,
    observationDetailErrorContainer,
    observationDetail
  } = useSelector(state => state.observation);

  useEffect(() => {
    document.title = createTitle('Observation Card');

    if (location) {
      dispatch(getObservationsDetail(location.state?.observationId));
    }

    return () => {
      dispatch(revertObservationFormData());
    };
  }, []);

  useEffect(() => {
    if (!isObservationDetailLoading) {
      setLoading(false);
    }
  }, [observationDetail]);

  return (
    <>
      <Typography mb={1} sx={{ fontSize: '32px', fontWeight: '700' }}>
        Observation Report
      </Typography>
      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}
      {!loading && (
        <>
          <Grid container borderRadius={2} padding={2} bgcolor={'white'}>
            <Grid item display={'flex'} lg={12} alignItems={'center'}>
              <Grid marginRight={2}>
                {observationDetail?.userImage ? (
                  <Avatar
                    src={observationDetail?.userImage}
                    alt={observationDetail?.userName}
                    sx={{ width: 80, height: 80, marginRight: 1 }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      width: 80,
                      height: 80,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      backgroundColor: '#c9c9c9',
                      color: 'white',
                      marginRight: '5px'
                    }}
                  >
                    {observationDetail
                      ? observationDetail.userName
                          ?.split(' ')
                          .map(word => word[0])
                          .join('')
                          .toUpperCase()
                      : ''}
                  </Avatar>
                )}
              </Grid>
              <Grid display={'flex'}>
                <p>
                  <span style={{ display: 'flex' }}>
                    <Typography
                      fontWeight={'700'}
                      fontSize={'24px'}
                      color={colorTextBlack}
                    >
                      {observationDetail?.userName} &nbsp;
                    </Typography>
                    <Typography
                      ffontWeight={'700'}
                      fontSize={'24px'}
                      color={colorLinkGrey}
                    >
                      ( {observationDetail?.userGroup} )
                    </Typography>
                  </span>

                  <Box
                    style={{
                      fontWeight: 700,
                      fontSize: '32px',
                      display: 'flex'
                    }}
                    sx={{ marginY: 1 }}
                  >
                    <Rating
                      name="simple-controlled"
                      precision={0.5}
                      value={
                        observationDetail?.observationAvgRatings
                          ? observationDetail.observationAvgRatings
                          : 0
                      }
                      readOnly
                    />
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ margin: '3px' }}
                    />
                    <Typography fontWeight={'600'} color={'GrayText'}>
                      {observationDetail?.observationAvgRatings
                        ? `${observationDetail.observationAvgRatings}/5`
                        : '0.0'}
                    </Typography>
                  </Box>
                </p>
              </Grid>
            </Grid>
            <Grid item lg={12}>
              {observationDetail?.evidenceResponseList &&
                isArrayNotEmpty(observationDetail.evidenceResponseList) && (
                  <Box borderRadius={2} marginBottom={2}>
                    <Typography fontSize={'20px'} fontWeight={'700'}>
                      Evidence Cards (
                      {observationDetail.evidenceResponseList.length})
                    </Typography>
                    <Grid container>
                      {observationDetail.evidenceResponseList.map(
                        (evidence, key) => {
                          return (
                            <Grid
                              item
                              sm={12}
                              md={12}
                              key={key}
                              sx={{}}
                              onClick={() => {
                                dispatch(revertEvidenceFormData());
                                return navigate('evidence-card', {
                                  state: {
                                    observationId:
                                      location.state?.observationId,
                                    evidenceId: evidence.evidenceId
                                  }
                                });
                              }}
                            >
                              <Box
                                sx={{
                                  padding: '16px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  borderRadius: '8px',
                                  cursor: 'pointer',
                                  ':hover': { bgcolor: colorSecondary }
                                }}
                              >
                                <Box>
                                  <Typography
                                    fontSize={'16px'}
                                    color={colorTextBlack}
                                    fontWeight={'700'}
                                    mb={1}
                                  >
                                    Evidence card {key + 1}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: '600',
                                      fontSize: '14px',
                                      color: colorLinkGrey
                                    }}
                                  >
                                    {evidence.indicatorName}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '10px'
                                  }}
                                >
                                  <Icon>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10.8 14H4.62091C4.21704 14 4.0151 14 3.92159 13.9201C3.84045 13.8508 3.79739 13.7469 3.80577 13.6405C3.81541 13.5179 3.95821 13.3751 4.24379 13.0895L9.91242 7.42091C10.1764 7.15691 10.3084 7.0249 10.4607 6.97544C10.5946 6.93193 10.7388 6.93193 10.8727 6.97544C11.0249 7.0249 11.1569 7.1569 11.4209 7.42091L14 10V10.8M10.8 14C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8M10.8 14H5.2C4.0799 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8M7 5.66667C7 6.40305 6.40305 7 5.66667 7C4.93029 7 4.33333 6.40305 4.33333 5.66667C4.33333 4.93029 4.93029 4.33333 5.66667 4.33333C6.40305 4.33333 7 4.93029 7 5.66667Z"
                                        stroke={colorTextBlack}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </Icon>
                                  <Typography
                                    sx={{
                                      marginLeft: '3px',
                                      marginTop: '8px',
                                      fontSize: '14px'
                                    }}
                                  >
                                    {evidence?.fileCount?.Image || 0}
                                  </Typography>
                                  <Divider
                                    orientation="vertical"
                                    sx={{
                                      marginX: 1,
                                      borderColor: '#E4E7EB',
                                      height: '16px'
                                    }}
                                  />
                                  <Icon>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12.6673 6.66659V7.99992C12.6673 10.5772 10.578 12.6666 8.00065 12.6666M3.33398 6.66659V7.99992C3.33398 10.5772 5.42332 12.6666 8.00065 12.6666M8.00065 12.6666V14.6666M5.33398 14.6666H10.6673M8.00065 9.99992C6.89608 9.99992 6.00065 9.10449 6.00065 7.99992V3.33325C6.00065 2.22868 6.89608 1.33325 8.00065 1.33325C9.10522 1.33325 10.0007 2.22868 10.0007 3.33325V7.99992C10.0007 9.10449 9.10522 9.99992 8.00065 9.99992Z"
                                        stroke={colorTextBlack}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </Icon>
                                  <Typography
                                    sx={{
                                      marginLeft: '3px',
                                      marginTop: '8px',
                                      fontSize: '14px'
                                    }}
                                  >
                                    {evidence?.fileCount?.Audio || 0}
                                  </Typography>
                                  <Divider
                                    orientation="vertical"
                                    sx={{
                                      marginX: 1,
                                      borderColor: '#E4E7EB',
                                      height: '16px'
                                    }}
                                  />
                                  <Icon>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M14.6673 5.95417C14.6673 5.55029 14.6673 5.34835 14.5875 5.25484C14.5182 5.1737 14.4142 5.13065 14.3078 5.13902C14.1852 5.14867 14.0424 5.29146 13.7569 5.57704L11.334 7.99992L13.7569 10.4228C14.0424 10.7084 14.1852 10.8512 14.3078 10.8608C14.4142 10.8692 14.5182 10.8261 14.5875 10.745C14.6673 10.6515 14.6673 10.4495 14.6673 10.0457V5.95417Z"
                                        stroke={colorTextBlack}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M1.33398 6.53325C1.33398 5.41315 1.33398 4.85309 1.55197 4.42527C1.74372 4.04895 2.04968 3.74299 2.426 3.55124C2.85383 3.33325 3.41388 3.33325 4.53398 3.33325H8.13398C9.25409 3.33325 9.81414 3.33325 10.242 3.55124C10.6183 3.74299 10.9243 4.04895 11.116 4.42527C11.334 4.85309 11.334 5.41315 11.334 6.53325V9.46659C11.334 10.5867 11.334 11.1467 11.116 11.5746C10.9243 11.9509 10.6183 12.2569 10.242 12.4486C9.81414 12.6666 9.25409 12.6666 8.13398 12.6666H4.53398C3.41388 12.6666 2.85383 12.6666 2.426 12.4486C2.04968 12.2569 1.74372 11.9509 1.55197 11.5746C1.33398 11.1467 1.33398 10.5867 1.33398 9.46659V6.53325Z"
                                        stroke={colorTextBlack}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </Icon>
                                  <Typography
                                    sx={{
                                      marginLeft: '3px',
                                      marginTop: '8px',
                                      fontSize: '14px'
                                    }}
                                  >
                                    {evidence?.fileCount?.Video || 0}
                                  </Typography>
                                  <Divider
                                    orientation="vertical"
                                    sx={{
                                      marginX: 1,
                                      borderColor: '#E4E7EB',
                                      height: '16px'
                                    }}
                                  />
                                  <Icon>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.33333 1.33325H10.1333C11.2534 1.33325 11.8135 1.33325 12.2413 1.55124C12.6176 1.74299 12.9236 2.04895 13.1153 2.42527C13.3333 2.85309 13.3333 3.41315 13.3333 4.53325V11.4666C13.3333 12.5867 13.3333 13.1467 13.1153 13.5746C12.9236 13.9509 12.6176 14.2569 12.2413 14.4486C11.8135 14.6666 11.2534 14.6666 10.1333 14.6666H5.86667C4.74656 14.6666 4.18651 14.6666 3.75869 14.4486C3.38236 14.2569 3.0764 13.9509 2.88465 13.5746C2.66667 13.1467 2.66667 12.5867 2.66667 11.4666V10.9999M10.6667 8.66659H7.66667M10.6667 5.99992H8.33333M10.6667 11.3333H5.33333M4 6.66659V2.99992C4 2.44763 4.44772 1.99992 5 1.99992C5.55228 1.99992 6 2.44763 6 2.99992V6.66659C6 7.77115 5.10457 8.66659 4 8.66659C2.89543 8.66659 2 7.77116 2 6.66659V3.99992"
                                        stroke={colorTextBlack}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </Icon>
                                  <Typography
                                    sx={{
                                      marginLeft: '3px',
                                      marginTop: '8px',
                                      fontSize: '14px'
                                    }}
                                  >
                                    {evidence?.fileCount?.Document || 0}
                                  </Typography>
                                </Box>
                              </Box>
                              <Divider sx={{ marginBottom: 1 }} />
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Box>
                )}
            </Grid>
            {observationDetail?.feedbackDescription && (
              <Grid item lg={12}>
                <Typography variant="body1" fontWeight={'600'}>
                  Feedback note for teacher
                </Typography>
                <Typography variant="body2">
                  {observationDetail?.feedbackDescription}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            borderRadius={2}
            padding={2}
            bgcolor={'white'}
            mt={2}
            gap={2}
            display={'flex'}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
              onClick={() => navigate('/observations')}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                // width: '94px',
                // height: '42px',
                boxShadow: 'none'
              }}
              startIcon={
                <SvgIcon sx={{ width: '16px', height: '16px' }}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.91769 12.0771C1.94831 11.8015 1.96363 11.6636 2.00533 11.5348C2.04234 11.4205 2.09461 11.3117 2.16075 11.2114C2.2353 11.0984 2.33336 11.0003 2.52948 10.8042L11.3337 2.00004C12.0701 1.26366 13.264 1.26366 14.0003 2.00004C14.7367 2.73642 14.7367 3.93033 14.0003 4.66671L5.19614 13.4709C5.00002 13.667 4.90196 13.7651 4.78892 13.8396C4.68862 13.9058 4.57985 13.958 4.46556 13.995C4.33673 14.0367 4.1989 14.0521 3.92324 14.0827L1.66699 14.3334L1.91769 12.0771Z"
                      stroke="#1F2933"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
              }
              onClick={() => {
                // console.log(location.state, 'state');
                dispatch(revertEvidenceFormData());
                navigate(
                  '/observations/observation-report/create-evidence-card',
                  {
                    state: { observationId: location.state?.observationId }
                  }
                );
              }}
            >
              Create Evidence Card
            </Button>{' '}
          </Grid>
        </>
      )}
    </>
  );
};

export default ObservationReportCard;
