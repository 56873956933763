import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Divider } from '@mui/material';
import CustomPagination from './CustomTablePagination';
import { colorPrimary, colorSecondary, colorTextGrey } from '../config/theme';
import { CheckboxCheckedIcon, CheckboxIcon, TrashIcon } from '../utils/iconSvg';

const visuallyHidden = {
  position: 'absolute',
  top: -9999,
  left: -9999,
  width: 1,
  height: 1,
  overflow: 'hidden'
};

const EnhancedTableHead = ({
  columns,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  allowSelection
}) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: '#F5F7FA',
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
          '&:first-of-type th:first-of-type': { borderTopLeftRadius: '8px' },
          '&:first-of-type th:last-of-type': { borderTopRightRadius: '8px' }
        }}
      >
        {allowSelection && (
          <TableCell sx={{ width: 10, padding: '0px 12px' }}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all items'
              }}
            />
          </TableCell>
        )}
        {columns.map(column => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.id ? order : false}
            sx={{
              width: column.width || 'auto',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: column.width || '100px',
              fontWeight: '600'
              // borderColor: '#E4E7EB'
            }}
          >
            {column.sortable ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
                sx={{ color: colorTextGrey }}
              >
                <Typography
                  sx={{ color: colorTextGrey }}
                  fontSize={'14px'}
                  fontWeight={'700'}
                >
                  {column.label}
                </Typography>
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography
                sx={{ color: colorTextGrey }}
                fontSize={'14px'}
                fontWeight={'700'}
              >
                {column.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  allowSelection: PropTypes.bool
};

function CustomDataTable({
  columns,
  data,
  onPageChange,
  onRowsPerPageChange,
  defaultPage = 0,
  defaultRowsPerPage = 1,
  selectedRows,
  onSelectionChange,
  totalCount,
  allowSelection,
  defaultOrderBy,
  allowPagination = true,
  hideEmptyRows = false,
  onRowClick = () => {},
  allowHover = true
}) {
  const [order, setOrder] = useState(defaultOrderBy ? 'desc' : 'asc');
  const [orderBy, setOrderBy] = useState(
    defaultOrderBy ? defaultOrderBy : columns[0].uinqueKey
  );
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(defaultPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  useEffect(() => {
    setSelected(selectedRows || []);
  }, [selectedRows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = data.map(item => item[columns[0].uinqueKey]);
      setSelected(newSelected);
      onSelectionChange && onSelectionChange(newSelected);
      return;
    }
    setSelected([]);
    onSelectionChange && onSelectionChange([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    onSelectionChange && onSelectionChange(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange && onPageChange(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    onRowsPerPageChange && onRowsPerPageChange(newRowsPerPage);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const getComparator = (order, orderBy) => {
    if (orderBy === 'creationDate') {
      return order === 'desc'
        ? (a, b) => {
            const dateA = new Date(a[orderBy]);
            const dateB = new Date(b[orderBy]);
            return dateB - dateA;
          }
        : (a, b) => {
            const dateA = new Date(a[orderBy]);
            const dateB = new Date(b[orderBy]);
            return dateA - dateB;
          };
    } else {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // return order === 'desc'
    //   ? (a, b) => descendingComparator(a, b, orderBy)
    //   : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    // if (b[orderBy] < a[orderBy]) {
    //   return -1;
    // }
    // if (b[orderBy] > a[orderBy]) {
    //   return 1;
    // }
    // return 0;

    if (orderBy === 'creationDate') {
      const dateA = new Date(a[orderBy]);
      const dateB = new Date(b[orderBy]);
      if (dateB < dateA) {
        return -1;
      }
      if (dateB > dateA) {
        return 1;
      }
      return 0;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = hideEmptyRows ? data.length : 10 - data.length;

  const visibleRows = useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        0 * rowsPerPage,
        0 + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, data]
  );

  return (
    <>
      <TableContainer sx={{}}>
        <Table aria-labelledby="tableTitle" sx={{}} size={'small'}>
          {data.length > 0 && (
            <EnhancedTableHead
              columns={columns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              allowSelection={allowSelection}
            />
          )}
          <TableBody>
            {data.length > 0 ? (
              visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row[columns[0].uinqueKey]);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover={allowHover}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row[columns[0].uinqueKey]}
                    selected={isItemSelected}
                    // sx={{
                    //   cursor: 'pointer',
                    //   '&.Mui-selected': {
                    //     backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    //   },
                    //   '.MuiTableRow-hover&:hover': {
                    //     backgroundColor: colorSecondary
                    //     // color: 'inherit'
                    //   }
                    // }}
                    sx={{
                      height: '54px',
                      cursor: allowHover ? 'pointer' : 'default',
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)'
                      },
                      ...(allowHover && {
                        '.MuiTableRow-hover&:hover': {
                          backgroundColor: colorSecondary
                        }
                      })
                    }}
                  >
                    {allowSelection && (
                      <TableCell sx={{ padding: '0px 12px' }}>
                        <Checkbox
                          onClick={event => {
                            handleClick(event, row[columns[0].uinqueKey]);
                          }}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.map((column, columnIndex) => (
                      <TableCell
                        onClick={() => onRowClick(row, index)}
                        key={column.id}
                        align={column.numeric ? 'right' : 'left'}
                        sx={{
                          width: column.width || 'auto',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          minWidth: column.width || '100px',
                          fontWeight: '600',
                          borderColor: '#E4E7EB'
                        }}
                        // sx={{
                        //   minWidth: column.width || '100%',
                        //   whiteSpace: 'pre-line',
                        //   fontWeight: '600',
                        //   borderColor: '#E4E7EB'
                        // }}
                      >
                        {column.render
                          ? column.render(row, columnIndex)
                          : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              // Render message when no data is present
              <TableRow>
                <TableCell
                  colSpan={columns.length + (allowSelection ? 1 : 0)}
                  sx={{ textAlign: 'center', borderBottom: 'none ' }}
                >
                  <Typography variant="body1" sx={{ p: 2, pb: 4 }}>
                    No data found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!hideEmptyRows && emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows
                }}
              >
                <TableCell
                  colSpan={columns.length + (allowSelection ? 1 : 0)}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length > 0 && <Divider orientation="horizontal" />}

      {/* {allowPagination && (
        <TablePagination
          // rowsPerPageOptions={[11]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )} */}
      {data.length > 0 && (
        <>
          {allowPagination && (
            <CustomPagination
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              paginationLabelMode={false}
              onPageChange={handleChangePage}
            />
          )}
        </>
      )}
    </>
  );
}

CustomDataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.string, // Add this line for width prop
      sortable: PropTypes.bool,
      numeric: PropTypes.bool,
      render: PropTypes.func
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  onPageChange: PropTypes.func, // New prop
  onRowsPerPageChange: PropTypes.func, // New prop
  defaultPage: PropTypes.number,
  defaultRowsPerPage: PropTypes.number,
  onSelectionChange: PropTypes.func,
  selectedRows: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  allowSelection: PropTypes.bool,
  defaultOrderBy: PropTypes.string,
  allowPagination: PropTypes.bool,
  hideEmptyRows: PropTypes.bool,
  onRowClick: PropTypes.func,
  allowHover: PropTypes.bool
};

export default CustomDataTable;
