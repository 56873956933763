import React, { useContext, useEffect, useRef } from 'react';
import {
  AppBar,
  Button,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  styled,
  alpha,
  Divider,
  TextField,
  InputAdornment,
  Typography,
  Avatar,
  IconButton,
  Autocomplete,
  SvgIcon,
  Box,
  List,
  ListItem,
  ListItemText,
  keyframes
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  colorMidGray,
  colorPureWhite,
  colorSecondary,
  colorTextBlack
} from '../../config/theme';
import { drawerWidth } from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAndClearToken } from '../../redux/reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import {
  generateImageSrc,
  getOptionLabel,
  isArrayNotEmpty
} from '../../utils/utils';
import { CaseContext, privateRoute, WebFlagContext } from '../../App';
import { getBasicDetails } from '../../config/cookie';
import { GroupHeader, GroupItems } from '../../utils/styledLayout';
import _ from 'lodash';
import {
  getNotificaitonsList,
  readAllNotification,
  setActiveModule,
  updateFirebaseFlag
} from '../../redux/reducers/layoutSlice';
import { HeaderBellIcon, SearchMdIcon, TrashIcon } from '../../utils/iconSvg';
import NotificationList from '../NotificationList';

const circleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: '#c9c9c9',
  color: 'white',
  fontSize: '12px',
  marginRight: '5px'
};

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow: '0px 4px 14px 0px #1F29331F',
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.primary[300],
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      },
      '&:hover': {
        backgroundColor: colorSecondary
      }
    }
  }
}));

function HeaderBar({ menulist, sidebarOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notificationRef = useRef(null);

  const [menuItem, setMenuItem] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { authData } = useSelector(state => state.auth);
  const { notifications, isNotificationsLoading, isNotificationsSucess } =
    useSelector(state => state.layout);
  const [showNotifications, setShowNotifications] = React.useState(false);
  const { setBasicDetailsContext, basicDetailsContext } =
    useContext(CaseContext);
  const { webFlag } = useContext(WebFlagContext);

  useEffect(() => {
    if (menulist) {
      setMenuItem(
        Object.entries({ Dashboard: [], ...menulist }).flatMap(
          ([category, items]) =>
            items.length > 0
              ? items.map(item => ({ category, item }))
              : [
                  {
                    category,
                    item: {
                      pageName: category
                    }
                  }
                ]
        )
      );
    }
    let getBasicDetailsResponse = JSON.parse(getBasicDetails());
    setBasicDetailsContext({
      userImage: getBasicDetailsResponse?.profileImg,
      name: getBasicDetailsResponse?.name
    });
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOutside = event => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setShowNotifications(false);
    }
  };

  const handleNavigation = menu_name => {
    let tempMenu = menu_name === 'Dashboard' ? 'dashboard' : menu_name;
    if (!_.includes(['', null, undefined], tempMenu)) {
      dispatch(setActiveModule(tempMenu));
      let routeTo = privateRoute.find(cc => cc.menu_name === tempMenu);
      if (routeTo) {
        return navigate(routeTo?.path);
      } else {
        navigate('/forbidden');
      }
    }
  };

  useEffect(() => {
    if (showNotifications) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotifications]);

  return (
    <>
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: sidebarOpen
            ? `calc(100% - ${drawerWidth}px)`
            : `calc(100% - 100px)`,
          marginLeft: sidebarOpen ? `${drawerWidth}px` : '100px',
          backgroundColor: colorPureWhite
        }}
      >
        <Toolbar>
          <Grid
            containter="true"
            display={'flex'}
            width={'100%'}
            justifyContent={'space-between'}
            // padding={"16px 24px 16px 24px"}
          >
            <Grid
              item
              sx={{
                width: {
                  xl: '416px',
                  lg: '416px',
                  md: '46%',
                  sm: '90%'
                },
                height: '42px',
                display: 'flex',
                alignSelf: 'center'
              }}
            >
              <Autocomplete
                size="small"
                onChange={(event, newValue) => {
                  handleNavigation(newValue ? newValue?.item.pageName : null);
                }}
                popupIcon={false}
                groupBy={option => option.category}
                getOptionLabel={option => option?.item?.pageName}
                options={isArrayNotEmpty(menuItem) ? menuItem : []}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    paddingRight: '16px !important'
                  }
                }}
                renderGroup={params => (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{ paddingRight: 0 }}
                    placeholder="Search here..."
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 17.5L14.375 13.875M16.3333 9.16667C16.3333 12.8486 13.3486 15.8333 9.66667 15.8333C5.98477 15.8333 3 12.8486 3 9.16667C3 5.48477 5.98477 2.5 9.66667 2.5C13.3486 2.5 16.3333 5.48477 16.3333 9.16667Z"
                              stroke="#ABB4BD"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>

                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    '& .MuiAutocomplete-option:hover': {
                      backgroundColor: colorSecondary
                    }
                  }
                }}
              />
            </Grid>
            <Grid item display={'flex'}>
              <Box sx={{ position: 'relative' }} ref={notificationRef}>
                <IconButton
                  sx={{
                    height: '48px',
                    width: '48px',
                    borderRadius: '8px !important',
                    padding: '8px',
                    mr: 1,
                    ':hover': {
                      backgroundColor: colorSecondary
                    }
                  }}
                  onClick={() => {
                    if (!showNotifications && !webFlag)
                      dispatch(getNotificaitonsList());
                    setShowNotifications(!showNotifications);
                  }}
                >
                  <SvgIcon fontSize="24px">
                    <HeaderBellIcon />
                  </SvgIcon>
                  {notifications.isNewNotification && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '16px',
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: '#D62828'
                        // animation: `${pulse} 1.5s infinite`
                      }}
                    />
                  )}
                </IconButton>

                {showNotifications && (
                  <NotificationList
                    notificationsData={notifications}
                    readAllNotificaiton={() => {
                      dispatch(readAllNotification());
                    }}
                  />
                )}
              </Box>
              {/* <IconButton aria-label="apps">
                <AppsOutlined fontSize="24px" />
              </IconButton> */}
              <Divider orientation="vertical" variant="middle" flexItem />
              <Button
                disableRipple
                disableTouchRipple
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                disableElevation
                sx={{
                  minWidth: '150px',
                  fontSize: '20px',
                  color: colorTextBlack
                }}
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}
              >
                {authData ? (
                  <>
                    {basicDetailsContext?.userImage ? (
                      <Avatar
                        src={basicDetailsContext?.userImage}
                        alt={basicDetailsContext?.name}
                        sx={{ width: 32, height: 32, marginRight: 1 }}
                      />
                    ) : (
                      <div style={circleStyle}>
                        {basicDetailsContext
                          ? basicDetailsContext?.name
                              ?.split(' ')[0]
                              .charAt(0)
                              .toUpperCase()
                          : 'A'}
                      </div>
                    )}
                    <Typography fontSize={16} fontWeight={'600'}>
                      {basicDetailsContext?.name?.split(' ')[0] || ''}
                    </Typography>
                  </>
                ) : (
                  'User'
                )}
              </Button>
              <StyledMenu
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                  sx: {}
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/my-account');
                  }}
                  disableRipple
                >
                  My account
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/update-password');
                  }}
                  disableRipple
                >
                  Change password
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={() => {
                    navigate('/login');
                    dispatch(logoutAndClearToken());
                    handleClose();
                  }}
                  disableRipple
                >
                  Log out
                </MenuItem>
              </StyledMenu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default HeaderBar;
