import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import moment from 'moment';
import { CustomTextLink } from '../containers/login/LoginForm';
import { colorSecondary, colorTextBlack } from '../config/theme';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { readNotification } from '../redux/reducers/layoutSlice';

const NotificationHeader = ({ title, count }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '700',
          alignContent: 'center'
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          p: '4px',
          display: 'flex',
          minWidth: '33px',
          height: '26px',
          alignItems: 'center',
          borderRadius: '8px',
          backgroundColor: '#F5F7FA'
        }}
      >
        {' '}
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.45672 12.2498C5.86804 12.6129 6.40835 12.8332 7.00011 12.8332C7.59187 12.8332 8.13218 12.6129 8.54351 12.2498M10.5001 4.6665C10.5001 3.73825 10.1314 2.84801 9.47499 2.19163C8.81861 1.53525 7.92837 1.1665 7.00011 1.1665C6.07186 1.1665 5.18162 1.53525 4.52524 2.19163C3.86886 2.84801 3.50011 3.73825 3.50011 4.6665C3.50011 6.46911 3.04539 7.70331 2.53742 8.51966C2.10894 9.20826 1.8947 9.55257 1.90255 9.64861C1.91125 9.75496 1.93378 9.79552 2.01948 9.85909C2.09688 9.9165 2.44579 9.9165 3.14361 9.9165H10.8566C11.5544 9.9165 11.9033 9.9165 11.9807 9.85909C12.0664 9.79552 12.089 9.75496 12.0977 9.64861C12.1055 9.55257 11.8913 9.20826 11.4628 8.51966C10.9548 7.70331 10.5001 6.46911 10.5001 4.6665Z"
            stroke="#4E565F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Typography sx={{ fontSize: '14px', fontWeight: '700', ml: 0.3 }}>
          {count}
        </Typography>
      </Box>
    </Box>
  );
};
const notificationNavigation = {
  1: '/flows',
  2: '/schedules',
  4: '/flows',
  5: '/my-account',
  6: '/flows',
  7: '/schedules',
  8: '/schedules',
  9: '/schedules',
  10: '/my-account'
};

const NotificationList = ({ notificationsData = {}, readAllNotificaiton }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [viewAll, setViewAll] = React.useState(false);

  const renderNotifications = ({ notifications = [], limit }) => {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        m: 'a min',
        mm: '%d min',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years'
      }
    });

    return notifications
      .slice(0, viewAll ? notifications.length : limit)
      .map(notification => (
        <ListItem
          onClick={() => {
            let navigateTo = notificationNavigation[notification?.templateId];

            // if (condition) {

            // }
            dispatch(readNotification(notification?.userNotificationId));
            navigate(navigateTo);
          }}
          key={notification.userNotificationId}
          sx={{
            padding: '8px',
            borderRadius: '8px',
            height: '65px',
            cursor: 'pointer',
            ':hover': {
              bgcolor: colorSecondary
            }
          }}
        >
          {!notification.isRead && (
            <Box
              sx={{
                marginTop: '3px',
                alignSelf: 'start',
                display: 'flex',
                padding: '3px',
                width: '6px',
                height: '6px',
                borderRadius: '100%',
                backgroundColor: '#D62828',
                mr: 1
              }}
            />
          )}
          <ListItemText
            primaryTypographyProps={{
              fontWeight: '600',
              fontSize: '12px',
              color: colorTextBlack,
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              textOverflow: 'ellipsis',
              whiteSpace: 'normal',
              maxHeight: '28px',
              lineHeight: '14px',
              marginBottom: '5px'
            }}
            secondaryTypographyProps={{
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '14px',
              textAlign: ' left'
            }}
            primary={notification.content}
            secondary={moment(notification.createdDate).fromNow()}
          />
        </ListItem>
      ));
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50px',
        left: '-160px',
        width: '380px',
        minHeight: '513px',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: '4px 4px 20px 0px #00000040',
        borderRadius: 2,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          p: '16px 16px 0px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography fontWeight={'700'} fontSize={'20px'}>
          Notifications
        </Typography>
        <CustomTextLink
          onClick={() => {
            readAllNotificaiton();
          }}
          fontWeight="700"
          title="Mark all as read"
          fontSize="12px"
        />
      </Box>
      <Box
        sx={{
          m: 1,
          overflowY: 'auto',
          flex: 1,
          '&::-webkit-scrollbar': {
            width: '0.2em'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.3)'
          }
        }}
      >
        <Box sx={{ p: 1.5 }}>
          {' '}
          <NotificationHeader
            title={'Today'}
            count={notificationsData.todayCount}
          />
          <List sx={{ p: 0 }}>
            {renderNotifications({
              notifications: notificationsData?.today,
              limit: 3
            })}
          </List>
        </Box>
        <Divider />
        <Box sx={{ p: 1.5 }}>
          <NotificationHeader
            title={'Yesterday'}
            count={notificationsData.yesterdayCount}
          />
          <List>
            {renderNotifications({
              notifications: notificationsData.yesterday,
              limit: 2
            })}
          </List>
        </Box>

        {viewAll && (
          <>
            <Divider />
            <Box sx={{ p: 1.5 }}>
              <NotificationHeader
                title={'This Week'}
                count={notificationsData.weekCount}
              />
              <List>
                {renderNotifications({
                  notifications: notificationsData.week,
                  limit: 2
                })}
              </List>
            </Box>
            <Divider />
            <Box sx={{ p: 1.5 }}>
              <NotificationHeader
                title={'This Month'}
                count={notificationsData.monthCount}
              />
              <List>
                {renderNotifications({
                  notifications: notificationsData.month,
                  limit: 2
                })}
              </List>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>
        <CustomTextLink
          fontSize="14px"
          fontWeight="700"
          onClick={() => setViewAll(!viewAll)}
          title={viewAll ? 'View Less' : 'View All'}
        />
      </Box>
    </Box>
  );
};

export default NotificationList;
