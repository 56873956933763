import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Skeleton,
  SvgIcon,
  Switch,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import CustomBreadcrumb from '../../components/Breadcrumb';
import { useEffect, useState } from 'react';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUpdateRole,
  getMenuData,
  getPageData,
  getRoleDataById,
  getRoleGroups,
  revertRoleFormData
} from '../../redux/reducers/roleSlice';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import SnackBarBox from '../../components/SnackBarBox';
import { ChevronDownIcon, ChevronRightIcon } from '../../utils/iconSvg';

const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: colorPrimary,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const AddEditRoleAccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [parentRoleData, setParentRoleData] = useState([]);
  const [accessData, setAccessData] = useState([]);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [showError, setShowError] = useState(null);

  const handleExpand = index => {
    const currentIndexes = [...expandedIndexes];
    const indexPosition = currentIndexes.indexOf(index);

    if (indexPosition === -1) {
      currentIndexes.push(index);
    } else {
      currentIndexes.splice(indexPosition, 1);
    }

    setExpandedIndexes(currentIndexes);
  };

  const isExpanded = index => {
    return expandedIndexes.includes(index);
  };

  const { authData } = useSelector(state => state.auth);
  const {
    isRoleGroupsLoading,
    roleGroupsData,
    // isRoleGroupsSuccess,
    // isRoleGroupsFailed,
    isPageDataLoading,
    // isPageDataSuccess,
    // isPageDataFailed,
    pageData,
    // pageDataErrorContainer,
    isMenuDataLoading,
    // isMenuDataSuccess,
    // isMenuDataFailed,
    menuData,
    // menuDataErrorContainer,
    // isRoleByIdLoading,
    isRoleByIdSuccess,
    // isRoleByIdFailed,
    roleByIdData,
    // roleByIdDataErrorContainer,
    isCreateUpdateRoleLoading,
    isCreateUpdateRoleFailed,
    isCreateUpdateRoleSuccess,
    isCreateUpdateRoleResponse,
    createUpdateRoleErrorContainer
  } = useSelector(state => state.roles);

  const formik = useFormik({
    initialValues: {
      roleName: '',
      levelId: null,
      parentRoleId: null
    },
    validationSchema: yup.object().shape({
      roleName: yup.string().required('Role name is required'),
      levelId: yup
        .object()
        .shape({
          id: yup.string().required(),
          label: yup.string()
        })
        .required('Level is required'),
      parentRoleId: yup
        .object()
        .shape({
          id: yup.string().required(),
          label: yup.string()
        })
        .required('Parent role is required')
    }),
    onSubmit: values => {
      let accessPageData = accessData
        .filter(item => item.isChecked)
        .flatMap(item => item.pageData)
        .filter(page => page.isChecked)
        .map(page => page.pageId);

      setLoading(true);
      let submitPayload = {
        roleName: values.roleName,
        roleGroupId: values.parentRoleId.id,
        ids: accessPageData,
        loggedInUserName: authData?.userName
      };

      dispatch(
        createUpdateRole({
          payload: submitPayload,
          roleId: location.state?.roleId || null
        })
      );
    }
  });

  useEffect(() => {
    document.title = createTitle(
      `${location.pathname === '/roles/edit-role' ? 'Edit' : 'Add'} Role`
    );

    dispatch(getRoleGroups());
    dispatch(getPageData());
    dispatch(getMenuData());
    if (location.pathname === '/roles/edit-role' && location.state) {
      dispatch(getRoleDataById(location.state?.roleId));
    }

    return () => dispatch(revertRoleFormData());
  }, []);

  function getOptionLabel(option) {
    if (!option) {
      return '';
    }

    if (option.label !== null) {
      return option.label;
    }

    if (option.id !== null) {
      return option.id;
    }

    return '';
  }

  useEffect(() => {
    setTimeout(() => {
      if (
        (!isMenuDataLoading && menuData) ||
        (!isPageDataLoading && pageData)
      ) {
        if (isArrayNotEmpty(menuData) && isArrayNotEmpty(pageData)) {
          let tempAccessData = [];

          for (let index = 0; index < menuData.length; index++) {
            const element = menuData[index];
            const tempPageData = pageData.filter(
              page => page.moduleId === element.moduleId
            );

            let childAccess = tempPageData.map(cc => {
              const isChecked = roleByIdData
                ? roleByIdData.pageIds?.includes(cc.pageId)
                : false;
              return { ...cc, isChecked };
            });

            let isChecked = childAccess.some(
              item =>
                item.moduleId === element.moduleId && item.isChecked === true
            );

            tempAccessData.push({
              id: element.moduleId,
              label: element.moduleName,
              hasChildren: element.hasChildren,
              isChecked: isChecked,
              pageData: tempPageData.map(cc => {
                const isChecked = roleByIdData
                  ? roleByIdData.pageIds?.includes(cc.pageId)
                  : false;
                return { ...cc, isChecked: isChecked };
              })
            });
          }

          if (roleByIdData) {
            let tempLevelId = getLabelById(
              roleByIdData.roleType,
              roleGroupsData
            );

            let roleGroupsDataIndx = roleGroupsData.findIndex(
              cc => cc.id === tempLevelId
            );

            let tmpParentRoleLabel = roleGroupsData[
              roleGroupsDataIndx
            ]?.others.find(xx => +xx.id === roleByIdData.roleType);

            formik.setValues({
              levelId: { id: tempLevelId, label: tempLevelId },
              roleName: roleByIdData.roleName,
              parentRoleId: {
                id: roleByIdData.roleType,
                label: tmpParentRoleLabel?.value
              }
            });

            const updatedExpandedIndexes = [];
            [...tempAccessData]
              .filter(cc => cc.hasChildren)
              .forEach(group => {
                if (group.pageData.some(item => item.isChecked)) {
                  updatedExpandedIndexes.push(group.id);
                }
              });

            setExpandedIndexes(updatedExpandedIndexes);
            setParentRoleData(roleGroupsData[roleGroupsDataIndx]?.others);
          }
          setAccessData([...tempAccessData]);
          setLoading(false);
        }
      }
    }, 1500);
  }, [
    isMenuDataLoading,
    isPageDataLoading,
    isRoleGroupsLoading,
    isRoleByIdSuccess,
    roleGroupsData
  ]);

  const handleGroupSwitchChange = groupId => {
    const updatedAccessData = accessData.map(group => {
      if (group.id === groupId) {
        const newSwitchState = !group.isChecked;
        return {
          ...group,
          isChecked: newSwitchState,
          pageData: group.pageData.map(item => ({
            ...item,
            isChecked: newSwitchState
          }))
        };
      }
      return group;
    });
    setAccessData(updatedAccessData);
  };

  const handleChildCheckboxChange = (groupId, itemId) => {
    const updatedAccessData = accessData.map(group => {
      if (group.id === groupId) {
        const updatedItems = group.pageData.map(item =>
          item.pageId === itemId
            ? { ...item, isChecked: !item.isChecked }
            : item
        );
        const anyChecked = updatedItems.some(item => item.isChecked);

        return {
          ...group,
          isChecked: anyChecked,
          pageData: updatedItems
        };
      }
      return group;
    });
    setAccessData(updatedAccessData);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (roleByIdData && roleGroupsData) {
  //       let tempLevelId = getLabelById(roleByIdData.roleType, roleGroupsData);

  //       let roleGroupsDataIndx = roleGroupsData.findIndex(
  //         cc => cc.id === tempLevelId
  //       );

  //       let tmpParentRoleLabel = roleGroupsData[
  //         roleGroupsDataIndx
  //       ]?.others.find(xx => +xx.id === roleByIdData.roleType);

  //       // let tempParentRole = roleGroupsData.find(cc => +cc.id === tempLevelId);
  //       // console.log(tempParentRole, 'tempParentRole');

  //       formik.setValues({
  //         levelId: { id: tempLevelId, label: tempLevelId },
  //         roleName: roleByIdData.roleName,
  //         parentRoleId: {
  //           id: roleByIdData.roleType,
  //           label: tmpParentRoleLabel?.value
  //         }
  //       });

  //       // if (
  //       //   (!isMenuDataLoading && menuData) ||
  //       //   (!isPageDataLoading && pageData)
  //       // ) {
  //       //   if (isArrayNotEmpty(menuData) && isArrayNotEmpty(pageData)) {
  //       //     let tempAccessData = [];
  //       //     for (let index = 0; index < menuData.length; index++) {
  //       //       const element = menuData[index];
  //       //       const tempPageData = pageData.filter(
  //       //         page => page.moduleId === element.moduleId
  //       //       );

  //       //       let childAccess = tempPageData.map(cc => {
  //       //         const isChecked = roleByIdData.pageIds?.includes(cc.pageId);
  //       //         return { ...cc, isChecked };
  //       //       });

  //       //       let isChecked = childAccess.some(
  //       //         item =>
  //       //           item.moduleId === element.moduleId && item.isChecked === true
  //       //       );

  //       //       tempAccessData.push({
  //       //         id: element.moduleId,
  //       //         label: element.moduleName,
  //       //         isChecked: isChecked,
  //       //         pageData: tempPageData.map(cc => {
  //       //           const isChecked = roleByIdData.pageIds?.includes(cc.pageId);
  //       //           return { ...cc, isChecked };
  //       //         })
  //       //       });
  //       //     }

  //       //     const updatedExpandedIndexes = [];
  //       //     [...tempAccessData].forEach(group => {
  //       //       if (group.pageData.some(item => item.isChecked)) {
  //       //         updatedExpandedIndexes.push(group.id);
  //       //       }
  //       //     });

  //       //     setExpandedIndexes(updatedExpandedIndexes);
  //       //     setParentRoleData(roleGroupsData[roleGroupsDataIndx]?.others);
  //       //     setAccessData([...tempAccessData]);
  //       //   }
  //       // }

  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   }, 1200);
  // }, [isRoleByIdSuccess, roleGroupsData]);

  useEffect(() => {
    if (isCreateUpdateRoleSuccess) {
      setTimeout(() => {
        navigate('/roles');
        dispatch(revertRoleFormData());
      }, 1800);
    }

    if (isCreateUpdateRoleFailed) {
      setLoading(false);
    }
  }, [isCreateUpdateRoleSuccess, isCreateUpdateRoleFailed]);

  function getLabelById(id, data) {
    if (roleGroupsData.length > 0) {
      for (let item of data) {
        if ('others' in item) {
          for (let other of item.others) {
            if (+other.id === +id) {
              return item.label;
            }
          }
        }
      }
      return null;
    }
  }

  const isCheckedPageDataExist = somdata => somdata && isArrayNotEmpty(somdata);

  const areAllRequiredFieldsFilled = () => {
    return (
      formik.values.roleName &&
      formik.values.parentRoleId &&
      formik.values.levelId
    );
  };

  const isSaveDisabled = !formik.isValid || !areAllRequiredFieldsFilled();

  return (
    <>
      <Box>
        {/* <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
          {location.pathname === '/roles/edit-role' ? 'Edit' : 'New'} Role
        </Typography> */}
        <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={colorTextGrey} />
              </SvgIcon>
            }
            sx={{ marginBottom: 2 }}
          >
            <Link
              color="inherit"
              href={'role'}
              onClick={event => {
                event.preventDefault();
                navigate('/roles');
              }}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                fontWeight={'700'}
                color={colorLinkGrey}
                fontSize={'24px'}
              >
                Roles
              </Typography>
            </Link>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
                color: colorTextBlack
              }}
            >
              {`${location.pathname === '/roles/edit-role' ? 'Edit' : 'Add new'} role`}
            </Typography>
          </Breadcrumbs>
        </Typography>

        {loading && (
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={400}
            animation="wave"
          />
        )}
        {!loading && (
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Box
              bgcolor={'white'}
              padding={2}
              borderRadius={2}
              marginBottom={2}
            >
              {' '}
              {location.pathname !== '/roles/edit-role' && (
                <Typography mb={1} fontSize="20px" fontWeight={'700'}>
                  Create new role
                </Typography>
              )}
              <Grid container spacing={3}>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Add role name
                  </InputLabel>
                  <TextField
                    name="roleName"
                    fullWidth
                    size="small"
                    inputProps={{ maxLength: 64 }}
                    value={formik.values.roleName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.roleName && Boolean(formik.errors.roleName)
                    }
                    InputLabelProps={{
                      shrink: formik.values.roleName !== ''
                    }}
                    helperText={
                      formik.touched.roleName && formik.errors.roleName
                    }
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Select level
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Autocomplete
                      size="small"
                      value={formik.values.levelId}
                      onChange={(event, newValue) => {
                        let tempParentRole = roleGroupsData.find(
                          cc => cc.id === newValue?.id
                        );

                        if (isArrayNotEmpty(tempParentRole?.others)) {
                          setParentRoleData(tempParentRole.others);
                        } else {
                          setParentRoleData([]);
                        }

                        formik.setFieldValue(
                          'levelId',
                          newValue ? newValue : null
                        );
                        formik.setFieldValue('parentRoleId', null);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === (value ? value.id : null)
                      }
                      getOptionLabel={getOptionLabel}
                      options={
                        isArrayNotEmpty(roleGroupsData)
                          ? roleGroupsData.map(c => {
                              return {
                                label: c.label,
                                id: c.id
                              };
                            })
                          : []
                      }
                      sx={{
                        width: '100%',
                        '& .MuiAutocomplete-endAdornment': {
                          right: 5
                        }
                      }}
                      clearIcon={false}
                      popupIcon={<ChevronDownIcon />}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            error={
                              formik.touched.levelId && formik.errors.levelId
                            }
                            helperText={
                              formik.touched.levelId && formik.errors.levelId
                            }
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.5 14L12.1667 11.6667M13.8333 7.66667C13.8333 10.7963 11.2963 13.3333 8.16667 13.3333C5.03705 13.3333 2.5 10.7963 2.5 7.66667C2.5 4.53705 5.03705 2 8.16667 2C11.2963 2 13.8333 4.53705 13.8333 7.66667Z"
                                      stroke={colorTextGrey}
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>

                                  {params.InputProps.startAdornment}
                                </>
                              )
                            }}
                          />
                        );
                      }}
                      ListboxProps={{
                        sx: {
                          '& .MuiAutocomplete-option:hover': {
                            backgroundColor: colorSecondary
                          }
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Select parent role
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Autocomplete
                      size="small"
                      value={formik.values.parentRoleId}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          'parentRoleId',
                          newValue ? newValue : null
                        );
                      }}
                      clearIcon={false}
                      isOptionEqualToValue={(option, value) =>
                        option.id === (value ? value.id : null)
                      }
                      getOptionLabel={getOptionLabel}
                      options={
                        isArrayNotEmpty([...parentRoleData])
                          ? [...parentRoleData].map(c => {
                              return {
                                label: c.value,
                                id: c.id
                              };
                            })
                          : []
                      }
                      sx={{ width: '100%' }}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            error={
                              formik.touched.parentRoleId &&
                              formik.errors.parentRoleId
                            }
                            helperText={
                              formik.touched.parentRoleId &&
                              formik.errors.parentRoleId
                            }
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.5 14L12.1667 11.6667M13.8333 7.66667C13.8333 10.7963 11.2963 13.3333 8.16667 13.3333C5.03705 13.3333 2.5 10.7963 2.5 7.66667C2.5 4.53705 5.03705 2 8.16667 2C11.2963 2 13.8333 4.53705 13.8333 7.66667Z"
                                      stroke={colorTextGrey}
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>

                                  {params.InputProps.startAdornment}
                                </>
                              )
                            }}
                          />
                        );
                      }}
                      ListboxProps={{
                        sx: {
                          '& .MuiAutocomplete-option:hover': {
                            backgroundColor: colorSecondary
                          }
                        }
                      }}
                      popupIcon={<ChevronDownIcon />}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            {accessData.length > 0 &&
              accessData.map((item, idx) => {
                return (
                  <Box key={idx} borderRadius={5} marginBottom={1}>
                    <Accordion
                      bgcolor={'white'}
                      expanded={isExpanded(item.id)}
                      onChange={() => {
                        if (
                          isCheckedPageDataExist(item.pageData) &&
                          item.hasChildren
                        ) {
                          handleExpand(item.id);
                        }
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        aria-controls={`panel-${idx}-content`}
                        id={`panel-${idx}-header`}
                        sx={{ height: 58, borderRadius: '8px' }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            margin: !item.hasChildren && '7px 0px'
                          }}
                        >
                          <Typography variant="body1" fontWeight={'700'}>
                            {item.label}
                          </Typography>
                          <Box sx={{}}>
                            <FormControlLabel
                              sx={{ marginRight: !item.hasChildren && '40px' }}
                              disabled={
                                (item.label &&
                                  item.label.toLowerCase() === 'dashboard') ||
                                !isCheckedPageDataExist(item.pageData) ||
                                false
                              }
                              control={
                                <IOSSwitch
                                  checked={
                                    item.isChecked ||
                                    (item.label &&
                                      item.label.toLowerCase() === 'dashboard')
                                  }
                                  onChange={() => {
                                    handleGroupSwitchChange(item.id);
                                  }}
                                  onClick={e => e.stopPropagation()}
                                />
                              }
                            />
                            {item.hasChildren && (
                              <IconButton
                                disabled={
                                  !(
                                    item.pageData &&
                                    isArrayNotEmpty(item.pageData)
                                  )
                                }
                                onClick={e => {
                                  e.stopPropagation();
                                  handleExpand(item.id);
                                }}
                                aria-expanded={isExpanded(item.id)}
                                aria-label="expand accordion"
                                sx={{
                                  transition: 'transform 0.3s ease-in-out', // Apply the transition for the rotation
                                  transform: isExpanded(item.id)
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)' // Rotate the icon based on expansion state
                                }}
                              >
                                <ExpandMore />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </AccordionSummary>
                      {item.hasChildren && (
                        <>
                          <Divider
                            variant="fullWidth"
                            orientation="horizontal"
                          />
                          <AccordionDetails>
                            {isArrayNotEmpty(item.pageData) &&
                              item.pageData.map((dd, idx) => {
                                return (
                                  <Box
                                    key={idx}
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between'
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {dd.pageName}
                                    </Typography>

                                    <Checkbox
                                      checked={dd.isChecked}
                                      onChange={() =>
                                        handleChildCheckboxChange(
                                          item.id,
                                          dd.pageId
                                        )
                                      }
                                    />
                                  </Box>
                                );
                              })}
                          </AccordionDetails>
                        </>
                      )}
                    </Accordion>
                  </Box>
                );
              })}
          </form>
        )}
      </Box>
      <Box bgcolor={'white'} padding={2} borderRadius={2} marginTop={2}>
        <Grid display={'flex'} gap={2}>
          <Button
            // variant="outlined"
            onClick={() => {
              navigate('/roles');
            }}
            variant="outlined"
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: colorTextBlack,
              borderWidth: '2px',
              height: '42px',
              width: '106px',
              '&:hover': {
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px'
              }
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            disabled={isSaveDisabled}
            onClick={() => formik.handleSubmit()}
            variant="contained"
            sx={{
              width: '94px',
              height: '42px',
              boxShadow: 'none'
            }}
            // style={{ marginRight: 24 }}
          >
            Save
          </LoadingButton>
        </Grid>
      </Box>

      {showError && <SnackBarBox type={'error'} message={showError} />}

      {!isCreateUpdateRoleLoading && isCreateUpdateRoleFailed && (
        <SnackBarBox
          type="error"
          message={handleError(createUpdateRoleErrorContainer?.errorMessage)}
        />
      )}

      {!isCreateUpdateRoleLoading && isCreateUpdateRoleSuccess && (
        <SnackBarBox
          type="success"
          message={
            isCreateUpdateRoleResponse || 'Action performed successfully'
          }
        />
      )}
    </>
  );
};

export default AddEditRoleAccess;
