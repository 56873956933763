import {
  Alert,
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Rating,
  Select,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  AssignFormIcon,
  AttachmentIcon,
  FileIcon,
  PreviewFormIcon,
  add_circle_icon,
  player_icon,
  sceneroy_icon,
  section_icon,
  text_icon
} from '../../utils/imageUrls';
import {
  FLOWS_FORMS_QUESTION_TYPE_SELECTOR,
  formsFileType,
  formsFileTypeIcon,
  iconMapFlows,
  uploadFileType
} from '../../utils/constant';
import { BootstrapDialog, IOSSwitch } from '../../utils/styledLayout';
import {
  Add,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  ContentCopy,
  Delete,
  Download,
  DragIndicator,
  East,
  Edit,
  SaveOutlined
} from '@mui/icons-material';
import {
  colorBorderGrey,
  colorFieldError,
  colorLightYellow,
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
  base64ToFile,
  base64ToTxtCSV,
  capitalizeFirstChar,
  generateImageSrc,
  getFileTypeFromBase64,
  getMimeTypeFromBase64,
  handleError,
  isArrayNotEmpty,
  mapArrayToTypography
} from '../../utils/utils';
import _, { at } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SnackBarBox from '../../components/SnackBarBox';
import AttachmentUploadComponent from './components/AttachmentUploadComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../components/Breadcrumb';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  createUpdateForm,
  fetchFormResponseCountById,
  previewFormById,
  revertFormsFormData,
  revertUserAndUserGroupAssignment
} from '../../redux/reducers/flowsSlice';
import { LoadingButton } from '@mui/lab';
import { getAllIndicators } from '../../redux/reducers/rubricSlice';
import FormResponses from './components/FormResponses';
import UserAndUserGroupAssingmentModal from './components/UserAndUserGroupAssingmentModal';
import {
  AddIcon,
  AlarmClockIcon,
  ChevronRightIcon,
  Copy05Icon,
  Edit05Icon,
  EditIcon,
  File02Icon,
  FileCheck02Icon,
  PlusIcon,
  TrashIcon
} from '../../utils/iconSvg';
import CustomTabLabel from '../../custom/CustomTabLabel';
import { CustomTextLink } from '../login/LoginForm';

export const LimitOptions = () => {
  let arr = [];
  for (let index = 1; index <= 10; index++) {
    arr.push({ value: index, label: `${index}` });
  }
  return arr;
};
const AddEditForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isNewForm = location.pathname == '/flows/flows-details/create-new-form';
  const [initialValues, setInitialValues] = useState({
    type: 0,
    size: 10,
    page: 0
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showError, setShowError] = useState('');
  const [addIndicators, setAddIndicators] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showFormAssigment, setShowFormAssigment] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [base64Content, setBase64Content] = useState('');
  const [formLayout, setFormLayout] = useState({
    flowId: location.state && location.state?.flowId,
    isDraft: 0,
    isDirty: false,
    formName: '',
    formText: '',
    formDescription: '',
    isActive: 1,
    questionImage1Desc: '',
    questionImage1: '',
    questionImage2Desc: '',
    questionImage2: '',
    questionImage3Desc: '',
    questionImage3: '',
    questionImage4Desc: '',
    questionImage4: '',
    questionImage5Desc: '',
    questionImage5: '',
    questionVideo1Desc: '',
    questionVideo1: '',
    questionVideo2Desc: '',
    questionVideo2: '',
    questionVideo3Desc: '',
    questionVideo3: '',
    questionVideo4Desc: '',
    questionVideo4: '',
    questionVideo5Desc: '',
    questionVideo5: '',
    questionFile1Desc: '',
    questionFile1: '',
    questionFile2Desc: '',
    questionFile2: '',
    questionFile3Desc: '',
    questionFile3: '',
    questionFile4Desc: '',
    questionFile4: '',
    questionFile5Desc: '',
    questionFile5: '',
    questionLink1: '',
    questionLink1Desc: '',
    questionLink2: '',
    questionLink2Desc: '',
    questionLink3: '',
    questionLink3Desc: '',
    questionLink4: '',
    questionLink4Desc: '',
    questionLink5: '',
    questionLink5Desc: '',
    loggedInUserName: '',
    sectionRequestList: []
  });
  const [countQuestionResponses, setCountQuestionResponses] = useState({
    question: 0,
    responses: 0
  });
  const [selectedSection, setSelectedSection] = useState(-1);
  const [showAttachmentModal, setShowAttachmentModal] = useState('');
  const [listOfAttachments, setListOfAttachments] = useState([]);
  const [selectedIndicators, setSelectedIndicators] = useState([]);
  const [formValidation, setFormValidation] = useState([]);
  const { authData } = useSelector(state => state.auth);
  const { indicatorsList } = useSelector(state => state.rubric);
  const {
    isPreviewFormLoading,
    isPreviewFormSuccess,
    isPreviewFormFailed,
    previewForm,
    previewFormErrorContainer,
    isCreateUpdateFormsSuccess,
    isCreateUpdateFormsLoading,
    isCreateUpdateFormsFail,
    createUpdateFormsErrorContainer,
    createUpdateFormsResponse,
    isAssignFormLoading,
    assignFormResponse,
    isAssignFormSuccess,
    scheduleReminderUserFormLoading,
    scheduleReminderUserFormSuccess,
    sendScheduleReminderDateFormLoading,
    sendScheduleReminderDateFormSuccess,
    scheduleReminderUserGroupFormLoading,
    scheduleReminderUserGroupFormSuccess
  } = useSelector(state => state.flow);

  useEffect(() => {
    dispatch(getAllIndicators());

    if (location.state && location.state.formId) {
      dispatch(previewFormById(location.state.formId));
      const fetchData = async () => {
        const data = await fetchFormResponseCountById({
          formId: location.state?.formId,
          flowId: location.state?.flowId
        });
        // console.log(data, 'data');

        if (data) {
          setCountQuestionResponses({
            ...countQuestionResponses,
            responses: data
          });
        }
      };

      fetchData();
    } else setLoading(false);

    return () => {
      // second;
      dispatch(revertFormsFormData());
    };
  }, []);

  const previewFormHandlr = () => {
    if (formLayout.isDirty) {
      setShowError('Form values changed, please save the form');
      return true;
    }

    navigate('/flows/flows-details/preview', {
      state: {
        formLayout: { ...formLayout, flowName: location.state?.flowName },
        listOfAttachments: listOfAttachments
      }
    });
  };
  useEffect(() => {
    if (isPreviewFormSuccess && previewForm) {
      let tempSectionList = [];
      let tempListOfAttachment = [];
      let previewAttachmentObject = {};

      for (let i = 1; i <= 5; i++) {
        const questionImageKey = `questionImage${i}`;
        const questionVideoKey = `questionVideo${i}`;
        const questionFileKey = `questionFile${i}`;
        const questionLinkKey = `questionLink${i}`;

        if (previewForm?.[questionImageKey]) {
          // previewAttachmentObject[questionImageKey] =
          //   previewForm?.[questionImageKey];
          previewAttachmentObject[questionImageKey] = base64ToFile(
            previewForm?.[questionImageKey],
            `${questionImageKey}Desc`
          );
        }

        if (previewForm?.[questionVideoKey]) {
          previewAttachmentObject[questionVideoKey] = base64ToFile(
            previewForm?.[questionVideoKey],
            `${questionVideoKey}Desc`
          );
        }

        if (previewForm?.[questionFileKey]) {
          previewAttachmentObject[questionFileKey] = base64ToTxtCSV(
            previewForm?.[questionFileKey],
            `${questionFileKey}Desc`
          );
        }

        if (previewForm?.[questionLinkKey]) {
          previewAttachmentObject[questionLinkKey] =
            previewForm?.[`${questionLinkKey}`];
        }
      }

      for (const [key, value] of Object.entries(previewAttachmentObject)) {
        const item = {
          title: previewForm[`${key}Desc`],
          // link: key.includes('questionLink1') ? value : null,
          link: _.includes(
            [
              'questionLink1',
              'questionLink2',
              'questionLink3',
              'questionLink4',
              'questionLink5'
            ],
            key
          )
            ? value
            : null,
          attachment: !_.includes(
            [
              'questionLink1',
              'questionLink2',
              'questionLink3',
              'questionLink4',
              'questionLink5'
            ],
            key
          )
            ? value
            : null,
          thumbnail: null,
          type: !_.includes(
            [
              'questionLink1',
              'questionLink2',
              'questionLink3',
              'questionLink4',
              'questionLink5'
            ],
            key
          )
            ? formsFileType[value.type]
            : 'link'
        };
        tempListOfAttachment.push(item);
      }

      if (isArrayNotEmpty(previewForm.sections)) {
        previewForm.sections.map((section, secIdx) => {
          let tempQuestionList = [];
          if (isArrayNotEmpty(section.questions)) {
            section.questions.map((questionList, questIdx) => {
              let linearOptions = {
                lowerLimit: questionList.lowerLimit,
                lowerLimitLabel: questionList.lowerLimitLabel,
                upperLimitLabel: questionList.upperLimitLabel,
                upperLimit: questionList.upperLimit
              };
              return tempQuestionList.push({
                questionText: questionList.questionText,
                questionDescription: questionList.questionDescription,
                questionOptionId: questionList.questionOptionId,
                isRequired: questionList.isRequired ? 1 : 0,
                optionTexts: questionList.questionOptions
                  ? isArrayNotEmpty(questionList.questionOptions)
                    ? questionList.questionOptions.map(cc => cc.optionText)
                    : []
                  : [],
                indicatorIds: questionList.indicators
                  ? isArrayNotEmpty(questionList.indicators)
                    ? questionList.indicators.map(cc => cc.indicatorId)
                    : []
                  : [],
                ...linearOptions
              });
            });
          }

          tempSectionList.push({
            sectionName: section.sectionName,
            sectionDescription: section.sectionDescription,
            sectionOrder: section.sectionOrder,
            questionRequestList: tempQuestionList
          });
        });
        setLoading(false);
        setSelectedSection(0);
      }

      setFormLayout({
        ...formLayout,
        flowId: location.state && location.state?.flowId,
        isDraft: previewForm.isDraft ? 1 : 0,
        formName: previewForm.formName,
        formText: previewForm.formText,
        formDescription: previewForm.formDescription,
        isActive: previewForm.status ? 1 : 0,
        questionImage1Desc: previewForm.questionImage1Desc,
        questionImage1: previewForm.questionImage1,
        questionImage2Desc: previewForm.questionImage2Desc,
        questionImage2: previewForm.questionImage2,
        questionImage3Desc: previewForm.questionImage3Desc,
        questionImage3: previewForm.questionImage3,
        questionImage4Desc: previewForm.questionImage4Desc,
        questionImage4: previewForm.questionImage4,
        questionImage5Desc: previewForm.questionImage5Desc,
        questionImage5: previewForm.questionImage5,
        questionVideo1Desc: previewForm.questionVideo1Desc,
        questionVideo1: previewForm.questionVideo1,
        questionVideo2Desc: previewForm.questionVideo2Desc,
        questionVideo2: previewForm.questionVideo2,
        questionVideo3Desc: previewForm.questionVideo3Desc,
        questionVideo3: previewForm.questionVideo3,
        questionVideo4Desc: previewForm.questionVideo4Desc,
        questionVideo4: previewForm.questionVideo4,
        questionVideo5Desc: previewForm.questionVideo5Desc,
        questionVideo5: previewForm.questionVideo5,
        questionFile1Desc: previewForm.questionFile1Desc,
        questionFile1: previewForm.questionFile1,
        questionFile2Desc: previewForm.questionFile2Desc,
        questionFile2: previewForm.questionFile2,
        questionFile3Desc: previewForm.questionFile3Desc,
        questionFile3: previewForm.questionFile3,
        questionFile4Desc: previewForm.questionFile4Desc,
        questionFile4: previewForm.questionFile4,
        questionFile5Desc: previewForm.questionFile5Desc,
        questionFile5: previewForm.questionFile5,
        questionLink1: previewForm.questionLink1,
        questionLink1Desc: previewForm.questionLink1Desc,
        questionLink2: previewForm.questionLink2,
        questionLink2Desc: previewForm.questionLink2Desc,
        questionLink3: previewForm.questionLink3,
        questionLink3Desc: previewForm.questionLink3Desc,
        questionLink4: previewForm.questionLink4,
        questionLink4Desc: previewForm.questionLink4Desc,
        questionLink5: previewForm.questionLink5,
        questionLink5Desc: previewForm.questionLink5Desc,
        sectionRequestList: tempSectionList
      });
      setListOfAttachments([...tempListOfAttachment]);
    }
  }, [isPreviewFormSuccess]);

  useEffect(() => {
    if (!isCreateUpdateFormsLoading && isCreateUpdateFormsSuccess) {
      setTimeout(() => {
        navigate('/flows/flows-details', { state: location.state });
      }, 2500);
    }

    if (!isCreateUpdateFormsLoading && isCreateUpdateFormsFail) {
      setLoading(false);
    }
    // return () => {
    //   second;
    // };
  }, [isCreateUpdateFormsLoading]);

  const [questionCount, setQuestionCount] = useState(0);

  useEffect(() => {
    if (isArrayNotEmpty(formLayout.sectionRequestList)) {
      const fetchData = async () => {
        const questionCounts = await Promise.all(
          formLayout.sectionRequestList.map(section => {
            return section.questionRequestList.length;
          })
        );
        let count = questionCounts.reduce((sum, count) => sum + count, 0);

        setQuestionCount(count);
      };

      fetchData();
    }
  }, [formLayout]);

  function getOptionLabel(option) {
    if (!option) {
      return '';
    }

    if (option.label !== null) {
      return option.label;
    }

    if (option.id !== null) {
      return option.id;
    }

    return '';
  }

  const handleSectionClick = sectionKey => {
    setSelectedSection(sectionKey);
  };

  const onAddQuestionClick = id => {
    let cc = [...formLayout.sectionRequestList];
    let sectionIndex = cc.findIndex((cc, idx) => idx === selectedSection);
    if (sectionIndex !== -1) {
      let questionObj = {
        questionOptionId: -999,
        questionText: '',
        questionDescription: '',
        isRequired: 0
      };
      cc[sectionIndex].questionRequestList.push(questionObj);
    } else {
      cc.push({
        sectionName: '',
        sectionOrder: cc.length + 1,
        sectionDescription: '',
        questionRequestList: [
          {
            questionOptionId: -999,
            questionText: '',
            questionDescription: '',
            isRequired: 0
          }
        ]
      });
      setSelectedSection(cc.length === 1 ? 0 : cc.length);
    }

    setFormLayout({
      ...formLayout,
      sectionRequestList: [...cc],
      isDirty: true
    });
  };

  const onAddSectionClick = () => {
    let sectionObj = {
      sectionName: '',
      sectionOrder: formLayout.sectionRequestList.length + 1,
      sectionDescription: '',
      questionRequestList: [
        {
          questionOptionId: -999,
          questionText: '',
          questionDescription: '',
          isRequired: 0
        }
      ]
    };

    setFormLayout({
      ...formLayout,
      sectionRequestList: [...formLayout.sectionRequestList, sectionObj],
      isDirty: true
    });
    setSelectedSection(formLayout.sectionRequestList.length);
  };

  const handleLayoutDataChange = (
    sectionKey,
    questionKey,
    changeFor,
    valueChange
  ) => {
    let editingSection = formLayout.sectionRequestList;
    if (questionKey !== null) {
      let questionToUpdate =
        editingSection[sectionKey].questionRequestList[questionKey];
      questionToUpdate[changeFor] = valueChange;
      editingSection[sectionKey].questionRequestList[questionKey] =
        questionToUpdate;
    } else {
      editingSection[sectionKey][changeFor] = valueChange;
    }
    setFormLayout({
      ...formLayout,
      sectionRequestList: [...editingSection],
      isDirty: true
    });
  };

  const onCopyQuestionClick = (sectionKey, questionKey) => {
    const editingSection = [...formLayout.sectionRequestList];
    const copiedQuestion = {
      ...editingSection[sectionKey].questionRequestList[questionKey]
    };
    editingSection[sectionKey].questionRequestList.push(copiedQuestion);
    setFormLayout({
      ...formLayout,
      sectionRequestList: [...editingSection],
      isDirty: true
    });
  };

  const onDeleteQuestionClick = (sectionKey, questionKey) => {
    const editingSection = [...formLayout.sectionRequestList];
    editingSection[sectionKey].questionRequestList.splice(questionKey, 1);
    setFormLayout({
      ...formLayout,
      sectionRequestList: [...editingSection],
      isDirty: true
    });
  };

  const onOptionAdd = (sectionKey, questionKey, optionText) => {
    const editingSection = [...formLayout.sectionRequestList];

    const questionRequestList =
      editingSection[sectionKey].questionRequestList[questionKey];

    if (!('optionTexts' in questionRequestList)) {
      questionRequestList.optionTexts = [];
    }

    questionRequestList.optionTexts.push(optionText);
    setFormLayout({ ...formLayout, sectionRequestList: [...editingSection] });
    handleLayoutDataChange(sectionKey, questionKey, 'tempOption', '');
  };

  const onDragEnd = (result, sectionKey, questionKey, questionObj) => {
    if (!result.destination) {
      return;
    }

    const newOptions = Array.from(questionObj.optionTexts);
    const [removed] = newOptions.splice(result.source.index, 1);
    newOptions.splice(result.destination.index, 0, removed);
    // Update the state with the new order of options
    handleLayoutDataChange(sectionKey, questionKey, 'optionTexts', newOptions);
  };

  const onDeleteOption = (sectionKey, questionKey, optionIndex) => {
    const editingSection = [...formLayout.sectionRequestList];

    const questionRequestList =
      editingSection[sectionKey].questionRequestList[questionKey];

    if (questionRequestList && Array.isArray(questionRequestList.optionTexts)) {
      questionRequestList.optionTexts.splice(optionIndex, 1);

      setFormLayout({
        ...formLayout,
        sectionRequestList: [...editingSection],
        isDirty: true
      });
    }
  };

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError('');
      }, 800);
    }
  }, [showError]);

  const onSectionDragEnd = result => {
    if (!result.destination) return;

    const updatedSections = Array.from(formLayout.sectionRequestList);
    const [movedSection] = updatedSections.splice(result.source.index, 1);
    updatedSections.splice(result.destination.index, 0, movedSection);

    setFormLayout({
      ...formLayout,
      sectionRequestList: updatedSections.map((section, index) => ({
        ...section,
        sectionOrder: index + 1
      })),
      isDirty: true
    });

    if (selectedSection !== -1) {
      setSelectedSection(
        updatedSections.findIndex((_, idx) => idx === selectedSection)
      );
    }
  };

  const handleAttachments = values => {
    const countByType = listOfAttachments.reduce((acc, attachment) => {
      acc[attachment.type] = (acc[attachment.type] || 0) + 1;
      return acc;
    }, {});

    if (countByType[values.type] >= 5) {
      setShowError(`Cannot add more than 5 ${values.type}s.`);
      return;
    }

    const newAttachment = {
      title: values.title,
      link: values.link,
      attachment: values.attachment,
      thumbnail: values.thumbnail,
      type: values.type
    };

    const updatedFormLayout = { ...formLayout };
    const attachmentIndex = countByType[values.type]
      ? countByType[values.type] + 1
      : 1;

    switch (values.type) {
      case 'image':
        updatedFormLayout[`questionImage${attachmentIndex}Desc`] = values.title;
        updatedFormLayout[`questionImage${attachmentIndex}`] =
          values.attachment;
        break;
      case 'video':
        updatedFormLayout[`questionVideo${attachmentIndex}Desc`] = values.title;
        updatedFormLayout[`questionVideo${attachmentIndex}`] =
          values.attachment;
        break;
      case 'file':
        updatedFormLayout[`questionFile${attachmentIndex}Desc`] = values.title;
        updatedFormLayout[`questionFile${attachmentIndex}`] = values.attachment;
        break;
      case 'link':
        updatedFormLayout[`questionLink${attachmentIndex}Desc`] = values.title;
        updatedFormLayout[`questionLink${attachmentIndex}`] = values.link;
        break;
      default:
        return;
    }

    setFormLayout({ ...updatedFormLayout, isDirty: true });
    setListOfAttachments([...listOfAttachments, newAttachment]);
    setShowAttachmentModal('');
  };

  const removeAttachment = (index, attachmentId) => {
    const attachmentToRemove = listOfAttachments[index];

    const updatedList = listOfAttachments.filter((_, i) => i !== index);

    const updatedFormLayout = { ...formLayout };
    const type = capitalizeFirstChar(attachmentToRemove.type);
    let found = false;

    for (let i = 1; i <= 5; i++) {
      if (
        !found &&
        updatedFormLayout[`question${type}${i}Desc`] ===
          attachmentToRemove.title
      ) {
        updatedFormLayout[`question${type}${i}Desc`] = '';
        updatedFormLayout[`question${type}${i}`] = '';
        found = true;
      }

      if (found && i < 5) {
        updatedFormLayout[`question${type}${i}Desc`] =
          updatedFormLayout[`question${type}${i + 1}Desc`];
        updatedFormLayout[`question${type}${i}`] =
          updatedFormLayout[`question${type}${i + 1}`];
      }
    }

    // Clear the last entry if there was a shift
    if (found) {
      updatedFormLayout[`question${type}5Desc`] = '';
      updatedFormLayout[`question${type}5`] = '';
    }

    setListOfAttachments(updatedList);
    setFormLayout({ ...updatedFormLayout, isDirty: true });
  };

  // const handleSaveForm = () => {
  //   console.log(formLayout.sectionRequestList, 'SCCC');
  // };

  const handleSaveForm = () => {
    setLoading(true);
    const form = new FormData();
    const imageAttachments = listOfAttachments.filter(
      attachment => attachment.type === 'image'
    );
    const videoAttachments = listOfAttachments.filter(
      attachment => attachment.type === 'video'
    );
    const fileAttachments = listOfAttachments.filter(
      attachment => attachment.type === 'file'
    );

    for (let i = 1; i <= 5; i++) {
      if (i - 1 < imageAttachments.length) {
        form.append(`questionImage${i}`, imageAttachments[i - 1].attachment);
      } else {
        form.append(`questionImage${i}`, null);
      }

      if (i - 1 < videoAttachments.length) {
        form.append(`questionVideo${i}`, videoAttachments[i - 1].attachment);
      } else {
        form.append(`questionVideo${i}`, null);
      }

      if (i - 1 < fileAttachments.length) {
        form.append(`questionFile${i}`, fileAttachments[i - 1].attachment);
      } else {
        form.append(`questionFile${i}`, null);
      }
    }

    // for (let i = 1; i <= 5; i++) {
    //   const imageAttachment = listOfAttachments.find(
    //     (attachment, idx) => attachment.type === 'image' && idx + 1 === i
    //   );
    //   const videoAttachment = listOfAttachments.find(
    //     (attachment, idx) => attachment.type === 'video' && idx + 1 === i
    //   );
    //   const fileAttachment = listOfAttachments.find(
    //     (attachment, idx) => attachment.type === 'file' && idx + 1 === i
    //   );
    //   if (imageAttachment) {
    //     form.append(`questionImage${i}`, imageAttachment.attachment);
    //   } else {
    //     form.append(`questionImage${i}`, null);
    //   }

    //   if (videoAttachment) {
    //     form.append(`questionVideo${i}`, videoAttachment.attachment);
    //   } else form.append(`questionVideo${i}`, null);

    //   if (fileAttachment) {
    //     form.append(`questionFile${i}`, fileAttachment.attachment);
    //   } else form.append(`questionFile${i}`, null);
    // }

    let payload = {
      flowId: formLayout.flowId,
      isDraft: 0,
      formName: formLayout.formName,
      formText: formLayout.formName,
      formDescription: formLayout.formDescription,
      isActive: 1,
      loggedInUserName: authData.userName,
      questionImage1Desc: formLayout.questionImage1Desc,
      questionImage2Desc: formLayout.questionImage2Desc,
      questionImage3Desc: formLayout.questionImage3Desc,
      questionImage4Desc: formLayout.questionImage4Desc,
      questionImage5Desc: formLayout.questionImage5Desc,
      questionVideo1Desc: formLayout.questionVideo1Desc,
      questionVideo2Desc: formLayout.questionVideo2Desc,
      questionVideo3Desc: formLayout.questionVideo3Desc,
      questionVideo4Desc: formLayout.questionVideo4Desc,
      questionVideo5Desc: formLayout.questionVideo5Desc,
      questionFile1Desc: formLayout.questionFile1Desc,
      questionFile2Desc: formLayout.questionFile2Desc,
      questionFile3Desc: formLayout.questionFile3Desc,
      questionFile4Desc: formLayout.questionFile4Desc,
      questionFile5Desc: formLayout.questionFile5Desc,
      questionLink1: formLayout.questionLink1,
      questionLink1Desc: formLayout.questionLink1Desc,
      questionLink2: formLayout.questionLink2,
      questionLink2Desc: formLayout.questionLink2Desc,
      questionLink3: formLayout.questionLink3,
      questionLink3Desc: formLayout.questionLink3Desc,
      questionLink4: formLayout.questionLink4,
      questionLink4Desc: formLayout.questionLink4Desc,
      questionLink5: formLayout.questionLink5,
      questionLink5Desc: formLayout.questionLink5Desc,
      sectionRequestList: [...formLayout.sectionRequestList]
    };

    if (
      !validateSectionRequestList(payload.sectionRequestList, payload.formName)
    ) {
      setLoading(false);
      return;
    }

    form.append(
      location.state && location.state.formId
        ? 'updateFormRequest'
        : 'addFormRequest',
      JSON.stringify(payload)
    );

    dispatch(
      createUpdateForm({
        formId: location.state && location.state.formId,
        forceUpdate: 0,
        payload: form
      })
    );
    // console.log(validateSectionRequestList(payload.sectionRequestList), 'assa');
    // setLoading(false);
  };

  function validateSectionRequestList(sectionRequestList, formName) {
    const errors = [];

    if (_.includes(['', null, undefined], formName)) {
      errors.push('Form name is mandatory');
    }

    if (!Array.isArray(sectionRequestList) || sectionRequestList.length === 0) {
      errors.push(`Atleast one section to be added`);
    }

    sectionRequestList.forEach((section, sectionIndex) => {
      if (!section.sectionName) {
        errors.push(`Section Name is missing.`);
      }

      if (
        !Array.isArray(section.questionRequestList) ||
        section.questionRequestList.length === 0
      ) {
        errors.push(
          `At least one question needs to be added in ${section?.sectionName ? section?.sectionName : 'section'}`
        );
      } else {
        section.questionRequestList.forEach((question, questionIndex) => {
          if (question.questionOptionId === -999) {
            errors.push(`Please select question type.`);
          }
          if (!question.questionText) {
            errors.push(
              `Question title is missing in section ${section?.sectionName}`
            );
          }
        });
      }
    });

    if (errors.length > 0) {
      console.log('Validation errors:', errors);
      setFormValidation([...errors]);
      return false;
    }

    return true;
  }

  useEffect(() => {
    if (formValidation.length > 0) {
      const timer = setTimeout(() => {
        setFormValidation([]);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [formValidation]);

  const handleAddIndicators = indicatorValues => {
    const editingSection = [...formLayout.sectionRequestList];

    const questionRequestList =
      editingSection[indicatorValues.sectionKey].questionRequestList[
        indicatorValues.questionKey
      ];

    if (!('indicatorIds' in questionRequestList)) {
      questionRequestList.indicatorIds = [];
    }
    if (selectedIndicators.length === 0) {
      questionRequestList.indicatorIds = [];
    } else {
      let tmepList = selectedIndicators.map(cc => cc.indicatorId);

      questionRequestList.indicatorIds =
        questionRequestList.indicatorIds.filter(id => !tmepList.includes(id));
      questionRequestList.indicatorIds.push(...tmepList);
    }

    setFormLayout({
      ...formLayout,
      sectionRequestList: [...editingSection],
      isDirty: true
    });

    setAddIndicators(null);
    setSelectedIndicators([]);
  };

  const handleRemoveIndicator = (sectionKey, questionKey, indicatorId) => {
    const editingSection = [...formLayout.sectionRequestList];

    const questionRequestList =
      editingSection[sectionKey].questionRequestList[questionKey];

    if ('indicatorIds' in questionRequestList) {
      questionRequestList.indicatorIds =
        questionRequestList.indicatorIds.filter(id => id !== indicatorId);
    }

    setFormLayout({
      ...formLayout,
      sectionRequestList: [...editingSection],
      isDirty: true
    });
  };

  const handleBoxClick = attachment => {
    const isImage = attachment.type.startsWith('image');
    const isVideo = attachment.type.startsWith('video');

    if (isImage || isVideo) {
      setModalType(isImage ? 'image' : isVideo ? 'video' : null);

      const reader = new FileReader();
      reader.readAsDataURL(attachment?.attachment);
      reader.onload = e => {
        setBase64Content(e.target.result);
      };
    }
    setShowModal(attachment);
  };

  // const sections = formLayout?.sectionRequestList || [];

  // const sectionTitles = sections.map((section, index) => {
  //   const questionCount = section.questionRequestList.length;
  //   return `${section.sectionName} (Section ${
  //     index + 1
  //   }) - ${questionCount} Questions`;
  // });

  // const title = `Add Indicators of question: ${sectionTitles.join(', ')}`;

  useEffect(() => {
    if (!scheduleReminderUserFormLoading && scheduleReminderUserFormSuccess) {
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 1500);
    }
    if (
      !sendScheduleReminderDateFormLoading &&
      sendScheduleReminderDateFormSuccess
    ) {
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 1500);
    }

    if (
      !scheduleReminderUserGroupFormLoading &&
      scheduleReminderUserGroupFormSuccess
    ) {
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 1500);
    }
  }, [
    scheduleReminderUserFormSuccess,
    sendScheduleReminderDateFormSuccess,
    scheduleReminderUserGroupFormSuccess
  ]);

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} mb={1}>
        <Grid>
          <Typography mb={1} fontSize={20} fontWeight="medium">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <SvgIcon sx={{ height: 24, width: 24 }}>
                  <ChevronRightIcon color={colorTextGrey} />
                </SvgIcon>
              }
              sx={{ marginBottom: 2 }}
            >
              <Link
                color="inherit"
                href={'flows'}
                onClick={event => {
                  event.preventDefault();
                  navigate('/flows');
                }}
                sx={{ textDecoration: 'none' }}
              >
                <Typography
                  fontWeight={'700'}
                  color={colorLinkGrey}
                  fontSize={'24px'}
                >
                  Evaluation Flows
                </Typography>
              </Link>
              <Link
                color="inherit"
                href={'school'}
                onClick={event => {
                  event.preventDefault();
                  navigate('/flows/flows-details', { state: location.state });
                  dispatch(revertFormsFormData());
                }}
                sx={{ textDecoration: 'none' }}
              >
                <Typography
                  fontWeight={'700'}
                  color={colorLinkGrey}
                  fontSize={'24px'}
                >
                  {location.state?.flowName}
                </Typography>
              </Link>
              <Typography
                sx={{
                  fontSize: '32px',
                  fontWeight: '700',
                  color: colorTextBlack
                }}
              >
                {/* {`${!isNewForm ? `${previewForm?.formName || ''}` : 'Untitled form'}`} */}
                {formLayout.formName || 'Untitled form'}
              </Typography>
            </Breadcrumbs>
          </Typography>
        </Grid>
        <Box>
          <Button
            disabled={!location.state?.formId}
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: 'black',
              borderWidth: '2px',
              marginRight: 2,
              // width: 15,
              '&:hover': {
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px'
              }
            }}
            variant="outlined"
            onClick={() => {
              setShowFormAssigment(!showFormAssigment);
            }}
          >
            <SvgIcon sx={{ height: '16px', width: '16px', marginRight: 0.6 }}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3332 8.3335V4.5335C13.3332 3.41339 13.3332 2.85334 13.1152 2.42552C12.9234 2.04919 12.6175 1.74323 12.2412 1.55148C11.8133 1.3335 11.2533 1.3335 10.1332 1.3335H5.8665C4.7464 1.3335 4.18635 1.3335 3.75852 1.55148C3.3822 1.74323 3.07624 2.04919 2.88449 2.42552C2.6665 2.85334 2.6665 3.41339 2.6665 4.5335V11.4668C2.6665 12.5869 2.6665 13.147 2.88449 13.5748C3.07624 13.9511 3.3822 14.2571 3.75852 14.4488C4.18635 14.6668 4.7464 14.6668 5.8665 14.6668H7.99984M9.33317 7.3335H5.33317M6.6665 10.0002H5.33317M10.6665 4.66683H5.33317M9.6665 12.6668L10.9998 14.0002L13.9998 11.0002"
                  stroke={location.state?.formId ? colorTextBlack : '#CBD2D9'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
            Assign form
          </Button>
          {/* {initialValues.type === 0 && (
            <Button
              // startIcon={}
              sx={{
                boxShadow: 'none',
                marginRight: '16px'
              }}
              variant="contained"
              onClick={() => {
                handleSaveForm();
              }}
            >
              <SvgIcon sx={{ height: '16px', width: '16px', marginRight: 0.6 }}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 3V6.4C7 6.96005 7 7.24008 7.10899 7.45399C7.20487 7.64215 7.35785 7.79513 7.54601 7.89101C7.75992 8 8.03995 8 8.6 8H15.4C15.9601 8 16.2401 8 16.454 7.89101C16.6422 7.79513 16.7951 7.64215 16.891 7.45399C17 7.24008 17 6.96005 17 6.4V4M17 21V14.6C17 14.0399 17 13.7599 16.891 13.546C16.7951 13.3578 16.6422 13.2049 16.454 13.109C16.2401 13 15.9601 13 15.4 13H8.6C8.03995 13 7.75992 13 7.54601 13.109C7.35785 13.2049 7.20487 13.3578 7.10899 13.546C7 13.7599 7 14.0399 7 14.6V21M21 9.32548V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H14.6745C15.1637 3 15.4083 3 15.6385 3.05526C15.8425 3.10425 16.0376 3.18506 16.2166 3.29472C16.4184 3.4184 16.5914 3.59135 16.9373 3.93726L20.0627 7.06274C20.4086 7.40865 20.5816 7.5816 20.7053 7.78343C20.8149 7.96237 20.8957 8.15746 20.9447 8.36154C21 8.59171 21 8.8363 21 9.32548Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SvgIcon>
              Save form
            </Button>
          )} */}
          <Button
            // startIcon={<PreviewFormIcon color={'#000'} />}
            disabled={!location.state?.formId}
            sx={{
              boxShadow: 'none'
            }}
            variant="contained"
            onClick={previewFormHandlr}
          >
            <SvgIcon sx={{ height: '16px', width: '16px', marginRight: 0.6 }}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33317 7.3335H5.33317M6.6665 10.0002H5.33317M10.6665 4.66683H5.33317M13.3332 7.00016V4.5335C13.3332 3.41339 13.3332 2.85334 13.1152 2.42552C12.9234 2.04919 12.6175 1.74323 12.2412 1.55148C11.8133 1.3335 11.2533 1.3335 10.1332 1.3335H5.8665C4.7464 1.3335 4.18635 1.3335 3.75852 1.55148C3.3822 1.74323 3.07624 2.04919 2.88449 2.42552C2.6665 2.85334 2.6665 3.41339 2.6665 4.5335V11.4668C2.6665 12.5869 2.6665 13.147 2.88449 13.5748C3.07624 13.9511 3.3822 14.2571 3.75852 14.4488C4.18635 14.6668 4.7464 14.6668 5.8665 14.6668H7.6665M14.6665 14.6668L13.6665 13.6668M14.3332 12.0002C14.3332 13.2888 13.2885 14.3335 11.9998 14.3335C10.7112 14.3335 9.6665 13.2888 9.6665 12.0002C9.6665 10.7115 10.7112 9.66683 11.9998 9.66683C13.2885 9.66683 14.3332 10.7115 14.3332 12.0002Z"
                  stroke={
                    !location.state?.formId ? colorTextGrey : colorTextBlack
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>{' '}
            Preview form
          </Button>
        </Box>
      </Box>

      {/* <CustomBreadcrumb path={`${location.pathname}`} state={location.state} /> */}

      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        // component={Paper}
        sx={{
          borderBottom: 1,
          borderColor: 'divider'
          // paddingX: '10px'
        }}
      >
        <Tabs
          sx={{ backgroundColor: 'transparent' }}
          value={initialValues.type}
          onChange={(val, newValue) => {
            setInitialValues({ ...initialValues, type: newValue });
          }}
          TabIndicatorProps={{
            sx: {
              height: '4px',
              borderRadius: '4px'
            }
          }}
        >
          <Tab
            color={initialValues.type === 0 ? colorTextBlack : colorTextGrey}
            sx={{
              fontWeight: initialValues.type === 0 ? '700' : '600',
              fontSize: '16px',
              minHeight: 44,
              height: 44,
              color: colorTextGrey,
              '&.Mui-selected': {
                color: initialValues.type === 0 ? colorTextBlack : colorTextGrey
              }
            }}
            disabled={!location.state?.formId}
            icon={
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 7.125V5.1C15 3.83988 15 3.20982 14.7548 2.72852C14.539 2.30516 14.1948 1.96095 13.7715 1.74524C13.2902 1.5 12.6601 1.5 11.4 1.5H6.6C5.33988 1.5 4.70982 1.5 4.22852 1.74524C3.80516 1.96095 3.46095 2.30516 3.24524 2.72852C3 3.20982 3 3.83988 3 5.1V12.9C3 14.1601 3 14.7902 3.24524 15.2715C3.46095 15.6948 3.80516 16.039 4.22852 16.2548C4.70982 16.5 5.33988 16.5 6.6 16.5H10.5M10.5 8.25H6M7.5 11.25H6M12 5.25H6M12.375 11.2517C12.5071 10.876 12.768 10.5593 13.1113 10.3575C13.4546 10.1557 13.8583 10.082 14.2508 10.1493C14.6433 10.2166 14.9993 10.4207 15.2557 10.7253C15.5122 11.03 15.6525 11.4155 15.6519 11.8138C15.6519 12.9379 13.9657 13.5 13.9657 13.5M13.9874 15.75H13.9949"
                  stroke={
                    initialValues.type === 0 && !isNewForm
                      ? colorTextBlack
                      : colorTextGrey
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            iconPosition="start"
            label={
              <CustomTabLabel
                title="Questions"
                count={
                  (questionCount || 0) < 10
                    ? `0${questionCount || 0}`
                    : questionCount || 0
                }
                selected={initialValues.type === 0}
              />
            }
          />
          <Tab
            color={initialValues.type === 1 ? colorTextBlack : colorTextGrey}
            sx={{
              fontSize: '16px',
              minHeight: 44,
              height: 44,
              fontWeight: initialValues.type === 1 ? '700' : '600',
              '&.Mui-selected': {
                color: initialValues.type === 1 ? colorTextBlack : colorTextGrey
              }
            }}
            disabled={!location.state?.formId}
            icon={
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 9.375V5.1C15 3.83988 15 3.20982 14.7548 2.72852C14.539 2.30516 14.1948 1.96095 13.7715 1.74524C13.2902 1.5 12.6601 1.5 11.4 1.5H6.6C5.33988 1.5 4.70982 1.5 4.22852 1.74524C3.80516 1.96095 3.46095 2.30516 3.24524 2.72852C3 3.20982 3 3.83988 3 5.1V12.9C3 14.1601 3 14.7902 3.24524 15.2715C3.46095 15.6948 3.80516 16.039 4.22852 16.2548C4.70982 16.5 5.33988 16.5 6.6 16.5H9M10.5 8.25H6M7.5 11.25H6M12 5.25H6M10.875 14.25L12.375 15.75L15.75 12.375"
                  stroke={
                    initialValues.type === 1 ? colorTextBlack : colorTextGrey
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            iconPosition="start"
            label={
              <CustomTabLabel
                title="Responses"
                count={
                  countQuestionResponses.responses < 10
                    ? `0${countQuestionResponses?.responses}`
                    : countQuestionResponses?.responses
                }
                selected={initialValues.type === 1}
              />
            }
          />
        </Tabs>
      </Grid>
      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}

      {initialValues.type === 0 && (
        <>
          {!loading && (
            <Grid>
              <Grid
                sx={{
                  padding: '24px 0px',
                  overflow: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '0.2em'
                  },
                  '&::-webkit-scrollbar-track': {
                    background: 'transparent'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(0, 0, 0, 0.3)'
                  }
                }}
                height={'60vh'}
                width={'100%'}
              >
                <Box
                  sx={{ borderTop: `5px solid ${colorPrimary}` }}
                  bgcolor={'white'}
                  padding={2}
                  borderRadius={2}
                  marginBottom={2}
                  marginRight={1}
                >
                  <Grid container>
                    <Grid item sm={12}>
                      <Grid>
                        <TextField
                          name="formTitle"
                          // fullWidth

                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              color: colorTextBlack,
                              opacity: 1
                            },
                            width: '483px'
                          }}
                          placeholder="Untitled Form *"
                          // defaultValue="Untitled Form"
                          size="small"
                          variant="standard"
                          value={formLayout.formName}
                          InputProps={{
                            style: { fontWeight: 600, maxLength: 64 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 2.26946V6.4C14 6.96005 14 7.24008 14.109 7.45399C14.2049 7.64215 14.3578 7.79513 14.546 7.89101C14.7599 8 15.0399 8 15.6 8H19.7305M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z"
                                      stroke="#1F2933"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </SvgIcon>
                              </InputAdornment>
                            )
                          }}
                          onChange={e =>
                            setFormLayout({
                              ...formLayout,
                              formName: e.target.value,
                              isDirty: true
                            })
                          }
                        />
                      </Grid>

                      <TextField
                        sx={{ marginTop: 2, width: '483px' }}
                        name="formDescription"
                        // fullWidth
                        placeholder="Form description (optional)"
                        size="small"
                        variant="standard"
                        value={formLayout.formDescription}
                        inputProps={{ maxLength: 200 }}
                        onChange={e =>
                          setFormLayout({
                            ...formLayout,
                            formDescription: e.target.value,
                            isDirty: true
                          })
                        }
                      />
                    </Grid>

                    {isArrayNotEmpty(listOfAttachments) && (
                      <Grid item sm={12} md={12} mt={1}>
                        <FormControl variant="outlined" fullWidth size="small">
                          <Typography
                            fontWeight="600"
                            fontSize={'16px'}
                            color={colorTextBlack}
                          >
                            Attachments
                          </Typography>
                          <Box sx={{ width: '50%' }}>
                            {listOfAttachments.map((attach, key) => {
                              return (
                                <Box
                                  key={key}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0px 10px',
                                    border: '1px solid',
                                    borderRadius: '7px',
                                    marginBottom: '5px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    handleBoxClick(attach);
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: '100%',
                                      mr: 1,
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {formsFileTypeIcon[attach.type]}
                                    <a onClick={() => {}}>
                                      <Typography
                                        variant="body2"
                                        // color="text.secondary"
                                        fontWeight={'500'}
                                        sx={{ marginLeft: 1 }}
                                      >
                                        {attach.title}
                                      </Typography>
                                    </a>
                                  </Box>
                                  <Box sx={{ minWidth: 35, display: 'flex' }}>
                                    {/* <IconButton
                                    size="small"
                                    onClick={() => {
                                      // saveAs(attach.file);
                                    }}
                                  >
                                    <Download />
                                  </IconButton> */}

                                    <IconButton
                                      size="small"
                                      onClick={e => {
                                        e.stopPropagation();
                                        removeAttachment(
                                          key,
                                          attach.attachmentId !== 0
                                            ? attach.attachmentId
                                            : null
                                        );
                                      }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </Box>

                <DragDropContext onDragEnd={onSectionDragEnd}>
                  <Droppable droppableId="sections">
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {formLayout.sectionRequestList
                          .sort((a, b) => a.sectionOrder - b.sectionOrder)
                          .map((section, sectionKey) => {
                            return (
                              <Draggable
                                key={sectionKey}
                                draggableId={`section-${sectionKey}`}
                                index={sectionKey}
                              >
                                {provided => (
                                  <Box
                                    id={`section-${sectionKey}`}
                                    key={sectionKey}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    onClick={() =>
                                      handleSectionClick(sectionKey)
                                    }
                                    mr={1}
                                  >
                                    <Typography
                                      fontWeight="700"
                                      lineHeight={'26px'}
                                      fontSize={'20px'}
                                      color={
                                        sectionKey === selectedSection
                                          ? colorTextBlack
                                          : 'inherit'
                                      }
                                      sx={{ marginBottom: 1 }}
                                    >
                                      Section {sectionKey + 1} of{' '}
                                      {formLayout.sectionRequestList.length}
                                    </Typography>
                                    <Box
                                      sx={{
                                        borderTop: `5px solid ${colorPrimary}`
                                      }}
                                      bgcolor={'white'}
                                      padding={2}
                                      borderRadius={2}
                                      marginBottom={2}
                                    >
                                      <Grid container spacing={3}>
                                        <Grid item sm={12}>
                                          <TextField
                                            name="sectionTitle"
                                            fullWidth
                                            placeholder="Section Title *"
                                            // defaultValue=""
                                            value={section.sectionName}
                                            onChange={e => {
                                              handleLayoutDataChange(
                                                sectionKey,
                                                null,
                                                'sectionName',
                                                e.target.value
                                              );
                                            }}
                                            size="small"
                                            variant="standard"
                                            InputProps={{
                                              style: {
                                                fontWeight: 600,
                                                maxLength: 64
                                              }
                                            }}
                                          />
                                          <TextField
                                            sx={{
                                              marginTop: 2,
                                              maxLength: 64,
                                              '& .MuiInputBase-input': {
                                                color: colorLinkGrey,
                                                fontWeight: '500'
                                              }
                                            }}
                                            name="sectionDescription"
                                            fullWidth
                                            placeholder="Section description (optional)"
                                            size="small"
                                            variant="standard"
                                            value={section.sectionDescription}
                                            onChange={e => {
                                              handleLayoutDataChange(
                                                sectionKey,
                                                null,
                                                'sectionDescription',
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Box>

                                    {section.questionRequestList.map(
                                      (questionObj, key) => {
                                        let questionType =
                                          FLOWS_FORMS_QUESTION_TYPE_SELECTOR.find(
                                            qtyp =>
                                              qtyp.id ===
                                              questionObj.questionOptionId
                                          ) || { id: -999, label: '' };
                                        console.log(
                                          questionType,
                                          'questionType'
                                        );
                                        let options = LimitOptions();
                                        return (
                                          <Box
                                            key={key}
                                            bgcolor={'white'}
                                            padding={2}
                                            borderRadius={2}
                                            marginBottom={2}
                                          >
                                            <Grid
                                              container
                                              justifyContent={'space-between'}
                                              alignItems={'end'}
                                            >
                                              <Grid item lg={6} md={12}>
                                                <TextField
                                                  name="question"
                                                  fullWidth
                                                  value={
                                                    questionObj.questionText
                                                  }
                                                  placeholder="Write your question here *"
                                                  onChange={e =>
                                                    handleLayoutDataChange(
                                                      sectionKey,
                                                      key,
                                                      'questionText',
                                                      e.target.value
                                                    )
                                                  }
                                                  size="small"
                                                  sx={{
                                                    '& .MuiInputBase-input': {
                                                      color: colorTextBlack,
                                                      fontWeight: '600',
                                                      fontSize: '16px',
                                                      lineHeight: '20px'
                                                    }
                                                  }}
                                                  variant="standard"
                                                  inputProps={{
                                                    maxLength: 200
                                                  }}
                                                  InputProps={{
                                                    startAdornment: (
                                                      <span
                                                        style={{
                                                          padding: '0.5rem',
                                                          display:
                                                            'inline-flex',
                                                          alignItems: 'center',
                                                          color: colorTextBlack,
                                                          fontWeight: '600',
                                                          fontSize: '16px',
                                                          lineHeight: '20px'
                                                        }}
                                                      >
                                                        {key + 1}{' '}
                                                        <East
                                                          fontSize="small"
                                                          sx={{
                                                            marginLeft: '4px'
                                                          }}
                                                        />
                                                      </span>
                                                    )
                                                  }}
                                                />
                                                <TextField
                                                  name="description"
                                                  fullWidth
                                                  placeholder="Description (optional)"
                                                  size="small"
                                                  value={
                                                    questionObj.questionDescription
                                                  }
                                                  onChange={e =>
                                                    handleLayoutDataChange(
                                                      sectionKey,
                                                      key,
                                                      'questionDescription',
                                                      e.target.value
                                                    )
                                                  }
                                                  variant="standard"
                                                  sx={{ marginTop: 2 }}
                                                  inputProps={{
                                                    maxLength: 200,
                                                    color: 'gray'
                                                  }}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                display={'flex'}
                                                justifyContent={'end'}
                                              >
                                                <Grid lg={8}>
                                                  <Box
                                                    display={'flex'}
                                                    justifyContent={'end'}
                                                  >
                                                    <Autocomplete
                                                      size="small"
                                                      value={questionType}
                                                      clearIcon={false}
                                                      onChange={(
                                                        _,
                                                        newValue
                                                      ) => {
                                                        let editingSection = [
                                                          ...formLayout.sectionRequestList
                                                        ];
                                                        handleLayoutDataChange(
                                                          sectionKey,
                                                          key,
                                                          'questionOptionId',
                                                          newValue
                                                            ? newValue.id
                                                            : 1
                                                        );
                                                        let questionToUpdate =
                                                          editingSection[
                                                            sectionKey
                                                          ].questionRequestList[
                                                            key
                                                          ];

                                                        if (
                                                          questionToUpdate &&
                                                          newValue
                                                            ? newValue.id
                                                            : 1 ===
                                                              (3 || 6 || 7)
                                                        ) {
                                                          editingSection[
                                                            sectionKey
                                                          ].questionRequestList[
                                                            key
                                                          ]['optionTexts'] = [];
                                                          editingSection[
                                                            sectionKey
                                                          ].questionRequestList[
                                                            key
                                                          ]['tempOption'] = '';

                                                          editingSection[
                                                            sectionKey
                                                          ].questionRequestList[
                                                            key
                                                          ]['lowerLimitLabel'] =
                                                            '';

                                                          editingSection[
                                                            sectionKey
                                                          ].questionRequestList[
                                                            key
                                                          ]['upperLimitLabel'] =
                                                            '';

                                                          editingSection[
                                                            sectionKey
                                                          ].questionRequestList[
                                                            key
                                                          ]['lowerLimit'] = 1;

                                                          editingSection[
                                                            sectionKey
                                                          ].questionRequestList[
                                                            key
                                                          ]['upperLimit'] = 10;
                                                        }
                                                        setFormLayout({
                                                          ...formLayout,
                                                          sectionRequestList: [
                                                            ...editingSection
                                                          ],
                                                          isDirty: true
                                                        });
                                                      }}
                                                      isOptionEqualToValue={(
                                                        option,
                                                        value
                                                      ) =>
                                                        option.id ===
                                                        (value
                                                          ? value.id
                                                          : null)
                                                      }
                                                      getOptionLabel={
                                                        getOptionLabel
                                                      }
                                                      options={
                                                        FLOWS_FORMS_QUESTION_TYPE_SELECTOR
                                                      }
                                                      sx={{
                                                        width: '274px',
                                                        height: '42px'
                                                      }}
                                                      // renderOption={(
                                                      //   props,
                                                      //   option
                                                      // ) => {
                                                      //   return (
                                                      //     <ListItem {...props}>
                                                      //       <ListItemIcon
                                                      //         sx={{ minWidth: 40 }}
                                                      //       >
                                                      //         {
                                                      //           iconMapFlows[
                                                      //             option.id
                                                      //           ]
                                                      //         }
                                                      //       </ListItemIcon>
                                                      //       <ListItemText>
                                                      //         <Typography>
                                                      //           {option.label}
                                                      //         </Typography>
                                                      //       </ListItemText>
                                                      //     </ListItem>
                                                      //   );
                                                      // }}
                                                      ListboxProps={{
                                                        sx: {
                                                          '& .MuiAutocomplete-option:hover':
                                                            {
                                                              backgroundColor:
                                                                colorSecondary
                                                            }
                                                        }
                                                      }}
                                                      renderInput={params => {
                                                        const selectedOption =
                                                          params.inputProps
                                                            .value
                                                            ? FLOWS_FORMS_QUESTION_TYPE_SELECTOR.find(
                                                                option =>
                                                                  option.label ===
                                                                  params
                                                                    .inputProps
                                                                    .value
                                                              )
                                                            : null;

                                                        return (
                                                          <TextField
                                                            {...params}
                                                            placeholder="Select question type"
                                                            variant="outlined"
                                                            inputProps={{
                                                              ...params.inputProps,
                                                              readOnly: true
                                                            }}
                                                            // InputProps={{
                                                            //   ...params.InputProps,
                                                            //   startAdornment:
                                                            //     selectedOption ? (
                                                            //       <ListItemIcon
                                                            //         style={{
                                                            //           minWidth: 0
                                                            //         }}
                                                            //       >
                                                            //         {
                                                            //           iconMapFlows[
                                                            //             selectedOption
                                                            //               .id
                                                            //           ]
                                                            //         }
                                                            //       </ListItemIcon>
                                                            //     ) : null
                                                            // }}
                                                          />
                                                        );
                                                      }}
                                                    />
                                                  </Box>
                                                  <Box
                                                    display={'flex'}
                                                    justifyContent={'flex-end'}
                                                  >
                                                    <FormControlLabel
                                                      sx={{
                                                        marginLeft: 0,
                                                        marginRight: 2,
                                                        '& .MuiFormControlLabel-label':
                                                          {
                                                            color:
                                                              colorLinkGrey,
                                                            fontSize: '12px',
                                                            lineHeight: '14px',
                                                            fontWeight: '500'
                                                          }
                                                      }}
                                                      control={<IOSSwitch />}
                                                      label={'Required'}
                                                      checked={
                                                        questionObj.isRequired ===
                                                        1
                                                      }
                                                      onChange={e => {
                                                        handleLayoutDataChange(
                                                          sectionKey,
                                                          key,
                                                          'isRequired',
                                                          questionObj.isRequired ===
                                                            1
                                                            ? 0
                                                            : 1
                                                        );
                                                      }}
                                                      labelPlacement="start"
                                                    />
                                                    <Divider
                                                      orientation="vertical"
                                                      variant="middle"
                                                      flexItem
                                                    />
                                                    <Tooltip title="Copy question">
                                                      <IconButton
                                                        onClick={() => {
                                                          onCopyQuestionClick(
                                                            sectionKey,
                                                            key
                                                          );
                                                        }}
                                                        sx={{ marginRight: 1 }}
                                                      >
                                                        <Copy05Icon />
                                                      </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete question">
                                                      <IconButton
                                                        onClick={() => {
                                                          onDeleteQuestionClick(
                                                            sectionKey,
                                                            key
                                                          );
                                                        }}
                                                      >
                                                        <TrashIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              {questionType.id === 4 && (
                                                <Tooltip
                                                  placement="top"
                                                  title="Click to add new indicator"
                                                >
                                                  <Button
                                                    sx={{
                                                      height: 30,
                                                      mt: 1,
                                                      color: 'black',
                                                      borderBottom:
                                                        '1px solid black',
                                                      borderRadius: 0,
                                                      ':hover': {
                                                        color: colorPrimary,
                                                        borderBottom: `1px solid ${colorPrimary}`,
                                                        bgcolor: 'white'
                                                      }
                                                    }}
                                                    variant="text"
                                                    onClick={() => {
                                                      if (
                                                        questionObj?.indicatorIds
                                                      ) {
                                                        let tmpN = [
                                                          ...indicatorsList
                                                        ];
                                                        let filteredData =
                                                          tmpN.filter(cc =>
                                                            _.includes(
                                                              questionObj?.indicatorIds,
                                                              cc.indicatorId
                                                            )
                                                          );
                                                        setSelectedIndicators([
                                                          ...filteredData
                                                        ]);
                                                      }
                                                      setAddIndicators({
                                                        sectionKey: sectionKey,
                                                        questionKey: key
                                                      });
                                                    }}
                                                    size="small"
                                                    startIcon={<Add />}
                                                  >
                                                    Add Indicator
                                                  </Button>
                                                </Tooltip>
                                              )}

                                              <Grid item lg={12}>
                                                {questionType.id === 8 && (
                                                  <>
                                                    <Grid
                                                      item
                                                      sx={{
                                                        display: 'flex',
                                                        gap: '16px',
                                                        alignItems: 'center',
                                                        marginTop: 2
                                                      }}
                                                    >
                                                      <Grid item>
                                                        <FormControl
                                                          sx={{
                                                            width: '76px',
                                                            height: '34px'
                                                          }}
                                                          variant="outlined"
                                                          size="small"
                                                          error={
                                                            questionObj.lowerLimit >
                                                            questionObj.upperLimit
                                                          }
                                                        >
                                                          <Select
                                                            sx={{
                                                              width: '100%',
                                                              fontSize: '14px',
                                                              lineHeight:
                                                                '18px',
                                                              fontWeight: '600',
                                                              paddingRight:
                                                                '8px'
                                                            }}
                                                            inputProps={{
                                                              sx: {
                                                                alignContent:
                                                                  'center !important',
                                                                padding:
                                                                  '8px 14px !important' // Customize the input padding here
                                                              }
                                                            }}
                                                            value={
                                                              questionObj.lowerLimit ??
                                                              '1'
                                                            }
                                                            IconComponent={() => (
                                                              <SvgIcon
                                                                sx={{
                                                                  width: '16px',
                                                                  height: '16px'
                                                                }}
                                                              >
                                                                <svg
                                                                  width="16"
                                                                  height="16"
                                                                  viewBox="0 0 16 16"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M4 6L8 10L12 6"
                                                                    stroke="#1F2933"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                  />
                                                                </svg>
                                                              </SvgIcon>
                                                            )}
                                                            onChange={(
                                                              e,
                                                              value
                                                            ) => {
                                                              handleLayoutDataChange(
                                                                sectionKey,
                                                                key,
                                                                'lowerLimit',
                                                                e.target.value
                                                              );
                                                              handleLayoutDataChange(
                                                                sectionKey,
                                                                key,
                                                                'upperLimit',
                                                                e.target
                                                                  .value === 10
                                                                  ? null
                                                                  : 10
                                                              );
                                                            }}
                                                          >
                                                            {options.map(
                                                              option => (
                                                                <MenuItem
                                                                  key={
                                                                    option.value
                                                                  }
                                                                  value={
                                                                    option.value
                                                                  }
                                                                >
                                                                  {option.label}
                                                                </MenuItem>
                                                              )
                                                            )}
                                                          </Select>
                                                        </FormControl>
                                                      </Grid>
                                                      <Grid item>
                                                        <Typography>
                                                          to
                                                        </Typography>
                                                      </Grid>

                                                      <Grid item>
                                                        <FormControl
                                                          sx={{
                                                            width: '76px',
                                                            height: '34px'
                                                          }}
                                                          variant="outlined"
                                                          size="small"
                                                          error={
                                                            questionObj.lowerLimit >
                                                            questionObj.upperLimit
                                                          }
                                                        >
                                                          <Select
                                                            sx={{
                                                              width: '100%',
                                                              fontSize: '14px',
                                                              lineHeight:
                                                                '18px',
                                                              fontWeight: '600',
                                                              paddingRight:
                                                                '8px'
                                                            }}
                                                            inputProps={{
                                                              sx: {
                                                                alignContent:
                                                                  'center !important',
                                                                padding:
                                                                  '8px 14px !important' // Customize the input padding here
                                                              }
                                                            }}
                                                            IconComponent={() => (
                                                              <SvgIcon
                                                                sx={{
                                                                  width: '16px',
                                                                  height: '16px'
                                                                }}
                                                              >
                                                                <svg
                                                                  width="16"
                                                                  height="16"
                                                                  viewBox="0 0 16 16"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M4 6L8 10L12 6"
                                                                    stroke="#1F2933"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                  />
                                                                </svg>
                                                              </SvgIcon>
                                                            )}
                                                            value={
                                                              questionObj.upperLimit ??
                                                              '10'
                                                            }
                                                            onChange={e => {
                                                              handleLayoutDataChange(
                                                                sectionKey,
                                                                key,
                                                                'upperLimit',
                                                                e.target.value
                                                              );
                                                            }}
                                                          >
                                                            {options.map(
                                                              option => (
                                                                <MenuItem
                                                                  key={
                                                                    option.value
                                                                  }
                                                                  value={
                                                                    option.value
                                                                  }
                                                                  disabled={
                                                                    option.value <=
                                                                    questionObj.lowerLimit
                                                                  }
                                                                  sx={
                                                                    option.value <=
                                                                    questionObj.lowerLimit
                                                                      ? {
                                                                          color:
                                                                            colorTextGrey
                                                                        }
                                                                      : {}
                                                                  }
                                                                >
                                                                  {option.label}
                                                                </MenuItem>
                                                              )
                                                            )}
                                                          </Select>
                                                        </FormControl>
                                                      </Grid>
                                                    </Grid>
                                                    {questionObj.lowerLimit >
                                                      questionObj.upperLimit && (
                                                      <Typography
                                                        sx={{
                                                          marginTop: 0.5,
                                                          fontSize: '12px',
                                                          lineHeight: '14px',
                                                          fontWeight: '500',
                                                          color: colorFieldError
                                                        }}
                                                      >
                                                        Lower limit cannot be
                                                        greater than the upper
                                                        limit.
                                                      </Typography>
                                                    )}

                                                    <Grid sx={{ marginTop: 2 }}>
                                                      <TextField
                                                        name="label"
                                                        // fullWidth
                                                        placeholder="1.&nbsp;&nbsp;Label (optional)"
                                                        size="small"
                                                        sx={{ width: '172px' }}
                                                        value={
                                                          questionObj.lowerLimitLabel
                                                        }
                                                        onChange={e =>
                                                          handleLayoutDataChange(
                                                            sectionKey,
                                                            key,
                                                            'lowerLimitLabel',
                                                            e.target.value
                                                          )
                                                        }
                                                        variant="standard"
                                                        // sx={{ marginTop: 2 }}
                                                        inputProps={{
                                                          maxLength: 200,
                                                          color: colorTextGrey
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid sx={{ marginTop: 2 }}>
                                                      <TextField
                                                        name="label"
                                                        // fullWidth
                                                        placeholder="2.&nbsp;&nbsp;Label (optional)"
                                                        size="small"
                                                        value={
                                                          questionObj.upperLimitLabel
                                                        }
                                                        onChange={e =>
                                                          handleLayoutDataChange(
                                                            sectionKey,
                                                            key,
                                                            'upperLimitLabel',
                                                            e.target.value
                                                          )
                                                        }
                                                        variant="standard"
                                                        // sx={{ marginTop: 2 }}
                                                        inputProps={{
                                                          maxLength: 200,
                                                          color: 'gray'
                                                        }}
                                                      />
                                                    </Grid>
                                                  </>
                                                )}
                                                {(questionType.id === 4 ||
                                                  questionType.id === 5) && (
                                                  <>
                                                    <TextField
                                                      placeholder={`${
                                                        questionType.id === 4
                                                          ? 'Short answer'
                                                          : questionType.id ===
                                                              5
                                                            ? 'Long answer'
                                                            : ''
                                                      }`}
                                                      size="small"
                                                      disabled
                                                      variant="standard"
                                                      sx={{ marginTop: 2 }}
                                                    />
                                                  </>
                                                )}

                                                {_.includes(
                                                  [1, 2, 3],
                                                  questionType.id
                                                ) && (
                                                  <div
                                                    style={{ marginTop: 10 }}
                                                  >
                                                    <DragDropContext
                                                      onDragEnd={e =>
                                                        onDragEnd(
                                                          e,
                                                          sectionKey,
                                                          key,
                                                          questionObj
                                                        )
                                                      }
                                                    >
                                                      <Droppable droppableId="options">
                                                        {provided => (
                                                          <div
                                                            {...provided.droppableProps}
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                          >
                                                            {isArrayNotEmpty(
                                                              questionObj.optionTexts
                                                            ) &&
                                                              questionObj.optionTexts.map(
                                                                (
                                                                  optinList,
                                                                  optionIdx
                                                                ) => {
                                                                  return (
                                                                    <Draggable
                                                                      key={
                                                                        optionIdx
                                                                      }
                                                                      draggableId={`${optinList}-${optionIdx}`}
                                                                      index={
                                                                        optionIdx
                                                                      }
                                                                    >
                                                                      {provided => (
                                                                        <div
                                                                          {...provided.draggableProps}
                                                                          {...provided.dragHandleProps}
                                                                          ref={
                                                                            provided.innerRef
                                                                          }
                                                                        >
                                                                          <div
                                                                            key={
                                                                              optionIdx
                                                                            }
                                                                            style={{
                                                                              padding:
                                                                                '4px',
                                                                              width:
                                                                                '285px',
                                                                              display:
                                                                                'flex',
                                                                              border:
                                                                                '1px solid #E4E7EB',
                                                                              borderRadius:
                                                                                '4px',
                                                                              alignItems:
                                                                                'center',
                                                                              marginTop:
                                                                                '5px',
                                                                              justifyContent:
                                                                                'space-between',
                                                                              wordBreak:
                                                                                'break-all', // Breaks long words and strings
                                                                              whiteSpace:
                                                                                'normal'
                                                                            }}
                                                                          >
                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  'flex',
                                                                                alignItems:
                                                                                  'center',
                                                                                width:
                                                                                  '100%'
                                                                              }}
                                                                            >
                                                                              <DragIndicator
                                                                                sx={{
                                                                                  color:
                                                                                    '#E4E7EB',
                                                                                  marginRight: 1
                                                                                }}
                                                                              />
                                                                              {questionType.id ===
                                                                              3 ? (
                                                                                <div
                                                                                  style={{
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    borderRadius:
                                                                                      '50%',
                                                                                    border: `1px solid #E4E7EB`,
                                                                                    boxSizing:
                                                                                      'border-box',
                                                                                    marginRight: 10
                                                                                  }}
                                                                                ></div>
                                                                              ) : questionType.id ===
                                                                                7 ? (
                                                                                <div
                                                                                  style={{
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    borderRadius:
                                                                                      '25%',
                                                                                    border: `1px solid #E4E7EB`,
                                                                                    boxSizing:
                                                                                      'border-box',
                                                                                    marginRight: 10
                                                                                  }}
                                                                                ></div>
                                                                              ) : (
                                                                                <>

                                                                                </>
                                                                              )}
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    colorLinkGrey
                                                                                }}
                                                                              >
                                                                                {
                                                                                  optinList
                                                                                }
                                                                              </span>
                                                                            </div>
                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  'flex'
                                                                              }}
                                                                            >
                                                                              <IconButton
                                                                                size="small"
                                                                                onClick={() => {
                                                                                  handleLayoutDataChange(
                                                                                    sectionKey,
                                                                                    key,
                                                                                    'tempOption',
                                                                                    optinList
                                                                                  );
                                                                                  onDeleteOption(
                                                                                    sectionKey,
                                                                                    key,
                                                                                    optionIdx
                                                                                  );
                                                                                }}
                                                                                sx={{
                                                                                  padding: 0
                                                                                }}
                                                                              >
                                                                                <svg
                                                                                  width="16"
                                                                                  height="16"
                                                                                  viewBox="0 0 16 16"
                                                                                  fill="none"
                                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                  <g clipPath="url(#clip0_12740_16518)">
                                                                                    <path
                                                                                      d="M1.9172 12.0766C1.94783 11.801 1.96314 11.6632 2.00485 11.5343C2.04185 11.42 2.09413 11.3113 2.16026 11.211C2.23481 11.0979 2.33287 10.9999 2.52899 10.8037L11.3332 1.99955C12.0696 1.26317 13.2635 1.26317 13.9999 1.99955C14.7362 2.73593 14.7362 3.92984 13.9999 4.66622L5.19565 13.4704C4.99953 13.6665 4.90147 13.7646 4.78843 13.8391C4.68814 13.9053 4.57936 13.9575 4.46507 13.9945C4.33624 14.0362 4.19841 14.0516 3.92275 14.0822L1.6665 14.3329L1.9172 12.0766Z"
                                                                                      stroke="#1F2933"
                                                                                      strokeWidth="1.5"
                                                                                      strokeLinecap="round"
                                                                                      strokeLinejoin="round"
                                                                                    />
                                                                                  </g>
                                                                                  <defs>
                                                                                    <clipPath id="clip0_12740_16518">
                                                                                      <rect
                                                                                        width="16"
                                                                                        height="16"
                                                                                        fill="white"
                                                                                      />
                                                                                    </clipPath>
                                                                                  </defs>
                                                                                </svg>
                                                                              </IconButton>
                                                                              <IconButton
                                                                                size="small"
                                                                                onClick={() => {
                                                                                  onDeleteOption(
                                                                                    sectionKey,
                                                                                    key,
                                                                                    optionIdx
                                                                                  );
                                                                                }}
                                                                                sx={{
                                                                                  padding: 0
                                                                                }}
                                                                              >
                                                                                <Close
                                                                                  fontSize="small"
                                                                                  sx={{
                                                                                    fill: colorTextBlack
                                                                                  }}
                                                                                />
                                                                              </IconButton>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </Draggable>
                                                                  );
                                                                }
                                                              )}
                                                          </div>
                                                        )}
                                                      </Droppable>
                                                    </DragDropContext>
                                                    <div
                                                      style={{
                                                        display: 'flex'
                                                      }}
                                                    >
                                                      <TextField
                                                        variant="standard"
                                                        size="small"
                                                        placeholder="Add option"
                                                        sx={{ marginTop: 2 }}
                                                        value={
                                                          questionObj
                                                            ? questionObj.tempOption
                                                            : ''
                                                        }
                                                        onChange={e => {
                                                          handleLayoutDataChange(
                                                            sectionKey,
                                                            key,
                                                            'tempOption',
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                      <IconButton
                                                        onClick={() => {
                                                          if (
                                                            questionObj.tempOption ==
                                                              '' ||
                                                            questionObj.tempOption ==
                                                              null ||
                                                            questionObj.tempOption ==
                                                              undefined
                                                          ) {
                                                            setShowError(
                                                              'Add value for options'
                                                            );
                                                          } else {
                                                            onOptionAdd(
                                                              sectionKey,
                                                              key,
                                                              questionObj
                                                                ? questionObj.tempOption
                                                                : ''
                                                            );
                                                          }
                                                        }}
                                                        sx={{}}
                                                      >
                                                        {add_circle_icon()}
                                                      </IconButton>
                                                    </div>
                                                  </div>
                                                )}

                                                {questionType.id === 6 && (
                                                  <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                  >
                                                    <DatePicker
                                                      disabled
                                                      format="DD-MM-YYYY"
                                                      sx={{ marginTop: 2 }}
                                                      slotProps={{
                                                        textField: {
                                                          size: 'small',
                                                          variant: 'standard',
                                                          placeholder:
                                                            'Month/Day/Year'
                                                        }
                                                      }}
                                                      components={{
                                                        OpenPickerIcon:
                                                          props => (
                                                            <IconButton
                                                              size="small"
                                                              {...props}
                                                            >
                                                              <svg
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <path
                                                                  d="M14 6.66634H2M10.6667 1.33301V3.99967M5.33333 1.33301V3.99967M5.2 14.6663H10.8C11.9201 14.6663 12.4802 14.6663 12.908 14.4484C13.2843 14.2566 13.5903 13.9506 13.782 13.5743C14 13.1465 14 12.5864 14 11.4663V5.86634C14 4.74624 14 4.18618 13.782 3.75836C13.5903 3.38204 13.2843 3.07607 12.908 2.88433C12.4802 2.66634 11.9201 2.66634 10.8 2.66634H5.2C4.0799 2.66634 3.51984 2.66634 3.09202 2.88433C2.71569 3.07607 2.40973 3.38204 2.21799 3.75836C2 4.18618 2 4.74624 2 5.86634V11.4663C2 12.5864 2 13.1465 2.21799 13.5743C2.40973 13.9506 2.71569 14.2566 3.09202 14.4484C3.51984 14.6663 4.0799 14.6663 5.2 14.6663Z"
                                                                  stroke={
                                                                    colorTextGrey
                                                                  }
                                                                  strokeWidth="1.5"
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"
                                                                />
                                                              </svg>
                                                            </IconButton>
                                                          )
                                                      }}
                                                    />
                                                  </LocalizationProvider>
                                                )}

                                                {questionType.id === 7 && (
                                                  <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                  >
                                                    <TimePicker
                                                      disabled
                                                      sx={{ marginTop: 2 }}
                                                      slotProps={{
                                                        textField: {
                                                          size: 'small',
                                                          variant: 'standard',
                                                          placeholder: 'Time'
                                                        }
                                                      }}
                                                      components={{
                                                        OpenPickerIcon:
                                                          props => (
                                                            <IconButton
                                                              size="small"
                                                              {...props}
                                                            >
                                                              <svg
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <g clipPath="url(#clip0_5467_42319)">
                                                                  <path
                                                                    d="M7.99967 3.99967V7.99967L10.6663 9.33301M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967Z"
                                                                    stroke={
                                                                      colorTextGrey
                                                                    }
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                  />
                                                                </g>
                                                                <defs>
                                                                  <clipPath id="clip0_5467_42319">
                                                                    <rect
                                                                      width="16"
                                                                      height="16"
                                                                      fill="white"
                                                                    />
                                                                  </clipPath>
                                                                </defs>
                                                              </svg>
                                                            </IconButton>
                                                          )
                                                      }}
                                                    />
                                                  </LocalizationProvider>
                                                )}
                                              </Grid>

                                              {questionObj?.indicatorIds &&
                                                isArrayNotEmpty(
                                                  questionObj?.indicatorIds
                                                ) && (
                                                  <Grid item lg={12} mt={2}>
                                                    {/* <Typography
                                                      variant="subtitle1"
                                                      fontWeight={'bold'}
                                                    >
                                                      Indicators
                                                    </Typography> */}
                                                    <Box
                                                      display={'flex'}
                                                      flexDirection={'column'}
                                                    >
                                                      {isArrayNotEmpty(
                                                        questionObj?.indicatorIds
                                                      ) &&
                                                        questionObj?.indicatorIds.map(
                                                          (
                                                            indicators,
                                                            indiIdx
                                                          ) => {
                                                            let tempIndObj =
                                                              indicatorsList.find(
                                                                cc =>
                                                                  cc.indicatorId ===
                                                                  indicators
                                                              );

                                                            return (
                                                              <>
                                                                <Box
                                                                  key={indiIdx}
                                                                  sx={{
                                                                    display:
                                                                      'flex',
                                                                    gap: 2
                                                                  }}
                                                                >
                                                                  <Typography
                                                                    fontWeight={
                                                                      '500'
                                                                    }
                                                                    fontsize={
                                                                      '14px'
                                                                    }
                                                                    sx={{
                                                                      color:
                                                                        colorTextBlack
                                                                    }}
                                                                  >
                                                                    {`${tempIndObj?.indicatorName} :`}
                                                                  </Typography>
                                                                  <Box
                                                                    sx={{
                                                                      display:
                                                                        'flex',
                                                                      alignItems:
                                                                        'center'
                                                                    }}
                                                                  >
                                                                    <Rating
                                                                      name="simple-controlled"
                                                                      precision={
                                                                        0.5
                                                                      }
                                                                      readOnly
                                                                      // value={row?.ratings}
                                                                    />
                                                                    <IconButton
                                                                      onClick={() => {
                                                                        handleRemoveIndicator(
                                                                          sectionKey,
                                                                          key,
                                                                          indicators
                                                                        );
                                                                      }}
                                                                      sx={{
                                                                        padding: 0,
                                                                        ml: 2
                                                                      }}
                                                                    >
                                                                      <Close fontSize="small" />
                                                                    </IconButton>
                                                                  </Box>
                                                                </Box>
                                                              </>

                                                              // <Typography
                                                              //   sx={{
                                                              //     display:
                                                              //       'flex',
                                                              //     justifyContent:
                                                              //       'space-between',
                                                              //     mt: 1,
                                                              //     mr: 1,
                                                              //     fontWeight:
                                                              //       '600',
                                                              //     padding:
                                                              //       '5px',
                                                              //     background:
                                                              //       '#F5F7FA',
                                                              //     borderRadius:
                                                              //       '6px'
                                                              //   }}
                                                              //   key={indiIdx}
                                                              //   variant="caption"
                                                              // >
                                                              //   {
                                                              //     tempIndObj?.indicatorName
                                                              //   }
                                                              //   <IconButton
                                                              //     onClick={() => {
                                                              //       handleRemoveIndicator(
                                                              //         sectionKey,
                                                              //         key,
                                                              //         indicators
                                                              //       );
                                                              //     }}
                                                              //     sx={{
                                                              //       padding: 0,
                                                              //       ml: 2
                                                              //     }}
                                                              //   >
                                                              //     <Close fontSize="small" />
                                                              //   </IconButton>
                                                              // </Typography>
                                                            );
                                                          }
                                                        )}
                                                    </Box>
                                                  </Grid>
                                                )}
                                            </Grid>
                                          </Box>
                                        );
                                      }
                                    )}
                                  </Box>
                                )}
                              </Draggable>
                            );
                          })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <Box
                  bgcolor={'white'}
                  sx={{
                    float: 'inline-end',
                    borderRadius: '8px',
                    marginRight: '10px'
                  }}
                  width={'250px'}
                  padding={1}
                >
                  <Tooltip title="Add question">
                    <IconButton
                      onClick={() => {
                        onAddQuestionClick(selectedSection);
                      }}
                      sx={{}}
                    >
                      {add_circle_icon()}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add link">
                    <IconButton
                      onClick={() => {
                        setShowAttachmentModal('link');
                      }}
                    >
                      {text_icon()}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add file">
                    <IconButton
                      onClick={() => {
                        setShowAttachmentModal('file');
                      }}
                    >
                      {/* <FileIcon /> */}
                      <AttachmentIcon color="#1F2933" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add image">
                    <IconButton
                      onClick={() => {
                        setShowAttachmentModal('image');
                      }}
                      sx={{}}
                    >
                      {sceneroy_icon()}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add video">
                    <IconButton
                      onClick={() => {
                        setShowAttachmentModal('video');
                      }}
                    >
                      {player_icon()}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add section">
                    <IconButton
                      onClick={() => {
                        onAddSectionClick();
                      }}
                    >
                      {section_icon()}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid
                elevation={0}
                width={'99%'}
                height={'74px'}
                borderRadius={'8px'}
                p={'16px'}
                component={Paper}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ marginRight: 24 }}
                  sx={{
                    borderColor: colorPrimary,
                    borderRadius: '10px',
                    color: colorTextBlack,
                    borderWidth: '2px',
                    height: '42px',
                    width: '106px',
                    '&:hover': {
                      borderColor: colorPrimary,
                      backgroundColor: 'transparent',
                      borderRadius: '10px',
                      borderWidth: '2px'
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: '94px',
                    height: '42px',
                    boxShadow: 'none'
                  }}
                  // disabled={
                  //   !formLayout.formName ||
                  //   formLayout.sectionRequestList.length === 0 ||
                  //   formLayout.sectionRequestList?.[0]?.questionRequestList
                  //     .length === 0
                  // }
                  variant="contained"
                  onClick={() => {
                    handleSaveForm();
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {!loading && initialValues.type === 1 && (
        <>
          <FormResponses
            formId={location.state.formId}
            flowId={location.state?.flowId}
          />
        </>
      )}

      {showError && <SnackBarBox type="error" message={showError} />}

      <BootstrapDialog
        maxWidth="sm"
        fullWidth
        open={_.includes(
          ['image', 'video', 'file', 'link'],
          showAttachmentModal
        )}
        onClose={() => {
          setShowAttachmentModal('');
        }}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: 'white',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '26px'
          }}
          id="customized-dialog-title"
        >
          {showAttachmentModal === 'link' ? 'Insert' : 'Add'}{' '}
          {showAttachmentModal}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowAttachmentModal('');
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <AttachmentUploadComponent
            attachmentType={showAttachmentModal}
            onSave={val => handleAttachments(val)}
            onCancel={() => setShowAttachmentModal('')}
            title=""
          />
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        maxWidth="sm"
        fullWidth
        open={!!showModal}
        onClose={() => {
          setShowModal(null);
          setBase64Content('');
        }}
      >
        <DialogTitle
          sx={{
            paddingTop: 2,
            marginLeft: 1,
            backgroundColor: '#fff',
            fontSize: '20px',
            lineHeight: '26px',
            fontWeight: '700'
          }}
        >
          {showModal?.title}
        </DialogTitle>

        <DialogContent>
          <>
            <DialogContent>
              {showModal?.type === 'image' && (
                <Grid>
                  <img
                    src={base64Content}
                    alt={base64Content}
                    style={{ width: '100%', maxHeight: '80vh' }}
                  />
                </Grid>
              )}

              {showModal?.type === 'video' && (
                <Grid>
                  <video
                    controls
                    src={base64Content}
                    style={{ width: '100%', maxHeight: '80vh' }}
                  />
                </Grid>
              )}
              {showModal?.type === 'link' && (
                <Grid container direction="column">
                  <Typography variant="body1" sx={{ marginBottom: 2 }}>
                    {showModal?.link}
                  </Typography>
                  {/* <iframe
                    src={
                      'https://images.ctfassets.net/hrltx12pl8hq/28ECAQiPJZ78hxatLTa7Ts/2f695d869736ae3b0de3e56ceaca3958/free-nature-images.jpg?fit=fill&w=1200&h=630'
                    }
                    title="Link Preview"
                    style={{
                      width: '100px',
                      height: '100px',
                      border: 'none',
                      borderRadius: '8px'
                    }}
                  ></iframe> */}

                  <CustomTextLink
                    onClick={() => {
                      let url = showModal?.link;

                      if (!/^https?:\/\//i.test(url)) {
                        url = `https://${url}`;
                      }

                      // Open the URL in a new tab
                      window.open(url, '_blank', 'noopener,noreferrer');
                    }}
                    target={'_blank'}
                    title="Open link in new tab"
                  />
                </Grid>
              )}
            </DialogContent>
            <Grid container>
              <Button
                variant="outlined"
                onClick={() => setShowModal(null)}
                sx={{
                  borderColor: colorPrimary,
                  borderRadius: '10px',
                  color: colorTextBlack,
                  borderWidth: '2px',
                  height: '42px',
                  width: '106px',
                  '&:hover': {
                    borderColor: colorPrimary,
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    borderWidth: '2px'
                  }
                }}
              >
                Cancel
              </Button>
            </Grid>
          </>
        </DialogContent>
      </BootstrapDialog>

      <Dialog
        maxWidth="sm"
        open={addIndicators ? true : false}
        onClose={() => {
          setAddIndicators(null);
          setSelectedIndicators([]);
        }}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '600px',
            maxWidth: '600px'
          }
        }}
      >
        <Typography
          paddingX={3}
          paddingY={2}
          fontSize={'18px'}
          fontWeight={'bold'}
        >
          {addIndicators
            ? `Add indicators for question ${
                Number(addIndicators?.questionKey) + 1 || ''
              } `
            : 'Add Indicators'}

          {/* {`Add Indicators for question  ${addIndicators?.questionKey + 1}`} */}
          {/* {console.log(addIndicators)} */}
        </Typography>
        <Divider orientation="horizontal" />
        <DialogContent>
          <Autocomplete
            size="small"
            multiple
            options={[...indicatorsList]}
            value={selectedIndicators}
            disableCloseOnSelect
            limitTags={2}
            getOptionLabel={option => option.indicatorName}
            isOptionEqualToValue={(option, value) =>
              option.indicatorName === (value ? value.indicatorName : null)
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.indicatorName}
              </li>
            )}
            style={{ width: '100%', height: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={
                  selectedIndicators.length > 0 ? '' : 'Select indicator'
                }
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <SvgIcon
                          sx={{ width: '16px', height: '16px', marginLeft: 1 }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 14L11.6667 11.6667M13.3333 7.66667C13.3333 10.7963 10.7963 13.3333 7.66667 13.3333C4.53705 13.3333 2 10.7963 2 7.66667C2 4.53705 4.53705 2 7.66667 2C10.7963 2 13.3333 4.53705 13.3333 7.66667Z"
                              stroke="#ABB4BD"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SvgIcon>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  )
                }}
              />
            )}
            onChange={(event, newValue) => {
              setSelectedIndicators(newValue);
            }}
            ListboxProps={{
              sx: {
                '& .MuiAutocomplete-option:hover': {
                  backgroundColor: colorSecondary
                }
              }
            }}
          />
        </DialogContent>
        <Divider orientation="horizontal" />
        {/* <DialogActions > */}
        <Grid display={'flex'} gap={2} p={2} ml={1}>
          <Button
            variant="outlined"
            onClick={() => {
              setAddIndicators(null);
              setSelectedIndicators([]);
            }}
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: colorTextBlack,
              borderWidth: '2px',
              height: '42px',
              width: '106px',
              '&:hover': {
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px'
              }
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ width: '94px', height: '42px', boxShadow: 'none' }}
            disabled={selectedIndicators.length === 0}
            onClick={() => {
              handleAddIndicators(addIndicators);
            }}
          >
            <SvgIcon
              fontSize="small"
              sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                  stroke={
                    selectedIndicators.length === 0 ? colorTextGrey : '#1F2933'
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
            Add
          </LoadingButton>
        </Grid>
        {/* </DialogActions> */}
      </Dialog>

      <Dialog
        open={showFormAssigment}
        onClose={() => setShowFormAssigment(!showFormAssigment)}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '520px',
            borderRadius: '8px'
          }
        }}
      >
        <Typography paddingX={3} pt={3} fontSize={'20px'} fontWeight={'bold'}>
          Assign form
        </Typography>
        {/* <Divider orientation="horizontal" /> */}
        <DialogContent sx={{ paddingX: 3 }}>
          <UserAndUserGroupAssingmentModal
            id={location.state?.formId}
            flowId={location.state?.flowId}
            onCancelClick={() => {
              dispatch(revertUserAndUserGroupAssignment());
              setShowFormAssigment(!showFormAssigment);
            }}
            type="form"
          />
        </DialogContent>
      </Dialog>
      <BootstrapDialog
        onClose={() => setShowSuccessModal(!showSuccessModal)}
        open={showSuccessModal}
        maxWidth={'sm'}
        fullWidth
      >
        <Box sx={{ padding: '40px', display: 'block', textAlign: 'center' }}>
          <AlarmClockIcon color={colorPrimary} />

          <Typography variant="h2" fontWeight={'700'} fontSize={'24px'} mt={3}>
            Great
          </Typography>
          <Typography
            fontSize={'16px'}
            fontWeight={'600'}
            color={colorLinkGrey}
            mt={1}
          >
            Reminder sent successfully to all pending users
          </Typography>
        </Box>
      </BootstrapDialog>
      {formValidation.length > 0 && (
        <SnackBarBox
          type="error"
          message={mapArrayToTypography(formValidation)}
        />
      )}
      {!isCreateUpdateFormsLoading && isCreateUpdateFormsSuccess && (
        <SnackBarBox type="success" message={createUpdateFormsResponse} />
      )}

      {!isAssignFormLoading && isAssignFormSuccess && (
        <SnackBarBox type="success" message={assignFormResponse} />
      )}

      {!isCreateUpdateFormsLoading && isCreateUpdateFormsFail && (
        <SnackBarBox
          type="error"
          message={handleError(createUpdateFormsErrorContainer?.errorMessage)}
        />
      )}
    </>
  );
};

export default AddEditForm;
