import { Box, Typography } from '@mui/material';
import {
  colorDisableGrey,
  colorPureWhite,
  colorSecondary,
  colorTextBlack
} from '../config/theme';

const CustomTabLabel = ({ title = '', count = 0, selected }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1
      }}
    >
      <Typography variant="h4" sx={{ fontSize: '16px', fontWeight: '600' }}>
        {title}
      </Typography>
      <Typography
        sx={{
          backgroundColor: selected ? colorSecondary : colorDisableGrey,
          color: selected ? colorTextBlack : colorPureWhite,
          borderRadius: '5px',
          padding: '1px',
          fontWeight: '700',
          minWidth: '32px',
          width: '25px',
          height: '22px',
          fontSize: '14px'
        }}
      >
        {count}
      </Typography>
    </Box>
  );
};

export default CustomTabLabel;
