import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import {
  colorLinkGrey,
  colorPureWhite,
  colorSecondary,
  colorTextBlack
} from '../config/theme';
import { IMG_SPLASH_LOGO } from '../utils/imageUrls';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { passwordRecovery } from '../redux/reducers/authSlice';
import GenericInput from '../components/GenericInput';
import { enterWithNoSpaces } from '../utils/utils';
import {
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined
} from '@mui/icons-material';
import { FormButtonBottom } from '../utils/styledLayout';
import { LoadingButton } from '@mui/lab';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [open, setOpen] = useState(false);
  // constants
  const dispatch = useDispatch();

  // form style
  const formControl = {
    margin: '0.5rem auto'
  };

  // form control
  const formik = useFormik({
    initialValues: {
      username: ''
    },
    validationSchema: yup.object({
      username: yup.string().required('Username is required')
    }),
    onSubmit: value => {
      dispatch(passwordRecovery(value.username));
    }
  });

  const handleFormValue = (e, field) => {
    formik.setFieldValue(field, e.target.value);
  };

  return (
    <Grid
      sx={{
        p: 2,
        height: '100vh',
        position: 'fixed',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
        backgroundColor: colorPureWhite
      }}
    >
      <Grid>
        <Grid>
          <img src={IMG_SPLASH_LOGO} alt={IMG_SPLASH_LOGO} />
        </Grid>
        <Grid
          sx={{
            p: 3,
            py: 17
          }}
        >
          <Box>
            <Typography fontSize={'32px'} fontWeight={'700'}>
              Create New Password
            </Typography>
            <Typography sx={{ color: colorLinkGrey }} fontSize={'16px'}>
              Your new password must be different from previous used passwords.
            </Typography>
          </Box>

          <Box>
            <Box mt={5} mb={2}>
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid>
                  <InputLabel
                    sx={{
                      fontWeight: '700',
                      fontSize: '16px',
                      color: colorTextBlack
                    }}
                  >
                    Password
                  </InputLabel>
                  <FormControl sx={formControl} variant="outlined" fullWidth>
                    <TextField
                      name="password"
                      placeholder="Enter your password"
                      type={!showPassword ? 'text' : 'password'}
                      inputProps={{ maxLength: 16, minLength: 8 }}
                      value={formik.values.password}
                      onChange={e =>
                        formik.setFieldValue(
                          'password',
                          enterWithNoSpaces(e.target.value)
                        )
                      }
                      size="small"
                      error={formik.touched.password && formik.errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showPassword ? (
                              <VisibilityOffOutlined
                                sx={{ color: colorTextBlack }}
                              />
                            ) : (
                              <VisibilityOutlined
                                sx={{ color: colorTextBlack }}
                              />
                            )}
                          </InputAdornment>
                        )
                      }}
                      onEndIconClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid>
                  <InputLabel
                    sx={{
                      fontWeight: '700',
                      fontSize: '16px',
                      color: colorTextBlack
                    }}
                  >
                    Password
                  </InputLabel>
                  <FormControl sx={formControl} variant="outlined" fullWidth>
                    <TextField
                      name="confirmPassword"
                      placeholder="Enter your confirm password"
                      type={!showConfirmPassword ? 'text' : 'password'}
                      inputProps={{ maxLength: 16, minLength: 8 }}
                      value={formik.values.confirmPassword}
                      onChange={e =>
                        formik.setFieldValue(
                          'confirmPassword',
                          enterWithNoSpaces(e.target.value)
                        )
                      }
                      size="small"
                      error={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showConfirmPassword ? (
                              <VisibilityOffOutlined
                                sx={{ color: colorTextBlack }}
                              />
                            ) : (
                              <VisibilityOutlined
                                sx={{ color: colorTextBlack }}
                              />
                            )}
                          </InputAdornment>
                        )
                      }}
                      onEndIconClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    />
                  </FormControl>
                </Grid>
              </form>
            </Box>
            <Divider />
          </Box>
          <Box mt={3}>
            <Grid
              sx={formControl}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display={'flex'}
              gap={2}
            >
              <Button variant="contained" size="medium" fullWidth>
                Reset password
              </Button>
              <Button
                variant="outlined"
                sx={{ color: colorTextBlack }}
                size="medium"
                fullWidth
              >
                Back to login
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid
        sx={{
          backgroundColor: colorSecondary,
          width: '20%',
          borderRadius: '5px'
        }}
      ></Grid>
    </Grid>
  );
};

export default ResetPassword;
