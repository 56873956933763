import { useEffect } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import MyAccountForm from '../containers/myaccount/MyAccount';
import { createTitle, handleError } from '../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserDetails,
  revertAllUsersData
} from '../redux/reducers/usersSlice';
import Loader from '../components/Loader';
import {
  getArea,
  getDistrict,
  getModules,
  getState
} from '../redux/reducers/mastersSlice';
import {
  getPageData,
  getRoles,
  revertRoleFormData,
  revertRolePageData
} from '../redux/reducers/roleSlice';
import SnackBarBox from '../components/SnackBarBox';

const MyAccount = () => {
  const dispatch = useDispatch();

  const { isGetUserLoading } = useSelector(state => state.users);
  const { authData } = useSelector(state => state.auth);
  const { isRolesFailed } = useSelector(state => state.roles);
  const { isStateFailed, isDistrictFailed, isAreaFailed } = useSelector(
    state => state.masters
  );
  const { isUpadteUserDataFailed, UpadteUserDataErrorContainer } = useSelector(
    state => state.users
  );

  useEffect(() => {
    // dispatch(revertAllMyAccountDetails());
    document.title = createTitle('My Account');
    let mastersPayload = {
      type: 'all',
      page: 0,
      size: 0,
      search: ''
    };
    dispatch(getUserDetails(authData?.userId));
    dispatch(getArea(mastersPayload));
    dispatch(getState(mastersPayload));
    dispatch(getDistrict(mastersPayload));
    dispatch(getRoles(mastersPayload));
    dispatch(getModules());
    dispatch(getPageData());
    return () => {
      dispatch(revertAllUsersData());
      dispatch(revertRoleFormData());
    };
  }, []);

  return (
    <Box>
      {!isGetUserLoading && (
        <Grid>
          <Typography fontSize={32} fontWeight={'700'} mb={2}>
            My Account
          </Typography>

          <MyAccountForm />
        </Grid>
      )}

      {isGetUserLoading && <Loader text="Fetching users detail....." />}
      {(isRolesFailed || isDistrictFailed || isAreaFailed || isStateFailed) && (
        <SnackBarBox
          type={'error'}
          message={`Failed to fetch data for:- ${
            isRolesFailed
              ? 'Role'
              : isAreaFailed
                ? 'Area'
                : isStateFailed
                  ? 'State'
                  : ''
          } Master `}
        />
      )}
      {isUpadteUserDataFailed && (
        <SnackBarBox
          type={'error'}
          message={handleError(UpadteUserDataErrorContainer?.errorMessage)}
        />
      )}
    </Box>
  );
};

export default MyAccount;
