import { combineReducers } from 'redux';
import authSlice from './reducers/authSlice';
import layoutSlice from './reducers/layoutSlice';
import sessionSlice from './reducers/sessionSlice';
import usersSlice from './reducers/usersSlice';
import mastersSlice from './reducers/mastersSlice';
import rolesSlice from './reducers/roleSlice';
import userGroupsSlice from './reducers/userGroupsSlice';
import rubricSlice from './reducers/rubricSlice';
import flowsSlice from './reducers/flowsSlice';
import observationSlice from './reducers/observationSlice';
import analyticsSlice from './reducers/analyticsSlice';

const reducers = {
  auth: authSlice,
  layout: layoutSlice,
  session: sessionSlice,
  users: usersSlice,
  userGroups: userGroupsSlice,
  masters: mastersSlice,
  roles: rolesSlice,
  rubric: rubricSlice,
  flow: flowsSlice,
  observation: observationSlice,
  analytics: analyticsSlice
};

export default combineReducers(reducers);
