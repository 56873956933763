import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';

const intial = {
  evidenceList: [],
  isCreateUpdateObservationLoading: false,
  isCreateUpdateObservationSuccess: false,
  isCreateUpdateObservationFailed: false,
  createUpdateObservationResponse: '',
  createUpdateObservationErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isObservationsLoading: false,
  isObservationsSuccess: false,
  isObservationsFailed: false,
  observationData: { totalCount: 0, observation: [] },
  observationErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isObservationDetailLoading: false,
  isObservationDetailSuccess: false,
  isObservationDetailFailed: false,
  observationDetail: null,
  observationDetailErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isEvidenceDetailLoading: false,
  isEvidenceDetailSuccess: false,
  isEvidenceDetailFailed: false,
  evidenceDetail: null,
  evidenceDetailErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateEvidenceLoading: false,
  isCreateUpdateEvidenceSuccess: false,
  isCreateUpdateEvidenceFailed: false,
  createUpdateEvidenceResponse: '',
  createUpdateEvidenceResponseData: null,
  createUpdateEvidenceErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  deleteEvidenceLoading: false,
  deleteEvidenceSuccess: false,
  deleteEvidenceFailed: false,
  deleteEvidenceResponse: null,
  deleteEvidenceErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  deleteObservationLoading: false,
  deleteObservationSuccess: false,
  deleteObservationFailed: false,
  deleteObservationErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const createUpdateEvidence = createAsyncThunk(
  'observation/createUpdateEvidence',
  async (
    { payload: payload, evidenceId: evidenceId },
    { rejectWithValue, getState }
  ) => {
    try {
      let response;

      if (!evidenceId) {
        response = await multipartApi.post(`teachers/saveEvidence`, payload);
      } else {
        response = await multipartApi.put(
          `teachers/updateEvidence/${evidenceId}`,
          payload
        );
      }

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getObservations = createAsyncThunk(
  'observation/getObservations',
  async ({ payload, filterPayload }, { rejectWithValue, getState }) => {
    try {
      let response;
      response = await api.post(
        `teachers/observation/getAllObservation/search/${getState().auth.authData.userId}`,
        payload
      );
      // if (!filterPayload) {
      //   response = await api.post(
      //     `teachers/observation/getAllObservation/${
      //       getState().auth.authData.userId
      //     }`,
      //     payload
      //   );
      // } else {
      //   response = await api.post(
      //     `teachers/observation/getAllObservation/search/${
      //       getState().auth.authData.userId
      //     }`,
      //     filterPayload
      //   );
      // }
      return response.data.payload || intial.observationData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getObservationsDetail = createAsyncThunk(
  'observation/getObservationsDetail',
  async (observationId, { rejectWithValue }) => {
    try {
      const response = await api.get(`teachers/observation/${observationId}`);
      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getEvidenceDetails = createAsyncThunk(
  'observation/getEvidenceDetails',
  async (evidenceId, { rejectWithValue }) => {
    try {
      const response = await api.get(`teachers/evidence/${evidenceId}`);
      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteAttachment = createAsyncThunk(
  'observation/deleteAttachment',
  async (attachmentId, { rejectWithValue, getState }) => {
    try {
      let userName = getState().auth?.authData?.userName;
      const response = await api.delete(`teachers/deleteAttachment`, {
        data: { ids: [attachmentId], loggedInUserName: userName }
      });
      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteObservation = createAsyncThunk(
  'observation/deleteObservation',
  async (observationId, { rejectWithValue, getState }) => {
    try {
      let userName = getState().auth?.authData?.userName;
      const response = await api.delete(`teachers/deleteObservation`, {
        data: { ids: [observationId], loggedInUserName: userName }
      });
      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUpdateObservation = createAsyncThunk(
  'observation/createUpdateObservation',
  async (
    { payload: payload, observationId: observationId },
    { rejectWithValue }
  ) => {
    try {
      let response;

      if (!observationId) {
        response = await api.post(`teachers/saveObservation`, payload);
      } else {
        response = await api.put(
          `teachers/updateObservation/${observationId}`,
          payload
        );
      }
      return response.data.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const revertEvidenceFormData = createAction('REVERT_EVIDENCE_FORM');
export const revertObservationFormData = createAction(
  'REVERT_OBSERVATION_FORM'
);
export const SET_EVIDENCE_LIST = createAction('SET_EVIDENCE_LIST');

export const observationSlice = createSlice({
  name: 'observation',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(revertObservationFormData, (state, action) => {
        state.isCreateUpdateObservationLoading =
          intial.isCreateUpdateObservationLoading;
        state.isCreateUpdateObservationSuccess =
          intial.isCreateUpdateObservationSuccess;
        state.isCreateUpdateObservationFailed =
          intial.isCreateUpdateObservationFailed;
        state.createUpdateObservationResponse =
          intial.createUpdateObservationResponse;
        state.createUpdateObservationErrorContainer =
          intial.createUpdateObservationErrorContainer;
        state.evidenceList = intial.evidenceList;
        state.isObservationDetailLoading = intial.isObservationDetailLoading;
        state.isObservationDetailFailed = intial.isObservationDetailFailed;
        state.isObservationDetailSuccess = intial.isObservationDetailSuccess;
        state.observationDetail = intial.observationDetail;
        state.observationDetailErrorContainer =
          intial.observationDetailErrorContainer;

        state.deleteObservationLoading = intial.deleteObservationLoading;
        state.deleteObservationFailed = intial.deleteObservationFailed;
        state.deleteObservationSuccess = intial.deleteObservationSuccess;
        state.deleteObservationErrorContainer =
          intial.deleteObservationErrorContainer;
      })
      .addCase(SET_EVIDENCE_LIST, (state, action) => {
        state.evidenceList = action.payload;
      })
      .addCase(revertEvidenceFormData, state => {
        state.isCreateUpdateEvidenceLoading = false;
        state.isCreateUpdateEvidenceSuccess = false;
        state.isCreateUpdateEvidenceFailed = false;
        state.createUpdateEvidenceErrorContainer =
          intial.createUpdateEvidenceErrorContainer;
        state.createUpdateEvidenceResponse =
          intial.createUpdateEvidenceResponse;
        state.createUpdateEvidenceResponseData =
          intial.createUpdateEvidenceResponseData;

        state.deleteEvidenceLoading = false;
        state.deleteEvidenceSuccess = false;
        state.deleteEvidenceFailed = false;
        state.deleteEvidenceErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.isEvidenceDetailLoading = false;
        state.isEvidenceDetailSuccess = false;
        state.isEvidenceDetailFailed = false;
        state.evidenceDetail = intial.evidenceDetail;
        state.evidenceDetailErrorContainer =
          intial.evidenceDetailErrorContainer;
      })
      .addCase(getObservations.pending, state => {
        state.isObservationsLoading = true;
        state.isObservationsSuccess = false;
        state.isObservationsFailed = false;
        state.observationData = intial.observationData;
        state.observationErrorContainer = intial.observationErrorContainer;
      })
      .addCase(getObservations.fulfilled, (state, action) => {
        state.isObservationsLoading = false;
        state.isObservationsSuccess = true;
        state.isObservationsFailed = false;
        state.observationData = {
          totalCount: action.payload.totalCount,
          observation: action.payload.dataList
        };
        state.observationErrorContainer = intial.observationErrorContainer;
      })
      .addCase(getObservations.rejected, (state, action) => {
        state.isObservationsLoading = false;
        state.isObservationsSuccess = false;
        state.isObservationsFailed = true;
        state.observationData = intial.observationData;
        state.observationErrorContainer = {
          ...state.observationErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateEvidence.pending, state => {
        state.isCreateUpdateEvidenceLoading = true;
        state.isCreateUpdateEvidenceSuccess = false;
        state.isCreateUpdateEvidenceFailed = false;
        state.createUpdateEvidenceErrorContainer =
          intial.createUpdateEvidenceErrorContainer;
      })
      .addCase(createUpdateEvidence.fulfilled, (state, action) => {
        state.isCreateUpdateEvidenceLoading = false;
        state.isCreateUpdateEvidenceSuccess = true;
        state.isCreateUpdateEvidenceFailed = false;
        state.createUpdateEvidenceResponseData = {
          evidenceId: action.payload['evidenceId'],
          domainId: action.payload['domainId'],
          domainName: action.payload['domainName'],
          indicatorId: action.payload['indicatorId'],
          indicatorName: action.payload['indicatorName'],
          attachmentResponse: action.payload['attachmentResponse'],
          averageRating: action.payload['averageRating'],
          fileCount: action.payload['fileCount']
        };

        const newEvidence = {
          evidenceId: action.payload['evidenceId'],
          domainName: action.payload['domainName'],
          indicatorName: action.payload['indicatorName'],
          averageRating: action.payload['averageRating'],
          attachmentResponse: action.payload['attachmentResponse'],
          fileCount: action.payload['fileCount']
        };

        const existingIndex = state.evidenceList.findIndex(
          evidence => evidence.evidenceId === newEvidence.evidenceId
        );

        if (existingIndex !== -1) {
          state.evidenceList[existingIndex] = newEvidence;
        } else {
          state.evidenceList.push(newEvidence);
        }

        state.createUpdateEvidenceResponse = action.payload?.message;
      })
      .addCase(createUpdateEvidence.rejected, (state, action) => {
        state.isCreateUpdateEvidenceLoading = false;
        state.isCreateUpdateEvidenceSuccess = false;
        state.isCreateUpdateEvidenceFailed = true;
        state.createUpdateEvidenceResponse =
          intial.createUpdateEvidenceResponse;
        state.createUpdateEvidenceResponseData =
          intial.createUpdateEvidenceResponseData;
        state.createUpdateEvidenceErrorContainer = {
          ...state.createUpdateEvidenceErrorContainer,
          ...action.payload
        };
      })
      .addCase(getEvidenceDetails.pending, state => {
        state.isEvidenceDetailLoading = true;
        state.isEvidenceDetailSuccess = false;
        state.isEvidenceDetailFailed = false;
        state.evidenceDetail = null;
        state.evidenceDetailErrorContainer =
          intial.evidenceDetailErrorContainer;
      })
      .addCase(getEvidenceDetails.fulfilled, (state, action) => {
        state.isEvidenceDetailLoading = false;
        state.isEvidenceDetailSuccess = true;
        state.isEvidenceDetailFailed = false;
        state.evidenceDetail = action.payload;
        state.evidenceDetailErrorContainer =
          intial.evidenceDetailErrorContainer;
      })
      .addCase(getEvidenceDetails.rejected, (state, action) => {
        state.isEvidenceDetailLoading = false;
        state.isEvidenceDetailSuccess = false;
        state.isEvidenceDetailFailed = true;
        state.evidenceDetailErrorContainer = {
          ...state.evidenceDetailErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteAttachment.pending, state => {
        state.deleteEvidenceLoading = true;
        state.deleteEvidenceSuccess = false;
        state.deleteEvidenceFailed = false;
        state.deleteEvidenceErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteAttachment.fulfilled, state => {
        state.deleteEvidenceLoading = false;
        state.deleteEvidenceFailed = false;
        state.deleteEvidenceSuccess = true;
      })
      .addCase(deleteAttachment.rejected, (state, action) => {
        state.deleteEvidenceLoading = false;
        state.deleteEvidenceSuccess = false;
        state.deleteEvidenceFailed = true;
        state.deleteEvidenceErrorContainer = {
          ...state.deleteEvidenceErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateObservation.pending, state => {
        state.isCreateUpdateObservationLoading = true;
        state.isCreateUpdateObservationSuccess = false;
        state.isCreateUpdateObservationFailed = false;
        state.createUpdateObservationErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateObservation.fulfilled, (state, action) => {
        state.isCreateUpdateObservationLoading = false;
        state.isCreateUpdateObservationFailed = false;
        state.isCreateUpdateObservationSuccess = true;
        state.createUpdateObservationResponse = action.payload?.message;
      })
      .addCase(createUpdateObservation.rejected, (state, action) => {
        state.isCreateUpdateObservationLoading = false;
        state.isCreateUpdateObservationSuccess = false;
        state.isCreateUpdateObservationFailed = true;
        state.createUpdateObservationErrorContainer = {
          ...state.createUpdateObservationErrorContainer,
          ...action.payload
        };
      })
      .addCase(deleteObservation.pending, state => {
        state.deleteObservationLoading = true;
        state.deleteObservationSuccess = false;
        state.deleteObservationFailed = false;
        state.deleteObservationErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteObservation.fulfilled, state => {
        state.deleteObservationLoading = false;
        state.deleteObservationFailed = false;
        state.deleteObservationSuccess = true;
      })
      .addCase(deleteObservation.rejected, (state, action) => {
        state.deleteObservationLoading = false;
        state.deleteObservationSuccess = false;
        state.deleteObservationFailed = true;
        state.deleteObservationErrorContainer = {
          ...state.deleteObservationErrorContainer,
          ...action.payload
        };
      })

      .addCase(getObservationsDetail.pending, state => {
        state.isObservationDetailLoading = true;
        state.isObservationDetailSuccess = false;
        state.isObservationDetailFailed = false;
        state.observationDetail = null;
        state.observationDetailErrorContainer =
          intial.observationDetailErrorContainer;
      })
      .addCase(getObservationsDetail.fulfilled, (state, action) => {
        state.isObservationDetailLoading = false;
        state.isObservationDetailSuccess = true;
        state.isObservationDetailFailed = false;
        state.observationDetail = action.payload;
        state.observationDetailErrorContainer =
          intial.observationDetailErrorContainer;
        state.evidenceList = action.payload['evidenceResponseList'];
      })
      .addCase(getObservationsDetail.rejected, (state, action) => {
        state.isObservationDetailLoading = false;
        state.isObservationDetailSuccess = false;
        state.isObservationDetailFailed = true;
        state.observationDetailErrorContainer = {
          ...state.observationDetailErrorContainer,
          ...action.payload
        };
      });
  }
});

export default observationSlice.reducer;
