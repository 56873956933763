import {
  AccessTime,
  CalendarToday,
  CheckBoxOutlined,
  ExpandCircleDown,
  RadioButtonChecked,
  ShortText,
  Subject
} from '@mui/icons-material';
import {
  FileIcon,
  MIC_ICON,
  player_icon,
  sceneroy_icon,
  text_icon
} from './imageUrls';

export const imageAllowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
export const videoAllowedTypes = ['video/mp4'];
export const fileAllowedTypes = ['text/plain', 'text/csv'];
export const CaptureImageAllowedTypes = ['image/jpeg', 'image/jpg'];
export const UploadDocumentAllowedTypes = [
  'image/jpeg',
  'image/jpg',
  'application/pdf'
];
export const imageAllowedExtention = ['jpg', 'jpeg', 'png'];
export const videoAllowedExtention = ['mp4'];
export const fileAllowedExtention = ['csv', 'txt'];
export const UploadDocumentAllowedExtention = ['jpg', 'jpeg', 'pdf'];
export const CaptureImageAllowedExtention = ['jpg', 'jpeg'];
export const IMAGE_SIZE_LIMIT_MESSAGE = `Max size is 5 MB.`;
export const OCR_IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_BORROWER_MULTIPART_FILE_SIZE_LIMIT_OCR} MB.`;
export const SELECT_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG, PNG format.`;
export const UPLOAD_DOCUMENT_SELECT_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG or PDF format.`;
export const SELECT_VIDEO_MESSAGE = `Please upload an video in mp4 format.`;
export const SELECT_FILE_MESSAGE = `Please upload an file in either CSV, TXT format.`;
export const BORROWER_IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_BORROWER_MULTIPART_FILE_SIZE_LIMIT_OCR} MB.`;
export const SELECT_BORROWER_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG, PNG, or PDF format.`;
export const BORROWER_IMAGE_ALLOWED_TYPE = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf'
];
export const BORROWER_IMAGE_EXTENSION = ['jpg', 'jpeg', 'png', 'pdf'];

export const NET_WORTH_MANDATORY_LIMIT = 1000000;

export const USER_TYPE = 'User';
export const RERECTED_TO_THE_LOGIN_PAGE =
  'You will be redirected to the login page';
export const GO_BACK = 'Go Back';

export const PASSWORD_RESET_TYPE = 'password';
export const PIN_RESET_TYPE = 'pin';
export const CREATE_ACCOUNT = 'Create Account';
export const UNEXPECTED_ERROR =
  'It appears that there is an error on the server.';

export const INVESTOR_REQUIREMENT = 'investorRequirements';
export const PAN_VERIFICATION = 'panVerification';
export const AADHAR_VERIFICATION = 'aadhaarVerification';
export const UPLOAD_LATEST_PHOTO = 'photoUpload';
export const PENNY_DROP_VERIFICATION = 'bankAccountVerification';
export const NOMINEE_DETAILS = 'nomineeDetails';
export const DOCUMENT_UPLOAD = 'documentUpload';
export const INVESTOR_SUMMARY = 'investorSummary';

export const BORROWER_REQUIREMENTS = 'borrowerRequirements';

export const USER_TYPE_INVESTOR = 'Investor';

export const USER_TYPE_BORROWER = 'Borrower';

export const OTP_VERIFICATION = 'otpVerification';
export const ADD_BASIC_DETAILS = 'addBasicDetails';
export const PASSWORD_SETUP = 'passwordSetup';
export const PIN_SETUP = 'pinSetup';
export const FINISH = 'finish';

export const LEND_BOX_INVESTOR_INITIAL = 'initial';
export const LEND_BOX_INVESTOR_REGISTRATION_STAGE = 'investorRegistration';
export const LEND_BOX_INVESTOR_DOCUMENT_UPLOAD = 'documentUpload';
export const LEND_BOX_INVESTOR_WALLET_ORDER = 'walletOrder';

export const LEND_BOX_REGISTRATION = 'lendboxRegistration';
export const LEND_BOX_E_SIGNING = 'eSigning';

export const PAYMENT_INITIATED = 'paymentInitiated';

export const CAPTURE_IMAGE = 'Capture image';

export const investorSteps = [
  INVESTOR_REQUIREMENT,
  PAN_VERIFICATION,
  AADHAR_VERIFICATION,
  UPLOAD_LATEST_PHOTO,
  DOCUMENT_UPLOAD,
  PENNY_DROP_VERIFICATION,
  NOMINEE_DETAILS
];

export const borrowerSteps = [
  BORROWER_REQUIREMENTS,
  PAN_VERIFICATION,
  AADHAR_VERIFICATION,
  UPLOAD_LATEST_PHOTO,
  DOCUMENT_UPLOAD,
  PENNY_DROP_VERIFICATION
];

export const createUserSteps = [
  ADD_BASIC_DETAILS,
  OTP_VERIFICATION,
  PASSWORD_SETUP,
  PIN_SETUP
];

export const SELECT_NEXT_BUTTON =
  'Clicking "Save & Next" will save your details.';
export const SELECT_NOTIFY_BUTTON =
  'Clicking "Save & Notify" will trigger a mail.';
export const SELECT_BACK_BUTTON = 'Click "Back" to view your details.';

export const DOC_TYPE_PAN = 'pan';
export const DOC_TYPE_AADHAAR = 'aadhaar';
export const DOC_TYPE_NET_WORTH_CERT = 'networth';
export const DOC_TYPE_AADHAAR_FRONT = 'aadhaarFront';
export const DOC_TYPE_AADHAAR_BACK = 'aadhaarBack';
export const DOC_TYPE_PHOTO = 'photo';
export const AADHAR_OCR_FRONT = 'aadhaarFront';
export const AADHAR_OCR_BACK = 'aadhaarBack';
export const DOC_TYPE_CHEQUE_OCR = 'cheque';

export const documentTypes = [
  DOC_TYPE_PAN,
  DOC_TYPE_AADHAAR_FRONT,
  DOC_TYPE_AADHAAR_BACK,
  DOC_TYPE_NET_WORTH_CERT
];

export const drawerWidth = 240;

export const userTypesList = [
  { label: 'PAF', value: 'PAF' },
  { label: 'Registered', value: 'Registered' }
];

export const ADMIN_ROLE_TYPE = 'Admin';

export const DASHBOARD_MENUS = [
  {
    menu_name: 'User Management',
    menu_desc: 'User Management',
    menu_content:
      'User management ensures efficient administration, security and personalization of user accounts and access within a system.',
    subMenu: [
      {
        menu_name: 'Roles and App Access',
        menu_desc: 'Create role',
        path: '/roles/new-role'
      },
      {
        menu_name: 'User Groups',
        menu_desc: 'Create user groups',
        path: '/user-groups/new-user-group'
      },
      {
        menu_name: 'Users',
        menu_desc: 'Add user',
        path: '/users/new-user'
      }
    ]
  },
  {
    menu_name: 'Location Management',
    menu_desc: 'Location Management',
    menu_content:
      'Location management ensures accurate, efficient, and optimized use of geographic resources and data.',
    subMenu: [
      {
        menu_name: 'Schools',
        menu_desc: 'Add new class',
        path: '/schools'
      },
      {
        menu_name: 'Schools',
        menu_desc: 'Create new school',
        path: '/schools/new-school'
      }
    ]
  },
  {
    menu_name: 'Analytics',
    menu_desc: 'Analytics',
    menu_content:
      'Analytics provides insights and data-driven decisions to optimize performance and achieve strategic goals.',
    subMenu: [
      {
        menu_name: 'User & Role Analytics',
        menu_desc: 'Product Analytics',
        path: '/product-analytics/user-and-role-analytics'
      },
      {
        menu_name: 'Usage Analytics',
        menu_desc: 'Usage Analytics',
        path: '/usage-analytics'
      }
    ]
  },
  {
    menu_name: 'Schedules',
    menu_desc: 'Schedules',
    menu_content:
      'Calendar organizes and schedules events, appointments, and tasks to manage time effectively.',
    subMenu: [
      {
        menu_name: 'Schedules',
        menu_desc: 'Share calendar',
        path: '/schedules'
      },
      {
        menu_name: 'Schedules',
        menu_desc: 'Create new event',
        path: '/schedules'
      }
    ]
  }
];

export const FLOWS_FORMS_QUESTION_TYPE_SELECTOR = [
  {
    id: 4,
    label: 'Short Answer'
  },
  {
    id: 5,
    label: 'Long Answer'
  },
  {
    id: 3,
    label: 'Multiple Choice'
  },
  {
    id: 6,
    label: 'Date'
  },
  {
    id: 7,
    label: 'Time'
  },
  {
    id: 1,
    label: 'Drop Down'
  },
  {
    id: 2,
    label: 'Check box'
  },
  { id: 8, label: 'Linear scale' }
];

export const iconMapFlows = {
  4: <ShortText />,
  5: <Subject />,
  3: <RadioButtonChecked />,
  6: <CalendarToday />,
  7: <AccessTime />,
  1: <ExpandCircleDown />,
  2: <CheckBoxOutlined />
};

export const ROLE_LEVEL = ['PAF Central Office', 'District', 'Area'];
export const FLOWS_STATUS = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' }
];

export const attachmentType = {
  image: 'Image',
  video: 'Video',
  file: 'File',
  link: 'Link'
};

export const FORM_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png'];
export const FORM_FILE_EXTENSIONS = ['txt', 'csv'];

export const TEACHER_ROLE_TYPE = ['Teacher'];
export const UPLOAD_FILES_ALLOWED_EXTENSION = [
  '.jpg',
  '.jpeg',
  '.png',
  '.mp4',
  '.csv',
  '.txt',
  '.mp3'
];
export const uploadFilesAcceptedExtensions = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'text/plain',
  'text/csv',
  'video/mp4',
  'audio/mpeg'
];

export const uploadFileType = {
  'image/jpeg': 'jpeg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'application/pdf': 'pdf',
  'text/plain': 'txt',
  'text/csv': 'csv',
  'video/mp4': 'mp4',
  'audio/mpeg': 'mp3'
};

export const uploadFileTypeIcon = {
  jpeg: sceneroy_icon(),
  jpg: sceneroy_icon(),
  png: sceneroy_icon(),
  pdf: FileIcon(''),
  txt: FileIcon(''),
  csv: FileIcon(''),
  mp4: player_icon(),
  mp3: MIC_ICON('')
};

export const formsFileTypeIcon = {
  image: sceneroy_icon(),
  file: FileIcon(''),
  link: text_icon(),
  video: player_icon('')
};

export const formsFileType = {
  'image/jpeg': 'image',
  'image/jpg': 'image',
  'image/png': 'image',
  'application/pdf': 'file',
  'text/plain': 'file',
  'text/csv': 'file',
  'video/mp4': 'video'
};

export const TOKEN_KEY = 'google_calendar_access_token';
export const SCOPES = 'https://www.googleapis.com/auth/calendar';
export const TIMEZONE = 'Asia/Kolkata';
export const GOOGLE_ACCOUNT_INFO = 'GOOGLE_ACCOUNT_INFO';
