import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';

const intial = {
  isRolesLoading: false,
  isRolesSuccess: false,
  isRolesFailed: false,
  rolesData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    roles: []
  },
  rolesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isRoleGroupsLoading: false,
  isRoleGroupsSuccess: false,
  isRoleGroupsFailed: false,
  roleGroupsData: [],
  rolesGroupErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isActivateDeactivateRolesSuccess: false,
  isActivateDeactivateRolesFailed: false,
  activateDeactivateRolesResponse: '',
  activateDeactivateRolesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeleteRolesSuccess: false,
  isDeleteRolesFailed: false,
  deleteRolesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isPageDataLoading: false,
  isPageDataSuccess: false,
  isPageDataFailed: false,
  pageData: [],
  pageDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMenuDataLoading: false,
  isMenuDataSuccess: false,
  isMenuDataFailed: false,
  menuData: [],
  menuDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isRoleByIdLoading: false,
  isRoleByIdSuccess: false,
  isRoleByIdFailed: false,
  roleByIdData: null,
  roleByIdDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateRoleLoading: false,
  isCreateUpdateRoleSuccess: false,
  isCreateUpdateRoleFailed: false,
  isCreateUpdateRoleResponse: '',
  createUpdateRoleErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const revertRolePageData = createAction('REVERT_ROLES_PAGE_DATA');
export const revertRoleFormData = createAction('REVERT_ROLES_FORM_DATA');

export const getRoles = createAsyncThunk(
  'roles/getRoles',
  async (rolesPayload, { rejectWithValue }) => {
    try {
      let response;

      if (rolesPayload?.search == '' || rolesPayload?.search == null) {
        response = await api.post(`metadata/roles`, rolesPayload);
      } else {
        response = await api.post(
          `roles/search?searchCriteria=${rolesPayload?.search}`,
          rolesPayload
        );
      }
      return response.data?.payload || intial.rolesData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getRoleGroups = createAsyncThunk(
  'roles/getRoleGroups',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('metadata/roleGroups');

      if (response.data.payload) {
        return Object.entries(response.data.payload).map(([label, items]) => ({
          label,
          id: label,
          others: Object.entries(items).map(([id, value]) => ({ id, value }))
        }));
      } else return [];
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getPageData = createAsyncThunk(
  'roles/getPageData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('metadata/pages');
      return response.data?.payload || intial.pageData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getMenuData = createAsyncThunk(
  'roles/getMenuData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('metadata/modules');
      return response.data?.payload || intial.menuData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const activateDeactivateRoles = createAsyncThunk(
  'roles/activateDeactivateRoles',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`roles/activate`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteRoles = createAsyncThunk(
  'master/deleteRoles',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.delete(`roles`, {
        data: payload
      });
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getRoleDataById = createAsyncThunk(
  'roles/getRoleDataById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`roles/${id}`);
      return response.data?.payload?.dataList || intial.roleByIdData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUpdateRole = createAsyncThunk(
  'roles/createUpdateRole',
  async ({ payload, roleId }, { rejectWithValue }) => {
    try {
      let response;
      if (roleId) {
        response = await api.put(`roles/${roleId}`, payload);
      } else {
        response = await api.post(`roles`, payload);
      }

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getRolesCount = createAsyncThunk(
  'roles/getRolesCount',
  async (rolesPayload, { rejectWithValue }) => {
    try {
      let response = await api.post(`metadata/roles`, {
        type: 'all',
        page: 0,
        size: 10
      });

      return response.data?.payload || intial.rolesData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const roleSlice = createSlice({
  name: 'role',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(revertRoleFormData, state => {
        state.isCreateUpdateRoleLoading = false;
        state.isCreateUpdateRoleSuccess = false;
        state.isCreateUpdateRoleFailed = false;
        state.createUpdateRoleErrorContainer =
          intial.createUpdateRoleErrorContainer;

        state.roleByIdData = intial.roleByIdData;
        // state.roleByIdData = intial.roleByIdData;
      })
      .addCase(revertRolePageData, state => {
        // state.isRolesSuccess = false;
        // state.isRolesFailed = false;
        // state.rolesData = intial.rolesData;
        // state.rolesErrorContainer = intial.rolesErrorContainer;

        state.isActivateDeactivateRolesFailed = false;
        state.isActivateDeactivateRolesSuccess = false;
        state.activateDeactivateRolesResponse = '';
        state.activateDeactivateRolesErrorContainer =
          intial.activateDeactivateRolesErrorContainer;

        state.isDeleteRolesFailed = false;
        state.isDeleteRolesSuccess = false;
        state.deleteRolesErrorContainer = intial.deleteRolesErrorContainer;
      })
      .addCase(getRoles.pending, state => {
        state.isRolesLoading = true;
        state.isRolesSuccess = false;
        state.isRolesFailed = false;
        state.rolesErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.isRolesLoading = false;
        state.isRolesSuccess = true;
        state.rolesData = {
          ...state.rolesData,
          roles: action.payload.dataList,
          totalCount: action.payload.totalCount
        };
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.isRolesLoading = false;
        state.isRolesFailed = true;
        state.rolesData = intial.rolesData;
        state.rolesErrorContainer = {
          ...state.rolesErrorContainer,
          ...action.payload
        };
      })

      .addCase(getRoleGroups.pending, state => {
        state.isRoleGroupsLoading = true;
        state.isRoleGroupsSuccess = false;
        state.isRoleGroupsFailed = false;
      })
      .addCase(getRoleGroups.fulfilled, (state, action) => {
        state.isRoleGroupsLoading = false;
        state.isRoleGroupsSuccess = true;
        state.roleGroupsData = action.payload;
      })
      .addCase(getRoleGroups.rejected, state => {
        state.isRoleGroupsLoading = false;
        state.isRoleGroupsFailed = true;
        state.roleGroupsData = [];
      })

      .addCase(activateDeactivateRoles.pending, state => {
        state.isRolesLoading = true;
        state.isActivateDeactivateRolesSuccess = false;
        state.isActivateDeactivateRolesFailed = false;
      })
      .addCase(activateDeactivateRoles.fulfilled, (state, action) => {
        // state.isRolesLoading = false;
        state.isActivateDeactivateRolesSuccess = true;
        state.isActivateDeactivateRolesFailed = false;
        state.activateDeactivateRolesResponse = action.payload?.message;
      })
      .addCase(activateDeactivateRoles.rejected, (state, action) => {
        state.isRolesLoading = false;
        state.isActivateDeactivateRolesFailed = true;
        state.activateDeactivateRolesResponse = action.payload?.message;
        state.activateDeactivateRolesErrorContainer = {
          ...state.activateDeactivateRolesErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteRoles.pending, state => {
        state.isRolesLoading = true;
        state.isDeleteRolesSuccess = false;
        state.isDeleteRolesFailed = false;
      })
      .addCase(deleteRoles.fulfilled, state => {
        state.isDeleteRolesSuccess = true;
        state.isDeleteRolesFailed = false;
      })
      .addCase(deleteRoles.rejected, (state, action) => {
        state.isRolesLoading = false;
        state.isDeleteRolesFailed = true;
        state.deleteRolesErrorContainer = {
          ...state.deleteRolesErrorContainer,
          ...action.payload
        };
      })

      .addCase(getPageData.pending, state => {
        state.isPageDataLoading = true;
        state.isPageDataSuccess = false;
        state.isPageDataFailed = false;
        state.pageData = intial.pageData;
      })
      .addCase(getPageData.fulfilled, (state, action) => {
        state.isPageDataLoading = false;
        state.isPageDataSuccess = true;
        state.isPageDataFailed = false;
        state.pageData = action.payload;
      })
      .addCase(getPageData.rejected, (state, action) => {
        state.isPageDataLoading = false;
        state.isPageDataFailed = true;
        state.pageDataErrorContainer = {
          ...state.pageDataErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMenuData.pending, state => {
        state.isMenuDataLoading = false;
        state.isMenuDataSuccess = false;
        state.isMenuDataFailed = false;
        state.menuData = intial.menuData;
      })
      .addCase(getMenuData.fulfilled, (state, action) => {
        state.isMenuDataLoading = false;
        state.isMenuDataSuccess = true;
        state.isMenuDataFailed = false;
        state.menuData = action.payload;
      })
      .addCase(getMenuData.rejected, (state, action) => {
        state.isMenuDataLoading = false;
        state.isMenuDataFailed = true;
        state.menuDataErrorContainer = {
          ...state.menuDataErrorContainer,
          ...action.payload
        };
      })

      .addCase(getRoleDataById.pending, state => {
        state.isRoleByIdLoading = false;
        state.isRoleByIdSuccess = false;
        state.isRoleByIdFailed = false;
        state.roleByIdData = intial.roleByIdData;
      })
      .addCase(getRoleDataById.fulfilled, (state, action) => {
        state.isRoleByIdLoading = false;
        state.isRoleByIdSuccess = true;
        state.isRoleByIdFailed = false;
        state.roleByIdData = action.payload;
      })
      .addCase(getRoleDataById.rejected, (state, action) => {
        state.isRoleByIdLoading = false;
        state.isRoleByIdFailed = true;
        state.roleByIdDataErrorContainer = {
          ...state.roleByIdDataErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateRole.pending, state => {
        state.isCreateUpdateRoleLoading = true;
        state.isCreateUpdateRoleSuccess = false;
        state.isCreateUpdateRoleFailed = false;
        state.createUpdateRoleErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateRole.fulfilled, (state, action) => {
        state.isCreateUpdateRoleLoading = false;
        state.isCreateUpdateRoleSuccess = true;
        state.isCreateUpdateRoleFailed = false;
        state.isCreateUpdateRoleResponse = action.payload?.message;
      })
      .addCase(createUpdateRole.rejected, (state, action) => {
        state.isCreateUpdateRoleLoading = false;
        state.isCreateUpdateRoleSuccess = false;
        state.isCreateUpdateRoleFailed = true;
        state.createUpdateRoleErrorContainer = {
          ...state.createUpdateRoleErrorContainer,
          ...action.payload
        };
      })
      .addCase(getRolesCount.pending, state => {
        state.rolesData.allCount = 0;
        state.rolesData.activeCount = 0;
        state.rolesData.inActiveCount = 0;
      })
      .addCase(getRolesCount.fulfilled, (state, action) => {
        // console.log(action.payload, 'payload');
        state.rolesData = {
          ...state.rolesData,
          allCount: action.payload?.allCount,
          activeCount: action.payload?.activeCount,
          inActiveCount: action.payload?.inActiveCount
        };
      })
      .addCase(getRolesCount.rejected, (state, action) => {
        state.rolesData.allCount = 0;
        state.rolesData.activeCount = 0;
        state.rolesData.inActiveCount = 0;
      });
  }
});

export default roleSlice.reducer;
