import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  Skeleton,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import {
  createUpdateDistrict,
  getDistrictDetails,
  getState,
  revertDistrictFormDetails
} from '../../redux/reducers/mastersSlice';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import SnackBarBox from '../../components/SnackBarBox';
import CustomBreadcrumb from '../../components/Breadcrumb';
import { ChevronRightIcon, SearchMdIcon } from '../../utils/iconSvg';

const AddDistrict = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { authData } = useSelector(state => state.auth);
  const {
    stateData,
    isStateFailed,
    isDistrictDetailsSuccess,
    districtDetails,
    isCreateUpdateDistrictLoading,
    isCreateUpdateDistrictSuccess,
    isCreateUpdateDistrictFailed,
    createUpdateDistrictResponse,
    createUpdateDistrictErrorContainer
  } = useSelector(state => state.masters);

  useEffect(() => {
    // console.log(location, 'location');
    let mastersPayload = {
      type: 'all',
      page: 0,
      size: 0
    };
    dispatch(getState(mastersPayload));
    document.title = createTitle(
      `${location.pathname === '/district/district-details/edit-district' ? 'Edit' : 'Add'} District`
    );

    if (
      location.pathname === '/district/district-details/edit-district' &&
      location.state
    ) {
      dispatch(
        getDistrictDetails({
          districtId: location.state?.districtId
        })
      );
    }

    return () => dispatch(revertDistrictFormDetails());
  }, []);

  // Formik form control
  const formik = useFormik({
    initialValues: {
      stateId: null,
      districtName: null,
      districtCode: null
    },
    validationSchema: yup.object().shape({
      stateId: yup
        .object()
        .shape({
          id: yup.string().required()
        })
        .required('State is required'),
      districtCode: yup.string().required('District code is required'),
      districtName: yup.string().required('District name is required')
    }),
    onSubmit: values => {
      setLoading(true);

      let submitPayload = {
        stateId: values.stateId.id,
        districtCode: values.districtCode,
        districtName: values.districtName,
        loggedInUserName: authData?.userName
      };

      dispatch(
        createUpdateDistrict({
          payload: submitPayload,
          districtId: districtDetails?.districtId
        })
      );
    }
  });

  useEffect(() => {
    setTimeout(() => {
      if (districtDetails.districtId) {
        formik.setValues({
          stateId: {
            id: districtDetails.stateId,
            label: districtDetails.stateName
          },
          districtName: districtDetails.districtName,
          districtCode: districtDetails.districtCode
        });

        setLoading(false);
      } else {
        setLoading(false);
      }
    }, 1200);
  }, [isDistrictDetailsSuccess]);

  function getOptionLabel(option) {
    if (!option) {
      return ''; // or any default label you want to return for null options
    }

    if (option.label !== null) {
      return option.label;
    }

    if (option.id !== null) {
      return option.id;
    }

    return ''; // or any default label you want to return for null label and id
  }

  const handleFormValue = (e, field) => {
    formik.setFieldValue(field, e.target.value);
  };

  useEffect(() => {
    if (isCreateUpdateDistrictSuccess) {
      setTimeout(() => {
        dispatch(revertDistrictFormDetails());
        navigate('/district');
      }, 2000);
    }

    if (isCreateUpdateDistrictFailed) {
      setLoading(false);
    }
  }, [isCreateUpdateDistrictSuccess, isCreateUpdateDistrictFailed]);

  const areAllRequiredFieldsFilled = () => {
    return (
      formik.values.districtCode &&
      formik.values.districtName &&
      formik.values.stateId
    );
  };

  const isSaveDisabled = !formik.isValid || !areAllRequiredFieldsFilled();

  return (
    <Box>
      {/* <Typography fontSize={18} fontWeight="700" marginBottom={1}>
        {location.pathname === '/district/district-details/edit-district'
          ? 'Edit'
          : 'New'}{' '}
        District
      </Typography> */}
      <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <SvgIcon sx={{ height: 24, width: 24 }}>
              <ChevronRightIcon color={colorTextGrey} />
            </SvgIcon>
          }
          sx={{ marginBottom: 2 }}
        >
          <Link
            color="inherit"
            href={'area'}
            onClick={event => {
              event.preventDefault();
              navigate(
                location.pathname === '/district/district-details/edit-district'
                  ? '/district/district-details'
                  : '/district',
                { state: { ...location.state } }
              );
            }}
            sx={{ textDecoration: 'none' }}
          >
            <Typography
              fontWeight={'700'}
              color={colorLinkGrey}
              fontSize={'24px'}
            >
              {districtDetails?.districtName || 'District'}
            </Typography>
          </Link>
          <Typography
            sx={{ fontSize: '32px', fontWeight: '700', color: colorTextBlack }}
          >
            {`${location.pathname === '/district/district-details/edit-district' ? 'Edit' : 'Add new'} district`}
          </Typography>
        </Breadcrumbs>
      </Typography>
      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}
      {!loading && (
        <>
          <Box
            bgcolor={'white'}
            padding={2}
            borderRadius={2}
            marginBottom={2}
            sx={{
              width: '100%'
              // width: {
              //   lg: '60%',
              //   md: '100%'
              // }
            }}
          >
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid spacing={3} display={'flex'} gap={2}>
                <Grid sx={{ width: '280px' }}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    State
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Autocomplete
                      size="small"
                      value={formik.values.stateId}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          'stateId',
                          newValue ? newValue : null
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === (value ? value.id : null)
                      }
                      getOptionLabel={getOptionLabel}
                      options={
                        isArrayNotEmpty(stateData.state)
                          ? stateData.state.map(c => {
                              return { label: c.stateName, id: c.stateId };
                            })
                          : []
                      }
                      sx={{ width: '100%' }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={
                            formik.touched.stateId && formik.errors.stateId
                          }
                          helperText={
                            formik.touched.stateId && formik.errors.stateId
                          }
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    padding: '0.2rem 0px 5px 5px',
                                    display: 'inline-flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <SearchMdIcon
                                    color={colorTextGrey}
                                    height="16px"
                                    width="16px"
                                  />
                                </span>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                      ListboxProps={{
                        sx: {
                          '& .MuiAutocomplete-option:hover': {
                            backgroundColor: colorSecondary
                          }
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid sx={{ width: '280px' }}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    District code
                  </InputLabel>
                  <TextField
                    // label="*"

                    name="districtCode"
                    size="small"
                    fullWidth
                    inputProps={{ maxLength: 4 }}
                    value={formik.values.districtCode}
                    onChange={e => handleFormValue(e, 'districtCode')}
                    error={
                      formik.touched.districtCode && formik.errors.districtCode
                    }
                    InputLabelProps={{
                      shrink: formik.values.districtCode !== ''
                    }}
                    helperText={
                      formik.touched.districtCode && formik.errors.districtCode
                    }
                    onInput={e => {
                      e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '');
                    }}
                  />
                </Grid>
                <Grid sx={{ width: '280px' }}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    District Name
                  </InputLabel>
                  <TextField
                    // label="District Name *"
                    name="districtName"
                    fullWidth
                    size="small"
                    inputProps={{ maxLength: 64 }}
                    value={formik.values.districtName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.districtName &&
                      Boolean(formik.errors.districtName)
                    }
                    InputLabelProps={{
                      shrink: formik.values.districtName !== ''
                    }}
                    helperText={
                      formik.touched.districtName && formik.errors.districtName
                    }
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
          <Grid
            bgcolor={'white'}
            padding={2}
            display={'flex'}
            borderRadius={2}
            marginTop={2}
            gap={2}
            sx={{ width: '100%' }}
            // sx={{
            //   width: {
            //     lg: '60%',
            //     md: '100%'
            //   }
            // }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/district', {
                  state: location.state
                });
              }}
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              disabled={isSaveDisabled}
              onClick={() => formik.handleSubmit()}
              variant="contained"
              sx={{
                width: '94px',
                height: '42px',
                boxShadow: 'none'
              }}
            >
              Save
            </LoadingButton>
          </Grid>
        </>
      )}
      {isStateFailed && (
        <SnackBarBox
          type={'error'}
          message={`Failed to fetch data for:- State Master `}
        />
      )}

      {!isCreateUpdateDistrictLoading && isCreateUpdateDistrictSuccess && (
        <SnackBarBox
          type="success"
          message={
            createUpdateDistrictResponse || 'Action performed successfully'
          }
        />
      )}

      {!isCreateUpdateDistrictLoading && isCreateUpdateDistrictFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            createUpdateDistrictErrorContainer?.errorMessage
          )}
        />
      )}
    </Box>
  );
};

export default AddDistrict;
