import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Rating,
  Typography
} from '@mui/material';
import { IMG_OBSERVATION_ICON } from '../../utils/imageUrls';
import CustomDataTable from '../../components/CustomDataTable';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorLightYellow, colorPrimary } from '../../config/theme';
import { generateImageSrc } from '../../utils/utils';

const CountTile = ({ count, title, backgroundColor, color }) => {
  return (
    <Box
      bgcolor={backgroundColor}
      alignContent={'center'}
      borderRadius={2}
      width={'167px'}
      height={'134px'}
      margin={'1%'}
      borderColor={color}
      color={color}
      border={2}
    >
      <Typography
        color={color}
        sx={{
          textAlign: 'center',
          fontWeight: 700,
          fontSize: 40
        }}
      >
        {count}
      </Typography>
      <Typography
        color={color}
        sx={{
          textAlign: 'center',
          fontWeight: 700,
          fontSize: 24
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

function RightSection() {
  const navigate = useNavigate();
  const { teacherDashboardCountData } = useSelector(state => state.auth);

  const columns = [
    {
      uinqueKey: 'id',
      id: 'userAssessed',
      label: 'User Assessed',
      sortable: true,
      render: row => (
        <>
          {' '}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.userImage ? (
              <Avatar
                src={row.userImage}
                alt={row.name}
                sx={{ width: 24, height: 24, marginRight: 1 }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#c9c9c9',
                  color: 'white',
                  fontSize: '12px',
                  marginRight: '5px'
                }}
              >
                {row
                  ? row.userAssessed?.split(' ')[0]?.charAt(0).toUpperCase()
                  : ''}
              </Avatar>
            )}

            <Typography variant="body2" fontWeight={'600'}>
              {row.userAssessed}
            </Typography>
          </div>
          {/* <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: '#c9c9c9',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              {row?.userAssessed
                ? row?.userAssessed
                    .split(' ')
                    .map(word => word[0])
                    .join('')
                    .toUpperCase()
                : ''}
            </Avatar>
            <Typography variant="body2" fontWeight={'600'}>
              {row?.userAssessed}
            </Typography>
          </span> */}
        </>
      )
    },
    {
      id: 'reportedBy',
      label: 'Reported By',
      render: row => (
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {row.reportedByImage ? (
            <Avatar
              src={row.reportedByImage}
              alt={row.reportedByImage}
              sx={{ width: 24, height: 24, marginRight: 1 }}
            />
          ) : (
            <Avatar
              sx={{
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: '#c9c9c9',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              {row?.reportedBy
                ? row?.reportedBy
                    .split(' ')
                    .map(word => word[0])
                    .join('')
                    .toUpperCase()
                : ''}
            </Avatar>
          )}
          <Typography fontSize={'14px'} fontWeight={'600'}>
            {row?.reportedBy}
          </Typography>
        </span>
      )
    },
    {
      id: 'ratings',
      label: 'Ratings',
      render: row => (
        <Rating
          sx={{ color: '#EA7804' }}
          size="small"
          value={row.ratings}
          readOnly
          precision={0.5}
        />
      )
    }
  ];

  return (
    <Grid
      style={{
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      padding={2}
      borderRadius={2}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <img src={IMG_OBSERVATION_ICON} alt="Icon Image" />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '20px',
              lineHeight: '20px',
              ml: 1
            }}
          >
            Observations
          </Typography>
        </Box>
        <Button
          variant="outlined"
          sx={{
            borderColor: colorPrimary,
            borderRadius: '8px',
            color: 'black',
            borderWidth: '2px',
            fontWeight: '700',
            fontSize: '14px',
            width: '134px',
            height: '36px',
            '&:hover': {
              borderColor: colorPrimary,
              backgroundColor: 'transparent',
              borderRadius: '8px',
              borderWidth: '2px'
            }
          }}
          onClick={() => {
            navigate('/observations');
          }}
        >
          All observations
        </Button>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <CountTile
          count={teacherDashboardCountData.total}
          title="Total"
          backgroundColor="#EBF9D9"
          color="#749E35"
        />
        <CountTile
          count={teacherDashboardCountData.byMe}
          title="By me"
          backgroundColor="#FEF8EC"
          color="#E5AD1F"
        />
        <CountTile
          count={teacherDashboardCountData.forMe}
          title="For me"
          backgroundColor="#FDF0E3"
          color="#EA7804"
        />
      </Box>
      {/* <Divider orientation="horizontal" style={{ margin: '10px 0px' }} /> */}
      <Grid width={'100%'} marginTop={'16px'}>
        <CustomDataTable
          size="large"
          allowPagination={false}
          allowSelection={false}
          columns={columns}
          defaultOrderBy={'createdDate'}
          data={
            teacherDashboardCountData.observations.length > 0
              ? teacherDashboardCountData.observations
              : []
          }
          defaultRowsPerPage={10}
          defaultPage={0}
          totalCount={teacherDashboardCountData.observations.length}
        />
      </Grid>
    </Grid>
  );
}

export default RightSection;
