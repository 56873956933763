import React from 'react';
import PropTypes from 'prop-types';
import { Box, Pagination, Typography } from '@mui/material';
import { colorContainedHoverButton, colorPrimary } from '../config/theme';

const CustomPagination = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  paginationLabelMode
}) => {
  const numPages = count ? Math.ceil(count / rowsPerPage) : 1;
  const formatNumber = num => (num < 10 ? `${num}` : num);
  const handlePageChange = (event, newPage) => {
    onPageChange(event, newPage - 1);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
    >
      <Typography variant="body2" color={'#ABB4BD'} fontWeight={'500'}>
        {paginationLabelMode
          ? `${formatNumber(page + 1)} showing of ${formatNumber(numPages)}`
          : `${formatNumber(page + 1)} page of ${formatNumber(numPages)}`}
      </Typography>
      <Pagination
        sx={{
          '& .MuiPaginationItem-root': {
            bgcolor: '#F5F7FA',
            border: 'none',
            '&.Mui-selected': {
              bgcolor: colorPrimary,
              color: 'black',
              borderRadius: '5px',
              '&:hover': {
                bgcolor: colorPrimary,
                boxShadow: 'none'
              }
            },
            '&:hover': {
              backgroundColor: colorContainedHoverButton,
              boxShadow: 'none'
            }
          }
        }}
        color="primary"
        count={numPages}
        page={page + 1}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
      />
    </Box>
  );
};

CustomPagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  paginationLabelMode: PropTypes.bool
};
CustomPagination.defaultProps = {
  paginationLabelMode: false // Default is false, meaning it's not CustomTable
};

export default CustomPagination;
