import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  assignFlow,
  assignForm,
  getAssignedUserAndUserGroupOfFlow,
  getAssignedUserAndUserGroupOfForm,
  revertUserAndUserGroupAssignment
} from '../../../redux/reducers/flowsSlice';
import { handleError, isArrayNotEmpty } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  Search
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  colorPrimary,
  colorSecondary,
  colorTextBlack
} from '../../../config/theme';
import SnackBarBox from '../../../components/SnackBarBox';
import { getUserList } from '../../../redux/reducers/usersSlice';
import { getUserGroupsList } from '../../../redux/reducers/userGroupsSlice';

const UserAndUserGroupAssingmentModal = ({
  id = null,
  flowId = null,
  onCancelClick,
  type = ''
}) => {
  const dispatch = useDispatch();
  const [selectedAssigns, setSelectedAssigns] = useState({
    user: [],
    userGroups: [],
    deletedUser: [],
    deletedUserGroup: []
  });
  const [showError, setShowError] = useState({ error: false, message: '' });
  const { authData } = useSelector(state => state.auth);
  const { usersData } = useSelector(state => state.users);
  const { userGroupsData } = useSelector(state => state.userGroups);

  const {
    isAssignFlowLoading,
    isAssignFlowSuccess,
    isAssignFlowFail,
    assignFlowErrorContainer,

    isAssignFormLoading,
    isAssignFormSuccess,
    isAssignFormFail,
    assignFormErrorContainer,

    assignedUserAndUserGroup,
    isAssignedUserAndUserGroupSuccess
  } = useSelector(state => state.flow);

  React.useEffect(() => {
    let mastersPayload = { page: 0, size: 0, search: '', type: 'all' };
    dispatch(getUserList({ payload: mastersPayload }));
    dispatch(getUserGroupsList({ payload: mastersPayload }));

    if (flowId) {
      if (type === 'flow') {
        dispatch(getAssignedUserAndUserGroupOfFlow(flowId));
      }
      if (type === 'form') {
        dispatch(
          getAssignedUserAndUserGroupOfForm({ formId: id, flowId: flowId })
        );
      }
    }
  }, [flowId]);

  useEffect(() => {
    if (isAssignedUserAndUserGroupSuccess && assignedUserAndUserGroup) {
      if (type == 'flow') {
        setSelectedAssigns({
          user: assignedUserAndUserGroup[flowId]
            ? isArrayNotEmpty(assignedUserAndUserGroup[flowId].Users)
              ? assignedUserAndUserGroup[flowId].Users.map(cc => {
                  return { id: cc.userId, label: cc.name };
                })
              : []
            : [],
          userGroups: assignedUserAndUserGroup[flowId]
            ? isArrayNotEmpty(assignedUserAndUserGroup[flowId].UserGroups)
              ? assignedUserAndUserGroup[flowId].UserGroups.map(cc => {
                  return { id: cc.userGroupId, label: cc.groupName };
                })
              : []
            : [],
          deletedUser: [],
          deletedUserGroup: []
        });

        return;
      }

      if (type == 'form') {
        setSelectedAssigns({
          user: assignedUserAndUserGroup
            ? isArrayNotEmpty(assignedUserAndUserGroup.Users)
              ? assignedUserAndUserGroup.Users.map(cc => {
                  return { id: cc.userId, label: cc.name };
                })
              : []
            : [],
          userGroups: assignedUserAndUserGroup
            ? isArrayNotEmpty(assignedUserAndUserGroup.UserGroups)
              ? assignedUserAndUserGroup.UserGroups.map(cc => {
                  return { id: cc.userGroupId, label: cc.groupName };
                })
              : []
            : [],
          deletedUser: [],
          deletedUserGroup: []
        });
      }
    }
  }, [isAssignedUserAndUserGroupSuccess]);

  useEffect(() => {
    if (
      (!isAssignFlowLoading && isAssignFlowSuccess) ||
      (!isAssignFormLoading && isAssignFormSuccess)
    ) {
      setTimeout(() => {
        dispatch(revertUserAndUserGroupAssignment());
        handleAssignModalClose();
      }, 3000);
    }
  }, [isAssignFlowSuccess, isAssignFormSuccess]);

  const handleAssignModalClose = () => {
    onCancelClick();
    setSelectedAssigns({
      user: [],
      userGroups: [],
      deletedUser: [],
      deletedUserGroup: []
    });
  };

  return (
    <Grid display={'block'}>
      <Grid>
        <InputLabel
          required
          sx={{
            fontWeight: '700',
            color: colorTextBlack,
            mb: 0.2,
            fontSize: '16px'
          }}
        >
          Select user
        </InputLabel>
        <Autocomplete
          size="small"
          multiple
          loading={true}
          limitTags={2}
          disableCloseOnSelect
          options={
            isArrayNotEmpty(usersData.userList)
              ? usersData.userList.map(cc => {
                  return { id: cc.userId, label: cc.name };
                })
              : []
          }
          getOptionLabel={option => `${option.label}`}
          isOptionEqualToValue={(option, value) =>
            option.label === (value ? value.label : null)
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                // icon={<CheckBoxOutlineBlank fontSize="small" />}
                // checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          style={{ width: '440px', height: '42px' }}
          renderInput={params => (
            <TextField {...params} sx={{ height: '42px' }} />
          )}
          value={selectedAssigns.user}
          onChange={(event, newValue) => {
            setSelectedAssigns({
              ...selectedAssigns,
              user: newValue ? newValue : []
            });
          }}
          ListboxProps={{
            sx: {
              '& .MuiAutocomplete-option:hover': {
                backgroundColor: colorSecondary
              }
            }
          }}
        />
        <Box
          display="flex"
          flexWrap="wrap"
          overflowY="auto"
          maxHeight="200px"
          // mt={1}
          sx={{ marginBottom: 2 }}
        >
          {selectedAssigns.user &&
            selectedAssigns.user.map((cc, indiIdx) => {
              return (
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                    mr: 1,
                    fontWeight: '600',
                    padding: '2px 6px',
                    background: '#FCEBC5',
                    border: `1px solid ${colorPrimary}`,
                    borderRadius: '6px'
                  }}
                  key={indiIdx}
                  variant="caption"
                >
                  {cc?.label}
                  <IconButton
                    onClick={() => {
                      let existingData = [...selectedAssigns.deletedUser];
                      setSelectedAssigns({
                        ...selectedAssigns,
                        deletedUser: [
                          ...existingData,
                          ...selectedAssigns.user.filter(
                            (_, i) => i === indiIdx
                          )
                        ],
                        user: selectedAssigns.user.filter(
                          (_, i) => i !== indiIdx
                        )
                      });
                    }}
                    sx={{
                      padding: 0,
                      ml: 2
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </Typography>
              );
            })}
        </Box>
        <InputLabel
          required
          sx={{
            fontWeight: '700',
            color: colorTextBlack,
            mb: 0.2,
            fontSize: '16px'
          }}
        >
          Select user groups
        </InputLabel>
        <Autocomplete
          multiple
          size="small"
          disableCloseOnSelect
          limitTags={2}
          options={
            isArrayNotEmpty(userGroupsData.userGroupsList)
              ? userGroupsData.userGroupsList.map(cc => {
                  return { id: cc.userGroupId, label: cc.groupName };
                })
              : []
          }
          getOptionLabel={option => (option ? option.label : '')}
          isOptionEqualToValue={(option, value) =>
            option.label === (value ? value.label : '')
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                // icon={<CheckBoxOutlineBlank fontSize="small" />}
                // checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          freeSolo
          style={{ width: '440px', height: '42px' }}
          renderInput={params => <TextField {...params} sx={{}} />}
          value={selectedAssigns.userGroups}
          onChange={(event, newValue) => {
            setSelectedAssigns({
              ...selectedAssigns,
              userGroups: newValue ? newValue : []
            });
          }}
          ListboxProps={{
            sx: {
              '& .MuiAutocomplete-option:hover': {
                backgroundColor: colorSecondary
              }
            }
          }}
        />
        <Box
          display="flex"
          flexWrap="wrap"
          overflowY="auto"
          maxHeight="200px"
          mt={0.2}
        >
          {selectedAssigns.userGroups &&
            selectedAssigns.userGroups.map((cc, indiIdx) => {
              return (
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                    mr: 1,
                    fontWeight: '600',
                    padding: '2px 6px',
                    background: '#FCEBC5',
                    border: `1px solid ${colorPrimary}`,
                    borderRadius: '6px'
                  }}
                  key={indiIdx}
                  variant="caption"
                >
                  {cc?.label}
                  <IconButton
                    onClick={() => {
                      let existingData = [...selectedAssigns.deletedUserGroup];

                      setSelectedAssigns({
                        ...selectedAssigns,
                        deletedUserGroup: [
                          ...existingData,
                          ...selectedAssigns.userGroups.filter(
                            (_, i) => i === indiIdx
                          )
                        ],
                        userGroups: selectedAssigns.userGroups.filter(
                          (_, i) => i !== indiIdx
                        )
                      });
                    }}
                    sx={{
                      padding: 0,
                      ml: 2
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </Typography>
              );
            })}
        </Box>
      </Grid>
      {/* <Divider
        orientation="horizontal"
        variant="fullWidth"
        sx={{ margin: '10px 0px' }}
      /> */}

      <Grid
        pt={1}
        mt={2}
        item
        display={'flex'}
        justifyContent={'start'}
        gap={2}
      >
        <Button
          variant="outlined"
          onClick={() => {
            handleAssignModalClose();
          }}
          sx={{
            borderWidth: 2,
            // px: 4,
            borderColor: colorPrimary,
            borderRadius: '10px',
            color: colorTextBlack,
            height: '42px',
            width: '106px',
            '&:hover': {
              borderColor: colorPrimary,
              backgroundColor: 'transparent',
              borderRadius: '10px',
              borderWidth: '2px'
            }
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isAssignFlowLoading}
          variant="contained"
          sx={{ mr: 2, px: 4, borderWidth: 2, boxShadow: 'none' }}
          disabled={
            !(
              selectedAssigns.user.length > 0 ||
              selectedAssigns.userGroups.length > 0
            )
          }
          onClick={() => {
            if (
              !(
                selectedAssigns.user.length > 0 ||
                selectedAssigns.userGroups.length > 0
              )
            ) {
              setShowError({ error: true, message: 'Select User / UserGroup' });

              setTimeout(() => {
                setShowError({ error: false, message: '' });
              }, 3000);

              return;
            }
            let payload = {
              userIds: [...selectedAssigns.user.map(cc => cc.id)],
              userGroupIds: [...selectedAssigns.userGroups.map(cc => cc.id)],
              deletedUserIds: [...selectedAssigns.deletedUser.map(cc => cc.id)],
              deletedUserGroupIds: [
                ...selectedAssigns.deletedUserGroup.map(cc => cc.id)
              ],
              loggedInUserName: authData.userName
            };

            if (type == 'flow') {
              dispatch(assignFlow({ ...payload, flowId: flowId }));
            }

            if (type == 'form') {
              dispatch(assignForm({ ...payload, formId: id, flowId: flowId }));
            }

            //
          }}
        >
          Assign
        </LoadingButton>
      </Grid>

      {showError.error && (
        <SnackBarBox type={'error'} message={showError.message} />
      )}

      {!isAssignFlowLoading && isAssignFlowFail && (
        <SnackBarBox
          type={'error'}
          message={handleError(assignFlowErrorContainer.errorMessage)}
        />
      )}

      {!isAssignFormLoading && isAssignFormFail && (
        <SnackBarBox
          type={'error'}
          message={handleError(assignFormErrorContainer.errorMessage)}
        />
      )}
    </Grid>
  );
};

export default UserAndUserGroupAssingmentModal;
