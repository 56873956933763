import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import {
  getAuthData,
  getMenuArray,
  getMenuData,
  getToken,
  getOpenMenuData,
  getActiveMenuData
} from '../config/cookie';
import { useDispatch } from 'react-redux';
import { setAuthDetailsByLocalStorage } from '../redux/reducers/authSlice';
import {
  setActiveModule,
  setMenusDataByLocalStorage,
  toggleExpandMenu
} from '../redux/reducers/layoutSlice';

const ProtectedRoutes = ({ routesData }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const token = getToken();
  const authData = getAuthData();
  const menuData = getMenuData();
  const menuArrayData = getMenuArray();
  const activeMenu = getActiveMenuData();
  const menuOpen = getOpenMenuData();
  if (
    token &&
    auth.authData?.userId === 0 &&
    authData !== undefined &&
    menuData
  ) {
    dispatch(setAuthDetailsByLocalStorage(authData));
    dispatch(setMenusDataByLocalStorage(menuData));
    dispatch(setActiveModule(activeMenu));
    dispatch(toggleExpandMenu(menuOpen));
  }

  const isAuthenticated = Boolean(token && authData?.userId);

  const currentPath = window.location.pathname;

  let accessibleRoutess = {};
  if (menuArrayData) {
    accessibleRoutess = generateAccessibleRoutes(routesData, menuArrayData);
  } else {
    console.log('Menu Array Data is undefined.');
  }

  // console.log(`3 exit`)
  if (isAuthenticated) {
    if (accessibleRoutess && accessibleRoutess[currentPath]) {
      return <Outlet />;
    } else {
      return <Navigate to="/forbidden" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

const generateAccessibleRoutes = (routesData, userAccessData) => {
  const accessibleRoutes = {};

  routesData.forEach(route => {
    const menuName = route.menu_name;
    accessibleRoutes[route.path] = [
      ...userAccessData,
      'dashboard',
      'my account'
    ].includes(menuName);
  });

  return accessibleRoutes;
};

export default ProtectedRoutes;
