import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  styled,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser } from '../../redux/reducers/authSlice';
import {
  colorFieldError,
  colorPrimary,
  colorPureWhite,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import { EyeOffIcon, EyeOnIcon } from '../../utils/iconSvg';

export const CustomTextLink = ({
  onClick,
  title = '',
  fontWeight = '',
  fontSize = '',
  href,
  target
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <a
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        cursor: 'pointer',
        textDecoration: 'none'
      }}
      onClick={onClick}
      href={href}
      target={target}
    >
      <Typography
        fontWeight={fontWeight || '500'}
        sx={{
          color: isHovered ? colorPrimary : colorTextBlack,
          textDecoration: 'underline',
          textUnderlineOffset: '5px',
          fontSize: fontSize || '14px'
        }}
      >
        {title}
      </Typography>
    </a>
  );
};
export const LoginForm = () => {
  // constants
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticationLoading } = useSelector(state => state.auth);
  const { isError, errorContainer } = useSelector(state => state.auth);

  // usestates
  const [showPassword, setShowPassword] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);

  // form style
  const formControl = {
    marginBottom: '0.5rem'
  };

  // form control
  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: yup.object({
      username: yup.string().required('Username is required'),
      password: yup.string().required('Password cannot be blank')
    }),
    onSubmit: value => {
      dispatch(
        authenticateUser({
          username: value.username,
          password: value.password,
          rememberMe: rememberMe
        })
      );
    }
  });

  const handleFormValue = (e, field) => {
    formik.setFieldValue(field, e.target.value);
  };

  const handleResetPassword = () => {
    navigate('/password-recovery');
  };

  const handleFaq = () => {
    navigate('/frequently-asked-questions');
  };
  useEffect(() => {
    const rememberedUser = localStorage.getItem('rememberedUser');

    if (rememberedUser) {
      const { username, password } = JSON.parse(rememberedUser);

      formik.setValues({
        username,
        password
      });

      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (errorContainer?.errorMessage === 'Invalid password entered') {
      formik.setFieldError('password', 'Incorrect password entered');
    }
  }, [isError]);

  // console.log(formik.errors, 'sadad');

  return (
    <Grid container>
      <Grid sx={formControl} item xs={12} sm={12} md={12} lg={12}>
        <InputLabel sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.8 }}>
          Username
        </InputLabel>
        <FormControl variant="outlined" sx={formControl} fullWidth>
          <TextField
            name="username"
            placeholder="Enter your username"
            inputProps={{ maxLength: 64 }}
            value={formik.values.username}
            error={formik.touched.username && formik.errors.username}
            size="small"
            onChange={e => handleFormValue(e, 'username')}
          />
          {formik.touched.username && formik.errors.username && (
            <FormHelperText>{formik.errors.username}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginBottom: '2px' }}>
        <InputLabel sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.8 }}>
          Password
        </InputLabel>
        <FormControl variant="outlined" fullWidth>
          <TextField
            name="password"
            size="small"
            placeholder="Enter your password"
            type={!showPassword ? 'text' : 'password'}
            inputProps={{ maxLength: 64 }}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
            InputProps={{
              style: { backgroundColor: colorPureWhite },
              endAdornment: (
                <InputAdornment position="end">
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      cursor: 'pointer',
                      display: 'inline-flex',
                      alignItems: 'center'
                    }}
                  >
                    {!showPassword ? (
                      <EyeOnIcon
                        color={
                          formik.values.password !== ''
                            ? colorTextBlack
                            : colorTextGrey
                        }
                      />
                    ) : (
                      <EyeOffIcon
                        color={
                          formik.values.password !== ''
                            ? colorTextBlack
                            : colorTextGrey
                        }
                      />
                    )}
                  </span>
                </InputAdornment>
              ),
              tabIndex: -1
            }}
            onChange={e => handleFormValue(e, 'password')}
          />
          {formik.touched.password && formik.errors.password && (
            <FormHelperText sx={{ mt: 0.2 }}>
              <SvgIcon sx={{ marginRight: 0.5, height: '16px', width: '16px' }}>
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.64093 5.87662V8.54329M7.64093 11.21H7.6476M6.71781 2.4711L1.23455 11.9422C0.930414 12.4675 0.778346 12.7302 0.800822 12.9457C0.820425 13.1338 0.918939 13.3046 1.07184 13.4158C1.24715 13.5433 1.55066 13.5433 2.15767 13.5433H13.1242C13.7312 13.5433 14.0347 13.5433 14.21 13.4158C14.3629 13.3046 14.4614 13.1338 14.481 12.9457C14.5035 12.7302 14.3514 12.4675 14.0473 11.9422L8.56405 2.4711C8.261 1.94766 8.10948 1.68594 7.9118 1.59803C7.73936 1.52136 7.5425 1.52136 7.37006 1.59803C7.17238 1.68594 7.02085 1.94766 6.71781 2.4711Z"
                    stroke={colorFieldError}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SvgIcon>
              {formik.errors.password}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid
        sx={formControl}
        display={'flex'}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        justifyContent={'space-between'}
        height={'25px'}
      >
        <Grid item display={'flex'} textAlign={'start'} alignItems={'center'}>
          <Checkbox
            size="small"
            sx={{
              padding: '5px',
              ':root': {
                padding: 0
              }
            }}
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          />
          {/* <input
            className="custom-checkbox"
            type="checkbox"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          /> */}

          <Typography fontSize={'14px'} fontWeight={'500'}>
            Remember me
          </Typography>
        </Grid>
        <Grid item textAlign={'end'}>
          <CustomTextLink
            title="Forgot password?"
            onClick={handleResetPassword}
          />
        </Grid>
      </Grid>
      <Grid
        sx={formControl}
        display={'flex'}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <LoadingButton
          fullWidth
          disabled={!formik.values.username || !formik.values.password}
          loading={isAuthenticationLoading}
          variant="contained"
          size="medium"
          color="primary"
          // disabled
          sx={{
            mt: 1,
            height: 42,
            fontSize: 14,
            fontWeight: '700',
            boxShadow: 'none'
          }}
          onClick={() => formik.handleSubmit()}
        >
          Log In
        </LoadingButton>
      </Grid>
      <Grid
        display={'flex'}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        justifyContent={'center'}
        mt={3}
      >
        <Typography
          sx={{
            textAlign: 'center',
            color: '#ABB4BD',
            display: 'flex',
            alignSelf: 'center',
            mr: 1,
            fontWeight: '600',
            fontSize: '14px'
          }}
        >
          Don&apos;t have an account?
        </Typography>
        <CustomTextLink title="Sign up" fontWeight="700" />
      </Grid>
      <Grid
        sx={formControl}
        display={'flex'}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        justifyContent={'center'}
        mt={2}
      >
        <Typography
          sx={{
            textAlign: 'center',
            color: '#ABB4BD',
            display: 'flex',
            alignSelf: 'center',
            mr: 1,
            fontWeight: '600',
            fontSize: '14px'
          }}
        >
          Can&apos;t access your account?{' '}
        </Typography>
        <CustomTextLink
          title="Click here for help"
          fontWeight="700"
          onClick={handleFaq}
        />
      </Grid>
    </Grid>
  );
};
