import {
  Add,
  CheckCircleOutline,
  MoreVert,
  NotInterested
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { colorLightYellow, colorPrimary } from '../../config/theme';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../utils/styledLayout';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import {
  activateDeactivateSchool,
  deleteSchool,
  getSchools,
  revertSchoolPageData
} from '../../redux/reducers/mastersSlice';
import _ from 'lodash';
import CustomDataTable from '../../components/CustomDataTable';
import { useEffect, useState } from 'react';
import ConfirmBox from '../../components/ConfirmBox';
import moment from 'moment';
import SnackBarBox from '../../components/SnackBarBox';
import SearchInput from '../../components/SearchInput';
import { setActiveModule } from '../../redux/reducers/layoutSlice';
import { TrashIcon } from '../../utils/iconSvg';
let searchValue = '';

const ClassroomPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modules, isModulesSuccess } = useSelector(state => state.layout);
  const {
    isClassroomsLoading,
    classroomsData,
    isClassroomsFailed,
    classroomErrorContainer,
    isActivateDeactivateClassroomLoading,
    isActivateDeactivateClassroomSuccess,
    isActivateDeactivateClassroomFailed,
    activateDeactivateClassroomErrorContainer,
    activateDeactivateClassroomResponse,
    isDeleteClassroomSuccess,
    isDeleteClassroomFailed,
    isDeleteClassroomLoading,
    deleteClassroomErrorContainer
  } = useSelector(state => state.masters);
  const [initialValues, setInitalValues] = useState({
    type: 0,
    page: 0,
    size: 10,
    searchFilter: ''
  });
  const { authData } = useSelector(state => state.auth);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [confirmation, setConfimation] = useState(null);
  const [showError, setShowError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    document.title = createTitle('Classroom');
    dispatch(setActiveModule('Classroom'));
    return () => {
      dispatch(revertSchoolPageData());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getSchools({
        type:
          initialValues.type === 1
            ? true
            : initialValues.type === 2
              ? false
              : 'all',
        page: initialValues.page,
        size: initialValues.size,
        searchFilter: initialValues.searchFilter
      })
    );
  }, [initialValues]);

  const debouncedSearch = _.debounce(
    term => setInitalValues({ ...initialValues, searchFilter: term }),
    300
  );

  const handleSearchChange = e => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length >= 3 || term.length === 0) {
      debouncedSearch(term);
    }
  };

  const handleMenuActions = action => {
    setShowError(null);
    if (action === 'Delete') {
      handleDeleteArea();
    } else if (action === 'De-Activate') {
      handleDeActivateArea();
    } else if (action === 'Activate') {
      handleActivateArea();
    }
  };

  const handleDeleteArea = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(classroomsData?.schools)) {
      if (isArrayNotEmpty(selectedAreas)) {
        setConfimation(true);
      } else {
        errorMessage = 'Please select school[s] to delete.';
      }
    }
    setShowError(errorMessage);
  };

  const handleActivateArea = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(classroomsData.schools)) {
      if (!isArrayNotEmpty(selectedAreas)) {
        errorMessage = 'Please select school[s] to activate.';
      } else {
        const selectedUsersData = classroomsData.schools.filter(user =>
          selectedAreas.includes(user.schoolId)
        );
        const allUsersActive = selectedUsersData.every(user => !user.status);
        if (!allUsersActive) {
          errorMessage = 'Please select only inactive school[s] to activate.';
        } else {
          let payload = {
            isActivate: true,
            ids: [...selectedAreas],
            loggedInUserName: authData?.userName
          };
          dispatch(activateDeactivateSchool(payload));
        }
      }
    }
    setShowError(errorMessage);
  };

  useEffect(() => {
    if (isActivateDeactivateClassroomSuccess || isDeleteClassroomSuccess) {
      return handleReset();
    }
  }, [isActivateDeactivateClassroomSuccess, isDeleteClassroomSuccess]);

  const handleDeActivateArea = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(classroomsData?.schools)) {
      if (!isArrayNotEmpty(selectedAreas)) {
        errorMessage = 'Please select school[s] to in-activate.';
      } else {
        const selectedUsersData = classroomsData.schools.filter(user =>
          selectedAreas.includes(user.schoolId)
        );
        const allUsersActive = selectedUsersData.every(user => user.status);
        if (!allUsersActive) {
          errorMessage = 'Please select only active school[s] to in-activate.';
        } else {
          let payload = {
            isActivate: false,
            ids: [...selectedAreas],
            loggedInUserName: authData?.userName
          };

          dispatch(activateDeactivateSchool(payload));
        }
      }
    }
    setShowError(errorMessage);
  };

  const deleteUser = () => {
    let deletePayload = {
      ids: [...selectedAreas],
      loggedInUserName: authData?.userName
    };
    dispatch(deleteSchool(deletePayload));
  };

  const handleReset = () => {
    setSelectedAreas([]);
    setShowError(null);
    setConfimation(null);
    setInitalValues({ type: 0, page: 0, size: 10, searchFilter: '' });
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      isActivateDeactivateClassroomSuccess ||
      isDeleteClassroomSuccess ||
      isActivateDeactivateClassroomSuccess
    ) {
      return handleReset();
    }
  }, [
    isActivateDeactivateClassroomSuccess,
    isDeleteClassroomSuccess,
    isActivateDeactivateClassroomSuccess
  ]);

  const columns = [
    {
      uinqueKey: 'schoolId',
      id: 'schoolName',
      label: 'School',
      sortable: true,
      width: '150px',
      render: row => {
        return (
          <a
            style={{
              textDecoration: 'underline',
              textUnderlineOffset: '3px'
            }}
            onClick={() => {
              return navigate(`school-details`, {
                state: { schoolId: row.schoolId }
              });
            }}
          >
            <Typography variant="body2" fontWeight={'600'}>
              {row.schoolName}
            </Typography>
          </a>
        );
      }
    },
    {
      id: 'area',
      label: 'Area',
      sortable: true
    },
    {
      id: 'districtName',
      label: 'District ',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {/* {row.districtCode ? `(${row.districtCode})` : ''}  */}
            {row.districtName}
          </Typography>
        );
      }
    },
    {
      id: 'stateName',
      label: 'State ',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {/* {row.stateCode ? `(${row.stateCode})` : ''}  */}
            {row.stateName}
          </Typography>
        );
      }
    },
    {
      id: 'users',
      label: 'Users',
      sortable: true
    },
    {
      id: 'status',
      label: 'Status',
      width: '150px',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '3px',
              borderRadius: '1.25rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 90,
              color: row.status ? '#749E35' : '#D62828'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'createdBy',
      label: 'Created By'
    },
    {
      id: 'creationDate',
      label: 'Created On',
      width: '150px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'}>
                {row.creationDate
                  ? moment(row.creationDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    }
  ];

  return (
    <>
      <Container mb={2}>
        <Grid>
          <Typography mb={1} fontSize={20} fontWeight="medium">
            Classrooms ({classroomsData?.totalCount})
          </Typography>
        </Grid>

        <Grid>
          <Button
            startIcon={<Add />}
            sx={{
              backgroundColor: colorLightYellow,
              color: colorPrimary,
              fontWeight: '600'
            }}
            variant="outlined"
            onClick={() => {
              navigate('add-new-class');
            }}
          >
            Create New Classroom
          </Button>
        </Grid>
      </Container>
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
      >
        <Container
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            paddingX: '10px'
          }}
        >
          <Tabs
            value={initialValues.type}
            onChange={(val, newValue) => {
              setInitalValues({ ...initialValues, type: newValue, page: 0 });
              setSelectedAreas([]);
            }}
            aria-label="user tab selection"
          >
            <Tab
              color={initialValues.type === 0 ? colorPrimary : '#ABB4BD'}
              sx={{
                fontWeight: initialValues.tabValue === 0 ? '700' : '600'
              }}
              iconPosition="start"
              label="All Classrooms"
            />
            <Tab
              color={initialValues.tabValue === 1 ? colorPrimary : '#ABB4BD'}
              sx={{
                fontWeight: initialValues.tabValue === 1 ? '700' : '600'
              }}
              iconPosition="start"
              label="Active Classrooms"
            />
            <Tab
              color={initialValues.tabValue === 2 ? colorPrimary : '#ABB4BD'}
              sx={{
                fontWeight: initialValues.tabValue === 2 ? '700' : '600'
              }}
              iconPosition="start"
              label="Inactive Classrooms"
            />
          </Tabs>
          <div style={{ display: 'flex', margin: 'auto 10px' }}>
            <SearchInput
              onSearchChange={handleSearchChange}
              searchTerm={searchTerm}
            />
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => {
                setAnchorEl(null);
                setShowError(null);
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: '20ch',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {['Activate', 'De-Activate', 'Delete'].map(option => (
                <MenuItem
                  key={option}
                  disabled={
                    (initialValues.type === 1 && option === 'Activate') ||
                    (initialValues.type === 2 && option === 'De-Activate')
                  }
                  onClick={() => {
                    handleMenuActions(option);
                  }}
                >
                  <ListItemIcon>
                    {option === 'Delete' ? (
                      <>
                        <SvgIcon sx={{ height: 20, width: 20 }}>
                          <TrashIcon color="red" />
                        </SvgIcon>
                      </>
                    ) : option === 'De-Activate' ? (
                      <SvgIcon
                        sx={{ height: 20, width: 20, color: 'orangered' }}
                      >
                        <NotInterested />
                      </SvgIcon>
                    ) : option === 'Activate' ? (
                      <SvgIcon sx={{ height: 20, width: 20, color: 'green' }}>
                        <CheckCircleOutline />
                      </SvgIcon>
                    ) : (
                      ''
                    )}
                  </ListItemIcon>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Container>
        <Grid sx={{ padding: '18px 10px' }} width={'100%'}>
          {(isClassroomsLoading ||
            isActivateDeactivateClassroomLoading ||
            isDeleteClassroomLoading) && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}

          {!isClassroomsLoading &&
            !isActivateDeactivateClassroomLoading &&
            !isDeleteClassroomLoading && (
              <CustomDataTable
                columns={columns}
                allowSelection={true}
                data={
                  classroomsData.classrooms.length > 0
                    ? classroomsData.classrooms
                    : []
                }
                onPageChange={val => {
                  setInitalValues({ ...initialValues, page: val });
                }}
                onRowsPerPageChange={val => {
                  setInitalValues({ ...initialValues, size: val });
                }}
                selectedRows={[...selectedAreas]}
                onSelectionChange={val => {
                  setSelectedAreas(val);
                }}
                defaultOrderBy={'creationDate'}
                defaultPage={initialValues.page}
                defaultRowsPerPage={initialValues.size}
                totalCount={classroomsData?.totalCount}
              />
            )}
        </Grid>

        <ConfirmBox
          isOpen={confirmation}
          title="Delete School!"
          subtitle={`${selectedAreas.length} school[s] are selected, Are you sure you want to delete. `}
          handleClose={() => setConfimation(null)}
          handleSubmit={() => {
            deleteUser();
          }}
        />

        {showError && <SnackBarBox type={'error'} message={showError} />}

        {!isClassroomsLoading && isClassroomsFailed && (
          <SnackBarBox
            type="error"
            message={handleError(classroomErrorContainer?.errorMessage)}
          />
        )}

        {!isDeleteClassroomLoading && isDeleteClassroomSuccess && (
          <SnackBarBox
            type="success"
            message={'schools[s] deleted successfully. '}
          />
        )}

        {!isDeleteClassroomLoading && isDeleteClassroomFailed && (
          <SnackBarBox
            type="error"
            message={handleError(deleteClassroomErrorContainer?.errorMessage)}
          />
        )}

        {!isActivateDeactivateClassroomLoading &&
          isActivateDeactivateClassroomSuccess && (
            <SnackBarBox
              type="success"
              message={activateDeactivateClassroomResponse}
            />
          )}

        {!isActivateDeactivateClassroomLoading &&
          isActivateDeactivateClassroomFailed && (
            <SnackBarBox
              type="error"
              message={handleError(
                activateDeactivateClassroomErrorContainer?.errorMessage
              )}
            />
          )}
      </Grid>
    </>
  );
};

export default ClassroomPage;
