import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModule } from '../../redux/reducers/layoutSlice';
import { createTitle, handleError } from '../../utils/utils';
import { isEmptyArray } from 'formik';
import {
  Grid,
  InputBase,
  Paper,
  Skeleton,
  Typography,
  styled
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import CustomDataTable from '../../components/CustomDataTable';
import { colorMidGray } from '../../config/theme';
import { getState } from '../../redux/reducers/mastersSlice';
import SnackBarBox from '../../components/SnackBarBox';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '0px',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const StatePage = () => {
  const dispatch = useDispatch();
  const { modules, isModulesSuccess } = useSelector(state => state.layout);
  const {
    isStateLoading,
    isStateSuccess,
    stateData,
    isStateFailed,
    stateErrorContainer
  } = useSelector(state => state.masters);
  const [initialValues, setInitalValues] = useState({
    type: 'all',
    page: 0,
    size: 10,
    searchFilter: ''
  });

  useEffect(() => {
    document.title = createTitle('State');
    dispatch(setActiveModule('States'));
  }, []);

  useEffect(() => {
    dispatch(getState(initialValues));
  }, [initialValues]);

  const debouncedSearch = _.debounce(
    term => setInitalValues({ ...initialValues, searchFilter: term }),
    300
  );

  const handleSearch = e => {
    const term = e.target.value;

    if (term.length >= 3 || term.length === 0) {
      debouncedSearch(term);
    }
  };

  const columns = [
    {
      uinqueKey: 'stateId',
      id: 'stateCode',
      label: 'State Code',
      sortable: true,
      width: '150px'
    },
    {
      id: 'stateName',
      label: 'State Name',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.stateName}
          </Typography>
        );
      }
    }
    // {
    //   id: 'status',
    //   label: 'Status',
    //   width: '150px',
    //   render: row => (
    //     <>
    //       <div
    //         style={{
    //           fontWeight: '600',
    //           backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
    //           padding: '3px', // p-1
    //           borderRadius: '1.25rem',
    //           display: 'flex',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           minWidth: 90,
    //           color: row.status ? '#749E35' : '#D62828'
    //         }}
    //       >
    //         <Avatar
    //           sx={{
    //             width: 8,
    //             height: 8,
    //             display: 'flex',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //             borderRadius: '50%',
    //             backgroundColor: row.status ? '#749E35' : '#D62828',
    //             color: 'white',
    //             fontSize: '12px',
    //             marginRight: '5px'
    //           }}
    //         >
    //           <></>
    //         </Avatar>
    //         {row.status ? 'Active' : 'Inactive'}
    //       </div>
    //     </>
    //   )
    // }
  ];

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} mb={1}>
        <Grid display={'flex'} alignItems={'center'}>
          <Typography fontSize={'32px'} fontWeight="700">
            States
          </Typography>
          <Typography
            // ml={1}
            sx={{ margin: '7px 0 0 10px' }}
            fontSize={20}
            fontWeight="700"
            alignSelf={'center'}
          >
            ({stateData.totalCount})
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        borderRadius={'8px'}
        sx={{ paddingX: '14px', pt: 1 }}
      >
        {/* <Grid
          display={'flex'}
          sx={{
            flexFlow: 'row-reverse',
            borderBottom: 1,
            borderColor: 'divider',
            height: 60
          }}
        >
          <Typography fontWeight={'600'} alignSelf="center">
            There are {stateData.totalCount} state&apos;s in list
          </Typography>
          <div style={{ display: 'flex', margin: 'auto 10px', flexFlow: '' }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: colorMidGray }} />
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 17.5L14.375 13.875M16.3333 9.16667C16.3333 12.8486 13.3486 15.8333 9.66667 15.8333C5.98477 15.8333 3 12.8486 3 9.16667C3 5.48477 5.98477 2.5 9.66667 2.5C13.3486 2.5 16.3333 5.48477 16.3333 9.16667Z"
                    stroke="#ABB4BD"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SearchIconWrapper>
              <StyledInputBase
                onChange={handleSearch}
                placeholder="Search state here…"
              />
            </Search>
          </div>
        </Grid> */}
        <Grid sx={{ padding: '18px 0px' }} width={'100%'}>
          {isStateLoading && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}
          {!isStateLoading && isStateSuccess && (
            <CustomDataTable
              columns={columns}
              // allowSelection={true}
              data={stateData.state.length > 0 ? stateData.state : []}
              onPageChange={val => {
                setInitalValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitalValues({ ...initialValues, size: val });
              }}
              defaultPage={initialValues.page}
              defaultRowsPerPage={initialValues.size}
              totalCount={stateData.totalCount}
              defaultOrderBy={'creationDate'}
              allowHover={false}
            />
          )}
        </Grid>

        {!isStateLoading && isStateFailed && (
          <SnackBarBox
            type="error"
            message={handleError(stateErrorContainer?.errorMessage)}
          />
        )}
      </Grid>
    </>
  );
};

export default StatePage;
