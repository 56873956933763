import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

function SnackBarBox({
  type,
  message = 'Error occurred',
  autoHide = 9000,
  vertical = 'top',
  open = true
}) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setTimeout(
      () => {
        setIsOpen(false);
      },
      type === 'error' ? 10000 : 4000
    );
  }, []);

  let severity = 'info';
  if (type === 'error') {
    severity = 'error';
  } else if (type === 'warning') {
    severity = 'warning';
  } else if (type === 'info') {
    severity = 'info';
  } else if (type === 'success') {
    severity = 'success';
  }
  return (
    <>
      {isOpen && (
        <Snackbar
          open={isOpen}
          autoHideDuration={autoHide}
          anchorOrigin={{
            vertical: vertical,
            horizontal: 'center'
          }}
        >
          <Alert
            severity={severity}
            variant="filled"
            sx={{ width: '100%', alignItems: 'center' }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default SnackBarBox;
