import { colorPrimary } from '../config/theme';

export const IMG_SPLASH_LOGO = '/images/splash_logo.svg';

export const IMG_SPLASH_LOGO_LEFT_TOP = '/images/splash_left_top.svg';

export const IMG_SPLASH_LOGO_RIGHT_BOTTOM = '/images/splash_right_bottom.svg';

export const IMG_ERROR_404 = '/images/error_404.gif';

export const IMG_ERROR_403 = '/images/error_403.gif';

export const IMG_LENDBOX = '/images/lendbox.png';

export const IMG_RBI = '/images/rbi_logo.png';

export const IMG_PERSON_AVTAR = '/images/person_preview.png';

export const IMG_PERSON_AVTAR_SMALL =
  '/images/person.jpg'; /** For Profile Picture */

export const IMG_PDF_PREVIEW = '/images/pdf_preview.png';

export const IMG_IMAGE_PREVIEW = '/images/image_preview.svg';

export const IMG_SUCCESS = '/images/success.png';

export const IMG_FAILED = '/images/failed.png';

export const DOWNLOAD_APP_ICON = '/images/download-app.png';

export const IMG_USER_ANALYTICS_ICON =
  '/images/analytics-images/user-analytics.svg';

export const IMG_USER_GROUP_ANALYTICS_ICON =
  '/images/analytics-images/user-group-analytics.svg';

export const IMG_ROLE_ANALYTICS_ICON =
  '/images/analytics-images/role-analytics.svg';

export const IMG_DISTRICT_ANALYTICS_ICON =
  '/images/analytics-images/district-analytics.svg';

export const IMG_AREA_ANALYTICS_ICON =
  '/images/analytics-images/area-analytics.svg';

export const IMG_SCHOOL_ANALYTICS_ICON =
  '/images/analytics-images/school-analytics.svg';

export const IMG_FORMS_ANALYTICS_ICON =
  '/images/analytics-images/forms-analytics.svg';

export const IMG_RESPONSE_ANALYTICS_ICON =
  '/images/analytics-images/response-analytics.svg';

export const IMG_RESPONSE_TIME_ANALYTICS_ICON =
  '/images/analytics-images/response-time-analytics.svg';

export const IMG_TOTAL_OBSERVATIONS_ANALYTICS_ICON =
  '/images/analytics-images/total-observations-analytics.svg';

export const IMG_TOTAL_INDICATORS_ANALYTICS_ICON =
  '/images/analytics-images/total-indicators-analytics.svg';

export const IMG_AVERAGE_SCORE_ANALYTICS_ICON =
  '/images/analytics-images/average-score-analytics.svg';

export const menu_location_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 15.1863C3.64864 16.003 2.5 17.1411 2.5 18.3999C2.5 20.8852 6.97715 22.8999 12.5 22.8999C18.0228 22.8999 22.5 20.8852 22.5 18.3999C22.5 17.1411 21.3514 16.003 19.5 15.1863M18.5 8.8999C18.5 12.9636 14 14.8999 12.5 17.8999C11 14.8999 6.5 12.9636 6.5 8.8999C6.5 5.58619 9.18629 2.8999 12.5 2.8999C15.8137 2.8999 18.5 5.58619 18.5 8.8999ZM13.5 8.8999C13.5 9.45219 13.0523 9.8999 12.5 9.8999C11.9477 9.8999 11.5 9.45219 11.5 8.8999C11.5 8.34762 11.9477 7.8999 12.5 7.8999C13.0523 7.8999 13.5 8.34762 13.5 8.8999Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menu_dashboard_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4823 3.6639C13.131 3.39065 12.9553 3.25402 12.7613 3.2015C12.5902 3.15516 12.4098 3.15516 12.2387 3.2015C12.0447 3.25402 11.869 3.39065 11.5177 3.6639L4.73539 8.93903C4.28202 9.29165 4.05534 9.46796 3.89203 9.68876C3.74737 9.88435 3.6396 10.1047 3.57403 10.339C3.5 10.6034 3.5 10.8906 3.5 11.465V18.6999C3.5 19.82 3.5 20.38 3.71799 20.8079C3.90973 21.1842 4.21569 21.4902 4.59202 21.6819C5.01984 21.8999 5.5799 21.8999 6.7 21.8999H8.7C8.98003 21.8999 9.12004 21.8999 9.227 21.8454C9.32108 21.7975 9.39757 21.721 9.4455 21.6269C9.5 21.5199 9.5 21.3799 9.5 21.0999V14.4999C9.5 13.9398 9.5 13.6598 9.60899 13.4459C9.70487 13.2577 9.85785 13.1048 10.046 13.0089C10.2599 12.8999 10.5399 12.8999 11.1 12.8999H13.9C14.4601 12.8999 14.7401 12.8999 14.954 13.0089C15.1422 13.1048 15.2951 13.2577 15.391 13.4459C15.5 13.6598 15.5 13.9398 15.5 14.4999V21.0999C15.5 21.3799 15.5 21.5199 15.5545 21.6269C15.6024 21.721 15.6789 21.7975 15.773 21.8454C15.88 21.8999 16.02 21.8999 16.3 21.8999H18.3C19.4201 21.8999 19.9802 21.8999 20.408 21.6819C20.7843 21.4902 21.0903 21.1842 21.282 20.8079C21.5 20.38 21.5 19.82 21.5 18.6999V11.465C21.5 10.8906 21.5 10.6034 21.426 10.339C21.3604 10.1047 21.2526 9.88435 21.108 9.68876C20.9447 9.46796 20.718 9.29165 20.2646 8.93903L13.4823 3.6639Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menu_user_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 20.8999C5.83579 18.4225 9.00702 16.8999 12.5 16.8999C15.993 16.8999 19.1642 18.4225 21.5 20.8999M17 8.3999C17 10.8852 14.9853 12.8999 12.5 12.8999C10.0147 12.8999 8 10.8852 8 8.3999C8 5.91462 10.0147 3.8999 12.5 3.8999C14.9853 3.8999 17 5.91462 17 8.3999Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// export const menu_location_icon = (
//   <svg
//     width="25"
//     height="25"
//     viewBox="0 0 25 25"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M3.5 20.8999C5.83579 18.4225 9.00702 16.8999 12.5 16.8999C15.993 16.8999 19.1642 18.4225 21.5 20.8999M17 8.3999C17 10.8852 14.9853 12.8999 12.5 12.8999C10.0147 12.8999 8 10.8852 8 8.3999C8 5.91462 10.0147 3.8999 12.5 3.8999C14.9853 3.8999 17 5.91462 17 8.3999Z"
//       stroke="#1F2933"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     />
//   </svg>
// );

export const menu_teacher_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 11.8999H8.5M10.5 15.8999H8.5M16.5 7.8999H8.5M20.5 11.3999V7.6999C20.5 6.01974 20.5 5.17967 20.173 4.53793C19.8854 3.97344 19.4265 3.5145 18.862 3.22688C18.2202 2.8999 17.3802 2.8999 15.7 2.8999H9.3C7.61984 2.8999 6.77976 2.8999 6.13803 3.22688C5.57354 3.5145 5.1146 3.97344 4.82698 4.53793C4.5 5.17967 4.5 6.01974 4.5 7.6999V18.0999C4.5 19.7801 4.5 20.6201 4.82698 21.2619C5.1146 21.8264 5.57354 22.2853 6.13803 22.5729C6.77976 22.8999 7.61984 22.8999 9.3 22.8999H12M22.5 22.8999L21 21.3999M22 18.8999C22 20.8329 20.433 22.3999 18.5 22.3999C16.567 22.3999 15 20.8329 15 18.8999C15 16.9669 16.567 15.3999 18.5 15.3999C20.433 15.3999 22 16.9669 22 18.8999Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menu_schedule_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 10.8999H3.5M16.5 2.8999V6.8999M8.5 2.8999V6.8999M9.5 16.8999L11.5 18.8999L16 14.3999M8.3 22.8999H16.7C18.3802 22.8999 19.2202 22.8999 19.862 22.5729C20.4265 22.2853 20.8854 21.8264 21.173 21.2619C21.5 20.6201 21.5 19.7801 21.5 18.0999V9.6999C21.5 8.01974 21.5 7.17967 21.173 6.53793C20.8854 5.97344 20.4265 5.5145 19.862 5.22688C19.2202 4.8999 18.3802 4.8999 16.7 4.8999H8.3C6.61984 4.8999 5.77976 4.8999 5.13803 5.22688C4.57354 5.5145 4.1146 5.97344 3.82698 6.53793C3.5 7.17967 3.5 8.01974 3.5 9.6999V18.0999C3.5 19.7801 3.5 20.6201 3.82698 21.2619C4.1146 21.8264 4.57354 22.2853 5.13803 22.5729C5.77976 22.8999 6.61984 22.8999 8.3 22.8999Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menu_analytics_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 21.8999H5.1C4.53995 21.8999 4.25992 21.8999 4.04601 21.7909C3.85785 21.695 3.70487 21.5421 3.60899 21.3539C3.5 21.14 3.5 20.86 3.5 20.2999V3.8999M21.5 7.8999L16.0657 13.3342C15.8677 13.5322 15.7687 13.6312 15.6545 13.6683C15.5541 13.701 15.4459 13.701 15.3455 13.6683C15.2313 13.6312 15.1323 13.5322 14.9343 13.3342L13.0657 11.4656C12.8677 11.2676 12.7687 11.1686 12.6545 11.1315C12.5541 11.0989 12.4459 11.0989 12.3455 11.1315C12.2313 11.1686 12.1323 11.2676 11.9343 11.4656L7.5 15.8999M21.5 7.8999H17.5M21.5 7.8999V11.8999"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const sceneroy_icon = color => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5604 16.2733L9.05752 10.7761C9.38753 10.4461 9.55254 10.2811 9.74281 10.2193C9.91018 10.1649 10.0905 10.1649 10.2578 10.2193C10.4481 10.2811 10.6131 10.4461 10.9431 10.7761L16.4036 16.2366M11.667 11.5L14.0575 9.10948C14.3875 8.77946 14.5525 8.61445 14.7428 8.55263C14.9102 8.49825 15.0905 8.49825 15.2578 8.55263C15.4481 8.61445 15.6131 8.77946 15.9431 9.10947L18.3337 11.5M8.33366 6.5C8.33366 7.42047 7.58747 8.16667 6.66699 8.16667C5.74652 8.16667 5.00033 7.42047 5.00033 6.5C5.00033 5.57953 5.74652 4.83333 6.66699 4.83333C7.58747 4.83333 8.33366 5.57953 8.33366 6.5ZM5.66699 16.5H14.3337C15.7338 16.5 16.4339 16.5 16.9686 16.2275C17.439 15.9878 17.8215 15.6054 18.0612 15.135C18.3337 14.6002 18.3337 13.9001 18.3337 12.5V5.5C18.3337 4.09987 18.3337 3.3998 18.0612 2.86502C17.8215 2.39462 17.439 2.01217 16.9686 1.77248C16.4339 1.5 15.7338 1.5 14.3337 1.5H5.66699C4.26686 1.5 3.5668 1.5 3.03202 1.77248C2.56161 2.01217 2.17916 2.39462 1.93948 2.86502C1.66699 3.3998 1.66699 4.09987 1.66699 5.5V12.5C1.66699 13.9001 1.66699 14.6002 1.93948 15.135C2.17916 15.6054 2.56161 15.9878 3.03202 16.2275C3.5668 16.5 4.26686 16.5 5.66699 16.5Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const player_icon = color => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.91667 6.47111C6.91667 6.07337 6.91667 5.8745 6.99978 5.76348C7.07222 5.66673 7.18309 5.6062 7.30365 5.59759C7.44199 5.58771 7.60927 5.69524 7.94384 5.91032L11.8777 8.43921C12.168 8.62585 12.3131 8.71917 12.3633 8.83783C12.4071 8.9415 12.4071 9.0585 12.3633 9.16217C12.3131 9.28083 12.168 9.37415 11.8777 9.56079L7.94384 12.0897C7.60927 12.3048 7.44199 12.4123 7.30365 12.4024C7.18309 12.3938 7.07222 12.3333 6.99978 12.2365C6.91667 12.1255 6.91667 11.9266 6.91667 11.5289V6.47111Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 5.5C1.5 4.09987 1.5 3.3998 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.3998 1.5 4.09987 1.5 5.5 1.5H12.5C13.9001 1.5 14.6002 1.5 15.135 1.77248C15.6054 2.01217 15.9878 2.39462 16.2275 2.86502C16.5 3.3998 16.5 4.09987 16.5 5.5V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V5.5Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const section_icon = color => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8333 7.33333C14.7668 7.33333 15.2335 7.33333 15.59 7.15168C15.9036 6.99189 16.1586 6.73692 16.3183 6.42332C16.5 6.0668 16.5 5.60009 16.5 4.66667V4.16667C16.5 3.23325 16.5 2.76654 16.3183 2.41002C16.1586 2.09641 15.9036 1.84145 15.59 1.68166C15.2335 1.5 14.7668 1.5 13.8333 1.5L4.16667 1.5C3.23325 1.5 2.76654 1.5 2.41002 1.68166C2.09641 1.84144 1.84144 2.09641 1.68166 2.41002C1.5 2.76654 1.5 3.23325 1.5 4.16667L1.5 4.66667C1.5 5.60009 1.5 6.0668 1.68166 6.42332C1.84144 6.73692 2.09641 6.99189 2.41002 7.15168C2.76654 7.33333 3.23325 7.33333 4.16667 7.33333L13.8333 7.33333Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8333 16.5C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1586 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7668 16.5 13.8333V13.3333C16.5 12.3999 16.5 11.9332 16.3183 11.5767C16.1586 11.2631 15.9036 11.0081 15.59 10.8483C15.2335 10.6667 14.7668 10.6667 13.8333 10.6667L4.16667 10.6667C3.23325 10.6667 2.76654 10.6667 2.41002 10.8483C2.09641 11.0081 1.84144 11.2631 1.68166 11.5767C1.5 11.9332 1.5 12.3999 1.5 13.3333L1.5 13.8333C1.5 14.7668 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1586 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const text_icon = color => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33301 3.83301C1.33301 3.05644 1.33301 2.66815 1.45988 2.36187C1.62903 1.95349 1.95349 1.62903 2.36187 1.45988C2.66815 1.33301 3.05644 1.33301 3.83301 1.33301H12.1663C12.9429 1.33301 13.3312 1.33301 13.6375 1.45988C14.0459 1.62903 14.3703 1.95349 14.5395 2.36187C14.6663 2.66815 14.6663 3.05644 14.6663 3.83301M5.49967 14.6663H10.4997M7.99967 1.33301V14.6663"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const add_circle_icon = color => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0003 6.66699V13.3337M6.66699 10.0003H13.3337M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AssignFormIcon = ({ color, className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3332 8.3335V4.5335C12.3332 3.41339 12.3332 2.85334 12.1152 2.42552C11.9234 2.04919 11.6175 1.74323 11.2412 1.55148C10.8133 1.3335 10.2533 1.3335 9.13317 1.3335H4.8665C3.7464 1.3335 3.18635 1.3335 2.75852 1.55148C2.3822 1.74323 2.07624 2.04919 1.88449 2.42552C1.6665 2.85334 1.6665 3.41339 1.6665 4.5335V11.4668C1.6665 12.5869 1.6665 13.147 1.88449 13.5748C2.07624 13.9511 2.3822 14.2571 2.75852 14.4488C3.18635 14.6668 3.7464 14.6668 4.8665 14.6668H6.99984M8.33317 7.3335H4.33317M5.6665 10.0002H4.33317M9.6665 4.66683H4.33317M8.6665 12.6668L9.99984 14.0002L12.9998 11.0002"
        stroke={color ? color : colorPrimary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IMG_TEACHER_DASHBOARD = '/images/teacher_dashboard.svg';

export const IMG_COURSES_ICON = '/images/courses_icon.svg';

export const IMG_CONTINUE_LEARNING_COURSE =
  '/images/continue_learning_course.svg';

export const IMG_RECOMMENDED_COURSE_1 = 'images/recommended_course_1.svg';

export const IMG_RECOMMENDED_COURSE_2 = 'images/recommended_course_2.svg';

export const IMG_CLOCK_ICON = 'images/clock_icon.svg';

export const IMG_OBSERVATION_ICON = 'images/observation_icon.svg';

export const IMG_FORWARD_ARROW_ICON = 'images/forward_arrow_icon.svg';

export const PreviewFormIcon = ({ color, className }) => {
  return (
    <svg
      className={className}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33366 7.33301H4.33366M5.66699 9.99967H4.33366M9.66699 4.66634H4.33366M12.3337 6.99967V4.53301C12.3337 3.4129 12.3337 2.85285 12.1157 2.42503C11.9239 2.0487 11.618 1.74274 11.2416 1.55099C10.8138 1.33301 10.2538 1.33301 9.13366 1.33301H4.86699C3.74689 1.33301 3.18683 1.33301 2.75901 1.55099C2.38269 1.74274 2.07673 2.0487 1.88498 2.42503C1.66699 2.85285 1.66699 3.4129 1.66699 4.53301V11.4663C1.66699 12.5864 1.66699 13.1465 1.88498 13.5743C2.07673 13.9506 2.38269 14.2566 2.75901 14.4484C3.18683 14.6663 3.74689 14.6663 4.86699 14.6663H6.66699M13.667 14.6663L12.667 13.6663M13.3337 11.9997C13.3337 13.2883 12.289 14.333 11.0003 14.333C9.71166 14.333 8.66699 13.2883 8.66699 11.9997C8.66699 10.711 9.71166 9.66634 11.0003 9.66634C12.289 9.66634 13.3337 10.711 13.3337 11.9997Z"
        stroke={color || '#1F2933'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FileIcon = ({ color, className }) => {
  return (
    <svg
      width="15"
      className={className}
      height="17"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1.26946V5.4C11 5.96005 11 6.24008 11.109 6.45399C11.2049 6.64215 11.3578 6.79513 11.546 6.89101C11.7599 7 12.0399 7 12.6 7H16.7305M17 8.98822V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H5.8C4.11984 21 3.27976 21 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H9.01178C9.74555 1 10.1124 1 10.4577 1.08289C10.7638 1.15638 11.0564 1.27759 11.3249 1.44208C11.6276 1.6276 11.887 1.88703 12.4059 2.40589L15.5941 5.59411C16.113 6.11297 16.3724 6.3724 16.5579 6.67515C16.7224 6.94356 16.8436 7.2362 16.9171 7.5423C17 7.88757 17 8.25445 17 8.98822Z"
        stroke={color || '#1F2933'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const menu_observation_icon = (
  <svg
    // className={}
    width="18"
    height="23"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 10.8999H5M7 14.8999H5M13 6.8999H5M17 6.6999V17.0999C17 18.7801 17 19.6201 16.673 20.2619C16.3854 20.8264 15.9265 21.2853 15.362 21.5729C14.7202 21.8999 13.8802 21.8999 12.2 21.8999H5.8C4.11984 21.8999 3.27976 21.8999 2.63803 21.5729C2.07354 21.2853 1.6146 20.8264 1.32698 20.2619C1 19.6201 1 18.7801 1 17.0999V6.6999C1 5.01974 1 4.17967 1.32698 3.53793C1.6146 2.97344 2.07354 2.5145 2.63803 2.22688C3.27976 1.8999 4.11984 1.8999 5.8 1.8999H12.2C13.8802 1.8999 14.7202 1.8999 15.362 2.22688C15.9265 2.5145 16.3854 2.97344 16.673 3.53793C17 4.17967 17 5.01974 17 6.6999Z"
      stroke={'#1F2933'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menu_courses_icon = (
  <svg
    width="18"
    height="19"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.57181 19.8999C7.90661 19.2597 9.40997 18.8999 11 18.8999C12.59 18.8999 14.0934 19.2597 15.4282 19.8999M5.8 15.8999H16.2C17.8802 15.8999 18.7202 15.8999 19.362 15.5729C19.9265 15.2853 20.3854 14.8264 20.673 14.2619C21 13.6201 21 12.7801 21 11.0999V6.6999C21 5.01974 21 4.17967 20.673 3.53793C20.3854 2.97344 19.9265 2.5145 19.362 2.22688C18.7202 1.8999 17.8802 1.8999 16.2 1.8999H5.8C4.11984 1.8999 3.27976 1.8999 2.63803 2.22688C2.07354 2.5145 1.6146 2.97344 1.32698 3.53793C1 4.17967 1 5.01975 1 6.6999V11.0999C1 12.7801 1 13.6201 1.32698 14.2619C1.6146 14.8264 2.07354 15.2853 2.63803 15.5729C3.27976 15.8999 4.11984 15.8999 5.8 15.8999Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PDF_ICON = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33333 1.33325H9.13333C10.2534 1.33325 10.8135 1.33325 11.2413 1.55124C11.6176 1.74299 11.9236 2.04895 12.1153 2.42527C12.3333 2.85309 12.3333 3.41315 12.3333 4.53325V11.4666C12.3333 12.5867 12.3333 13.1467 12.1153 13.5746C11.9236 13.9509 11.6176 14.2569 11.2413 14.4486C10.8135 14.6666 10.2534 14.6666 9.13333 14.6666H4.86667C3.74656 14.6666 3.18651 14.6666 2.75869 14.4486C2.38236 14.2569 2.0764 13.9509 1.88465 13.5746C1.66667 13.1467 1.66667 12.5867 1.66667 11.4666V10.9999M9.66667 8.66659H6.66667M9.66667 5.99992H7.33333M9.66667 11.3333H4.33333M3 6.66659V2.99992C3 2.44763 3.44772 1.99992 4 1.99992C4.55228 1.99992 5 2.44763 5 2.99992V6.66659C5 7.77115 4.10457 8.66659 3 8.66659C1.89543 8.66659 1 7.77116 1 6.66659V3.99992"
      stroke="#1F2933"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const VIDEO_ICON = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6673 3.95417C14.6673 3.55029 14.6673 3.34835 14.5875 3.25484C14.5182 3.1737 14.4142 3.13065 14.3078 3.13902C14.1852 3.14867 14.0424 3.29146 13.7569 3.57704L11.334 5.99992L13.7569 8.4228C14.0424 8.70838 14.1852 8.85117 14.3078 8.86082C14.4142 8.86919 14.5182 8.82613 14.5875 8.745C14.6673 8.65149 14.6673 8.44955 14.6673 8.04567V3.95417Z"
      stroke="#1F2933"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33398 4.53325C1.33398 3.41315 1.33398 2.85309 1.55197 2.42527C1.74372 2.04895 2.04968 1.74299 2.426 1.55124C2.85383 1.33325 3.41388 1.33325 4.53398 1.33325H8.13398C9.25409 1.33325 9.81414 1.33325 10.242 1.55124C10.6183 1.74299 10.9243 2.04895 11.116 2.42527C11.334 2.85309 11.334 3.41315 11.334 4.53325V7.46659C11.334 8.58669 11.334 9.14674 11.116 9.57457C10.9243 9.95089 10.6183 10.2569 10.242 10.4486C9.81414 10.6666 9.25409 10.6666 8.13398 10.6666H4.53398C3.41388 10.6666 2.85383 10.6666 2.426 10.4486C2.04968 10.2569 1.74372 9.95089 1.55197 9.57457C1.33398 9.14674 1.33398 8.58669 1.33398 7.46659V4.53325Z"
      stroke="#1F2933"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MIC_ICON = () => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6673 6.66659V7.99992C10.6673 10.5772 8.57798 12.6666 6.00065 12.6666M1.33398 6.66659V7.99992C1.33398 10.5772 3.42332 12.6666 6.00065 12.6666M6.00065 12.6666V14.6666M3.33398 14.6666H8.66732M6.00065 9.99992C4.89608 9.99992 4.00065 9.10449 4.00065 7.99992V3.33325C4.00065 2.22868 4.89608 1.33325 6.00065 1.33325C7.10522 1.33325 8.00065 2.22868 8.00065 3.33325V7.99992C8.00065 9.10449 7.10522 9.99992 6.00065 9.99992Z"
      stroke="#1F2933"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CUSTOM_PRINT_ICON = ({ color = '#1F2933' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 7V5.2C18 4.0799 18 3.51984 17.782 3.09202C17.5903 2.71569 17.2843 2.40973 16.908 2.21799C16.4802 2 15.9201 2 14.8 2H9.2C8.0799 2 7.51984 2 7.09202 2.21799C6.71569 2.40973 6.40973 2.71569 6.21799 3.09202C6 3.51984 6 4.0799 6 5.2V7M6 18C5.07003 18 4.60504 18 4.22354 17.8978C3.18827 17.6204 2.37962 16.8117 2.10222 15.7765C2 15.395 2 14.93 2 14V11.8C2 10.1198 2 9.27976 2.32698 8.63803C2.6146 8.07354 3.07354 7.6146 3.63803 7.32698C4.27976 7 5.11984 7 6.8 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V14C22 14.93 22 15.395 21.8978 15.7765C21.6204 16.8117 20.8117 17.6204 19.7765 17.8978C19.395 18 18.93 18 18 18M15 10.5H18M9.2 22H14.8C15.9201 22 16.4802 22 16.908 21.782C17.2843 21.5903 17.5903 21.2843 17.782 20.908C18 20.4802 18 19.9201 18 18.8V17.2C18 16.0799 18 15.5198 17.782 15.092C17.5903 14.7157 17.2843 14.4097 16.908 14.218C16.4802 14 15.9201 14 14.8 14H9.2C8.0799 14 7.51984 14 7.09202 14.218C6.71569 14.4097 6.40973 14.7157 6.21799 15.092C6 15.5198 6 16.0799 6 17.2V18.8C6 19.9201 6 20.4802 6.21799 20.908C6.40973 21.2843 6.71569 21.5903 7.09202 21.782C7.51984 22 8.07989 22 9.2 22Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AttachmentIcon = ({ color = 'black' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 5.25581V16.5C17.5 19.5376 15.0376 22 12 22C8.96243 22 6.5 19.5376 6.5 16.5V5.66667C6.5 3.64162 8.14162 2 10.1667 2C12.1917 2 13.8333 3.64162 13.8333 5.66667V16.4457C13.8333 17.4583 13.0125 18.2791 12 18.2791C10.9875 18.2791 10.1667 17.4583 10.1667 16.4457V6.65116"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
