import { Add, Close } from '@mui/icons-material';
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Snackbar,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BootstrapDialog, Container } from '../../utils/styledLayout';
import CustomDataTable from '../../components/CustomDataTable';
import { TIMEZONE, TOKEN_KEY } from '../../utils/constant';
import axios from 'axios';
import moment from 'moment';
import { render } from '@testing-library/react';
import AddEditEvents from '../../containers/schedules/AddEditEvents';
import { convertUTCtoTimezone } from '../../utils/utils';
import SnackBarBox from '../../components/SnackBarBox';

const Events = () => {
  const [showError, setShowError] = useState({ error: false, message: '' });
  const [listOfEvents, setListOfEvents] = useState([]);
  const [showEventCreationModal, setShowEventCreationModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalEvents: 0
  });

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      fetchEvents(token);
    } else {
      setShowError({
        error: true,
        message: 'Please login to google account from share calendar. '
      });
      setLoading(false);

      setTimeout(() => {
        setShowError({ error: false, message: '' });
      }, 3000);
    }

    return () => {};
  }, [refresh]);

  const fetchEvents = async () => {
    try {
      let minDate = '2024-01-01T00:00:00Z';
      await axios
        .get(
          `https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${minDate}&singleEvents=true&orderBy=startTime`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
            }
          }
        )
        .then(res => {
          if (res && res.data) {
            setListOfEvents(
              res.data.items
                .map(event => ({
                  id: event.id,
                  title: event.summary,
                  start: event.start.dateTime || event.start.date,
                  end: event.end.dateTime || event.end.date,
                  createdDate: convertUTCtoTimezone(event.created),
                  createdBy: event.creator ? event.creator.self : 'N/A'
                }))
                .sort(
                  (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
                )
            );
            setPagination({
              ...pagination,
              totalEvents: res.data.items?.length || 0
            });
            setLoading(false);
          }
        });
    } catch (error) {
      console.error('Error fetching events:', error.message);
    }
  };

  const columns = [
    { uinqueKey: 'id', label: 'Title', id: 'title' },
    {
      label: 'Start Date',
      id: 'start',
      render: val => moment(val.start).format('DD-MM-YYYY')
    },
    {
      label: 'End Date',
      id: 'end',
      render: val => moment(val.end).format('DD-MM-YYYY')
    },
    {
      label: 'Created By',
      id: 'createdBy',
      render: val => (val ? 'Self' : 'Admin')
    },
    {
      label: 'Created Date',
      id: 'createdDate',
      render: (val, val12) => {
        return val.createdDate;
      }
    }
  ];

  return (
    <>
      <Container mb={2}>
        <Grid>
          <Typography mb={1} fontSize={20} fontWeight="medium">
            Events
          </Typography>
        </Grid>
        <Grid>
          <Button
            startIcon={<Add />}
            disabled={showError.error}
            onClick={() => {
              //   createEvent();
              setShowEventCreationModal({ id: -999 });
            }}
            variant="contained"
            sx={{
              boxShadow: 'none'
            }}
          >
            Create New Event
          </Button>
        </Grid>
      </Container>
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        sx={{ padding: '18px 10px' }}
        width={'100%'}
      >
        {loading && (
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={400}
            animation="wave"
          />
        )}
        {!loading && (
          <CustomDataTable
            columns={columns}
            data={[...listOfEvents].slice(
              pagination.page * pagination.rowsPerPage,
              (pagination.page + 1) * pagination.rowsPerPage
            )}
            onPageChange={val => {
              setPagination({ ...pagination, page: val });
            }}
            onRowsPerPageChange={val => {
              setPagination({ ...pagination, rowsPerPage: val });
            }}
            defaultOrderBy={'createdDate'}
            defaultPage={pagination.page}
            defaultRowsPerPage={pagination.rowsPerPage}
            totalCount={pagination.totalEvents}
          />
        )}
      </Grid>

      <BootstrapDialog
        maxWidth="sm"
        fullWidth
        open={showEventCreationModal}
        onClose={() => {
          setShowEventCreationModal(null);
        }}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography fontWeight={'600'}>Add new event</Typography>{' '}
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowEventCreationModal(null);
            }}
            sx={{
              color: theme => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <AddEditEvents
            eventId={showEventCreationModal?.id}
            eventDetails={showEventCreationModal}
            handleRefresh={() => setRefresh(!refresh)}
            onCancelClick={() => setShowEventCreationModal(null)}
          />
        </DialogContent>
      </BootstrapDialog>

      {showError.error && (
        <SnackBarBox type="error" message={showError.message} />
      )}
    </>
  );
};

export default Events;
