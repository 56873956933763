import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import {
  SELECT_FILE_MESSAGE,
  SELECT_IMAGE_MESSAGE,
  SELECT_VIDEO_MESSAGE,
  fileAllowedExtention,
  fileAllowedTypes,
  formsFileTypeIcon,
  imageAllowedExtention,
  imageAllowedTypes,
  videoAllowedExtention,
  videoAllowedTypes
} from '../../../utils/constant';
import _ from 'lodash';
import {
  generateVideoThumbnail,
  handleError,
  isFileExtensionAllowed,
  isImageExtensionAllowed,
  isVideoExtensionAllowed
} from '../../../utils/utils';
import SnackBarBox from '../../../components/SnackBarBox';
import { CancelOutlined, Close, SaveOutlined } from '@mui/icons-material';
import { colorPrimary } from '../../../config/theme';

const AttachmentUploadComponent = ({
  attachmentType = '',
  title = '',
  onSave,
  onCancel
}) => {
  const [attachmentValues, setAttachmentValues] = useState({
    title: '',
    link: '',
    attachment: null,
    thumbnail: null,
    type: attachmentType
  });
  const [attachmentUploadError, setAttachmentUploadError] = useState({
    error: false,
    errorMessage: ''
  });

  const [showError, setShowError] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    // Handle file upload here
    setAttachmentUploadError({
      error: false,
      errorMessage: ''
    });
    let file = acceptedFiles[0];
    const allowedTypes =
      (attachmentType === 'image' && imageAllowedTypes) ||
      (attachmentType === 'video' && videoAllowedTypes) ||
      (attachmentType === 'file' && fileAllowedTypes);

    const maxSizeMB = 20;
    const fileName = file ? file?.name : 'NA';

    if (allowedTypes.includes(file.type)) {
      if (
        (attachmentType === 'image' && isImageExtensionAllowed(fileName)) ||
        (attachmentType === 'video' && isVideoExtensionAllowed(fileName)) ||
        (attachmentType === 'file' && isFileExtensionAllowed(fileName))
      ) {
        if (file.size / 1024 / 1024 <= maxSizeMB) {
          setAttachmentValues({
            ...attachmentValues,
            attachment: file,
            title: fileName
          });

          // const reader = new FileReader();
          // reader.onload = e => {

          // };

          // reader.readAsDataURL(file);
        } else {
          setAttachmentUploadError({
            error: true,
            errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
          });
        }
      } else {
        setAttachmentUploadError({
          error: true,
          errorMessage:
            (attachmentType === 'image' && SELECT_IMAGE_MESSAGE) ||
            (attachmentType === 'video' && SELECT_VIDEO_MESSAGE) ||
            (attachmentType === 'file' && SELECT_FILE_MESSAGE)
        });
      }
    } else {
      setAttachmentUploadError({
        error: true,
        errorMessage:
          (attachmentType === 'image' && SELECT_IMAGE_MESSAGE) ||
          (attachmentType === 'video' && SELECT_VIDEO_MESSAGE) ||
          (attachmentType === 'file' && SELECT_FILE_MESSAGE)
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <Grid>
        <TextField
          sx={{}}
          size="small"
          fullWidth
          value={attachmentValues.title}
          placeholder={
            attachmentType === 'link' ? 'Text to display' : 'Attachment Title'
          }
          onChange={e =>
            setAttachmentValues({ ...attachmentValues, title: e.target.value })
          }
        />
        {attachmentType === 'link' && (
          <TextField
            size="small"
            sx={{ marginTop: 2 }}
            fullWidth
            placeholder="Link to"
            value={attachmentValues.link}
            onChange={e => {
              setAttachmentValues({
                ...attachmentValues,
                link: e.target.value
              });
            }}
            error={showError}
            helperText={showError && 'Please enter valid url'}
          />
        )}
        <>
          {attachmentValues.attachment && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '0px 10px',
                border: '1px solid',
                borderRadius: '7px',
                marginTop: 1
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  mr: 1,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {formsFileTypeIcon[attachmentValues.type]}
                <Typography
                  variant="body2"
                  // color="text.secondary"
                  fontWeight={'500'}
                  sx={{ marginLeft: 1 }}
                >
                  {attachmentValues.title || 'Add title'}
                </Typography>
              </Box>
              <Box sx={{ minWidth: 35, display: 'flex' }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    setAttachmentValues({
                      ...attachmentValues,
                      attachment: ''
                    });
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
          )}
          {/* {((attachmentType === 'video' &&
            _.includes([null, undefined, ''], attachmentValues.thumbnail)) ||
            (attachmentType === 'image' &&
              _.includes([null, undefined, ''], attachmentValues.attachment)) ||
            attachmentType === 'file') && (
            <> */}
          {_.includes(['image', 'video', 'file'], attachmentType) && (
            <Box
              {...getRootProps()}
              elevation={0}
              sx={{
                p: 2,
                mt: 2,
                height: 150,
                border: '2px dashed #cccccc',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: isDragActive ? '#eeeeee' : '#fafafa',
                transition: 'background-color 0.3s'
              }}
            >
              <input
                {...getInputProps({
                  accept: [
                    `.${
                      (attachmentType === 'image' &&
                        imageAllowedExtention.join(', .')) ||
                      (attachmentType === 'video' &&
                        videoAllowedExtention.join(', .')) ||
                      (attachmentType === 'file' &&
                        fileAllowedExtention.join(', .'))
                    }`
                  ]
                })}
              />
              <Typography fontWeight="600" color="#ababab" variant="body2">
                {isDragActive ? (
                  'Drop the files here...'
                ) : (
                  <p>
                    Drag & drop or Browser/Upload your file here
                    <p>
                      supported files types:{' '}
                      {(attachmentType === 'image' &&
                        imageAllowedExtention.join(', ')) ||
                        (attachmentType === 'video' &&
                          videoAllowedExtention.join(', ')) ||
                        (attachmentType === 'file' &&
                          fileAllowedExtention.join(', '))}
                    </p>
                  </p>
                )}
              </Typography>
            </Box>
          )}
          {/* </> */}
          {/* )} */}
        </>

        {/* <Divider orientation="horizontal" sx={{ marginY: 1 }} /> */}
        <Grid display={'flex'} gap={2} mt={3}>
          <Button
            variant="outlined"
            // startIcon={<CancelOutlined />}
            onClick={() => onCancel()}
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: 'black',
              borderWidth: '2px',
              height: '42px',
              width: '106px',
              '&:hover': {
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            // startIcon={<SaveOutlined />}
            sx={{
              fontWeight: '600',
              marginRight: '16px',
              px: 6,
              boxShadow: 'none'
            }}
            variant="contained"
            onClick={() => {
              if (
                !_.includes([null, undefined, ''], attachmentValues.title) &&
                !_.includes(
                  [null, undefined, ''],
                  attachmentType !== 'link'
                    ? attachmentValues.attachment
                    : attachmentValues.link
                )
              ) {
                onSave(attachmentValues);
              } else {
                setAttachmentUploadError({
                  error: true,
                  errorMessage: 'Please add values to save'
                });
              }
              setTimeout(() => {
                setAttachmentUploadError({
                  error: false,
                  errorMessage: ''
                });
              }, 9000);
            }}
          >
            {attachmentType === 'link' ? 'Add' : 'Select'}
          </Button>
        </Grid>
      </Grid>

      {attachmentUploadError.error && (
        <SnackBarBox
          type="error"
          message={handleError(attachmentUploadError.errorMessage)}
        />
      )}
    </>
  );
};

AttachmentUploadComponent.propTypes = {
  attachmentType: PropTypes.string.isRequired,
  title: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default AttachmentUploadComponent;
