import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
  styled
} from '@mui/material';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setActiveModule } from '../../redux/reducers/layoutSlice';
import { isEmptyArray } from 'formik';
import _ from 'lodash';
import {
  activateDeactivateArea,
  deleteArea,
  getArea,
  getAreaCount,
  revertAreaDetails
} from '../../redux/reducers/mastersSlice';
import SnackBarBox from '../../components/SnackBarBox';
import CustomDataTable from '../../components/CustomDataTable';
import SearchIcon from '@mui/icons-material/Search';
import {
  colorLightYellow,
  colorMidGray,
  colorPrimary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import {
  Add,
  CheckCircleOutline,
  MoreVert,
  NotInterested
} from '@mui/icons-material';
import ConfirmBox from '../../components/ConfirmBox';
import SearchInput from '../../components/SearchInput';
import moment from 'moment';
import { EditIcon, TrashIcon } from '../../utils/iconSvg';
import CustomTabLabel from '../../custom/CustomTabLabel';

const Container = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'block'
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const AreaPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { modules, isModulesSuccess } = useSelector(state => state.layout);
  const {
    isAreaLoading,
    isAreaSuccess,
    areaData,
    isAreaFailed,
    areaErrorContainer,
    isActivateDeactivateAreaLoading,
    isActivateDeactivateAreaSuccess,
    isActivateDeactivateAreaFailed,
    activateDeactivateAreaErrorContainer,
    activateDeactivateAreaResponse,
    isDeleteAreaSuccess,
    isDeleteAreaFailed,
    isDeleteAreaLoading,
    deleteAreaErrorContainer
  } = useSelector(state => state.masters);
  const [initialValues, setInitalValues] = useState({
    tabValue: 0,
    page: 0,
    size: 10,
    searchFilter: ''
  });
  const { authData } = useSelector(state => state.auth);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [confirmation, setConfimation] = useState(null);
  const [showError, setShowError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    document.title = createTitle('Area');
    dispatch(setActiveModule('Area'));
    dispatch(getAreaCount());
    return () => {
      dispatch(revertAreaDetails());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getArea({
        type:
          initialValues.tabValue === 1
            ? true
            : initialValues.tabValue === 2
              ? false
              : 'all',
        page: initialValues.page,
        size: initialValues.size,
        search: initialValues.searchFilter
      })
    );
  }, [initialValues]);

  const debouncedSearch = _.debounce(
    term => setInitalValues({ ...initialValues, searchFilter: term }),
    300
  );

  const handleSearchChange = e => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length >= 3 || term.length === 0) {
      debouncedSearch(term);
    }
  };

  const handleAreaEditNavigation = areaId => {
    navigate('area-details/edit-area', {
      state: {
        ...location.state,
        pinId: areaId
      }
    });
  };

  const columns = [
    {
      uinqueKey: 'pinId',
      id: 'area',
      label: 'Area',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.area}
          </Typography>
        );
      }
    },
    {
      id: 'pinCode',
      label: 'Pincode ',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.pinCode}
          </Typography>
        );
      }
    },
    {
      id: 'districtName',
      label: 'District ',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {/* {row.districtCode ? `(${row.districtCode})` : ''}  */}
            {row.districtName}
          </Typography>
        );
      }
    },
    {
      id: 'stateName',
      label: 'State ',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {/* {row.stateCode ? `(${row.stateCode})` : ''}  */}
            {row.stateName}
          </Typography>
        );
      }
    },
    {
      id: 'schools',
      label: 'Schools'
    },
    {
      id: 'users',
      label: 'Users'
    },

    {
      id: 'status',
      label: 'Status',
      width: '150px',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: row.status ? '#749E35' : '#D62828',
              width: row.status ? '62px' : '70px',
              height: '22px'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'createdBy',
      label: 'Created By'
    },
    {
      id: 'creationDate',
      label: 'Created On',
      width: '150px',
      render: row => {
        return (
          <>
            <Typography fontSize={'14px'} fontWeight={'600'}>
              {row.creationDate
                ? moment(row.creationDate).format('DD-MM-YYYY hh:mm A')
                : 'NA'}
            </Typography>
          </>
        );
      }
    },
    {
      id: 'edit',
      label: 'Edit',
      width: '50px',
      render: row => {
        return (
          <IconButton
            size="small"
            onClick={e => {
              handleAreaEditNavigation(row.pinId);
              e.stopPropagation();
            }}
          >
            <EditIcon />
          </IconButton>
        );
      }
    }
  ];

  const handleMenuActions = action => {
    setShowError(null);
    if (action === 'Delete') {
      handleDeleteArea();
    } else if (action === 'De-Activate') {
      handleDeActivateArea();
    } else if (action === 'Activate') {
      handleActivateArea();
    }
  };

  const handleDeleteArea = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(areaData?.area)) {
      if (isArrayNotEmpty(selectedAreas)) {
        setConfimation(true);
      } else {
        errorMessage = 'Please select area[s] to delete.';
      }
    }
    setShowError(errorMessage);
  };

  const handleActivateArea = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(areaData.area)) {
      if (!isArrayNotEmpty(selectedAreas)) {
        errorMessage = 'Please select area[s] to activate.';
      } else {
        const selectedUsersData = areaData.area.filter(user =>
          selectedAreas.includes(user.pinId)
        );
        const allUsersActive = selectedUsersData.every(user => !user.status);
        if (!allUsersActive) {
          errorMessage = 'Please select only inactive area[s] to activate.';
        } else {
          let payload = {
            isActivate: true,
            ids: [...selectedAreas],
            loggedInUserName: authData?.userName
          };
          dispatch(activateDeactivateArea(payload));
        }
      }
    }
    setShowError(errorMessage);
  };

  const handleDeActivateArea = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(areaData?.area)) {
      if (!isArrayNotEmpty(selectedAreas)) {
        errorMessage = 'Please select area[s] to in-activate.';
      } else {
        const selectedUsersData = areaData.area.filter(user =>
          selectedAreas.includes(user.pinId)
        );
        const allUsersActive = selectedUsersData.every(user => user.status);
        if (!allUsersActive) {
          errorMessage = 'Please select only active area[s] to in-activate.';
        } else {
          let payload = {
            isActivate: false,
            ids: [...selectedAreas],
            loggedInUserName: authData?.userName
          };

          dispatch(activateDeactivateArea(payload));
        }
      }
    }
    setShowError(errorMessage);
  };

  const deleteUser = () => {
    let deletePayload = {
      ids: [...selectedAreas],
      loggedInUserName: authData?.userName
    };
    dispatch(deleteArea(deletePayload));
  };

  const handleReset = () => {
    setSelectedAreas([]);
    setShowError(null);
    setConfimation(null);
    setInitalValues({ tabValue: 0, page: 0, size: 10, searchFilter: '' });
    setAnchorEl(null);
    dispatch(getAreaCount());
  };

  useEffect(() => {
    if (isActivateDeactivateAreaSuccess || isDeleteAreaSuccess) {
      return handleReset();
    }
  }, [isActivateDeactivateAreaSuccess, isDeleteAreaSuccess]);

  const handleOnRowClick = row => {
    return navigate(`area-details`, {
      state: { pinId: row.pinId }
    });
  };

  return (
    <>
      <Container mb={1}>
        <Grid>
          <Typography fontSize={32} fontWeight="700" display={'flex'}>
            Area
            {/* <Typography
              ml={1}
              fontSize={20}
              fontWeight="700"
              alignSelf={'center'}
            >
              ({areaData?.totalCount})
            </Typography> */}
          </Typography>
        </Grid>

        <Grid>
          <Button
            sx={{
              width: '140px',
              height: '42px',
              boxShadow: 'none',
              fontWeight: '700',
              fontSize: '14px'
            }}
            variant="contained"
            onClick={() => {
              navigate('new-area');
            }}
          >
            <SvgIcon
              fontSize="small"
              sx={{ marginRight: 0.8, height: '16px', width: '16px' }}
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                  stroke={colorTextBlack}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>{' '}
            Add new
          </Button>
        </Grid>
      </Container>
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        borderRadius={'8px'}
        sx={{ paddingX: '14px', pt: 1 }}
      >
        <Container
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <Tabs
            value={initialValues.tabValue}
            onChange={(val, newValue) => {
              setInitalValues({
                ...initialValues,
                tabValue: newValue,
                page: 0
              });
              setSelectedAreas([]);
            }}
            TabIndicatorProps={{
              sx: {
                height: '4px',
                borderRadius: '4px'
              }
            }}
          >
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                fontSize: '16px',
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 0 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 0
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="All Areas"
                  count={
                    areaData?.allCount < 10
                      ? `0${areaData?.allCount}`
                      : areaData?.allCount
                  }
                  selected={initialValues.tabValue === 0}
                />
              }
            />
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                fontSize: '16px',
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 1 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 1
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="Active Areas"
                  count={
                    areaData?.activeCount < 10
                      ? `0${areaData?.activeCount}`
                      : areaData?.activeCount
                  }
                  selected={initialValues.tabValue === 1}
                />
              }
            />
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                fontSize: '16px',
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 2 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 2
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="InActive Areas"
                  count={
                    areaData?.inActiveCount < 10
                      ? `0${areaData?.inActiveCount}`
                      : areaData?.inActiveCount
                  }
                  selected={initialValues.tabValue === 2}
                />
              }
            />
          </Tabs>
          <div style={{ display: 'flex', margin: 'auto 10px' }}>
            <SearchInput
              onSearchChange={handleSearchChange}
              searchTerm={searchTerm}
            />
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => {
                setAnchorEl(null);
                setShowError(null);
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: '20ch',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {['Activate', 'De-Activate', 'Delete'].map(option => (
                <MenuItem
                  key={option}
                  disabled={
                    (initialValues.tabValue === 1 && option === 'Activate') ||
                    (initialValues.tabValue === 2 && option === 'De-Activate')
                  }
                  onClick={() => {
                    handleMenuActions(option);
                  }}
                >
                  <ListItemIcon>
                    {option === 'Delete' ? (
                      <>
                        <SvgIcon sx={{ height: 20, width: 20 }}>
                          <TrashIcon color="red" />
                        </SvgIcon>
                      </>
                    ) : option === 'De-Activate' ? (
                      <SvgIcon
                        sx={{ height: 20, width: 20, color: 'orangered' }}
                      >
                        <NotInterested />
                      </SvgIcon>
                    ) : option === 'Activate' ? (
                      <SvgIcon sx={{ height: 20, width: 20, color: 'green' }}>
                        <CheckCircleOutline />
                      </SvgIcon>
                    ) : (
                      ''
                    )}
                  </ListItemIcon>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Container>
        <Grid sx={{ padding: '18px 0px' }} width={'100%'}>
          {(isAreaLoading || isActivateDeactivateAreaLoading) && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}
          {((!isAreaLoading && isAreaSuccess) ||
            (!isActivateDeactivateAreaLoading &&
              isActivateDeactivateAreaSuccess)) && (
            <CustomDataTable
              columns={columns}
              allowSelection={true}
              data={areaData?.area?.length > 0 ? areaData?.area : []}
              onPageChange={val => {
                setInitalValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitalValues({ ...initialValues, size: val });
              }}
              selectedRows={[...selectedAreas]}
              onSelectionChange={val => {
                setSelectedAreas(val);
              }}
              defaultOrderBy={'creationDate'}
              defaultPage={initialValues.page}
              defaultRowsPerPage={initialValues.size}
              totalCount={areaData?.totalCount}
              onRowClick={row => handleOnRowClick(row)}
            />
          )}
        </Grid>

        <ConfirmBox
          isOpen={confirmation}
          title="Delete Area!"
          subtitle={`${selectedAreas.length} area[s] are selected, Are you sure you want to delete. `}
          handleClose={() => setConfimation(null)}
          handleSubmit={() => {
            deleteUser();
          }}
        />

        {showError && <SnackBarBox type={'error'} message={showError} />}

        {!isAreaLoading && isAreaFailed && (
          <SnackBarBox
            type="error"
            message={handleError(areaErrorContainer?.errorMessage)}
          />
        )}

        {!isDeleteAreaLoading && isDeleteAreaSuccess && (
          <SnackBarBox
            type="success"
            message={'Area[s] deleted successfully. '}
          />
        )}

        {!isDeleteAreaLoading && isDeleteAreaFailed && (
          <SnackBarBox
            type="error"
            message={handleError(deleteAreaErrorContainer?.errorMessage)}
          />
        )}

        {!isActivateDeactivateAreaLoading &&
          isActivateDeactivateAreaSuccess && (
            <SnackBarBox
              type="success"
              message={activateDeactivateAreaResponse}
            />
          )}

        {!isActivateDeactivateAreaLoading && isActivateDeactivateAreaFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              activateDeactivateAreaErrorContainer?.errorMessage
            )}
          />
        )}
      </Grid>
    </>
  );
};

export default AreaPage;
