import { Box, Container, CssBaseline, styled } from '@mui/material';
import {
  colorLightYellow,
  colorPrimary,
  colorSecondary
} from '../config/theme';

const ImageContainer = styled('div')({
  textAlign: 'center',
  transform: 'translate(-50%, -50%)'
  //   display: 'block',
  //   '@media (min-width: 768px)': {
  //     top: '55%'
  //   }
});
const YellowBackground = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '80vh',
  backgroundColor: colorPrimary,
  overflow: 'hidden'
};

const Image = styled('img')({
  height: 300,
  display: 'block'
});

export const ComingSoonComponent = () => {
  return (
    <Container disableGutters component="main">
      <CssBaseline />
      <Box sx={YellowBackground}>
        <Image
          src="/images/feature_will_available.svg"
          alt="Feature_will_available"
        />
      </Box>
    </Container>
  );
};
