import { createTheme, Icon } from '@mui/material';
import {
  Calendar01Icon,
  CheckboxCheckedIcon,
  CheckboxIcon,
  ChevronDownIcon
} from '../utils/iconSvg';
import { ArrowDropDown } from '@mui/icons-material';
import { CalendarIcon } from '@mui/x-date-pickers';

export const colorPrimary = '#F4C24A';
export const colorSecondary = '#FCEBC5';
export const colorWhite = '#fff';
export const colorGolden = '#FEC310';
export const colorGrayish = '#636363';
export const colorPureWhite = '#ffffff';
export const colorDanger = '#db3131';
export const colorDangerFade = '#FEF6F4';
export const colorDarkGray = '#333333';
export const colorIconGreen = '#25d366';
export const colorIconDanger = '#D14836';
export const colorMidGray = '#636363';
export const colorSuccess = '#5EAC1B';
export const colorLightYellow = '#FFF8E6';
export const colorWarning = '#ed6c02';
export const colorTextBorder = '#CBD2D9';
export const colorDisableButton = '#FCEBC5';
export const colorAppBackground = '#F5F7FA';
export const colorContainedHoverButton = '#F7D178';
export const colorContainedDisableButton = '#FCEBC5';
export const colorTextBlack = '#1F2933';
export const colorTextGrey = '#ABB4BD';
export const colorLinkGrey = '#4E565F';
export const colorFieldError = '#D62828';
export const colorBorderGrey = '#D5D5D5';
export const colorDisableGrey = '#E4E7EB';
export const colorRatingStarOrange = '#EA7804';

const lightTheme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0, // default
  //     sm: 600, // default
  //     md: 900, // customize as needed
  //     lg: 1200, // customize as needed
  //     xl: 1536 // customize as needed
  //   }
  // },
  palette: {
    mode: 'light',
    primary: {
      main: colorPrimary
    },
    secondary: {
      main: colorSecondary
    },
    default: {
      main: colorPureWhite
    },
    golden: {
      main: colorGolden
    }
  },
  typography: {
    fontFamily: ['Lato'].join(','),
    button: {
      textTransform: 'none'
    },
    color: '#000',
    subtitle1: {
      color: '#000'
    },
    subtitle2: {
      color: '#000'
    },
    body1: {
      color: '#000'
    },
    body2: {
      color: '#000'
    }
  },
  shape: {
    borderRadius: 3
  },
  components: {
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevronDownIcon
      },
      styleOverrides: {
        icon: {
          color: colorTextBlack
        },
        root: {
          paddingRight: '10px'
        }
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ChevronDownIcon />
      },
      styleOverrides: {
        listbox: {
          backgroundColor: '#ffffff', // Default background color
          '& .MuiAutocomplete-option': {
            backgroundColor: '#ffffff' // Default background color
          },
          '& .MuiAutocomplete-option:hover': {
            backgroundColor: '#FEF8EC' // Hover background color
          },
          '& .MuiAutocomplete-option.Mui-focused': {
            backgroundColor: '#FEF8EC' // Focused option background color
          },
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: '#FCEBC5' // Selected option background color
          }
        },
        icon: {
          color: colorTextBlack
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          height: '42px',
          fontWeight: '700',
          fontSize: '14px',
          // width: '176px'
          boxShadow: 'none'
        },
        contained: {
          '&:hover': {
            backgroundColor: colorContainedHoverButton,
            boxShadow: 'none'
          },
          '&.Mui-disabled': {
            backgroundColor: colorContainedDisableButton,
            boxShadow: 'none'
          }
        },
        outlined: {
          ':root': {
            borderColor: colorPrimary,
            borderRadius: '10px',
            backgroundColor: colorWhite,
            color: 'black',
            borderWidth: '2px'
          },
          '&:hover': {
            borderColor: colorPrimary,
            backgroundColor: 'transparent',
            borderRadius: '10px',
            borderWidth: '2px'
          },
          '&.Mui-disabled': {
            // backgroundColor: colorContainedHoverButton
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: {
            color: colorGrayish
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          display: 'flex',
          fontSize: '14px',
          fontWeight: 500,
          alignItems: 'center'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: colorTextBlack,
          borderRadius: '6px',
          paddingLeft: '0%',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colorTextBorder
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colorTextBorder
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colorTextBorder
          },
          '& label.Mui-focused': {
            color: colorTextBorder
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: colorTextBorder
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: colorTextBorder
            },
            '&:hover fieldset': {
              borderColor: colorTextBorder
            },
            '&.Mui-focused fieldset': {
              borderColor: colorTextBorder
            }
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: colorFieldError // Replace with your desired error color
          },
          '&.Mui-disabled': {
            backgroundColor: '#FEF8EC',
            color: colorTextBlack,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'lightgray' // Change this to your desired disabled border color
            }
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colorGrayish,
          '&.Mui-focused': {
            color: colorGrayish
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        inputAdornedStart: {
          '& .MuiInputAdornment-root': {
            color: colorTextBlack
          }
        },
        underline: {
          '&:before': {
            borderBottomColor: colorDisableGrey // Default color
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottomColor: colorDisableGrey // Hover color
          },
          '&:after': {
            borderBottomColor: colorDisableGrey // Focused color
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: colorGolden,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          padding: '0px 24px'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: colorDanger,
          '&$error': {
            color: colorDanger
          }
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
        indeterminateIcon: <CheckboxCheckedIcon />
      },
      styleOverrides: {
        root: {},
        '& .MuiSvgIcon-root': {
          borderRadius: '14px'
        }
      }
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="8" fill="#749E35" />
              <path
                d="M22.6666 11L13.4999 20.1667L9.33325 16"
                stroke="#EBF9D9"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
          error: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="8" fill="#D62828" />
              <path
                d="M21 11L11 21M11 11L21 21"
                stroke="#FFEDED"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )
        }
      },
      styleOverrides: {
        message: { alignContent: 'center' },
        root: {
          minHeight: '56px',
          padding: '12px',
          borderRadius: '8px',
          fontWeight: '600',
          fontSize: '14px',
          lineHeight: '20px',
          alignContent: 'center'
        },
        icon: {
          padding: 0
        },

        filledError: {
          border: `1.5px solid #D62828`,
          backgroundColor: '#FFEDED', // Example: custom error background color
          color: '#D62828' // Example: custom error text color
        },
        filledSuccess: {
          border: `1.5px solid #749E35`,
          backgroundColor: '#EBF9D9', // Example: custom error background color
          color: '#749E35' // Example: custom error text color
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          color: colorRatingStarOrange
        }
        // iconHover: {
        //   color: colorHover // Change this to the color you want for hovered stars
        // },
        // iconEmpty: {
        //   color: colorEmpty // Change this to the color you want for empty stars
        // }
      }
    }
  }
});

const darkTheme = createTheme({
  ...lightTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: colorWhite
    }
  }
});

export { lightTheme, darkTheme };
