import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  assignFormToFlow,
  getUnAssignedForms,
  revertFormAssignment
} from '../../../redux/reducers/flowsSlice';
import {
  getOptionLabel,
  handleError,
  isArrayNotEmpty
} from '../../../utils/utils';
import { useSelector } from 'react-redux';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  Search
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { colorPrimary, colorSecondary } from '../../../config/theme';
import SnackBarBox from '../../../components/SnackBarBox';

const FormAssignModal = ({ flowId = null, onCancelClick }) => {
  const dispatch = useDispatch();
  const [selectedForm, setSelectedForm] = useState([]);
  const [showError, setShowError] = useState({ error: false, message: '' });
  const { authData } = useSelector(state => state.auth);
  const {
    unAssignedFormList,
    isAssignFormToFlowSuccess,
    isAssignFormToFlowLoading,
    isAssignFormToFlowFailed,
    assignFormToFlowErrorContainer
  } = useSelector(state => state.flow);

  React.useEffect(() => {
    if (flowId) {
      dispatch(getUnAssignedForms(flowId));
    }
  }, [flowId]);

  useEffect(() => {
    if (!isAssignFormToFlowLoading && isAssignFormToFlowSuccess) {
      onCancelClick();
      setTimeout(() => {
        dispatch(revertFormAssignment());
      }, 3000);
    }
  }, [isAssignFormToFlowSuccess]);

  const handleAssignFormToFlow = () => {
    if (selectedForm.length > 0) {
      let payload = {
        id: flowId,
        ids: [...selectedForm.map(cc => cc.id)],
        loggedInUserName: authData.userName
      };
      dispatch(assignFormToFlow(payload));
    } else {
      setShowError({ error: true, message: 'Select form to assign' });

      setTimeout(() => {
        setShowError({ error: false, message: '' });
      }, 3000);
    }
  };

  return (
    <Grid display={'block'}>
      <Grid item>
        <FormControl variant="outlined" fullWidth size="small">
          <Autocomplete
            limitTags={2}
            multiple
            size="small"
            value={selectedForm}
            onChange={(event, newValue) => {
              setSelectedForm(newValue);
            }}
            getOptionLabel={option => (option ? option.label : '')}
            isOptionEqualToValue={(option, value) =>
              option.label === (value ? value.label : '')
            }
            options={
              isArrayNotEmpty(unAssignedFormList)
                ? unAssignedFormList.map(c => {
                    return {
                      label: c.formName,
                      id: c.formId
                    };
                  })
                : []
            }
            disableCloseOnSelect
            sx={{ width: '100%' }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            renderInput={params => <TextField name={`formId`} {...params} />}
            ListboxProps={{
              sx: {
                '& .MuiAutocomplete-option:hover': {
                  backgroundColor: colorSecondary
                }
              }
            }}
          />
        </FormControl>
        <Box
          display="flex"
          flexWrap="wrap"
          overflowY="auto"
          maxHeight="200px"
          mt={1}
        >
          {selectedForm &&
            selectedForm.map((cc, indiIdx) => {
              return (
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                    mr: 1,
                    fontWeight: '600',
                    padding: '2px 6px',
                    background: '#FCEBC5',
                    border: `1px solid ${colorPrimary}`,
                    borderRadius: '6px'
                  }}
                  key={indiIdx}
                  variant="caption"
                >
                  {cc?.label}
                  <IconButton
                    onClick={() => {
                      setSelectedForm([
                        ...selectedForm.filter((_, i) => i !== indiIdx)
                      ]);
                    }}
                    sx={{
                      padding: 0,
                      ml: 2
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </Typography>
              );
            })}
        </Box>
      </Grid>

      <Grid pt={1} item display={'flex'} justifyContent={'start'} gap={2}>
        <Button
          variant="outlined"
          onClick={() => {
            onCancelClick();
          }}
          sx={{
            px: 4,
            borderColor: colorPrimary,
            borderRadius: '10px',
            color: 'black',
            borderWidth: '2px',
            height: '42px',
            width: '106px',
            '&:hover': {
              borderColor: colorPrimary,
              backgroundColor: 'transparent',
              borderRadius: '10px',
              borderWidth: '2px'
            }
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isAssignFormToFlowLoading}
          variant="contained"
          sx={{ mr: 2, px: 4, borderWidth: 2 }}
          onClick={() => {
            handleAssignFormToFlow();
          }}
        >
          Add
        </LoadingButton>
      </Grid>

      {showError.error && (
        <SnackBarBox type={'error'} message={showError.message} />
      )}
      {!isAssignFormToFlowLoading && isAssignFormToFlowFailed && (
        <SnackBarBox
          type={'error'}
          message={handleError(assignFormToFlowErrorContainer.errorMessage)}
        />
      )}
    </Grid>
  );
};

export default FormAssignModal;
