import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getMetaDataArea,
  getMetaDataDistrict,
  getMetaDataSchool,
  getMetaDataState,
  getObservationByUserId,
  getTeacherObservationsRubric,
  getTeacherObservationsUserAnalytics,
  getUserAnalytics,
  getUserAndRoleAnalytics
} from '../../redux/reducers/analyticsSlice';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import {
  Calendar01Icon,
  Expand05Icon,
  FileDownload03Icon,
  FilterLinesIcon,
  User01Icon,
  XClose
} from '../../utils/iconSvg';
import { isArrayNotEmpty } from '../../utils/utils';
import { useSelector } from 'react-redux';
import {
  colorPrimary,
  colorSecondary,
  colorTextBlack
} from '../../config/theme';
import {
  Legend,
  prepareChartOptionsAndData,
  styledMenuItem,
  StyledSelect
} from '../productanalytics/UserAndRoleAnalytics';
import dayjs from 'dayjs';
import { HighlightOff } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Chart from 'react-google-charts';
import { useLocation } from 'react-router-dom';
import { AutocompleteStyled } from '../productanalytics/TeacherObservationAnalytics';
import { apiNoAuth } from '../../config/api';

const SelectStyled = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '&:hover  .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8
  }
};
const ShareRubricAnalytics = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let chartType = new URLSearchParams(location.search).get('chartType');

  const [tempDateFilter, setTempDateFilter] = useState({
    dateType: null,
    startDate: null,
    endDate: null
  });
  const [chart, setChart] = useState('');
  const [open, setOpen] = useState(null);
  const [chart1Payload, setChart1Payload] = useState({
    indicatorId: null,
    dateType: 'all',
    startDate: null,
    endDate: null
  });

  const [chart2Payload, setChart2Payload] = useState({
    userId: null,
    districtId: 'all',
    schoolId: 'all',
    dateType: 'all',
    startDate: null,
    endDate: null
  });
  const [chart3Payload, setChart3Payload] = useState({
    userId: null,
    dateType: 'all',
    startDate: null,
    endDate: null
  });

  const [chart1Wrapper, setChart1Wrapper] = useState(null);
  const [chart2Wrapper, setChart2Wrapper] = useState(null);
  const [chart3Wrapper, setChart3Wrapper] = useState(null);

  const [indicatorOptions, setIndicatorOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [indicatorsLoading, setIndicatorsLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);

  const {
    isTeacherObservationsUserAnalyticsLoading,
    teacherObservationsAnalyticsData,
    isTeacherObservationsRubricAnalyticsLoading,
    metaDataDistrict,
    metaDataSchool,
    observationDetail,
    observationCardData,
    isObservationCardDataLoading
  } = useSelector(state => state.analytics);

  useEffect(() => {
    let mastersPayload = {
      type: 'all',
      page: 0,
      size: 0,
      search: ''
    };
    if (chartType == 'rubricChart') {
      fetchIndicatorOptions('');
    }

    if (chartType == 'usersChart') {
      fetchUsersOptions('');
    }

    dispatch(getMetaDataState(mastersPayload));
    dispatch(getMetaDataDistrict(mastersPayload));
    dispatch(getMetaDataArea(mastersPayload));
    dispatch(getMetaDataSchool(mastersPayload));
  }, []);

  useEffect(() => {
    if (chartType == 'rubricChart') {
      let payload = {
        indicatorId:
          chart1Payload.indicatorId == 'all'
            ? null
            : chart1Payload.indicatorId?.id,
        dateType:
          chart1Payload.dateType == 'all' ? null : chart1Payload.dateType,
        startDate: chart1Payload.startDate
          ? dayjs(chart1Payload.startDate).format('YYYY-MM-DD')
          : null,
        endDate: chart1Payload.endDate
          ? dayjs(chart1Payload.endDate).format('YYYY-MM-DD')
          : null
      };
      dispatch(getTeacherObservationsRubric(payload));
    }
  }, [chart1Payload]);

  useEffect(() => {
    if (chartType == 'observationChart') {
      let payload = {
        userId: chart2Payload.userId ? chart2Payload.userId?.id : null,
        stateId: null,
        districtId:
          chart2Payload.districtId == 'all' ? null : chart2Payload.districtId,
        schoolId:
          chart2Payload.schoolId == 'all' ? null : chart2Payload.schoolId,
        dateType:
          chart2Payload.dateType == 'all' ? null : chart2Payload.dateType,
        startDate: chart2Payload.startDate
          ? dayjs(chart2Payload.startDate).format('YYYY-MM-DD')
          : null,
        endDate: chart2Payload.endDate
          ? dayjs(chart2Payload.endDate).format('YYYY-MM-DD')
          : null
      };

      dispatch(getTeacherObservationsUserAnalytics(payload));
    }
  }, [chart2Payload]);

  useEffect(() => {
    if (chartType == 'usersObservationChart') {
      if (chart3Payload.userId !== null || chart3Payload.dateType !== 'all') {
        let payload = {
          userId: chart3Payload?.userId?.id,
          dateType: chart3Payload?.dateType,
          startDate: chart3Payload.startDate
            ? dayjs(chart3Payload.startDate).format('YYYY-MM-DD')
            : null,
          endDate: chart3Payload.endDate
            ? dayjs(chart3Payload.endDate).format('YYYY-MM-DD')
            : null
        };
        dispatch(getObservationByUserId(payload));
      }
    }
  }, [chart3Payload]);

  const handleClickOpen = id => {
    setChart(id);
  };

  const handleClose = () => {
    setChart('');
  };

  const char1Options = {
    hAxis: {
      title: 'Date',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      }
    },
    vAxis: {
      title: 'Count',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      },
      viewWindow: {
        min: 0.0 || 0
      },

      gridlines: {
        count: 10,
        color: '#F5F7FA',
        units: {
          ones: { count: 0 }
        }
      }
    },
    curveType: 'function',
    series: {
      0: { color: colorPrimary, curveType: 'function' },
      1: { color: '#2F68C4', curveType: 'function' },
      2: { color: '#749E35', curveType: 'function' }
    },
    legend: { position: 'none' }
  };

  const char2Options = {
    hAxis: {
      title: 'Date',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      }
    },
    vAxis: {
      title: 'Count',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      },
      viewWindow: {
        min: 0
      },

      gridlines: {
        count: 10,
        color: '#F5F7FA',
        units: {
          ones: { count: 0 }
        }
      }
    },
    curveType: 'function',
    series: {
      0: { color: colorPrimary, curveType: 'function' },
      1: { color: '#2F68C4', curveType: 'function' },
      2: { color: '#749E35', curveType: 'function' }
    },
    legend: { position: 'none' }
  };

  const char3Options = {
    hAxis: {
      title: 'Date',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      }
    },
    vAxis: {
      title: 'Count',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      },
      viewWindow: {
        min: 0
      },

      gridlines: {
        count: 10,
        color: '#F5F7FA',
        units: {
          ones: { count: 0 }
        }
      }
    },
    curveType: 'function',
    series: {
      0: { color: colorPrimary, curveType: 'function' },
      1: { color: '#2F68C4', curveType: 'function' },
      2: { color: '#749E35', curveType: 'function' }
    },
    legend: { position: 'none' }
  };

  const handleDownload = ({ id = null }) => {
    if (id) {
      const chartWrapper =
        (id === 1 && chart1Wrapper) ||
        (id === 2 && chart2Wrapper) ||
        (id === 3 && chart3Wrapper);
      const imageURI = chartWrapper.getChart().getImageURI();
      const link = document.createElement('a');
      link.href = imageURI;
      link.download = 'chart.png';
      link.click();
    }
  };

  const fetchUsersOptions = async searchText => {
    try {
      setUsersLoading(true);
      const response = await apiNoAuth.get(
        `/analytics/metadata/search${!searchText ? '' : `?searchCriteria=${searchText}`}`
      );
      if (
        response.data &&
        response.data.payload &&
        response.data.payload.dataList
      ) {
        setUserOptions([...response.data.payload.dataList]);
      } else {
        setUserOptions([]);
      }

      setUsersLoading(false);
    } catch (error) {
      setUsersLoading(false);
    }
  };

  const fetchIndicatorOptions = async searchText => {
    try {
      setIndicatorsLoading(true);
      const response = await apiNoAuth.get(
        `analytics/metadata/indicatorsByNameSearch?searchCriteria=${searchText}`
      );
      let indicatorList =
        response.data && response.data.payload
          ? response.data.payload.dataList
          : [] || [];
      setIndicatorOptions(indicatorList);
      setIndicatorsLoading(false);
    } catch (error) {
      setIndicatorsLoading(false);
    }
  };

  const handleUserInputChange = (event, value) => {
    if (value && (value !== null || value !== '')) {
      fetchUsersOptions(value);
    } else fetchUsersOptions('');
  };

  const handleIndicatorInputChange = (event, value) => {
    if (value && (value !== null || value !== '')) {
      fetchIndicatorOptions(value);
    } else fetchIndicatorOptions('');
  };

  return (
    <Grid item>
      <Typography mb={2} variant="h5" fontWeight={'600'}>
        Teacher Observation Analytics
      </Typography>

      {chartType == 'rubricChart' && (
        <Grid
          mt={2}
          container
          display={'flex'}
          width={'100%'}
          flexDirection={'column'}
          sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
        >
          <Grid item display={'flex'} mb={1}>
            <Grid container display={'flex'} mb={1}>
              <Grid xs={12} lg={3} sm={12} md={6} mr={1} mb={1}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    sx={{
                      ...AutocompleteStyled
                    }}
                    loading={indicatorsLoading}
                    value={chart1Payload.indicatorId}
                    onChange={(_, value) => {
                      setChart1Payload({
                        ...chart1Payload,
                        indicatorId: value
                      });
                    }}
                    options={
                      isArrayNotEmpty(indicatorOptions)
                        ? indicatorOptions.map(c => {
                            return {
                              label: c.indicatorName,
                              id: c.indicatorId
                            };
                          })
                        : []
                    }
                    onInputChange={handleIndicatorInputChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label=""
                        sx={{ ...AutocompleteStyled }}
                        placeholder="Select indicator"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {indicatorsLoading ? 'Loading...' : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option:hover': {
                          backgroundColor: colorSecondary
                        }
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} lg={3} sm={12} md={6} mr={1} mb={1}>
                <FormControl fullWidth>
                  <StyledSelect
                    size="small"
                    open={open == 1}
                    value={
                      open == 1
                        ? tempDateFilter.dateType
                        : chart1Payload.dateType
                    }
                    onOpen={(e, va) => {
                      setOpen(1);
                      setTempDateFilter({
                        dateType: chart1Payload.dateType,
                        startDate: chart1Payload.startDate,
                        endDate: chart1Payload.endDate
                      });
                    }}
                    onChange={e => {
                      setTempDateFilter({
                        ...tempDateFilter,
                        dateType: e.target.value,
                        startDate: null,
                        endDate: null
                      });
                    }}
                    renderValue={value => {
                      let data =
                        open === 1
                          ? { ...tempDateFilter }
                          : { ...chart1Payload };

                      if (data.dateType == 'all') {
                        return (
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#ABB4BD'
                            }}
                          >
                            <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                              <FilterLinesIcon color="#ABB4BD" />
                            </SvgIcon>
                            Select date
                          </Typography>
                        );
                      }
                      switch (data.dateType) {
                        case 'selected_date':
                          return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                        case 'Week':
                          return 'Last Week';
                        case 'Month':
                          return 'This Month';
                        case 'past_3_months':
                          return 'Past 3 months';
                        case 'Past_1_Year':
                          return 'Past 1 year';
                        default:
                      }
                    }}
                    // endAdornment={
                    //   chart1Payload.dateType ? (
                    //     <IconButton
                    //       sx={{ marginRight: '10px' }}
                    //       aria-label="clear"
                    //       size="small"
                    //       onClick={() => {
                    //         setChart1Payload({
                    //           ...chart1Payload,
                    //           dateType: 'all',
                    //           startDate: null,
                    //           endDate: null
                    //         });
                    //         setTempDateFilter({
                    //           startDate: null,
                    //           dateType: null,
                    //           endDate: null
                    //         });
                    //       }}
                    //       edge="end"
                    //     >
                    //       <HighlightOff sx={{ color: '#ABB4BD' }} />
                    //     </IconButton>
                    //   ) : null
                    // }
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&:hover  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8
                      }
                    }}
                  >
                    <MenuItem
                      sx={{ ...styledMenuItem, paddingX: 0 }}
                      value="selected_date"
                      disableRipple
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display={'flex'} margin={'0px 6px'}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <DatePicker
                              name="Start-Date"
                              sx={{ marginRight: 1 }}
                              value={dayjs(tempDateFilter.startDate)}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack }
                                  }
                                }
                              }}
                              onChange={date =>
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  startDate: date,
                                  endDate: date
                                })
                              }
                              components={{
                                OpenPickerIcon: props => (
                                  <IconButton size="small" {...props}>
                                    <Calendar01Icon />
                                  </IconButton>
                                )
                              }}
                            />
                          </FormControl>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <DatePicker
                              minDate={dayjs(tempDateFilter.startDate)}
                              label="End Date"
                              value={dayjs(tempDateFilter.endDate)}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack }
                                  }
                                }
                              }}
                              onChange={date => {
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  endDate: date
                                });
                              }}
                              components={{
                                OpenPickerIcon: props => (
                                  <IconButton size="small" {...props}>
                                    <Calendar01Icon />
                                  </IconButton>
                                )
                              }}
                            />
                          </FormControl>
                        </Box>
                      </LocalizationProvider>
                    </MenuItem>

                    <MenuItem sx={styledMenuItem} value="Week" disableRipple>
                      Last Week
                    </MenuItem>
                    <MenuItem sx={styledMenuItem} value="Month" disableRipple>
                      This Month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="past_3_months"
                      disableRipple
                    >
                      Past 3 month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="Past_1_Year"
                      disableRipple
                    >
                      Past 1 year
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        if (tempDateFilter.dateType) {
                          setChart1Payload({
                            ...chart1Payload,
                            dateType: tempDateFilter.dateType,
                            startDate: tempDateFilter.startDate,
                            endDate: tempDateFilter.endDate
                          });
                        }
                        setTempDateFilter({
                          dateType: null,
                          endDate: null,
                          startDate: null
                        });
                        setOpen(null);
                      }}
                    >
                      Apply
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        setChart1Payload({
                          ...chart1Payload,
                          dateType: 'all',
                          startDate: null,
                          endDate: null
                        });
                        setTempDateFilter({
                          startDate: null,
                          dateType: null,
                          endDate: null
                        });
                        setOpen(null);
                      }}
                    >
                      Close
                    </MenuItem>
                  </StyledSelect>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
            <IconButton onClick={() => handleClickOpen(1)} sx={{ mx: 0.5 }}>
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <Expand05Icon />
              </SvgIcon>
            </IconButton>
            <IconButton
              onClick={() => {
                handleDownload({ id: 1 });
              }}
              sx={{ mr: 0.5 }}
            >
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <FileDownload03Icon />
              </SvgIcon>
            </IconButton>
          </Grid>
          <Grid item xs={12} mb={2}>
            {isTeacherObservationsRubricAnalyticsLoading && (
              <Skeleton height={300} width={'100%'} />
            )}
            {!isTeacherObservationsRubricAnalyticsLoading && (
              <Chart
                height={'300px'}
                width={'100%'}
                chartType="LineChart"
                data={teacherObservationsAnalyticsData.rubricAnalyticsData}
                options={char1Options}
                getChartWrapper={val => {
                  setChart1Wrapper(val);
                }}
              />
            )}
          </Grid>
        </Grid>
      )}

      {chartType == 'observationChart' && (
        <Grid
          mt={2}
          container
          display={'flex'}
          width={'100%'}
          flexDirection={'column'}
          sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
        >
          <Grid item display={'flex'} mb={1}>
            <Grid container display={'flex'} mb={1}>
              <Grid xs={12} lg={2.9} sm={12} md={6} mr={1} mb={1}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    sx={{
                      ...AutocompleteStyled
                    }}
                    loading={usersLoading}
                    value={chart2Payload.userId}
                    onChange={(_, value) => {
                      setChart2Payload({
                        ...chart2Payload,
                        userId: value
                      });
                    }}
                    options={
                      isArrayNotEmpty(userOptions)
                        ? [...userOptions].map(c => {
                            return {
                              label: c.name,
                              id: c.userId
                            };
                          })
                        : []
                    }
                    onInputChange={handleUserInputChange}
                    renderInput={params => (
                      <TextField
                        sx={{ ...AutocompleteStyled }}
                        {...params}
                        label=""
                        placeholder="Users"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {usersLoading ? 'Loading...' : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option:hover': {
                          backgroundColor: colorSecondary
                        }
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} lg={2.9} sm={12} md={6} mr={1} mb={1}>
                <FormControl variant="outlined" fullWidth size="small">
                  <Select
                    placeholder="Select district"
                    size="small"
                    value={chart2Payload.districtId}
                    onChange={e =>
                      setChart2Payload({
                        ...chart2Payload,
                        districtId: e.target.value,
                        schoolId: 'all'
                      })
                    }
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&:hover  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8
                      }
                    }}
                    renderValue={val => {
                      let value =
                        isArrayNotEmpty(metaDataDistrict) &&
                        metaDataDistrict.find(
                          (row, key) => row.districtId === val
                        );
                      return val === 'all' ? (
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#ABB4BD'
                          }}
                        >
                          Select district
                        </Typography>
                      ) : (
                        value?.districtName
                      );
                    }}
                  >
                    {isArrayNotEmpty(metaDataDistrict) &&
                      metaDataDistrict.map((row, key) => {
                        return (
                          <MenuItem
                            key={key}
                            value={row?.districtId}
                            sx={{
                              ':hover': {
                                backgroundColor: colorSecondary
                              }
                            }}
                          >
                            {row?.districtName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} lg={2.9} sm={12} md={6} mr={1} mb={1}>
                <FormControl variant="outlined" fullWidth size="small">
                  <Select
                    size="small"
                    value={chart2Payload.schoolId}
                    onChange={e =>
                      setChart2Payload({
                        ...chart2Payload,
                        schoolId: e.target.value
                      })
                    }
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&:hover  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8
                      }
                    }}
                    renderValue={val => {
                      let value =
                        isArrayNotEmpty(metaDataSchool) &&
                        metaDataSchool.find((row, key) => row.schoolId === val);

                      return val === 'all' ? (
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#ABB4BD'
                          }}
                        >
                          Select school
                        </Typography>
                      ) : (
                        value?.schoolName
                      );
                    }}
                  >
                    {isArrayNotEmpty(metaDataSchool) &&
                      metaDataSchool
                        .filter(
                          cc => cc.districtId === chart2Payload.districtId
                        )
                        .map((row, key) => {
                          return (
                            <MenuItem
                              key={key}
                              value={row?.schoolId}
                              sx={{
                                ':hover': {
                                  backgroundColor: colorSecondary
                                }
                              }}
                            >
                              {row?.schoolName}
                            </MenuItem>
                          );
                        })}
                    {isArrayNotEmpty(metaDataSchool) &&
                      !metaDataSchool.filter(
                        cc => cc.districtId === chart2Payload.districtId
                      ).length > 0 && <MenuItem value={'all'}>None</MenuItem>}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} lg={2.9} sm={12} md={6} mr={1} mb={1}>
                <FormControl variant="outlined" fullWidth size="small">
                  <StyledSelect
                    size="small"
                    open={open == 2}
                    value={
                      open == 2
                        ? tempDateFilter.dateType
                        : chart2Payload.dateType
                    }
                    onOpen={(e, va) => {
                      setOpen(2);
                      setTempDateFilter({
                        dateType: chart2Payload.dateType,
                        startDate: chart2Payload.startDate,
                        endDate: chart2Payload.endDate
                      });
                    }}
                    onChange={e => {
                      setTempDateFilter({
                        ...tempDateFilter,
                        dateType: e.target.value,
                        startDate: null,
                        endDate: null
                      });
                    }}
                    renderValue={value => {
                      let data = open === 2 ? tempDateFilter : chart2Payload;
                      if (data.dateType === 'all') {
                        return (
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#ABB4BD'
                            }}
                          >
                            <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                              <FilterLinesIcon color="#ABB4BD" />
                            </SvgIcon>
                            Select date
                          </Typography>
                        );
                      }
                      switch (data.dateType) {
                        case 'selected_date':
                          return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                        case 'Week':
                          return 'Last Week';
                        case 'Month':
                          return 'This Month';
                        case 'past_3_months':
                          return 'Past 3 months';
                        case 'Past_1_Year':
                          return 'Past 1 year';
                        default:
                          return;
                      }
                    }}
                    // endAdornment={
                    //   chart2Payload.dateType ? (
                    //     <IconButton
                    //       sx={{ marginRight: '10px' }}
                    //       aria-label="clear"
                    //       size="small"
                    //       onClick={() => {
                    //         setChart2Payload({
                    //           ...chart2Payload,
                    //           dateType: 'all',
                    //           startDate: null,
                    //           endDate: null
                    //         });
                    //         setTempDateFilter({
                    //           startDate: null,
                    //           dateType: null,
                    //           endDate: null
                    //         });
                    //       }}
                    //       edge="end"
                    //     >
                    //       <HighlightOff sx={{ color: '#ABB4BD' }} />
                    //     </IconButton>
                    //   ) : null
                    // }
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&:hover  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8
                      }
                    }}
                  >
                    <MenuItem
                      sx={{ ...styledMenuItem, paddingX: 0 }}
                      value="selected_date"
                      disableRipple
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display={'flex'} margin={'0px 6px'}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <DatePicker
                              name="Start-Date"
                              sx={{ marginRight: 1 }}
                              value={dayjs(tempDateFilter.startDate)}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack }
                                  }
                                }
                              }}
                              onChange={date =>
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  startDate: date,
                                  endDate: date
                                })
                              }
                              components={{
                                OpenPickerIcon: props => (
                                  <IconButton size="small" {...props}>
                                    <Calendar01Icon />
                                  </IconButton>
                                )
                              }}
                            />
                          </FormControl>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <DatePicker
                              minDate={dayjs(tempDateFilter.startDate)}
                              label="End Date"
                              value={dayjs(tempDateFilter.endDate)}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack }
                                  }
                                }
                              }}
                              onChange={date => {
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  endDate: date
                                });
                              }}
                              components={{
                                OpenPickerIcon: props => (
                                  <IconButton size="small" {...props}>
                                    <Calendar01Icon />
                                  </IconButton>
                                )
                              }}
                            />
                          </FormControl>
                        </Box>
                      </LocalizationProvider>
                    </MenuItem>

                    <MenuItem sx={styledMenuItem} value="Week" disableRipple>
                      Last Week
                    </MenuItem>
                    <MenuItem sx={styledMenuItem} value="Month" disableRipple>
                      This Month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="past_3_months"
                      disableRipple
                    >
                      Past 3 month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="Past_1_Year"
                      disableRipple
                    >
                      Past 1 year
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        if (tempDateFilter.dateType) {
                          setChart2Payload({
                            ...chart2Payload,
                            dateType: tempDateFilter.dateType,
                            startDate: tempDateFilter.startDate,
                            endDate: tempDateFilter.endDate
                          });
                        }
                        setTempDateFilter({
                          dateType: null,
                          endDate: null,
                          startDate: null
                        });
                        setOpen(null);
                      }}
                    >
                      Apply
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        setChart2Payload({
                          ...chart2Payload,
                          dateType: 'all',
                          startDate: null,
                          endDate: null
                        });
                        setTempDateFilter({
                          startDate: null,
                          dateType: null,
                          endDate: null
                        });
                        setOpen(null);
                      }}
                    >
                      Close
                    </MenuItem>
                  </StyledSelect>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
            <IconButton onClick={() => handleClickOpen(2)} sx={{ mx: 0.5 }}>
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <Expand05Icon />
              </SvgIcon>
            </IconButton>
            <IconButton
              onClick={() => {
                handleDownload({ id: 2 });
              }}
              sx={{ mr: 0.5 }}
            >
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <FileDownload03Icon />
              </SvgIcon>
            </IconButton>
          </Grid>
          <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
            <Legend
              series={
                teacherObservationsAnalyticsData.userAnalyticsData?.length > 0
                  ? teacherObservationsAnalyticsData.userAnalyticsData[0]
                      .slice(1)
                      .map((name, index) => {
                        return {
                          name,
                          color: char2Options.series[index].color
                        };
                      })
                  : []
              }
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            {isTeacherObservationsUserAnalyticsLoading && (
              <Skeleton height={300} width={'100%'} />
            )}
            {!isTeacherObservationsUserAnalyticsLoading && (
              <Chart
                height={'300px'}
                width={'100%'}
                chartType="LineChart"
                data={teacherObservationsAnalyticsData?.userAnalyticsData}
                options={char2Options}
                getChartWrapper={val => {
                  setChart2Wrapper(val);
                }}
              />
            )}
          </Grid>
        </Grid>
      )}

      {chartType == 'usersObservationChart' && (
        <Grid
          mt={2}
          container
          display={'flex'}
          width={'100%'}
          flexDirection={'column'}
          sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
        >
          <Grid item display={'flex'} mb={1}>
            <Grid container display={'flex'} mb={1}>
              <Grid xs={12} lg={3} sm={12} md={6} mr={1} mb={1}>
                <FormControl fullWidth>
                  <StyledSelect
                    size="small"
                    open={open == 3}
                    value={
                      open == 3
                        ? tempDateFilter.dateType
                        : chart3Payload.dateType
                    }
                    onOpen={(e, va) => {
                      setOpen(3);
                      setTempDateFilter({
                        dateType: chart3Payload.dateType,
                        startDate: chart3Payload.startDate,
                        endDate: chart3Payload.endDate
                      });
                    }}
                    onChange={e => {
                      setTempDateFilter({
                        ...tempDateFilter,
                        dateType: e.target.value,
                        startDate: null,
                        endDate: null
                      });
                    }}
                    renderValue={value => {
                      let data =
                        open === 1
                          ? { ...tempDateFilter }
                          : { ...chart3Payload };

                      if (data.dateType == 'all') {
                        return (
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#ABB4BD'
                            }}
                          >
                            <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                              <FilterLinesIcon color="#ABB4BD" />
                            </SvgIcon>
                            Select date
                          </Typography>
                        );
                      }
                      switch (data.dateType) {
                        case 'selected_date':
                          return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                        case 'Week':
                          return 'Last Week';
                        case 'Month':
                          return 'This Month';
                        case 'past_3_months':
                          return 'Past 3 months';
                        case 'Past_1_Year':
                          return 'Past 1 year';
                        default:
                      }
                    }}
                    // endAdornment={
                    //   chart3Payload.dateType ? (
                    //     <IconButton
                    //       sx={{ marginRight: '10px' }}
                    //       aria-label="clear"
                    //       size="small"
                    //       onClick={() => {
                    //         setChart1Payload({
                    //           ...chart3Payload,
                    //           dateType: 'all',
                    //           startDate: null,
                    //           endDate: null
                    //         });
                    //         setTempDateFilter({
                    //           startDate: null,
                    //           dateType: null,
                    //           endDate: null
                    //         });
                    //       }}
                    //       edge="end"
                    //     >
                    //       <HighlightOff sx={{ color: '#ABB4BD' }} />
                    //     </IconButton>
                    //   ) : null
                    // }
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&:hover  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8
                      }
                    }}
                  >
                    <MenuItem
                      sx={{ ...styledMenuItem, paddingX: 0 }}
                      value="selected_date"
                      disableRipple
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display={'flex'} margin={'0px 6px'}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <DatePicker
                              name="Start-Date"
                              sx={{ marginRight: 1 }}
                              value={dayjs(tempDateFilter.startDate)}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack }
                                  }
                                }
                              }}
                              onChange={date =>
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  startDate: date,
                                  endDate: date
                                })
                              }
                              components={{
                                OpenPickerIcon: props => (
                                  <IconButton size="small" {...props}>
                                    <Calendar01Icon />
                                  </IconButton>
                                )
                              }}
                            />
                          </FormControl>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <DatePicker
                              minDate={dayjs(tempDateFilter.startDate)}
                              label="End Date"
                              value={dayjs(tempDateFilter.endDate)}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack }
                                  }
                                }
                              }}
                              onChange={date => {
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  endDate: date
                                });
                              }}
                              components={{
                                OpenPickerIcon: props => (
                                  <IconButton size="small" {...props}>
                                    <Calendar01Icon />
                                  </IconButton>
                                )
                              }}
                            />
                          </FormControl>
                        </Box>
                      </LocalizationProvider>
                    </MenuItem>

                    <MenuItem sx={styledMenuItem} value="Week" disableRipple>
                      Last Week
                    </MenuItem>
                    <MenuItem sx={styledMenuItem} value="Month" disableRipple>
                      This Month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="past_3_months"
                      disableRipple
                    >
                      Past 3 month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="Past_1_Year"
                      disableRipple
                    >
                      Past 1 year
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        if (tempDateFilter.dateType) {
                          setChart3Payload({
                            ...chart3Payload,
                            dateType: tempDateFilter.dateType,
                            startDate: tempDateFilter.startDate,
                            endDate: tempDateFilter.endDate
                          });
                        }
                        setTempDateFilter({
                          dateType: null,
                          endDate: null,
                          startDate: null
                        });
                        setOpen(null);
                      }}
                    >
                      Apply
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        setChart3Payload({
                          ...chart3Payload,
                          dateType: 'all',
                          startDate: null,
                          endDate: null
                        });
                        setTempDateFilter({
                          startDate: null,
                          dateType: null,
                          endDate: null
                        });
                        setOpen(null);
                      }}
                    >
                      Close
                    </MenuItem>
                  </StyledSelect>
                </FormControl>
              </Grid>{' '}
              <Grid xs={12} lg={3} sm={12} md={6} mr={1} mb={1}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    sx={{
                      ...AutocompleteStyled
                    }}
                    loading={usersLoading}
                    value={chart3Payload.userId}
                    onChange={(_, value) => {
                      setChart3Payload({
                        ...chart3Payload,
                        userId: value
                      });
                    }}
                    options={
                      isArrayNotEmpty(userOptions)
                        ? [...userOptions].map(c => {
                            return {
                              label: c.name,
                              id: c.userId
                            };
                          })
                        : []
                    }
                    onInputChange={handleUserInputChange}
                    renderInput={params => (
                      <TextField
                        sx={{ ...AutocompleteStyled }}
                        {...params}
                        label=""
                        placeholder="Users"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {usersLoading ? 'Loading...' : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option:hover': {
                          backgroundColor: colorSecondary
                        }
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
            <IconButton onClick={() => handleClickOpen(3)} sx={{ mx: 0.5 }}>
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <Expand05Icon />
              </SvgIcon>
            </IconButton>
            <IconButton
              onClick={() => {
                handleDownload({ id: 3 });
              }}
              sx={{ mr: 0.5 }}
            >
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <FileDownload03Icon />
              </SvgIcon>
            </IconButton>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Legend
              series={
                isArrayNotEmpty(observationCardData)
                  ? observationCardData[0].slice(1).map((name, index) => ({
                      name,
                      color: char3Options.series[index].color
                    }))
                  : []
              }
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            {isObservationCardDataLoading && (
              <Skeleton height={300} width={'100%'} />
            )}
            {!isObservationCardDataLoading && (
              <Chart
                height={'300px'}
                width={'100%'}
                chartType="LineChart"
                data={observationCardData}
                options={char3Options}
                getChartWrapper={val => {
                  setChart3Wrapper(val);
                }}
              />
            )}
          </Grid>
        </Grid>
      )}

      <Dialog
        sx={{ borderRadius: '8px' }}
        open={chart !== ''}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ borderRadius: 10 }}>
          <Box textAlign={'end'}>
            <IconButton size="small" onClick={handleClose}>
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <XClose />
              </SvgIcon>
            </IconButton>
            {(chart == 2 || chart == 3) && (
              <Legend
                series={
                  chart === 3
                    ? observationCardData.length > 0
                      ? observationCardData[0].slice(1).map((name, index) => ({
                          name,
                          color: char3Options.series[index].color
                        }))
                      : []
                    : teacherObservationsAnalyticsData.userAnalyticsData
                          .length > 0
                      ? teacherObservationsAnalyticsData.userAnalyticsData[0]
                          .slice(1)
                          .map((name, index) => ({
                            name,
                            color: char2Options.series[index].color
                          }))
                      : []
                }
              />
            )}
          </Box>
          {chart == 1 && (
            <Chart
              height={'300px'}
              width={'100%'}
              chartType="LineChart"
              data={teacherObservationsAnalyticsData.rubricAnalyticsData}
              options={char1Options}
            />
          )}
          {chart == 2 && (
            <Chart
              height={'300px'}
              width={'100%'}
              chartType="LineChart"
              data={teacherObservationsAnalyticsData.userAnalyticsData}
              options={char2Options}
            />
          )}
          {chart == 3 && (
            <Chart
              height={'300px'}
              width={'100%'}
              chartType="LineChart"
              data={observationCardData}
              options={char3Options}
            />
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ShareRubricAnalytics;
