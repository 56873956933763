import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, apiNoAuth, multipartApi } from '../../config/api';
import {
  clearActiveMenuData,
  clearAuthData,
  clearBasicDetails,
  clearMenuArray,
  clearMenuData,
  clearOpenMenuData,
  clearToken,
  setAuthData,
  setBasicDetails,
  setToken
} from '../../config/cookie';
import axios from 'axios';
import { getNotificaitonsList, getSidebarMenu } from './layoutSlice';
import { TOKEN_KEY } from '../../utils/constant';
export const revertAll = createAction('REVERT_ALL');
export const setAuthDetailsByLocalStorage = createAction('SET_AUTH_DETAILS');
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');
export const clearLoginState = createAction('CLEAR_LOGIN_STATE');

const setTokenToApi = token => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  multipartApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post('/logout', credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const pingServer = createAsyncThunk(
  'auth/actuator/ping',
  async (_, { rejectWithValue }) => {
    try {
      let url = process.env.REACT_APP_BASE_API_URL;
      const parts = url.split('/api/v1');
      const trimmedURl = parts[0];
      const response = await axios.get(`${trimmedURl}/actuator/health`);
      return response.data;
    } catch (error) {
      if (
        error &&
        error?.message &&
        String(error?.message).includes('Network Error')
      ) {
        return { status: 'DOWN' };
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const authenticateUser = createAsyncThunk(
  'login/authenticateUser',
  async (credentials, { dispatch, rejectWithValue }) => {
    try {
      const { username, password, rememberMe } = credentials;
      const authenticateResponse = await apiNoAuth.post(
        '/authenticate',
        credentials
      );

      if (authenticateResponse.data?.payload?.token) {
        setTokenToApi(authenticateResponse.data?.payload?.token);

        let loginResponse = await api.post('/login', credentials);
        let responsePayload = {
          ...loginResponse.data?.payload,
          jwtToken: authenticateResponse.data?.payload?.token
        };

        if (rememberMe) {
          localStorage.setItem(
            'rememberedUser',
            JSON.stringify({ username, password })
          );
        } else {
          localStorage.removeItem('rememberedUser');
        }
        dispatch(getSidebarMenu(responsePayload.pageData));
        dispatch(getNotificaitonsList(loginResponse.data?.payload?.id));
        return responsePayload;
      }
    } catch (error) {
      if (error?.response && error?.response?.status === 401) {
        return rejectWithValue({
          error: false,
          errorMessage: 'Invalid password entered',
          description: 'Please enter correct details',
          statusCode: error.response.status
        });
      }

      if (error?.response && error?.response?.status === 400) {
        return rejectWithValue({
          error: false,
          errorMessage: error.response.data?.error?.errorMessage,
          description: error.response.data?.error?.errorMessage,
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const passwordRecovery = createAsyncThunk(
  'auth/passwordRecovery',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.get(
        `/login/forgotPassword/${credentials}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (credentials, { rejectWithValue }) => {
    try {
      // console.log(credentials, token, 'credentials');
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}login/updatePassword`,
        { username: credentials?.username, password: credentials?.password },
        {
          headers: {
            Authorization: `Bearer ${credentials?.token}`
          }
        }
      );

      return response;
    } catch (error) {
      console.log(error, 'rere');
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (credentials, { rejectWithValue, getState }) => {
    try {
      const response = await api.get(
        `login/resetPassword/${credentials}?loggedInUserName=${getState()?.auth?.authData?.userName}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const teacherDashboard = createAsyncThunk(
  'auth/teacherDashboard',
  async (credentials, { rejectWithValue, getState }) => {
    try {
      367;
      const response = await api.get(
        `teachers/observation/dashBoard/${getState()?.auth?.authData?.userId}`
      );

      return response.data?.payload || intial.teacherDashboardCountData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);
// Added by atul

const intial = {
  isLoading: false,
  isLoggedIn: false,
  authData: {
    jwtToken: '',
    role: '',
    roleId: 0,
    userName: '',
    name: '',
    userId: 0
  },
  name: '',
  profilePic: '',
  isError: false,
  errorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  // applicationStatus: 'UP',
  isPingServerLoading: false,
  isPingServerSuccess: false,
  isPingServerFailed: false,

  isAuthenticationLoading: false,
  isAuthenticationSuccess: false,
  isAuthenticationFailed: false,

  isPasswordRecoveryLoading: false,
  isPasswordRecoverySuccess: false,
  isPasswordRecoveryFailed: false,
  isPasswordRecoveryError: false,
  passwordRecoveryErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUpdatePasswordLoading: false,
  isUpdatePasswordSuccess: false,
  isUpdatePasswordFailed: false,
  isUpdatePasswordError: false,
  updatePasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isTeacherDashboardCountLoading: false,
  isTeacherDashboardCountSuccess: false,
  isTeacherDashboardCountFailed: false,
  isTeacherDashboardCountError: false,
  teacherDashboardCountData: {
    total: 0,
    byMe: 0,
    forMe: 0,
    schoolName: '',
    averageRating: 0.0,
    observations: []
  },
  teacherDashboardCountErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(logoutUser.pending, state => {
        (state.isLoading = true),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(logoutUser.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        clearToken();
        (state.isLoading = false),
          (state.isLoggedIn = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(revertAll, () => {
        return intial;
      })
      .addCase(logoutAndClearToken, state => {
        clearToken();
        clearAuthData();
        clearMenuArray();
        clearMenuData();
        clearOpenMenuData();
        clearActiveMenuData();
        clearBasicDetails();
        state.isLoggedIn = false;
        // localStorage.removeItem(TOKEN_KEY);
        // return intial;
      })
      .addCase(clearLoginState, state => {
        state.isPasswordRecoveryError = false;
        state.isAuthenticationLoading = false;
        state.isPasswordRecoverySuccess = false;
        state.isUpdatePasswordSuccess = false;
        state.isError = false;
        state.errorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
        state.passwordRecoveryErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(setAuthDetailsByLocalStorage, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.authData.userId = action.payload['userId'];
        state.authData.role = action.payload['role'];
        state.authData = {
          ...action.payload
        };
      })
      .addCase(pingServer.pending, state => {
        (state.isPingServerLoading = true), (state.isPingServerFailed = false);
      })
      .addCase(pingServer.fulfilled, (state, action) => {
        (state.isPingServerLoading = false),
          (state.isPingServerSuccess = true),
          (state.applicationStatus = action.payload?.status);
      })
      .addCase(pingServer.rejected, state => {
        (state.isPingServerLoading = false), (state.isPingServerFailed = true);
      })

      .addCase(authenticateUser.pending, state => {
        state.isAuthenticationLoading = true;
        state.isAuthenticationSuccess = false;
        state.isAuthenticationFailed = false;
        state.isError = false;
        state.errorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        clearToken();
        clearAuthData();
        setToken(action.payload?.jwtToken);
        setAuthData({
          userId: action.payload?.id,
          role: action.payload?.role,
          roleId: action.payload?.roleId,
          name: action.payload?.name
            ? action.payload?.name
            : action.payload?.userName,
          userName: action.payload?.userName,
          userImage: action.payload?.userImageUrl,
          isAdmin: action.payload?.isAdmin || false,
          roleType: action.payload?.roleType
        });
        setBasicDetails(action.payload?.userImageUrl, action.payload?.name);
        state.isLoggedIn = true;
        state.isAuthenticationLoading = false;
        state.isAuthenticationSuccess = true;
        state.isAuthenticationFailed = false;
        state.authData = {
          ...state.authData,
          ...action.payload,
          userId: action.payload?.id,
          jwtToken: action.payload?.jwtToken
        };
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.isAuthenticationLoading = false;
        state.isAuthenticationSuccess = true;
        state.isAuthenticationFailed = false;
        (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })

      .addCase(passwordRecovery.pending, state => {
        state.isPasswordRecoveryLoading = true;
        state.isPasswordRecoverySuccess = false;
        state.isPasswordRecoveryFailed = false;
        state.isPasswordRecoveryError = false;
        state.passwordRecoveryErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(passwordRecovery.fulfilled, state => {
        state.isPasswordRecoveryLoading = false;
        state.isPasswordRecoverySuccess = true;
      })
      .addCase(passwordRecovery.rejected, (state, action) => {
        (state.isLoggedIn = false), (state.isAuthenticationSuccess = true);
        state.isPasswordRecoveryLoading = false;
        state.isAuthenticationFailed = true;
        state.isPasswordRecoveryError = true;
        state.passwordRecoveryErrorContainer = {
          ...state.passwordRecoveryErrorContainer,
          ...action.payload
        };
      })

      .addCase(updatePassword.pending, state => {
        state.isUpdatePasswordLoading = true;
        state.isUpdatePasswordSuccess = false;
        state.isUpdatePasswordFailed = false;
        state.isUpdatePasswordError = false;
        state.updatePasswordErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(updatePassword.fulfilled, state => {
        state.isUpdatePasswordLoading = false;
        state.isUpdatePasswordSuccess = true;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        (state.isLoggedIn = false), (state.isAuthenticationSuccess = true);
        state.isUpdatePasswordLoading = false;
        state.isUpdatePasswordError = true;
        state.updatePasswordErrorContainer = {
          ...state.updatePasswordErrorContainer,
          ...action.payload
        };
      })

      .addCase(teacherDashboard.pending, state => {
        state.isTeacherDashboardCountLoading = true;
        state.isTeacherDashboardCountSuccess = false;
        state.isTeacherDashboardCountFailed = false;
        state.isTeacherDashboardCountError = false;
        state.teacherDashboardCountErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(teacherDashboard.fulfilled, (state, action) => {
        state.isTeacherDashboardCountLoading = false;
        state.isTeacherDashboardCountSuccess = true;
        state.isTeacherDashboardCountFailed = false;
        state.teacherDashboardCountData = {
          total: action.payload['total'] || 0,
          byMe: action.payload['byMe'] || 0,
          forMe: action.payload['forMe'] || 0,
          schoolName: action.payload['schoolName'] || '',
          averageRating: action.payload['averageRating'] || 0,
          observations: action.payload['observations'] || [],
          progressPoint: action.payload['progressPoint'] || 0,
          pointStatus: action.payload['pointStatus'] || 'constant'
        };
      })
      .addCase(teacherDashboard.rejected, (state, action) => {
        state.isTeacherDashboardCountLoading = false;
        state.isTeacherDashboardCountFailed = true;
        state.isTeacherDashboardCountSuccess = false;
        state.teacherDashboardCountData = intial.teacherDashboardCountData;
        state.teacherDashboardCountErrorContainer = {
          ...state.teacherDashboardCountErrorContainer,
          ...action.payload
        };
      });
  }
});

export default authSlice.reducer;
