import React, { useEffect, useRef, useState } from 'react';
import {
  colorDisableGrey,
  colorLinkGrey,
  colorPrimary,
  colorPureWhite,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import { createTitle } from '../../utils/utils';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TIMEZONE, TOKEN_KEY } from '../../utils/constant';
import SnackBarBox from '../../components/SnackBarBox';
import {
  DateCalendar,
  DatePicker,
  DateTimePicker,
  LocalizationProvider
} from '@mui/x-date-pickers';
import axios from 'axios';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Close } from '@mui/icons-material';
import dayjs from 'dayjs';
import { Calendar01Icon, ClockIcon, UserGroupIcon } from '../../utils/iconSvg';
import { sendNotificationOnEventCreation } from '../../redux/reducers/layoutSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const AddEditNewEvents = ({
  eventId = -999,
  eventDetails = null,
  onCancelClick,
  handleRefresh
}) => {
  const dispatch = useDispatch();
  const { authData } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(true);
  const [listOfAttendees, setListOfAttendees] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(dayjs(new Date()));
  const [startTime, setStartTime] = useState('12:00 am');
  const [endTime, setEndTime] = useState('12:00 am');
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState('Event');
  const [showError, setShowError] = useState({ error: false, message: '' });
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  const buttons = ['Event', 'Out of office', 'Appointment schedule'];

  useEffect(() => {
    document.title = createTitle('Add Edit Event');

    if (eventDetails) {
      //   formik.setValues({});
      setLoading(false);
    } else {
      setLoading(false);
    }

    let now = dayjs();

    const roundedMinutes = Math.ceil(now.minute() / 15) * 15;

    if (roundedMinutes === 60) {
      now = now.add(1, 'hour').minute(0);
    } else {
      now = now.minute(roundedMinutes);
    }

    const formattedTime = now.format('h:mm a');
    const end = now.add(1, 'hour');
    const formattedEndTime = end.format('h:mm a');

    setStartTime(formattedTime);
    setEndTime(formattedEndTime);

    return () => {};
  }, []);

  const formik = useFormik({
    initialValues: {
      title: '',
      startEvent: null,
      endEvent: null,
      attendees: ''
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title is required'),
      startEvent: Yup.date()
        .test(
          'startEvent-endEvent',
          'If startEvent is provided, endEvent is mandatory',
          function (value) {
            const { endEvent } = this.parent;
            if (value && !endEvent) {
              return this.createError({
                path: 'endEvent',
                message: 'End event is mandatory if start event is provided'
              });
            }
            return true;
          }
        )
        .nullable(),
      endEvent: Yup.date()
        .test(
          'endEvent-startEvent',
          'If endEvent is provided, startEvent is mandatory',
          function (value) {
            const { startEvent } = this.parent;
            if (value && !startEvent) {
              return this.createError({
                path: 'startEvent',
                message: 'Start event is mandatory if end event is provided'
              });
            }
            return true;
          }
        )
        .nullable(),
      attendees: Yup.string().nullable()
    }),
    onSubmit: values => {
      let payload = {
        startEvent: `${values.startEvent ? values.startEvent : dayjs().format('YYYY-MM-DD')} ${startTime}`,
        endEvent: `${values.startEvent ? values.startEvent : dayjs().format('YYYY-MM-DD')} ${endTime}`,
        title: values.title,
        attendees: values.attendees
      };

      setLoading(true);

      if (eventId == -999) {
        createEvent(payload);
      }
    }
  });

  const handleAddAttendee = () => {
    const newAttendee = { email: formik.values.attendees };

    if (
      Yup.string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov|mil|biz|info|mobi|name|aero|jobs|museum)$/
        )
        .isValidSync(newAttendee.email)
    ) {
      setListOfAttendees([...listOfAttendees, newAttendee]);
      formik.setFieldValue('attendees', '');
    } else {
      formik.setFieldError('attendees', 'Enter valid email');
    }
  };

  const handleRemoveAttendee = index => {
    const updatedAttendees = listOfAttendees.filter((_, i) => i !== index);
    setListOfAttendees(updatedAttendees);
  };

  const createEvent = async eventDetails => {
    if (eventDetails.attendees === '') {
      try {
        const response = await axios.post(
          `https://www.googleapis.com/calendar/v3/calendars/primary/events?sendUpdates=all&conferenceDataVersion=1&key=${process.env.REACT_APP_GOOGLE_CALENDAR_API}`,
          {
            summary: eventDetails.title,
            start: {
              dateTime: eventDetails.startEvent
                ? dayjs(eventDetails.startEvent).format('YYYY-MM-DDTHH:mm:ss')
                : moment().format('YYYY-MM-DDTHH:mm:ss'),
              timeZone: 'Asia/Kolkata'
            },
            end: {
              dateTime: eventDetails.endEvent
                ? dayjs(eventDetails.endEvent).format('YYYY-MM-DDTHH:mm:ss')
                : moment().format('YYYY-MM-DDTHH:mm:ss'),
              timeZone: 'Asia/Kolkata'
            },
            attendees: [...listOfAttendees],
            conferenceData: {
              createRequest: {
                requestId: Math.random().toString(36).substring(2, 15),
                conferenceSolutionKey: {
                  type: 'hangoutsMeet'
                },
                status: {
                  statusCode: 'success'
                }
              }
            }
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
            }
          }
        );
        if (response.status === 200) {
          let payload = {
            userId: authData?.userId,
            startDateTime: eventDetails.startEvent
              ? dayjs(eventDetails.startEvent).format('YYYY-MM-DDTHH:mm:ss')
              : moment().format('YYYY-MM-DDTHH:mm:ss'),
            endDateTime: eventDetails.endEvent
              ? dayjs(eventDetails.endEvent).format('YYYY-MM-DDTHH:mm:ss')
              : moment().format('YYYY-MM-DDTHH:mm:ss'),
            emailList: [...listOfAttendees.map(cc => cc.email)],
            title: eventDetails.title
          };
          setShowSuccess(true);
          dispatch(sendNotificationOnEventCreation(payload));
          handleRefresh();
          setTimeout(() => onCancelClick(), 400);
        } else {
          setShowError({ error: true, message: 'Error creating event' });
          setLoading(false);
          console.error('Error creating event:', response.data.error.message);
        }
      } catch (error) {
        setShowError({ error: true, message: 'Error creating event' });
        setLoading(false);
        console.error('Error creating event:', error.message);
      }
    } else {
      setShowError({
        error: true,
        message: 'Please add attendees by using Tab or Enter'
      });
      setLoading(false);
    }
    setTimeout(() => {
      setShowError({
        error: false,
        message: ''
      });
    }, 3000);
  };

  const handleTimeChange = (type, value) => {
    if (type === 'start') {
      setStartTime(value);
      const bufferedEndTime = dayjs(value, 'h:mm a')
        .add(30, 'minute')
        .format('h:mm a');
      setEndTime(bufferedEndTime);
      // setEndTime(value);
      setIsStartOpen(false);
    } else {
      setEndTime(value);
      setIsEndOpen(false);
    }
  };

  function ButtonField(props) {
    const {
      setOpen,
      id,
      disabled,
      InputProps: { ref } = {},
      inputProps: { 'aria-label': ariaLabel } = {}
    } = props;

    return (
      <Button
        variant="text"
        sx={{
          color: colorLinkGrey,
          fontSize: '14px',
          '&:hover': { backgroundColor: '#FEF8EC' }
        }}
        id={id}
        disabled={disabled}
        ref={ref}
        aria-label={ariaLabel}
        startIcon={
          <>
            {/* <Calendar01Icon color={open ? colorPrimary : colorTextBlack} /> */}
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 8.33366H2.5M13.3333 1.66699V5.00033M6.66667 1.66699V5.00033M6.5 18.3337H13.5C14.9001 18.3337 15.6002 18.3337 16.135 18.0612C16.6054 17.8215 16.9878 17.439 17.2275 16.9686C17.5 16.4339 17.5 15.7338 17.5 14.3337V7.33366C17.5 5.93353 17.5 5.23346 17.2275 4.69868C16.9878 4.22828 16.6054 3.84583 16.135 3.60614C15.6002 3.33366 14.9001 3.33366 13.5 3.33366H6.5C5.09987 3.33366 4.3998 3.33366 3.86502 3.60614C3.39462 3.84583 3.01217 4.22828 2.77248 4.69868C2.5 5.23346 2.5 5.93353 2.5 7.33366V14.3337C2.5 15.7338 2.5 16.4339 2.77248 16.9686C3.01217 17.439 3.39462 17.8215 3.86502 18.0612C4.3998 18.3337 5.09987 18.3337 6.5 18.3337Z"
                stroke={open ? colorPrimary : colorTextBlack}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </>
        }
        onClick={() => setOpen?.(prev => !prev)}
      >
        {props?.value ? props?.value?.format('dddd, MMMM D') : 'Select Date'}
      </Button>
    );
  }

  function ButtonDatePicker(props) {
    return (
      <DatePicker
        slots={{ ...props.slots, field: ButtonField }}
        slotProps={{ ...props.slotProps, field: { setOpen } }}
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    );
  }

  const timeOptions = Array.from({ length: 48 * 2 }, (_, i) => {
    const hour = Math.floor(i / 4) % 12 || 12;
    const minute = (i % 4) * 15;
    const formatminute = minute === 0 ? '00' : minute;
    const ampm = i < 12 * 4 ? 'am' : 'pm';
    // const minute = i % 4 === 0 ? '00' : '15';
    // const ampm = i < 12 * 2 ? 'am' : 'pm';
    return {
      label: `${hour}:${formatminute} ${ampm}`,
      value: `${hour}:${formatminute} ${ampm}`
    };
  });

  const convertTimeToMinutes = time => {
    const [hourMinute, period] = time.split(' ');
    let [hour, minute] = hourMinute.split(':').map(Number);

    if (period === 'pm' && hour !== 12) hour += 12;
    if (period === 'am' && hour === 12) hour = 0;

    return hour * 60 + minute;
  };

  const handleStartTimeClickOutside = event => {
    if (startTimeRef.current && !startTimeRef.current.contains(event.target)) {
      setIsStartOpen(false);
    }
  };

  const handleEndTimeClickOutside = event => {
    if (endTimeRef.current && !endTimeRef.current.contains(event.target)) {
      setIsEndOpen(false);
    }
  };

  useEffect(() => {
    if (isStartOpen) {
      document.addEventListener('mousedown', handleStartTimeClickOutside);
    } else {
      document.removeEventListener('mousedown', handleStartTimeClickOutside);
    }

    if (isEndOpen) {
      document.addEventListener('mousedown', handleEndTimeClickOutside);
    } else {
      document.removeEventListener('mousedown', handleEndTimeClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleEndTimeClickOutside);
      document.removeEventListener('mousedown', handleStartTimeClickOutside);
    };
  }, [isStartOpen, isEndOpen]);

  const handleStartModalToggle = () => {
    setIsStartOpen(startModal => {
      setIsEndOpen(false);
      return !startModal;
    });
  };

  const handleEndModalToggle = () => {
    setIsEndOpen(EndModal => {
      setIsStartOpen(false);
      return !EndModal;
    });
  };

  return (
    <>
      <Box sx={{ padding: '0 15px 15px 15px' }}>
        {loading && (
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={400}
            animation="wave"
          />
        )}
        {!loading && (
          <Box bgcolor={colorPureWhite} borderRadius={2}>
            <Grid>
              <Grid>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  placeholder="Add title"
                  variant="outlined"
                  size="small"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>

              <Grid sx={{ display: 'flex', gap: 1 }} mt={1}>
                {buttons
                  .filter(cc => cc == 'Event')
                  .map(eventName => (
                    <Button
                      key={eventName}
                      variant="outlined"
                      sx={{
                        borderColor:
                          activeEvent === eventName
                            ? colorSecondary
                            : colorDisableGrey,
                        borderRadius: '10px',
                        color:
                          activeEvent === eventName
                            ? colorTextBlack
                            : colorTextGrey,
                        borderWidth: '2px',
                        fontSize: '14px',
                        fontWeight: '700',
                        backgroundColor:
                          activeEvent === eventName
                            ? colorSecondary
                            : 'transparent',
                        '&:hover': {
                          backgroundColor: colorSecondary,
                          borderColor: colorSecondary
                        },
                        pointerEvents:
                          activeEvent === eventName ? 'auto' : 'none'
                      }}
                      onClick={() => setActiveEvent(eventName)}
                    >
                      {eventName}
                    </Button>
                  ))}
              </Grid>

              <Grid sx={{ display: 'flex', gap: 1, marginTop: 2 }}>
                <Grid>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ButtonDatePicker
                      label={value ? value.format('MM/DD/YYYY') : null}
                      value={value}
                      onChange={newValue => {
                        setValue(newValue);
                        formik.setFieldValue(
                          'startEvent',
                          dayjs(newValue).format('YYYY-MM-DD')
                        );
                        formik.setFieldValue(
                          'endEvent',
                          dayjs(newValue).format('YYYY-MM-DD')
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid ref={isStartOpen ? startTimeRef : endTimeRef}>
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      gap: 1,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_11321_104110)">
                        <path
                          d="M9.99935 5.00033V10.0003L13.3327 11.667M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003Z"
                          stroke="#1F2933"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_11321_104110">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <Button
                      variant="text"
                      sx={{
                        color: colorLinkGrey,
                        fontSize: '14px',
                        '&:hover': { backgroundColor: '#FEF8EC' }
                      }}
                      onClick={handleStartModalToggle}
                    >
                      {startTime}
                    </Button>
                    <Typography sx={{ color: colorLinkGrey }}>-</Typography>
                    <Button
                      variant="text"
                      sx={{
                        color: colorLinkGrey,
                        fontSize: '14px',
                        '&:hover': { backgroundColor: '#FEF8EC' }
                      }}
                      onClick={handleEndModalToggle}
                    >
                      {endTime}
                    </Button>
                  </Grid>
                  {isStartOpen && (
                    <Paper
                      sx={{
                        position: 'absolute',
                        zIndex: 1000,
                        boxShadow: '0 0 13px 2px rgba(0, 0, 0, 0.1)',
                        minHeight: '130px',
                        maxHeight: '140px',
                        overflowY: 'auto',
                        width: 'auto',
                        borderRadius: '5px',
                        borderColor: colorLinkGrey,
                        '&::-webkit-scrollbar': {
                          width: '2px'
                        },
                        '&::-webkit-scrollbar-track': {
                          background: '#E4E7EB',
                          border: '2px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: colorLinkGrey,
                          borderRadius: '7px'
                        }
                      }}
                    >
                      <List>
                        {timeOptions.map(option => (
                          <Button
                            key={option.value}
                            onClick={() =>
                              handleTimeChange('start', option.value)
                            }
                            sx={{
                              display: 'block',
                              borderRadius: '0px',

                              width: '100%',
                              fontSize: '14px',
                              color: colorTextBlack,
                              '&:hover': {
                                backgroundColor: colorSecondary
                              }
                            }}
                          >
                            {option.label}
                          </Button>
                        ))}
                      </List>
                    </Paper>
                  )}
                  {isEndOpen && (
                    <Paper
                      sx={{
                        position: 'absolute',
                        zIndex: 1000,
                        boxShadow: '0 0 13px 2px rgba(0, 0, 0, 0.1)',
                        minHeight: '130px',
                        maxHeight: '140px',
                        overflowY: 'auto',
                        width: 'auto',
                        marginLeft: 16,
                        '&::-webkit-scrollbar': {
                          width: '2px'
                        },
                        '&::-webkit-scrollbar-track': {
                          background: '#E4E7EB',
                          border: '2px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: colorLinkGrey,
                          borderRadius: '7px'
                        }
                      }}
                    >
                      <List>
                        {timeOptions.map(option => (
                          <Button
                            key={option.value}
                            disabled={
                              convertTimeToMinutes(option.value) <
                              convertTimeToMinutes(startTime)
                            }
                            onClick={() =>
                              handleTimeChange('end', option.value)
                            }
                            sx={{
                              display: 'block',
                              borderRadius: '0px',
                              width: '100%',
                              fontSize: '14px',
                              // color: colorTextBlack,
                              color:
                                convertTimeToMinutes(option.value) <
                                convertTimeToMinutes(startTime)
                                  ? colorTextGrey
                                  : colorTextBlack,
                              '&:hover': {
                                backgroundColor: colorSecondary
                              }
                            }}
                          >
                            {option.label}
                          </Button>
                        ))}
                      </List>
                    </Paper>
                  )}
                </Grid>
              </Grid>
              <Grid mt={2}>
                <TextField
                  fullWidth
                  id={`attendees`}
                  name={`attendees`}
                  placeholder="Add guests"
                  // label={`Add guests`}
                  variant="outlined"
                  size="small"
                  onChange={formik.handleChange}
                  value={formik.values.attendees}
                  onKeyDown={event => {
                    if (event.key === 'Enter' || event.key === 'Tab') {
                      event.preventDefault();
                      handleAddAttendee();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          sx={{
                            width: '16px',
                            height: '16px',
                            marginLeft: '8px'
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 19 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17 15.75V14.25C17 12.8521 16.0439 11.6775 14.75 11.3445M12.125 2.46807C13.2244 2.91311 14 3.99098 14 5.25C14 6.50902 13.2244 7.58689 12.125 8.03193M13.25 15.75C13.25 14.3522 13.25 13.6533 13.0216 13.1019C12.7172 12.3669 12.1331 11.7828 11.3981 11.4784C10.8467 11.25 10.1478 11.25 8.75 11.25H6.5C5.10218 11.25 4.40326 11.25 3.85195 11.4784C3.11687 11.7828 2.53284 12.3669 2.22836 13.1019C2 13.6533 2 14.3522 2 15.75M10.625 5.25C10.625 6.90685 9.28185 8.25 7.625 8.25C5.96815 8.25 4.625 6.90685 4.625 5.25C4.625 3.59315 5.96815 2.25 7.625 2.25C9.28185 2.25 10.625 3.59315 10.625 5.25Z"
                              stroke={colorTextGrey}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SvgIcon>
                        {/* </IconButton> */}
                      </InputAdornment>
                    )
                  }}
                  error={
                    formik.touched.attendees && Boolean(formik.errors.attendees)
                  }
                  helperText={
                    formik.touched.attendees && formik.errors.attendees
                  }
                />
                {/* </Grid> */}

                <Box
                  display="flex"
                  flexWrap="wrap"
                  overflowY="auto"
                  maxHeight="200px"
                  mt={1}
                >
                  {listOfAttendees.map((attendee, index) => {
                    return (
                      <Typography
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                          mr: 1,
                          fontWeight: '600',
                          padding: '2px 6px',
                          background: '#FCEBC5',
                          border: `1px solid ${colorPrimary}`,
                          borderRadius: '6px'
                        }}
                        key={index}
                        variant="caption"
                      >
                        {attendee?.email}
                        <IconButton
                          onClick={() => {
                            handleRemoveAttendee(index);
                          }}
                          sx={{
                            padding: 0,
                            ml: 2
                          }}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </Typography>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              onCancelClick();
            }}
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: colorTextBlack,
              borderWidth: '2px',
              height: '42px',
              width: '106px',
              '&:hover': {
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px'
              }
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={!formik.values.title}
            variant="contained"
            sx={{
              width: '94px',
              height: '42px',
              boxShadow: 'none'
            }}
          >
            Save
          </LoadingButton>
        </Box>

        {showError.error && (
          <SnackBarBox type="error" message={showError.message} />
        )}
        {showSuccess && (
          <SnackBarBox
            type="success"
            message={'Event created successfully...'}
          />
        )}
      </Box>
    </>
  );
};

export default AddEditNewEvents;
