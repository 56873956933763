import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  Typography
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import {
  colorIconGreen,
  colorLinkGrey,
  colorPrimary,
  colorTextBlack
} from '../config/theme';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ConfirmBox({
  title = '',
  subtitle,
  handleClose,
  isOpen,
  handleSubmit
}) {
  return (
    <>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={isOpen || false}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        scroll="body"
        className="custom-dialog"
      >
        <Grid sx={{ padding: '40px', gap: '24px' }}>
          {' '}
          {/* <DialogTitle
        sx={{ backgroundColor: 'white' }}
        id="max-width-dialog-title"
      > */}
          <Typography fontSize={'20px'} lineHeight={'26px'} fontWeight={700}>
            {title}
          </Typography>
          {/* </DialogTitle> */}
          {/* <DialogContent className="MuiDialogContentRootc"> */}
          {/* <DialogContentText marginTop={2}> */}
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{ color: colorLinkGrey }}
            mt={2}
            mb={2}
          >
            {subtitle}
          </Typography>
          {/* </DialogContentText>
      </DialogContent> */}
          {/* <DialogActions sx={{ justifyContent: 'flex-start' }}> */}
          <Grid sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '83px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
              onClick={handleClose}
            >
              No
            </Button>{' '}
            <Button
              size="small"
              variant="contained"
              sx={{ width: '86px', height: '42px' }}
              onClick={handleSubmit}
            >
              Yes
            </Button>
          </Grid>
          {/* </DialogActions> */}
        </Grid>
      </Dialog>
    </>
  );
}

ConfirmBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func
};
export default ConfirmBox;
