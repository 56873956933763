import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { createTitle } from '../utils/utils';
import ShareUserAndRoleAnalytics from '../containers/sharecharts/ShareUserAndRoleAnalytics';
import { useLocation } from 'react-router-dom';
import ShareLocationAnalytics from '../containers/sharecharts/ShareLocationAnalytics';
import ShareRubricAnalytics from '../containers/sharecharts/ShareRubricAnalytics';
import ShareFlowsAndFormsAnalytics from '../containers/sharecharts/ShareFormAndFlowsAnalytics';

export const analyticsTypeHeaderContent = {
  user: 'User and Role Analytics',
  location: 'Location Analytics',
  observation: 'Teacher Observation Analytics',
  flows: 'Flow & Form Analytics'
};

const ShareCharts = () => {
  const location = useLocation();
  let chartType = new URLSearchParams(location.search).get('chartType');
  useEffect(() => {
    document.title = createTitle(`Share Charts`);
  }, []);

  return (
    <Grid
      sx={{
        backgroundColor: theme =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        padding: '18px 24px'
      }}
    >
      {(chartType == 'usersChart' || chartType == 'userAndRoleChart') && (
        <ShareUserAndRoleAnalytics />
      )}

      {chartType == 'locationChart' && <ShareLocationAnalytics />}

      {(chartType == 'rubricChart' ||
        chartType == 'usersObservationChart' ||
        chartType == 'observationChart') && <ShareRubricAnalytics />}

      {chartType == 'flowsAndFormsChart' && <ShareFlowsAndFormsAnalytics />}
    </Grid>
  );
};

export default ShareCharts;
