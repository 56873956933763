import React, { useState } from 'react';
import { East, ErrorOutline } from '@mui/icons-material';
import { Box, Divider, Grid, Rating, SvgIcon, Typography } from '@mui/material';
import {
  colorLinkGrey,
  colorMidGray,
  colorTextBlack
} from '../../../config/theme';
import moment from 'moment';
import CustomDataTable from '../../../components/CustomDataTable';

const QuestionWiseResponseCard = ({
  title = '',
  reponse = [],
  count = 0,
  rating = 0
}) => {
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalEvents: 0
  });
  const responseColumns = [
    {
      uinqueKey: 'userName',
      id: 'responseValues',
      width: '50%',
      label: 'Responses',
      render: (row, idx) => {
        let ids = [...reponse].findIndex(cc => cc?.userName === row?.userName);
        return (
          <Typography>
            {ids + 1}. {row.responseValues}
          </Typography>
        );
      }
    },
    {
      label: 'Submitted by',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.name}
          </Typography>
        );
      }
    },
    {
      label: 'Submitted On',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.responseDate
              ? moment(row.responseDate).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        );
      }
    },
    {
      label: 'Ratings',
      // width:"2%",
      render: row => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography
              sx={{ flex: 0, textAlign: 'center' }}
              variant="body2"
              fontWeight={'600'}
            >
              {row.avgRating ? parseFloat(row.avgRating).toFixed(1) : 'NA'}
            </Typography>
            <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
            <Box sx={{ flex: 2 }}>
              <Rating
                size="small"
                precision={0.5}
                readOnly
                value={row.avgRating}
              />
            </Box>
          </Box>
        );
      }
    }
  ];

  return (
    <Grid>
      <Box
        sx={{ paddingY: 1, display: 'flex', justifyContent: 'space-between' }}
      >
        <Box>
          <Typography variant="h6" fontWeight="bold">
            {title?.split('.').length > 0 ? (
              <>
                <span
                  style={{
                    padding: '0.5rem 3px 0.5rem 0.0rem',
                    display: 'inline-flex',
                    alignItems: 'center'
                  }}
                >
                  {title?.split('.')[0]}
                  <East
                    fontSize="small"
                    sx={{
                      marginLeft: '8px'
                    }}
                  />
                </span>
                {title?.split('.')[1]}
              </>
            ) : (
              <>
                {console.log('</>')}
                {title}
              </>
            )}
          </Typography>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            color={colorLinkGrey}
            display={'flex'}
            mt={1}
          >
            <SvgIcon sx={{ mr: 0.5, height: 16, width: 16 }}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_5511_26833)">
                  <path
                    d="M8.00004 5.33398V8.00065M8.00004 10.6673H8.00671M14.6667 8.00065C14.6667 11.6826 11.6819 14.6673 8.00004 14.6673C4.31814 14.6673 1.33337 11.6826 1.33337 8.00065C1.33337 4.31875 4.31814 1.33398 8.00004 1.33398C11.6819 1.33398 14.6667 4.31875 14.6667 8.00065Z"
                    stroke="#4E565F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5511_26833">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>
            Responses cannot be edited
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colorMidGray}>
            Average rating
          </Typography>{' '}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Typography
              variant="caption"
              fontWeight={'600'}
              display={'flex'}
              mt={0.5}
            >
              {rating ? parseFloat(rating).toFixed(1) : '0.0'}
            </Typography>
            <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Rating size="small" precision={0.5} readOnly value={rating} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider orientation="horizontal" flexItem />
      <Typography
        my={2}
        sx={{
          fontWeight: '700',
          fontSize: '20px',
          lineHeight: '26px',
          color: colorTextBlack
        }}
      >
        Responses
      </Typography>
      {/* <Box
        sx={{
          //   width: '60%',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 255px)',
          '&::-webkit-scrollbar': {
            width: '0.2em'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.3)'
          }
        }}
      >
        {reponse.map((item, key) => (
          <Box key={key} mt={key != 0 ? 2 : 1}>
            <Typography
              color={colorMidGray}
              variant="body2"
              display={'flex'}
              alignItems={'center'}
              fontWeight={'500'}
            >
              <span
                style={{
                  padding: '0.5rem 0.5rem 0.5rem 0.0rem',
                  display: 'inline-flex',
                  alignItems: 'center'
                }}
              >
                {key + 1}.{' '}
              </span>{' '}
              {item?.responseValues || ''}
            </Typography>

            <Divider flexItem variant="fullWidth" orientation="horizontal" />
          </Box>
        ))}
      </Box> */}

      <CustomDataTable
        allowSelection={false}
        columns={responseColumns}
        hideEmptyRows={true}
        allowPagination={true}
        defaultRowsPerPage={pagination.rowsPerPage}
        data={[...reponse].slice(
          pagination.page * pagination.rowsPerPage,
          (pagination.page + 1) * pagination.rowsPerPage
        )}
        onPageChange={val => {
          setPagination({ ...pagination, page: val });
        }}
        defaultPage={pagination.page}
        totalCount={[...reponse].length}
        onRowsPerPageChange={val => {
          setPagination({ ...pagination, rowsPerPage: val });
        }}
      />
    </Grid>
  );
};

export default QuestionWiseResponseCard;
