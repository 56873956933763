import React from 'react';
import { East, ErrorOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  Rating,
  SvgIcon,
  Typography
} from '@mui/material';
import { colorLinkGrey, colorMidGray } from '../../../config/theme';

const IndividualResponsesCard = ({
  title = '',
  reponse = [],
  count = 0,
  rating = 0
}) => {
  return (
    <Grid>
      <Box
        sx={{ paddingY: 1, display: 'flex', justifyContent: 'space-between' }}
      >
        <Box>
          <Typography variant="h6" fontWeight="bold">
            {title}
          </Typography>
          <Typography
            display={'flex'}
            mt={0.5}
            fontWeight={'500'}
            fontSize={'12px'}
            color={colorLinkGrey}
          >
            <SvgIcon sx={{ mr: 0.5, height: 16, width: 16 }}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_5511_26833)">
                  <path
                    d="M8.00004 5.33398V8.00065M8.00004 10.6673H8.00671M14.6667 8.00065C14.6667 11.6826 11.6819 14.6673 8.00004 14.6673C4.31814 14.6673 1.33337 11.6826 1.33337 8.00065C1.33337 4.31875 4.31814 1.33398 8.00004 1.33398C11.6819 1.33398 14.6667 4.31875 14.6667 8.00065Z"
                    stroke="#4E565F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5511_26833">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>{' '}
            Responses cannot be edited
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Box marginRight={2}>
            <Typography variant="caption" color={colorMidGray}>
              Questions answered
            </Typography>
            <Typography
              variant="caption"
              fontWeight={'600'}
              display={'flex'}
              mt={0.5}
            >
              {`${reponse.length}/${count}`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color={colorMidGray}>
              Rating
            </Typography>{' '}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Typography
                // sx={{ flex: 1, textAlign: 'center' }}
                // variant="body2"
                // fontWeight={'600'}
                variant="caption"
                fontWeight={'600'}
                display={'flex'}
                mt={0.5}
              >
                {rating ? parseFloat(rating).toFixed(1) : '0'}
              </Typography>
              <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
              <Box sx={{ display: 'flex' }}>
                <Rating size="small" precision={0.5} readOnly value={rating} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider orientation="horizontal" flexItem />
      <Box
        sx={{
          //   width: '60%',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 255px)',
          '&::-webkit-scrollbar': {
            width: '0.2em'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.3)'
          }
        }}
      >
        {reponse.map((item, key) => (
          <Box key={key} mt={key != 0 ? 2 : 1}>
            <Grid container display={'flex'} justifyContent={'space-between'}>
              <Box>
                <Typography
                  variant="body2"
                  display={'flex'}
                  alignItems={'center'}
                  fontWeight={'500'}
                >
                  <span
                    style={{
                      padding: '0.5rem 0.5rem 0.5rem 0.0rem',
                      display: 'inline-flex',
                      alignItems: 'center'
                    }}
                  >
                    {key + 1}{' '}
                    <East
                      fontSize="small"
                      sx={{
                        marginLeft: '4px'
                      }}
                    />
                  </span>{' '}
                  {item?.questionText || ''}
                </Typography>
                <Typography variant="subtitle2" color={colorMidGray}>
                  {item?.responseValues}
                </Typography>
              </Box>
              {item.avgRating && (
                <Box>
                  <Typography variant="subtitle2" color={colorMidGray}>
                    Rating
                  </Typography>{' '}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <Typography
                      sx={{ flex: 1, textAlign: 'center' }}
                      variant="body2"
                      fontWeight={'600'}
                    >
                      {item.avgRating
                        ? parseFloat(item.avgRating).toFixed(1)
                        : ''}
                    </Typography>
                    <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
                    <Box sx={{ flex: 2 }}>
                      <Rating
                        size="small"
                        precision={0.5}
                        readOnly
                        value={item.avgRating}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
            <Divider flexItem variant="fullWidth" orientation="horizontal" />
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default IndividualResponsesCard;
