import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import { FormButtonBottom } from '../../utils/styledLayout';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GenericInput from '../../components/GenericInput';
import { useDispatch } from 'react-redux';
import { passwordRecovery } from '../../redux/reducers/authSlice';
import {
  colorLinkGrey,
  colorPrimary,
  colorPureWhite,
  colorSecondary,
  colorTextBlack
} from '../../config/theme';
import { IMG_SPLASH_LOGO } from '../../utils/imageUrls';
import { useNavigate } from 'react-router-dom';

const PasswordRecoveryForm = () => {
  // constants
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // form style
  const formControl = {
    margin: '0.5rem auto'
  };

  // form control
  const formik = useFormik({
    initialValues: {
      username: ''
    },
    validationSchema: yup.object({
      username: yup.string().required('Username is required')
    }),
    onSubmit: value => {
      dispatch(passwordRecovery(value.username));
    }
  });

  const handleFormValue = (e, field) => {
    formik.setFieldValue(field, e.target.value);
  };

  return (
    <>
      <Grid container display={'none'}>
        <Grid sx={formControl} item xs={12} sm={12} md={12} lg={12}>
          <InputLabel sx={{ fontWeight: '500', color: colorTextBlack }}>
            Username
          </InputLabel>
          <FormControl variant="outlined" sx={formControl} fullWidth>
            <TextField
              name="username"
              placeholder="Enter your username"
              inputProps={{
                maxLength: 64
              }}
              sx={{ backgroundColor: 'white', borderRadius: '8px' }}
              value={formik.values.username}
              size="small"
              onChange={e => handleFormValue(e, 'username')}
              error={formik.touched.username && formik.errors.username}
              // startIcon={<PersonOutlineOutlinedIcon className="icon_color" />}
            />
          </FormControl>
        </Grid>
        <Grid sx={formControl} item xs={12} sm={12} md={12} lg={12}>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            size="medium"
            onClick={() => formik.handleSubmit()}
            color="primary"
            sx={{
              mt: 3,
              height: 42,
              fontSize: 18,
              fontWeight: '700',
              // textTransform: 'uppercase'
              boxShadow: 'none'
            }}
          >
            Continue
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid
        sx={{
          p: 2,
          height: '100vh',
          width: '100%',
          position: 'fixed',
          display: 'flex',
          justifyContent: 'space-between',
          gap: 8,
          backgroundColor: colorPureWhite
        }}
      >
        <Grid>
          <Grid sx={{ px: 3 }}>
            <img src={IMG_SPLASH_LOGO} alt={IMG_SPLASH_LOGO} />
          </Grid>
          <Grid
            sx={{
              p: 6,
              py: 17
            }}
          >
            <Box>
              <Typography
                fontSize={'32px'}
                sx={{ color: colorTextBlack }}
                fontWeight={'700'}
              >
                Forgot Password?
              </Typography>
              <Typography
                sx={{ color: colorLinkGrey }}
                fontSize={'16px'}
                fontWeight={'600'}
              >
                Enter the username associated with your account and we will send
                an email with
              </Typography>
              <Typography
                sx={{ color: colorLinkGrey }}
                fontSize={'16px'}
                fontWeight={'600'}
              >
                instructions to reset your password.
              </Typography>
            </Box>

            <Box sx={{ width: '434px' }}>
              <Grid mt={2} mb={2}>
                <InputLabel
                  sx={{
                    fontWeight: '700',
                    fontSize: '16px',
                    color: colorTextBlack
                  }}
                >
                  Username
                </InputLabel>
                <FormControl variant="outlined" sx={formControl} fullWidth>
                  <TextField
                    name="username"
                    placeholder="Enter your username"
                    inputProps={{
                      maxLength: 64
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '8px'
                    }}
                    value={formik.values.username}
                    size="small"
                    onChange={e => handleFormValue(e, 'username')}
                    error={formik.touched.username && formik.errors.username}
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                </FormControl>
              </Grid>
              <Divider />
            </Box>
            <Box mt={3}>
              <Grid
                sx={formControl}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display={'flex'}
                gap={2}
              >
                <Button
                  onClick={() => navigate('/login')}
                  variant="outlined"
                  sx={{
                    color: colorTextBlack,
                    borderColor: colorPrimary,
                    fontSize: 14,
                    marginRight: 1,
                    width: '209px',
                    height: '42px',
                    fontWeight: '700',
                    borderWidth: '2px',
                    '&:hover': {
                      borderColor: colorPrimary,
                      backgroundColor: 'transparent',
                      borderRadius: '8px',
                      borderWidth: '2px'
                    }
                  }}
                  size="medium"
                  fullWidth
                >
                  Back to login
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={formik.values.username == ''}
                  onClick={() => formik.handleSubmit()}
                  sx={{
                    height: 42,
                    fontSize: '14px',
                    fontWeight: '700',
                    width: '209px',
                    boxShadow: 'none'
                  }}
                >
                  Send
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid
          sx={{
            backgroundColor: colorSecondary,
            width: '20%',
            borderRadius: '5px'
          }}
        ></Grid>
      </Grid>
    </>
  );
};

export default PasswordRecoveryForm;
