import { Box, Toolbar, styled } from '@mui/material';
import SideBar from './Layout/SideBar';
import HeaderBar from './Layout/HeaderBar';
import { useSelector } from 'react-redux';
import MuiAppBar from '@mui/material/AppBar';
import { drawerWidth } from '../utils/constant';
import { colorAppBackground } from '../config/theme';
import { WebFlagProvider } from '../App';
import React, { useState } from 'react';
import { sidebarToggle } from '../redux/reducers/layoutSlice';
import { useDispatch } from 'react-redux';

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    marginTop: 70,
    padding: theme.spacing(3),
    marginLeft: open ? `${drawerWidth}px` : '106px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '@media (max-width: 600px)': {
      opacity: open ? 0.1 : 1
    }
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  ...(open &&
    {
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: `${drawerWidth}px`
    })
}));

function Layout({ children, title = '' }) {
  const { modules } = useSelector(state => state.layout);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const dispatch = useDispatch();
  const handleSidebarToggle = open => {
    setSidebarOpen(open);
  };

  function filterPageData(pageData) {
    const filteredPageData = {};
    for (let key in pageData) {
      if (pageData[key].every(page => page.module.status)) {
        filteredPageData[key] = pageData[key];
      }
    }
    return filteredPageData;
  }

  React.useEffect(() => {
    dispatch(sidebarToggle(sidebarOpen));
  }, [sidebarOpen]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: colorAppBackground,
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto'
        }}
      >
        <AppBar
          sx={{
            backgroundColor: colorAppBackground
          }}
          elevation={0}
          position="static"
          open={sidebarOpen}
        >
          <HeaderBar menulist={modules} sidebarOpen={sidebarOpen} />
          <SideBar
            menulist={modules}
            sidebarOpen={sidebarOpen}
            onToggle={handleSidebarToggle}
          />
          <Main open={sidebarOpen}>
            {/* <Toolbar sx={{  }} /> */}
            {children}
          </Main>
        </AppBar>
      </Box>
    </>
  );
}

export default Layout;
