import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  Link,
  Skeleton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import CustomBreadcrumb from '../../components/Breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  createTitle,
  getOptionLabel,
  handleError,
  isArrayNotEmpty
} from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  getUserGroupsList,
  getUsersOfGroup
} from '../../redux/reducers/userGroupsSlice';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import {
  createUpdateObservation,
  getObservationsDetail,
  revertEvidenceFormData,
  revertObservationFormData
} from '../../redux/reducers/observationSlice';
import SnackBarBox from '../../components/SnackBarBox';
import {
  FileIcon,
  MIC_ICON,
  PDF_ICON,
  VIDEO_ICON,
  sceneroy_icon
} from '../../utils/imageUrls';
import RefreshConfirmationDialog from '../../components/RefreshConfirmationDialog';
import ClosableBanner from '../../components/ClosableBanner';
import { ChevronRightIcon } from '../../utils/iconSvg';

const AddObservation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isHover, setIshover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFeedbackCard, setShowFeedbackCard] = useState(false);
  const [evidenceListError, setEvidenceListError] = useState({
    error: false,
    errorMessage: ''
  });
  const { userGroupsData, usersOfGroupData } = useSelector(
    state => state.userGroups
  );
  const { authData } = useSelector(state => state.auth);
  const {
    isCreateUpdateEvidenceLoading,
    isCreateUpdateEvidenceSuccess,
    createUpdateEvidenceResponse,
    evidenceList,
    isCreateUpdateObservationLoading,
    isCreateUpdateObservationSuccess,
    isObservationDetailLoading,
    isObservationDetailSuccess,
    isObservationDetailFailed,
    observationDetail,
    isCreateUpdateObservationFailed,
    createUpdateObservationErrorContainer
  } = useSelector(state => state.observation);

  const formik = useFormik({
    initialValues: {
      user: null,
      userGroup: null,
      evidenceCards: [],
      feedbackNote: ''
    },
    validationSchema: Yup.object().shape({
      user: Yup.object()
        .shape({
          id: Yup.string().required()
        })
        .required('User is required'),
      userGroup: Yup.object()
        .shape({
          id: Yup.string().required()
        })
        .required('User group is required'),
      feedbackNote: Yup.string()
    }),
    onSubmit: values => {
      let tempEvid = [];

      if (isArrayNotEmpty(evidenceList)) {
        evidenceList.forEach(element => {
          tempEvid.push({
            domainId: element.domainId,
            indicatorId: element.indicatorId,
            averageRating: element.averageRating,
            loggedInUserName: authData.userName,
            evidenceId: element.evidenceId
          });
        });
      }

      let payload = {
        observationDate: moment().format('YYYY-MM-DD'),
        userGroupId: values.userGroup.id,
        userId: values.user.id,
        observationStatus: 'Completed',
        feedbackDescription: values.feedbackNote,
        loggedInUserName: authData.userName,
        evidenceRequestList: tempEvid
      };
      if (evidenceList.length === 0) {
        setEvidenceListError({
          error: true,
          errorMessage: `Please Create at least One evidence card`
        });
        return;
      }
      setEvidenceListError({
        error: false,
        errorMessage: ''
      });
      dispatch(
        createUpdateObservation({
          payload: payload,
          observationId: location.state?.observationId
        })
      );
    }
  });

  useEffect(() => {
    if (evidenceListError.error) {
      const timeoutId = setTimeout(() => {
        setEvidenceListError({
          error: false,
          errorMessage: ''
        });
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [evidenceListError]);

  useEffect(() => {
    document.title = createTitle('Add/Edit Observations');

    let mastersPayload = {
      type: 'all',
      page: 0,
      size: 0
    };
    dispatch(getUserGroupsList({ payload: mastersPayload }));
    dispatch(revertEvidenceFormData());
    if (
      location &&
      location.state &&
      location.state?.observationId &&
      !location.state?.formValues
    ) {
      dispatch(getObservationsDetail(location.state?.observationId));
    }

    if (location && location.state && location.state?.formValues) {
      formik.setValues({
        user: location.state?.formValues?.user || null,
        userGroup: location.state?.formValues?.userGroup || null,
        feedbackNote: location.state?.formValues?.feedbackNote || ''
      });

      if (location.state?.formValues?.userGroup?.id)
        dispatch(
          getUsersOfGroup({
            groupId: location.state?.formValues?.userGroup?.id,
            payload: {
              type: 'all',
              page: 0,
              size: 0
            }
          })
        );
      if (location.state?.formValues?.feedbackNote) setShowFeedbackCard(true);
    }
  }, []);

  const addEvidenceCard = () => {
    dispatch(revertEvidenceFormData());
    navigate('evidence-card', {
      state: {
        formValues: formik.values,
        observationId: location.state?.observationId
      }
    });
  };

  const handleSaveAsDraft = () => {
    let payload = {
      observationDate: moment().format('YYYY-MM-DD'),
      userGroupId: formik.values.userGroup ? formik.values.userGroup.id : null,
      userId: formik.values.user ? formik.values.user.id : null,
      observationStatus: 'Pending',
      feedbackDescription: formik.values.feedbackNote,
      loggedInUserName: authData.userName,
      evidenceRequestList: evidenceList
    };
    dispatch(
      createUpdateObservation({
        payload: payload,
        observationId: location.state?.observationId
      })
    );
  };

  useEffect(() => {
    if (!isCreateUpdateObservationLoading && isCreateUpdateObservationSuccess) {
      navigate('/observations');
    }
  }, [isCreateUpdateObservationSuccess]);

  useEffect(() => {
    if (!isObservationDetailLoading && isObservationDetailSuccess) {
      if (observationDetail.userGroupId)
        dispatch(
          getUsersOfGroup({
            groupId: observationDetail.userGroupId,
            payload: {
              type: 'all',
              page: 0,
              size: 0
            }
          })
        );
      if (observationDetail.feedbackDescription) setShowFeedbackCard(true);

      formik.setValues({
        user: {
          id: observationDetail.userId,
          label: observationDetail.userName
        },
        userGroup: {
          id: observationDetail.userGroupId,
          label: observationDetail.userGroup
        },
        feedbackNote: observationDetail.feedbackDescription
      });
    }
  }, [observationDetail]);

  const areAllRequiredFieldsFilled = () => {
    return formik.values.user && formik.values.userGroup;
  };

  const isSaveDisabled = !formik.isValid || !areAllRequiredFieldsFilled();

  return (
    <>
      {/* <ClosableBanner /> */}
      <Grid display={'flex'} justifyContent={'space-between'}>
        <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
          {/* {location.pathname === '/observations/edit-observation'
            ? 'Edit'
            : 'New'}{' '}
          Observation */}
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={colorTextGrey} />
              </SvgIcon>
            }
            sx={{ marginBottom: 2 }}
          >
            <Link
              color="inherit"
              href={'school'}
              onClick={event => {
                event.preventDefault();
                navigate('/observations');
              }}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                fontWeight={'700'}
                color={colorLinkGrey}
                fontSize={'24px'}
              >
                Observation
              </Typography>
            </Link>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
                color: colorTextBlack
              }}
            >
              {`${location.pathname === '/observations/edit-observation' ? 'Edit' : 'New'} Observation`}
            </Typography>
          </Breadcrumbs>
        </Typography>
        <Grid display={'flex'} gap={2}>
          <Button
            variant="outlined"
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: colorTextBlack,
              borderWidth: '2px',

              '&:hover': {
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px'
              }
            }}
            onClick={() => {
              handleSaveAsDraft();
            }}
          >
            Save as draft
          </Button>
          <Button
            // sx={{ marginRight: 1 }}
            variant="contained"
            onClick={() => {
              addEvidenceCard();
            }}
          >
            <SvgIcon sx={{ marginRight: 0.6, height: '16px', width: '16px' }}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_10982_19325)">
                  <path
                    d="M1.9172 12.0771C1.94783 11.8015 1.96314 11.6636 2.00485 11.5348C2.04185 11.4205 2.09413 11.3117 2.16026 11.2114C2.23481 11.0984 2.33287 11.0003 2.52899 10.8042L11.3332 2.00004C12.0696 1.26366 13.2635 1.26366 13.9999 2.00004C14.7362 2.73642 14.7362 3.93033 13.9999 4.66671L5.19565 13.4709C4.99953 13.667 4.90147 13.7651 4.78843 13.8396C4.68814 13.9058 4.57936 13.958 4.46507 13.995C4.33624 14.0367 4.19841 14.0521 3.92275 14.0827L1.6665 14.3334L1.9172 12.0771Z"
                    stroke="#1F2933"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10982_19325">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>{' '}
            Create evidence card
          </Button>
        </Grid>
      </Grid>
      {/* <CustomBreadcrumb path={`${location.pathname}`} state={location.state} /> */}
      {loading && (
        <Box
          bgcolor={'white'}
          padding={2}
          borderRadius={2}
          marginBottom={2}
          sx={{
            width: {
              lg: '60%',
              md: '100%'
            }
          }}
        >
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={400}
            animation="wave"
          />
        </Box>
      )}
      {!loading && (
        <>
          <Box
            bgcolor={'white'}
            padding={2}
            borderRadius={2}
            marginBottom={2}
            sx={{
              width: '100%'
            }}
          >
            <Typography fontWeight={'700'} fontSize={'20px'}>
              Create new observation{' '}
            </Typography>
            <Grid container display={'flex'} spacing={1} mt={0}>
              <Grid item>
                <InputLabel
                  sx={{
                    fontWeight: '700',
                    color: colorTextBlack,
                    mb: 0.2,
                    fontSize: '16px'
                  }}
                >
                  Select user group
                </InputLabel>
                <FormControl
                  variant="outlined"
                  sx={{ width: '280px', mr: 2 }}
                  size="small"
                >
                  <Autocomplete
                    size="small"
                    name="userGroup"
                    value={formik.values.userGroup}
                    onChange={(e, value) => {
                      if (value) {
                        dispatch(
                          getUsersOfGroup({
                            groupId: value.id,
                            payload: {
                              type: 'all',
                              page: 0,
                              size: 0
                            }
                          })
                        );
                        formik.setFieldValue('userGroup', value);
                        formik.setFieldValue('user', null);
                      } else {
                        formik.setFieldValue('userGroup', null);
                        formik.setFieldValue('user', null);
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === (value ? value.id : null)
                    }
                    getOptionLabel={getOptionLabel}
                    options={
                      isArrayNotEmpty(userGroupsData.userGroupsList)
                        ? userGroupsData.userGroupsList.map(c => {
                            return {
                              label: c.groupName,
                              id: c.userGroupId
                            };
                          })
                        : []
                    }
                    sx={{ width: '100%' }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          // label="Select User Group *"
                          error={
                            formik.touched.userGroup && formik.errors.userGroup
                          }
                          helperText={
                            formik.touched.userGroup && formik.errors.userGroup
                          }
                        />
                      );
                    }}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option:hover': {
                          backgroundColor: colorSecondary
                        }
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <InputLabel
                  sx={{
                    fontWeight: '700',
                    color: colorTextBlack,
                    mb: 0.2,
                    fontSize: '16px'
                  }}
                >
                  Select user
                </InputLabel>
                <FormControl
                  variant="outlined"
                  sx={{ width: '280px' }}
                  size="small"
                >
                  <Autocomplete
                    size="small"
                    name="user"
                    id="user"
                    value={formik.values.user}
                    onChange={(e, value) => {
                      formik.setFieldValue('user', value);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === (value ? value.id : null)
                    }
                    getOptionLabel={getOptionLabel}
                    options={
                      isArrayNotEmpty(usersOfGroupData.usersOfGroupList)
                        ? usersOfGroupData.usersOfGroupList
                            .filter(cc => cc.userId !== authData.userId)
                            .map(c => {
                              return {
                                label: c.name,
                                id: c.userId
                              };
                            })
                        : []
                    }
                    sx={{ width: '100%' }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          error={formik.touched.user && formik.errors.user}
                          helperText={formik.touched.user && formik.errors.user}
                        />
                      );
                    }}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option:hover': {
                          backgroundColor: colorSecondary
                        }
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {isArrayNotEmpty(evidenceList) && (
            <Box
              bgcolor={'white'}
              padding={2}
              borderRadius={2}
              marginBottom={2}
              sx={{
                width: '100%'
              }}
            >
              <Typography
                fontSize="20px"
                fontWeight={'700'}
                color={colorTextBlack}
              >
                Evidence Cards ({evidenceList.length})
              </Typography>
              <Grid container mt={1} spacing={2}>
                {evidenceList.map((evidence, key) => {
                  return (
                    <Grid item sm={12} md={12} key={key}>
                      <Box
                        sx={{
                          paddingX: 1,
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box>
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              return navigate('evidence-card', {
                                state: {
                                  observationId: location.state?.observationId,
                                  evidenceId: evidence.evidenceId,
                                  formValues: formik.values
                                }
                              });
                            }}
                          >
                            <Typography
                              fontSize="16px"
                              fontWeight={'700'}
                              color={colorTextBlack}
                            >
                              Evidence {key + 1}
                            </Typography>

                            <Typography
                              fontSize="14px"
                              fontWeight={'700'}
                              color={colorLinkGrey}
                            >
                              {evidence.indicatorName}
                            </Typography>
                          </a>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                          }}
                        >
                          {sceneroy_icon()}{' '}
                          <Typography sx={{ marginLeft: '3px' }}>
                            {evidence?.fileCount?.Image || 0}
                          </Typography>
                          <Divider orientation="vertical" sx={{ marginX: 1 }} />
                          {MIC_ICON()}{' '}
                          <Typography sx={{ marginLeft: '3px' }}>
                            {evidence?.fileCount?.Audio || 0}
                          </Typography>
                          <Divider orientation="vertical" sx={{ marginX: 1 }} />
                          {VIDEO_ICON()}{' '}
                          <Typography sx={{ marginLeft: '3px' }}>
                            {evidence?.fileCount?.Video || 0}
                          </Typography>
                          <Divider orientation="vertical" sx={{ marginX: 1 }} />
                          {PDF_ICON()}{' '}
                          <Typography sx={{ marginLeft: '3px' }}>
                            {evidence?.fileCount?.Document || 0}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider sx={{ marginTop: 1 }} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          )}

          <Tooltip placement="top" title="Click to add feedback note">
            <Button
              sx={{
                height: 30,
                color: colorTextBlack,
                borderBottom: `1px solid ${colorTextBlack}`,
                borderRadius: 0,
                ':hover': {
                  color: colorPrimary,
                  borderBottom: `1px solid ${colorPrimary}`
                }
              }}
              variant="text"
              onMouseEnter={() => setIshover(true)}
              onMouseLeave={() => setIshover(false)}
              onClick={() => setShowFeedbackCard(!showFeedbackCard)}
              size="small"
            >
              <SvgIcon sx={{ height: '16px', width: '16px', marginRight: 0.6 }}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99984 2.66675V13.3334M2.6665 8.00008H13.3332"
                    stroke={isHover ? colorPrimary : colorTextBlack}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SvgIcon>{' '}
              Add feedback note
            </Button>
          </Tooltip>

          {showFeedbackCard && (
            <Box
              bgcolor={'white'}
              padding={2}
              borderRadius={2}
              marginTop={2}
              sx={{
                width: '100%'
              }}
            >
              <Typography
                fontWeight={'700'}
                fontSize={'20px'}
                color={colorTextBlack}
              >
                Feedback note for teacher
              </Typography>
              <FormControl variant="outlined" fullWidth size="small">
                <TextField
                  name={`feedbackNote`}
                  // label=""
                  variant="outlined"
                  size="small"
                  maxRows={4}
                  inputProps={{ maxLength: 500 }}
                  multiline={true}
                  value={formik.values.feedbackNote}
                  onChange={formik.handleChange}
                />
                <div
                  style={{
                    display: 'flex',
                    alignSelf: 'end'
                  }}
                >
                  <Typography variant="body2" color="textSecondary">
                    {formik.values.feedbackNote.length}
                    /500
                  </Typography>
                </div>
              </FormControl>
            </Box>
          )}

          <Grid
            bgcolor={'white'}
            padding={2}
            borderRadius={2}
            marginTop={2}
            display={'flex'}
            gap={2}
            sx={{
              width: '100%'
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(revertObservationFormData());
                navigate('/observations', { replace: true });
              }}
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={() => formik.handleSubmit()}
              sx={{ width: '108px', height: '42px' }}
              // style={{ marginRight: 24 }}
              // sx={{ px: 6 }}
              disabled={isSaveDisabled}
            >
              Submit
            </LoadingButton>
          </Grid>
        </>
      )}
      {/* <RefreshConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      /> */}
      {!isCreateUpdateEvidenceLoading && isCreateUpdateEvidenceSuccess && (
        <SnackBarBox type="success" message={createUpdateEvidenceResponse} />
      )}
      {!isCreateUpdateEvidenceLoading && isCreateUpdateObservationFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            createUpdateObservationErrorContainer.errorMessage
          )}
        />
      )}
      {evidenceListError.error && (
        <SnackBarBox
          type="error"
          message={handleError(evidenceListError.errorMessage)}
        />
      )}
    </>
  );
};

export default AddObservation;
