import React, { useEffect, useState } from 'react';
import { colorPrimary, colorTextBlack } from '../../config/theme';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import { createTitle } from '../../utils/utils';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TIMEZONE, TOKEN_KEY } from '../../utils/constant';
import SnackBarBox from '../../components/SnackBarBox';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import axios from 'axios';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Close } from '@mui/icons-material';
import dayjs from 'dayjs';
import { sendNotificationOnEventCreation } from '../../redux/reducers/layoutSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const AddEditEvents = ({
  eventId = -999,
  eventDetails = null,
  onCancelClick,
  handleRefresh
}) => {
  const dispatch = useDispatch();

  const { authData } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(true);
  const [listOfAttendees, setListOfAttendeed] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState({ error: false, message: '' });

  useEffect(() => {
    document.title = createTitle('Add Edit Event');

    if (eventDetails) {
      //   formik.setValues({});
      setLoading(false);
    } else {
      setLoading(false);
    }
    return () => {};
  }, []);

  const formik = useFormik({
    initialValues: {
      title: '',
      startEvent: null,
      endEvent: null,
      attendees: '',
      meetLink: ''
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title is required'),
      startEvent: Yup.date()
        .test(
          'startEvent-endEvent',
          'If startEvent is provided, endEvent is mandatory',
          function (value) {
            const { endEvent } = this.parent;
            if (value && !endEvent) {
              return this.createError({
                path: 'endEvent',
                message: 'End event is mandatory if start event is provided'
              });
            }
            return true;
          }
        )
        .nullable(),
      endEvent: Yup.date()

        .test(
          'endEvent-startEvent',
          'If endEvent is provided, startEvent is mandatory',
          function (value) {
            const { startEvent } = this.parent;
            if (value && !startEvent) {
              return this.createError({
                path: 'startEvent',
                message: 'Start event is mandatory if end event is provided'
              });
            }
            return true;
          }
        )
        .nullable(),
      attendees: Yup.string().nullable()
    }),
    onSubmit: values => {
      setLoading(true);
      if (eventId == -999) {
        createEvent(values);
      }
    }
  });

  const handleAddAttendee = () => {
    const newAttendee = { email: formik.values.attendees };

    if (
      Yup.string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov|mil|biz|info|mobi|name|aero|jobs|museum)$/
        )
        .isValidSync(newAttendee.email)
    ) {
      setListOfAttendeed([...listOfAttendees, newAttendee]);
      formik.setFieldValue('attendees', '');
    } else {
      formik.setFieldError('attendees', 'Enter valid email');
    }
  };

  const handleRemoveAttendee = index => {
    const updatedAttendees = listOfAttendees.filter((_, i) => i !== index);
    setListOfAttendeed(updatedAttendees);
  };

  const createEvent = async eventDetails => {
    if (eventDetails.attendees === '') {
      try {
        const response = await axios.post(
          `https://www.googleapis.com/calendar/v3/calendars/primary/events?sendUpdates=all&conferenceDataVersion=1&key=${process.env.REACT_APP_GOOGLE_CALENDAR_API}`,
          {
            summary: eventDetails.title,
            start: {
              dateTime: eventDetails.startEvent
                ? dayjs(eventDetails.startEvent).format('YYYY-MM-DDTHH:mm:ss')
                : moment().format('YYYY-MM-DDTHH:mm:ss'), // Format: "YYYY-MM-DDTHH:mm:ss"
              timeZone: 'Asia/Kolkata'
            },
            end: {
              dateTime: eventDetails.endEvent
                ? dayjs(eventDetails.endEvent).format('YYYY-MM-DDTHH:mm:ss')
                : moment().format('YYYY-MM-DDTHH:mm:ss'), // Format: "YYYY-MM-DDTHH:mm:ss"
              timeZone: 'Asia/Kolkata'
            },
            attendees: [...listOfAttendees],
            conferenceData: {
              createRequest: {
                requestId: Math.random().toString(36).substring(2, 15),
                conferenceSolutionKey: {
                  type: 'hangoutsMeet'
                },
                status: {
                  statusCode: 'success'
                }
              }
            }
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
            }
          }
        );
        if (response.status === 200) {
          let payload = {
            userId: authData?.userId,
            startDateTime: eventDetails.startEvent
              ? dayjs(eventDetails.startEvent).format('YYYY-MM-DDTHH:mm:ss')
              : moment().format('YYYY-MM-DDTHH:mm:ss'),
            endDateTime: eventDetails.endEvent
              ? dayjs(eventDetails.endEvent).format('YYYY-MM-DDTHH:mm:ss')
              : moment().format('YYYY-MM-DDTHH:mm:ss'),
            emailList: [...listOfAttendees.map(cc => cc.email)],
            title: eventDetails.title
          };
          setShowSuccess(true);
          dispatch(sendNotificationOnEventCreation(payload));
          handleRefresh();
          setTimeout(() => onCancelClick(), 400);
        } else {
          setShowError({ error: true, message: 'Error creating event' });
          setLoading(false);
          console.error('Error creating event:', response.data.error.message);
        }
      } catch (error) {
        setShowError({ error: true, message: 'Error creating event' });
        setLoading(false);
        console.error('Error creating event:', error.message);
      }
    } else {
      setShowError({
        error: true,
        message: 'Please add attendees by using Tab or Enter'
      });
      setLoading(false);
    }
    setTimeout(() => {
      setShowError({
        error: false,
        message: ''
      });
    }, 3000);
  };

  return (
    <>
      <Box>
        {loading && (
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={400}
            animation="wave"
          />
        )}
        {!loading && (
          <Box bgcolor={'white'} padding={2} borderRadius={2} marginBottom={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  label="Title"
                  variant="outlined"
                  size="small"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    id="startEvent"
                    name="startEvent"
                    label="Start Event"
                    inputVariant="outlined"
                    value={formik.values.startEvent}
                    slotProps={{
                      textField: { size: 'small', fullWidth: true }
                    }}
                    onChange={date => formik.setFieldValue('startEvent', date)}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {formik.touched.startEvent &&
                  Boolean(formik.errors.startEvent) && (
                    <FormHelperText>
                      {formik.touched.startEvent && formik.errors.startEvent}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    fullWidth
                    id="endEvent"
                    name="endEvent"
                    label="End Event"
                    inputVariant="outlined"
                    minDateTime={formik.values.startEvent}
                    value={formik.values.endEvent}
                    onChange={date => formik.setFieldValue('endEvent', date)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.endEvent &&
                          Boolean(formik.errors.endEvent)
                        }
                        helperText={'Jey'}
                      />
                    )}
                    slotProps={{
                      textField: { size: 'small', fullWidth: true }
                    }}
                  />
                  {formik.touched.endEvent &&
                    Boolean(formik.errors.endEvent) && (
                      <FormHelperText>
                        {formik.touched.endEvent && formik.errors.endEvent}
                      </FormHelperText>
                    )}
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id={`attendees`}
                  name={`attendees`}
                  label={`Add attendees email`}
                  variant="outlined"
                  size="small"
                  onChange={formik.handleChange}
                  value={formik.values.attendees}
                  onKeyDown={event => {
                    if (event.key === 'Enter' || event.key === 'Tab') {
                      event.preventDefault();
                      handleAddAttendee();
                    }
                  }}
                  error={
                    formik.touched.attendees && Boolean(formik.errors.attendees)
                  }
                  helperText={
                    formik.touched.attendees && formik.errors.attendees
                  }
                />
                <Box
                  display="flex"
                  flexWrap="wrap"
                  overflowY="auto"
                  maxHeight="200px"
                  mt={1}
                >
                  {listOfAttendees.map((attendee, index) => {
                    return (
                      <Typography
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                          mr: 1,
                          fontWeight: '600',
                          padding: '2px 6px',
                          background: '#FCEBC5',
                          border: `1px solid ${colorPrimary}`,
                          borderRadius: '6px'
                        }}
                        key={index}
                        variant="caption"
                      >
                        {attendee?.email}
                        <IconButton
                          onClick={() => {
                            handleRemoveAttendee(index);
                          }}
                          sx={{
                            padding: 0,
                            ml: 2
                          }}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </Typography>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Divider orientation="horizontal" />
      <Box bgcolor={'white'} borderRadius={2} marginTop={2}>
        <Grid display={'flex'} gap={2}>
          <Button
            variant="outlined"
            onClick={() => {
              onCancelClick();
            }}
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: colorTextBlack,
              borderWidth: '2px',
              height: '42px',
              width: '106px',
              '&:hover': {
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px'
              }
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={() => formik.handleSubmit()}
            variant="contained"
            sx={{
              width: '94px',
              height: '42px',
              boxShadow: 'none'
            }}
          >
            Save
          </LoadingButton>
        </Grid>
      </Box>

      {showError.error && (
        <SnackBarBox type="error" message={showError.message} />
      )}
      {showSuccess && (
        <SnackBarBox type="success" message={'Event created successfully...'} />
      )}
    </>
  );
};

export default AddEditEvents;
