import { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  TextareaAutosize,
  Typography
} from '@mui/material';
import {
  colorPrimary,
  colorPureWhite,
  colorSecondary,
  colorTextBlack
} from '../../config/theme';
import {
  ROLE_LEVEL,
  SELECT_IMAGE_MESSAGE,
  imageAllowedTypes,
  userTypesList
} from '../../utils/constant';
import {
  enterOnlyNumbers,
  generateImageSrc,
  handleError,
  isArrayNotEmpty,
  isImageExtensionAllowed,
  isValidEmail,
  isValidMobileNumber
} from '../../utils/utils';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Delete, Edit, Upload } from '@mui/icons-material';
import SnackBarBox from '../../components/SnackBarBox';
import ImageCropper from '../../components/ImageCropper';
import {
  getUserDetails,
  profilePicDelete,
  profilePicUpload,
  updateUserDetails
} from '../../redux/reducers/usersSlice';
import moment from 'moment';
import { getRoleDataById } from '../../redux/reducers/roleSlice';
import _ from 'lodash';
import { Navigate, useNavigate } from 'react-router-dom';
import { getBasicDetails } from '../../utils/service';
import { CaseContext } from '../../App';
import { DeleteIcon, EditIcon, UploadIcon } from '../../utils/iconSvg';

const MyAccountForm = () => {
  const formControl = {
    margin: '0.7rem  auto auto auto'
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { authData } = useSelector(state => state.auth);
  const {
    userDetails,
    isUploadProfilePicLoading,
    isUploadProfilePicSuccess,
    isUploadProfilePicFailed,
    uploadProfilePicErrorContainer,
    isDeleteProfilePicLoading,
    isDeleteProfilePicFailed,
    isDeleteProfilePicSuccess,
    DeleteProfilePicErrorContainer,
    isUpadteUserDataSuccess,
    isUpadteUserDataLoading
  } = useSelector(state => state.users);
  const { areaData, stateData, districtData, modules } = useSelector(
    state => state.masters
  );
  const { rolesData, pageData, roleByIdData, isRoleByIdSuccess } = useSelector(
    state => state.roles
  );
  const [uploadedImage, setUploadedImage] = useState(null);
  const [tempUploadImage, setTempUploadImage] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const { setBasicDetailsContext, basicDetailsContext } =
    useContext(CaseContext);
  const [isHover, setIshover] = useState(false);
  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      role: '',
      mobileNo: '',
      modules: [],
      email: '',
      username: '',
      state: '',
      userType: '',
      district: '',
      area: '',
      profilePic: ''
    },
    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
      role: yup.string().nullable(),
      mobileNo: yup
        .string()
        .required('Phone Number is required')
        .test(
          'is-valid-mobile-number',
          'Mobile number should consist of exactly ten digits.',
          value => isValidMobileNumber(value)
        )
        .min(10, 'Mobile Number should be minimum of 10 digit.'),
      modules: yup.array().nullable(),
      email: yup
        .string()
        .required('Email is required')
        .test(
          'is-valid-email',
          'Please provide a valid email address.',
          value => isValidEmail(value)
        ),
      username: yup.string(),
      state: yup.string().required('State is required'),
      userType: yup.string(),
      district: yup.string().required('District is required'),
      area: yup.string().required('Area is required')
    }),
    onSubmit: value => {
      let updatePayload = {
        userName: value.username,
        name: value.name,
        contactNumber: value.mobileNo,
        email: value.email,
        dateOfBirth: moment(userDetails?.dateOfBirth).format('YYYY-MM-DD'),
        grade: userDetails?.grade,
        area: value.area,
        userType: value.userType,
        citizenship: userDetails?.citizenship,
        status: userDetails?.status,
        roleId: value.role,
        stateId: value.state,
        districtId: value.district,
        // moduleId: 44,
        schoolId: userDetails?.schoolId,
        isAdmin: false,
        loggedInUserName: authData?.userName
      };
      dispatch(
        updateUserDetails({
          updatePayload: updatePayload,
          userId: authData?.userId,
          profilePic: formik.values.profilePic,
          isMyAccount: true
        })
      );
    }
  });

  const handleFormValue = (e, field) => {
    formik.setFieldValue(field, e.target.value);
  };

  useEffect(() => {
    if (userDetails) {
      formik.setValues({
        name: userDetails?.name || '',
        mobileNo: userDetails?.contactNumber || '',
        email: userDetails?.email || '',
        state: userDetails?.stateId || null,
        district: userDetails?.districtId || null,
        area: userDetails?.areaId || null,
        role: userDetails?.roleId || null,
        username: userDetails?.userName,
        userType: userDetails?.userType || null
      });
      dispatch(getRoleDataById(userDetails?.roleId));
      if (userDetails?.userImageUrl) {
        setUploadedImage(userDetails?.userImageUrl);
      }
      // else if (
      //   userDetails?.userImage &&
      //   !String(userDetails?.userImage).startsWith('/image')
      // ) {
      //   setUploadedImage(userDetails?.userImage);
      // }
    }
  }, [userDetails]);

  const handleImageUpload = event => {
    console.log(event, 'evellev');
    fileInputRef.current.click();
    const file = event.target.files[0];
    if (file) {
      fileInputRef.current.value = '';
      const allowedTypes = imageAllowedTypes;
      const maxSizeMB = 1;
      const fileName = file ? file?.name : 'NA';

      if (allowedTypes.includes(file.type)) {
        if (isImageExtensionAllowed(fileName)) {
          if (file.size / 1024 / 1024 <= maxSizeMB) {
            setUploadedFileName(fileName);
            const reader = new FileReader();
            reader.onload = e => {
              setTempUploadImage(e.target.result);
            };

            reader.readAsDataURL(file);
          } else {
            setImageUploadError({
              error: true,
              errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
            });
          }
        } else {
          setImageUploadError({
            error: true,
            errorMessage: SELECT_IMAGE_MESSAGE
          });
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: SELECT_IMAGE_MESSAGE
        });
      }
    }
  };

  useEffect(() => {
    if (imageUploadError.error) {
      const timeoutId = setTimeout(() => {
        setImageUploadError({
          error: false,
          errorMessage: ''
        });
        handleDeleteImage();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [imageUploadError]);

  useEffect(() => {
    if (isRoleByIdSuccess && roleByIdData) {
      const moduleIdValue = pageData?.filter(item => {
        return roleByIdData?.pageIds?.includes(item?.pageId);
      });
      let moduleIdArray = [];
      const moduleIdPageName = moduleIdValue?.map(item => {
        moduleIdArray?.push(item?.pageName);
      });
      formik.setFieldValue('moduleId', moduleIdArray?.toString());
    }
  }, [isRoleByIdSuccess]);

  const handleRemoveImage = () => {
    handleDeleteImage();
    setTempUploadImage(null);
    setUploadedImage(null);
    formik.setFieldValue('profilePic', null);
  };

  const handleDeleteImage = () => {
    setTempUploadImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileUpload = file => {
    setUploadedImage(null);
    const formData = new FormData();
    formData.append('profilePhoto', file);
    formData.append('loggedInUserName', authData?.userName);
    formik.setFieldValue('profilePic', file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      setUploadedImage(
        e.target.result?.replace(/^data:image\/jpeg;base64,/, '')
      );
    };
    setTempUploadImage(null);
  };

  useEffect(() => {
    if (!isUpadteUserDataLoading && isUpadteUserDataSuccess) {
      fetchBasicResponse();
      // dispatch(getUserDetails(authData?.userId));
    }
  }, [isUpadteUserDataSuccess]);

  async function fetchBasicResponse() {
    const getBasicDetailsResponse = await getBasicDetails(authData?.userId);
    setBasicDetailsContext({
      userImage: getBasicDetailsResponse?.userImageUrl,
      name: getBasicDetailsResponse?.name
    });
  }

  return (
    <>
      <Box bgcolor={'white'} padding={2} borderRadius={2} paddingBottom={7}>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid sx={{ display: 'flex', gap: 5 }}>
            <Grid
              display={'flex'}
              flexDirection={'column'}
              // alignItems="center"
            >
              <Box
                bgcolor={'#FEF8EC'}
                padding={3}
                borderRadius={'8px'}
                textAlign="center"
                width={300}
              >
                {tempUploadImage && (
                  <ImageCropper
                    key="profilePicture"
                    uploadedFileName={uploadedFileName}
                    incomingFile={tempUploadImage}
                    handleFileUpload={handleFileUpload}
                    handleFileCancel={handleDeleteImage}
                  />
                )}
                {isUploadProfilePicLoading || isDeleteProfilePicLoading ? (
                  <>
                    <Skeleton
                      variant="circular"
                      width={132}
                      height={132}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        backgroundColor: '#c9c9c9',
                        color: 'white',
                        fontSize: '22px',
                        margin: 'auto'
                      }}
                    />
                  </>
                ) : uploadedImage ? (
                  <Avatar
                    src={
                      !/^[A-Za-z0-9+/=]+$/.test(uploadedImage)
                        ? uploadedImage
                        : generateImageSrc(uploadedImage)
                    }
                    alt={basicDetailsContext.name}
                    sx={{ width: 132, height: 132, margin: 'auto' }}
                  ></Avatar>
                ) : (
                  <Avatar
                    sx={{
                      width: 132,
                      height: 132,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      backgroundColor: '#c9c9c9',
                      color: 'white',
                      fontSize: '22px',
                      margin: 'auto'
                    }}
                  >
                    {basicDetailsContext
                      ? basicDetailsContext.name
                          .split(' ')
                          .map(word => word[0])
                          .join('')
                          .toUpperCase()
                      : ''}
                  </Avatar>
                )}
                <Typography margin="14px auto" fontWeight="600" fontSize={18}>
                  {basicDetailsContext?.name || ''}
                </Typography>

                <Box display={'flex'} justifyContent={'space-around'}>
                  {uploadedImage ? (
                    <>
                      <Button
                        sx={{
                          height: 30,
                          color: isHover ? colorPrimary : colorTextBlack,
                          borderBottom: `1px solid ${isHover ? colorPrimary : colorTextBlack}`,
                          borderRadius: 0
                          // ':hover': {
                          //   color: colorPrimary,
                          //   borderBottom: `1px solid ${colorPrimary}`
                          // }
                        }}
                        onClick={() => fileInputRef.current.click()}
                        onMouseEnter={() => setIshover(true)}
                        onMouseLeave={() => setIshover(false)}
                        variant="text"
                      >
                        <input
                          ref={fileInputRef}
                          key={'uploadProfile'}
                          type="file"
                          style={{ display: 'none' }}
                          accept={imageAllowedTypes.join(',')}
                          onChange={handleImageUpload}
                        />
                        <Icon
                          sx={{
                            mr: 0.3,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.41744 12.0771C2.44807 11.8015 2.46339 11.6636 2.50509 11.5348C2.54209 11.4205 2.59437 11.3117 2.66051 11.2114C2.73505 11.0984 2.83311 11.0003 3.02923 10.8042L11.8334 2.00004C12.5698 1.26366 13.7637 1.26366 14.5001 2.00004C15.2365 2.73642 15.2365 3.93033 14.5001 4.66671L5.6959 13.4709C5.49978 13.667 5.40172 13.7651 5.28867 13.8396C5.18838 13.9058 5.07961 13.958 4.96531 13.995C4.83648 14.0367 4.69865 14.0521 4.42299 14.0827L2.16675 14.3334L2.41744 12.0771Z"
                              stroke={isHover ? colorPrimary : colorTextBlack}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Icon>
                        <Typography
                          variant="button"
                          sx={{ fontWeight: '700', fontSize: '14px' }}
                          color={isHover ? colorPrimary : colorTextBlack}
                        >
                          {' '}
                          Edit photo
                        </Typography>
                      </Button>
                      <Button
                        sx={{
                          color: '#F3413B',
                          borderBottom: '1px solid #F3413B',
                          borderRadius: '0px',
                          height: 30,
                          margin: 0
                        }}
                        onClick={handleRemoveImage}
                      >
                        <Icon
                          sx={{
                            mr: 0.3,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.5 2H10.5M2.5 4H14.5M13.1667 4L12.6991 11.0129C12.629 12.065 12.5939 12.5911 12.3667 12.99C12.1666 13.3412 11.8648 13.6235 11.5011 13.7998C11.088 14 10.5607 14 9.50623 14H7.49377C6.43927 14 5.91202 14 5.49889 13.7998C5.13517 13.6235 4.83339 13.3412 4.63332 12.99C4.40607 12.5911 4.371 12.065 4.30086 11.0129L3.83333 4M7.16667 7V10.3333M9.83333 7V10.3333"
                              stroke="#D62828"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Icon>
                        <Typography
                          variant="button"
                          sx={{ fontWeight: '700', fontSize: '14px' }}
                          color={'#D62828'}
                        >
                          {' '}
                          Delete photo
                        </Typography>{' '}
                      </Button>
                    </>
                  ) : (
                    <Button
                      className="custom_menu"
                      component="label"
                      startIcon={<Add />}
                      sx={{
                        height: 30,
                        color: 'black',
                        borderBottom: '1px solid black',
                        borderRadius: 0
                      }}
                      variant="text"
                    >
                      Upload
                      <input
                        ref={fileInputRef}
                        key={'uploadProfile'}
                        type="file"
                        style={{ display: 'none' }}
                        accept={imageAllowedTypes.join(',')}
                        onChange={handleImageUpload}
                      />
                    </Button>
                  )}
                </Box>
              </Box>
              {/* <Box marginTop={2} textAlign={'center'}>
                <Link sx={{ color: 'black', fontSize: 14 }} href="#">
                  Send Reset Password Link
                </Link>
              </Box> */}
            </Grid>

            <Divider orientation="vertical" variant="middle" flexItem />

            <Grid sx={{ display: 'flex', gap: 2 }}>
              <Grid>
                <Grid>
                  <InputLabel
                    required
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    Name
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      name="name"
                      size="small"
                      sx={{ width: '340px', height: '42px' }}
                      value={formik.values.name}
                      onChange={e => handleFormValue(e, 'name')}
                      error={formik.touched.name && formik.errors.name}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid sx={formControl}>
                  <InputLabel
                    required
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    Phone No
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      name="mobileNo"
                      size="small"
                      sx={{ width: '340px', height: '42px' }}
                      type="tel"
                      inputProps={{ maxLength: 10 }}
                      value={formik.values.mobileNo}
                      onChange={e => {
                        formik.setFieldValue(
                          'mobileNo',
                          enterOnlyNumbers(e.target.value)
                        );
                      }}
                      error={formik.touched.mobileNo && formik.errors.mobileNo}
                      helperText={
                        formik.touched.mobileNo && formik.errors.mobileNo
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid sx={formControl}>
                  <InputLabel
                    required
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    Username
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <TextField
                      name="username"
                      size="small"
                      sx={{ width: '340px', height: '42px' }}
                      disabled
                      value={formik.values.username}
                      onChange={e => handleFormValue(e, 'username')}
                      error={formik.touched.username && formik.errors.username}
                      helperText={
                        formik.touched.username && formik.errors.username
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid sx={formControl}>
                  <InputLabel
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    User Type
                  </InputLabel>
                  <FormControl fullWidth size="small">
                    <TextField
                      name="userType"
                      size="small"
                      sx={{ width: '340px', height: '42px' }}
                      fullWidth
                      disabled
                      value={formik.values.userType}
                    />
                  </FormControl>
                </Grid>
                <Grid sx={formControl}>
                  <InputLabel
                    required
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    Area
                  </InputLabel>
                  <FormControl fullWidth size="small">
                    <Select
                      value={formik.values.area}
                      onChange={formik.handleChange}
                      name="area"
                      sx={{ textAlign: 'left', width: '340px', height: '42px' }}
                    >
                      {isArrayNotEmpty(areaData.area) &&
                        areaData.area
                          .filter(
                            cc =>
                              cc.stateId === formik.values.state &&
                              cc.districtId === formik.values.district
                          )
                          .map((row, key) => {
                            return (
                              <MenuItem
                                key={key}
                                value={row?.pinId}
                                sx={{
                                  ':hover': {
                                    backgroundColor: colorSecondary
                                  }
                                }}
                              >
                                {row?.area}
                              </MenuItem>
                            );
                          })}

                      {!isArrayNotEmpty(
                        areaData.area.filter(
                          cc =>
                            cc.stateId === formik.values.state &&
                            cc.districtId === formik.values.district
                        )
                      ) && (
                        <MenuItem key={-99} value={null}>
                          None
                        </MenuItem>
                      )}
                    </Select>
                    {formik.touched.area && formik.errors.area && (
                      <FormHelperText sx={{ textAlign: 'start' }}>
                        {formik.errors.area}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid>
                <Grid>
                  <InputLabel
                    required
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    Role
                  </InputLabel>
                  <FormControl fullWidth size="small">
                    <Select
                      disabled={true}
                      value={formik.values.role}
                      onChange={e => {
                        let rolesData = rolesData.roles.find(
                          cc => cc.roleId === e.target.value
                        );
                        formik.handleChange(e);
                        dispatch(getRoleDataById(rolesData.roleId));
                        // formik.setFieldValue('userType', rolesData?.roleType);
                        formik.setFieldValue(
                          'userType',
                          _.includes(ROLE_LEVEL, rolesData?.roleLevel)
                            ? 'PAF'
                            : 'Registered'
                        );
                      }}
                      name="role"
                      sx={{ textAlign: 'left', width: '340px', height: '42px' }}
                    >
                      {isArrayNotEmpty(rolesData.roles) &&
                        rolesData.roles.map((row, key) => {
                          return (
                            <MenuItem key={key} value={row?.roleId}>
                              {row.roleName}
                            </MenuItem>
                          );
                        })}
                      {!isArrayNotEmpty(rolesData.roles) && (
                        <MenuItem key={-99} value={null}>
                          None
                        </MenuItem>
                      )}
                    </Select>
                    {formik.touched.role && formik.errors.role && (
                      <FormHelperText sx={{ textAlign: 'start' }}>
                        {formik.errors.role}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid sx={formControl}>
                  <InputLabel
                    required
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    Email
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      name="email"
                      size="small"
                      sx={{ width: '340px', height: '42px' }}
                      value={formik.values.email}
                      onChange={e => handleFormValue(e, 'email')}
                      error={formik.touched.email && formik.errors.email}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </FormControl>
                </Grid>

                <Grid sx={formControl}>
                  <InputLabel
                    required
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    State
                  </InputLabel>
                  <FormControl fullWidth size="small">
                    <Select
                      value={formik.values.state}
                      onChange={e => {
                        formik.handleChange(e);
                        formik.setFieldValue('district', null);
                        formik.setFieldValue('area', null);
                      }}
                      name="state"
                      sx={{ textAlign: 'left', width: '340px', height: '42px' }}
                    >
                      {isArrayNotEmpty(stateData.state) &&
                        stateData.state.map((row, key) => {
                          return (
                            <MenuItem
                              key={key}
                              value={row.stateId}
                              sx={{
                                ':hover': {
                                  backgroundColor: colorSecondary
                                }
                              }}
                            >
                              {row?.stateName}
                            </MenuItem>
                          );
                        })}

                      {!isArrayNotEmpty(stateData.state) && (
                        <MenuItem
                          key={-99}
                          value={null}
                          sx={{
                            ':hover': {
                              backgroundColor: colorSecondary
                            }
                          }}
                        >
                          None
                        </MenuItem>
                      )}
                    </Select>
                    {formik.touched.state && formik.errors.state && (
                      <FormHelperText sx={{ textAlign: 'start' }}>
                        {formik.errors.state}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid sx={formControl}>
                  <InputLabel
                    required
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    District
                  </InputLabel>
                  <FormControl fullWidth size="small">
                    <Select
                      value={formik.values.district}
                      onChange={e => {
                        formik.handleChange(e);
                        formik.setFieldValue('area', null);
                      }}
                      name="district"
                      sx={{ textAlign: 'left', width: '340px', height: '42px' }}
                    >
                      {isArrayNotEmpty(districtData.district) &&
                        districtData.district
                          .filter(cc => cc.stateId === formik.values.state)
                          .map((row, key) => {
                            return (
                              <MenuItem
                                key={key}
                                value={row?.districtId}
                                sx={{
                                  ':hover': {
                                    backgroundColor: colorSecondary
                                  }
                                }}
                              >
                                {row?.districtName}
                              </MenuItem>
                            );
                          })}
                      {!isArrayNotEmpty(
                        districtData.district.filter(
                          cc => cc.stateId === formik.values.state
                        )
                      ) && (
                        <MenuItem
                          key={-99}
                          value={null}
                          sx={{
                            ':hover': {
                              backgroundColor: colorSecondary
                            }
                          }}
                        >
                          None
                        </MenuItem>
                      )}
                    </Select>
                    {formik.touched.district && formik.errors.district && (
                      <FormHelperText sx={{ textAlign: 'start' }}>
                        {formik.errors.district}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid sx={formControl}>
                  <InputLabel
                    required
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    Modules
                  </InputLabel>
                  <FormControl fullWidth size="small">
                    <TextField
                      name="moduleId"
                      size="small"
                      fullWidth
                      sx={{ width: '340px', height: '42px' }}
                      multiline={true}
                      rows={1}
                      InputLabelProps={{
                        shrink: formik?.values?.moduleId ? true : false
                      }}
                      disabled
                      style={{ maxWidth: '100%', minWidth: '100%' }}
                      value={formik?.values?.moduleId}
                      inputProps={{
                        style: { overflow: 'auto' }
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Grid bgcolor={colorPureWhite} p={2} mt={2} display={'flex'} gap={2}>
        <LoadingButton
          variant="outlined"
          size="small"
          // onClick={() => handleReset()}
          onClick={() => navigate('/dashboard')}
          sx={{
            borderColor: colorPrimary,
            borderRadius: '10px',
            color: colorTextBlack,
            borderWidth: '2px',
            height: '42px',
            width: '106px',
            '&:hover': {
              borderColor: colorPrimary,
              backgroundColor: 'transparent',
              borderRadius: '10px',
              borderWidth: '2px'
            }
          }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="contained"
          size="small"
          loading={isUpadteUserDataLoading}
          onClick={formik.handleSubmit}
          color="primary"
          sx={{
            fontSize: 14,
            // marginRight: 2,
            fontWeight: '700',
            minWidth: '94px',
            height: '42px'
          }}
        >
          Save
        </LoadingButton>
      </Grid>

      {imageUploadError.error && (
        <SnackBarBox
          type="error"
          message={handleError(imageUploadError.errorMessage)}
        />
      )}

      {!isUploadProfilePicLoading && isUploadProfilePicFailed && (
        <SnackBarBox
          type="error"
          message={handleError(uploadProfilePicErrorContainer?.errorMessage)}
        />
      )}
      {/* {!isUploadProfilePicLoading && isUploadProfilePicSuccess && (
        <SnackBarBox
          type="success"
          message={'Profile pic updated successfully..'}
        />
      )} */}

      {/* {!isDeleteProfilePicLoading && isDeleteProfilePicSuccess && (
        <SnackBarBox
          type="success"
          message={'Profile picture removed successfully...'}
        />
      )} */}
      {!isDeleteProfilePicLoading && isDeleteProfilePicFailed && (
        <SnackBarBox
          type="error"
          message={handleError(DeleteProfilePicErrorContainer?.errorMessage)}
        />
      )}

      {!isUpadteUserDataLoading && isUpadteUserDataSuccess && (
        <SnackBarBox type="success" message="User updated successfully.." />
      )}
    </>
  );
};

export default MyAccountForm;
