import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  Skeleton,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import SnackBarBox from '../../components/SnackBarBox';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  createUpdateSchool,
  getArea,
  getDistrict,
  getSchoolDetails,
  getState,
  revertSchoolFormData
} from '../../redux/reducers/mastersSlice';
import CustomBreadcrumb from '../../components/Breadcrumb';
import { ChevronRightIcon, SearchMdIcon } from '../../utils/iconSvg';

const AddEditSchool = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let mastersPayload = {
    type: 'all',
    page: 0,
    size: 0,
    search: '',
    districtId: ''
  };
  const {
    stateData,
    isStateFailed,
    districtData,
    isDistrictFailed,
    areaData,
    isAreaFailed,
    isCreateUpdateSchoolLoading,
    isCreateUpdateSchoolSuccess,
    isCreateUpdateSchoolFailed,
    isCreateUpdateSchoolResponse,
    createUpdateSchoolErrorContainer,
    isSchoolDetailsFailed,
    isSchoolDetailsSuccess,
    schoolDetails,
    isSchoolDetailsLoading
  } = useSelector(state => state.masters);
  const { authData } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(getState(mastersPayload));
    dispatch(getDistrict(mastersPayload));
    dispatch(getArea(mastersPayload));
    document.title = createTitle(
      `${location.pathname === '/schools/school-details/edit-school' ? 'Edit' : 'Add'} School`
    );

    if (
      location.pathname === '/schools/school-details/edit-school' &&
      location.state
    ) {
      dispatch(getSchoolDetails({ schoolId: location.state.schoolId }));
    }

    return () => dispatch(revertSchoolFormData());
  }, []);

  const formik = useFormik({
    initialValues: {
      stateId: null,
      districtId: null,
      pinId: null,
      schoolName: ''
    },
    validationSchema: yup.object().shape({
      stateId: yup
        .object()
        .shape({
          id: yup.string().required(),
          label: yup.string()
        })
        .required('State is required'),
      districtId: yup
        .object()
        .shape({
          id: yup.string().required(),
          label: yup.string()
        })
        .required('District is required'),
      pinId: yup
        .object()
        .shape({
          id: yup.string().required(),
          label: yup.string()
        })
        .required('Area is required'),
      schoolName: yup.string().required('School is required')
    }),
    onSubmit: values => {
      setLoading(true);

      let submitPayload = {
        stateId: values.stateId.id,
        districtId: values.districtId.id,
        pinId: values.pinId.id,
        schoolName: values.schoolName,
        locatedArea: values.pinId.label,
        loggedInUserName: authData?.userName
      };

      dispatch(
        createUpdateSchool({
          payload: submitPayload,
          schoolId: location.state?.schoolId || null
        })
      );
    }
  });

  function getOptionLabel(option) {
    if (!option) {
      return '';
    }

    if (option.label !== null) {
      return option.label;
    }

    if (option.id !== null) {
      return option.id;
    }

    return '';
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (schoolDetails?.schoolId) {
        formik.setValues({
          stateId: {
            id: schoolDetails.stateId,
            label: schoolDetails.stateName
          },
          districtId: {
            id: schoolDetails.districtId,
            label: schoolDetails.districtName
          },
          pinId: {
            id: schoolDetails.pinId,
            label: schoolDetails.area
          },
          schoolName: schoolDetails?.schoolName
        });

        setLoading(false);
      } else {
        setLoading(false);
      }
    }, 1200);
  }, [isSchoolDetailsSuccess]);

  useEffect(() => {
    if (isCreateUpdateSchoolSuccess) {
      setTimeout(() => {
        dispatch(revertSchoolFormData());
        navigate('/schools');
      }, 2000);
    }

    if (isCreateUpdateSchoolFailed) {
      setLoading(false);
    }
  }, [isCreateUpdateSchoolSuccess, isCreateUpdateSchoolFailed]);

  const areAllRequiredFieldsFilled = () => {
    return (
      formik.values.stateId &&
      formik.values.districtId &&
      formik.values.pinId &&
      formik.values.schoolName
    );
  };

  const isSaveDisabled = !formik.isValid || !areAllRequiredFieldsFilled();
  return (
    <Box>
      <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <SvgIcon sx={{ height: 24, width: 24 }}>
              <ChevronRightIcon color={colorTextGrey} />
            </SvgIcon>
          }
          sx={{ marginBottom: 2 }}
        >
          <Link
            color="inherit"
            href={'school'}
            onClick={event => {
              event.preventDefault();
              navigate(
                location.pathname === '/schools/school-details/edit-school'
                  ? '/schools/school-details'
                  : '/school',
                {
                  state: { ...location.state }
                }
              );
            }}
            sx={{ textDecoration: 'none' }}
          >
            <Typography
              fontWeight={'700'}
              color={colorLinkGrey}
              fontSize={'24px'}
            >
              {schoolDetails?.schoolName || 'School'}
            </Typography>
          </Link>
          <Typography
            sx={{ fontSize: '32px', fontWeight: '700', color: colorTextBlack }}
          >
            {`${location.pathname === '/schools/school-details/edit-school' ? 'Edit' : 'Add new'} school`}
          </Typography>
        </Breadcrumbs>
      </Typography>
      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}
      {!loading && (
        <>
          <Box
            bgcolor={'white'}
            padding={2}
            borderRadius={2}
            marginBottom={2}
            sx={{ width: '100%' }}
            // sx={{
            //   width: {
            //     lg: '60%',
            //     md: '100%'
            //   }
            // }}
          >
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid>
                <Grid sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <Grid item sm={12} sx={{ width: '280px' }}>
                    <InputLabel
                      required
                      sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    >
                      State
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        size="small"
                        value={formik.values.stateId}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            'stateId',
                            newValue ? newValue : null
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === (value ? value.id : null)
                        }
                        getOptionLabel={getOptionLabel}
                        options={
                          isArrayNotEmpty(stateData.state)
                            ? stateData.state.map(c => {
                                return { label: c.stateName, id: c.stateId };
                              })
                            : []
                        }
                        sx={{ width: '100%' }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span
                                    style={{
                                      padding: '0.2rem 0px 5px 5px',
                                      display: 'inline-flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <SearchMdIcon
                                      color={colorTextGrey}
                                      height="16px"
                                      width="16px"
                                    />
                                  </span>
                                </InputAdornment>
                              )
                            }}
                            placeholder="Select state"
                            error={
                              formik.touched.stateId && formik.errors.stateId
                            }
                            helperText={
                              formik.touched.stateId && formik.errors.stateId
                            }
                          />
                        )}
                        clearIcon={false}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} sx={{ width: '280px' }}>
                    <InputLabel
                      required
                      sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    >
                      District
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        size="small"
                        value={formik.values.districtId}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            'districtId',
                            newValue ? newValue : null
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === (value ? value.id : null)
                        }
                        getOptionLabel={getOptionLabel}
                        options={
                          isArrayNotEmpty(districtData.district)
                            ? districtData.district
                                .filter(
                                  cc => cc.stateId === formik.values.stateId?.id
                                )
                                .map(c => {
                                  return {
                                    label: c.districtName,
                                    id: c.districtId
                                  };
                                })
                            : []
                        }
                        sx={{ width: '100%' }}
                        clearIcon={false}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              placeholder="Select district"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <span
                                      style={{
                                        padding: '0.2rem 0px 5px 5px',
                                        display: 'inline-flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <SearchMdIcon
                                        color={colorTextGrey}
                                        height="16px"
                                        width="16px"
                                      />
                                    </span>
                                  </InputAdornment>
                                )
                              }}
                              error={
                                formik.touched.districtId &&
                                formik.errors.districtId
                              }
                              helperText={
                                formik.touched.districtId &&
                                formik.errors.districtId
                              }
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', gap: 2 }}>
                  <Grid item sm={12} sx={{ width: '280px' }}>
                    <InputLabel
                      required
                      sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    >
                      Area
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        size="small"
                        value={formik.values.pinId}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            'pinId',
                            newValue ? newValue : null
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === (value ? value.id : null)
                        }
                        getOptionLabel={getOptionLabel}
                        options={
                          isArrayNotEmpty(areaData.area)
                            ? areaData.area
                                .filter(
                                  cc =>
                                    cc.stateId === formik.values.stateId?.id &&
                                    cc.districtId ===
                                      formik.values.districtId?.id
                                )
                                .map(c => {
                                  return {
                                    label: c.area,
                                    id: c.pinId
                                  };
                                })
                            : []
                        }
                        sx={{ width: '100%' }}
                        clearIcon={false}
                        renderInput={params => (
                          <TextField
                            key={params.id}
                            {...params}
                            placeholder="Select area"
                            error={formik.touched.pinId && formik.errors.pinId}
                            helperText={
                              formik.touched.pinId && formik.errors.pinId
                            }
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span
                                    style={{
                                      padding: '0.2rem 0px 5px 5px',
                                      display: 'inline-flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <SearchMdIcon
                                      color={colorTextGrey}
                                      height="16px"
                                      width="16px"
                                    />
                                  </span>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={12} sx={{ width: '280px' }}>
                    <InputLabel
                      required
                      sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    >
                      School Name
                    </InputLabel>
                    <TextField
                      placeholder="Enter school name"
                      name="schoolName"
                      fullWidth
                      size="small"
                      inputProps={{ maxLength: 64 }}
                      value={formik.values.schoolName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.schoolName &&
                        Boolean(formik.errors.schoolName)
                      }
                      InputLabelProps={{
                        shrink: formik.values.schoolName !== ''
                      }}
                      helperText={
                        formik.touched.schoolName && formik.errors.schoolName
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Grid
            bgcolor={'white'}
            padding={2}
            borderRadius={2}
            marginTop={2}
            display={'flex'}
            gap={2}
            sx={{ width: '100%' }}
            // sx={{
            //   width: {
            //     lg: '60%',
            //     md: '100%'
            //   }
            // }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/schools');
              }}
              // style={{ marginRight: 24 }}
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              onClick={() => formik.handleSubmit()}
              disabled={isSaveDisabled}
              variant="contained"
              sx={{
                width: '94px',
                height: '42px',
                boxShadow: 'none'
              }}
            >
              Save
            </LoadingButton>
          </Grid>
        </>
      )}
      {isStateFailed && (
        <SnackBarBox
          type={'error'}
          message={`Failed to fetch data for:- State Master `}
        />
      )}
      {isDistrictFailed && (
        <SnackBarBox
          type={'error'}
          message={`Failed to fetch data for:- District Master `}
        />
      )}

      {isAreaFailed && (
        <SnackBarBox
          type={'error'}
          message={`Failed to fetch data for:- Area Master `}
        />
      )}
      {!isCreateUpdateSchoolLoading && isCreateUpdateSchoolSuccess && (
        <SnackBarBox
          type="success"
          message={
            isCreateUpdateSchoolResponse || 'Action performed successfully'
          }
        />
      )}
      {!isCreateUpdateSchoolLoading && isCreateUpdateSchoolFailed && (
        <SnackBarBox
          type="error"
          message={handleError(createUpdateSchoolErrorContainer?.errorMessage)}
        />
      )}
      {!isSchoolDetailsLoading && isSchoolDetailsFailed && (
        <SnackBarBox type="error" message={'Failed to fetch data of school'} />
      )}
    </Box>
  );
};

export default AddEditSchool;
