import React, { useState } from 'react';
import { East, ErrorOutline } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Fade,
  Grid,
  Rating,
  SvgIcon,
  Typography
} from '@mui/material';
import { colorLinkGrey, colorMidGray } from '../../../config/theme';
import moment from 'moment';
import CustomDataTable from '../../../components/CustomDataTable';
import { ChevronIcon } from '../../../utils/iconSvg';
import axios from 'axios';
import {
  getQuestionRatingByIndicatorId,
  revertQuestionList
} from '../../../redux/reducers/flowsSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { isArrayNotEmpty } from '../../../utils/utils';

const RubricWiseResponseCard = ({
  title = '',
  reponse = null,
  count = 0,
  rating = 0,
  flowId = null,
  formId = null
}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const [response, setResponse] = useState([]);
  const { questionListByIndicator } = useSelector(state => state.flow);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalEvents: 0
  });

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    let userResponseCount = isArrayNotEmpty(questionListByIndicator)
      ? questionListByIndicator[0]?.userWiseResponses.length || 0
      : [];
    setPagination({
      ...pagination,
      page: 0,
      totalEvents: userResponseCount || 0
    });
  };

  React.useEffect(() => {
    if (reponse && reponse.indicatorId) {
      dispatch(
        getQuestionRatingByIndicatorId({
          indicatorId: reponse.indicatorId,
          flowId: flowId,
          formId: formId
        })
      );
    }

    return () => dispatch(revertQuestionList());
  }, [reponse]);

  const columns = [
    { uinqueKey: 'userId', label: 'Response', id: 'responseValues' },
    { label: 'Response By', id: 'name' },
    {
      label: 'Response Date',
      id: 'responseDate',
      render: val => moment(val.responseDate).format('DD-MM-YYYY')
    },
    {
      label: 'Average rating',
      render: row => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {row.userRating && (
              <>
                <Typography
                  sx={{ flex: 1, textAlign: 'center' }}
                  variant="body2"
                  fontWeight={'600'}
                >
                  {row.userRating
                    ? parseFloat(row.userRating).toFixed(1)
                    : '0.0'}
                </Typography>
                <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
                <Box sx={{ flex: 2 }}>
                  <Rating
                    size="small"
                    precision={0.5}
                    readOnly
                    value={row.userRating}
                  />
                </Box>
              </>
            )}
          </Box>
        );
      }
    }
  ];

  return (
    <Grid>
      <Box
        sx={{ paddingY: 1, display: 'flex', justifyContent: 'space-between' }}
      >
        <Box>
          <Typography variant="h6" fontWeight="bold">
            {title}
          </Typography>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            color={colorLinkGrey}
            display={'flex'}
            mt={1}
          >
            <SvgIcon sx={{ mr: 0.5, height: 16, width: 16 }}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_5511_26833)">
                  <path
                    d="M8.00004 5.33398V8.00065M8.00004 10.6673H8.00671M14.6667 8.00065C14.6667 11.6826 11.6819 14.6673 8.00004 14.6673C4.31814 14.6673 1.33337 11.6826 1.33337 8.00065C1.33337 4.31875 4.31814 1.33398 8.00004 1.33398C11.6819 1.33398 14.6667 4.31875 14.6667 8.00065Z"
                    stroke="#4E565F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5511_26833">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>
            Responses cannot be edited
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colorMidGray}>
            Average rating
          </Typography>{' '}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Typography
              variant="caption"
              fontWeight={'600'}
              display={'flex'}
              mt={0.5}
            >
              {rating ? parseFloat(rating).toFixed(1) : '0.0'}
            </Typography>
            <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
            <Box sx={{ display: 'flex' }}>
              <Rating size="small" precision={0.5} readOnly value={rating} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider orientation="horizontal" flexItem />
      <Typography variant="h3" fontSize={'20px'} fontWeight={'700'} mt={2}>
        Responses
      </Typography>
      <Box
        sx={{
          //   width: '60%',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 255px)',
          '&::-webkit-scrollbar': {
            width: '0.2em'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.3)'
          }
        }}
      >
        {isArrayNotEmpty(questionListByIndicator)
          ? [...questionListByIndicator].map((item, key) => {
              return (
                <Box key={key} borderRadius={5} marginBottom={1}>
                  <Accordion
                    expanded={expanded === key}
                    onChange={handleChange(key)}
                    slots={{ transition: Fade }}
                    slotProps={{ transition: { timeout: 400 } }}
                    elevation={0}
                    sx={{
                      '& .MuiAccordion-region': {
                        height: expanded === key ? 'auto' : 0
                      },
                      '& .MuiAccordionDetails-root': {
                        display: expanded === key ? 'block' : 'none'
                      }
                    }}
                  >
                    <AccordionSummary id={key} expandIcon={<ChevronIcon />}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          alignItems: 'center',
                          marginRight: 2
                        }}
                      >
                        <Typography>
                          <span
                            style={{
                              padding: '0.5rem 0.5rem 0.5rem 0.0rem',
                              display: 'inline-flex',
                              alignItems: 'center'
                            }}
                          >
                            {key + 1}{' '}
                            <East
                              fontSize="small"
                              sx={{
                                marginLeft: '4px'
                              }}
                            />
                          </span>{' '}
                          {item?.questionText || ''}
                        </Typography>
                        <Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <Typography
                              variant="caption"
                              fontWeight={'600'}
                              display={'flex'}
                              mt={0.5}
                            >
                              {item.questionAvgRating
                                ? parseFloat(item.questionAvgRating).toFixed(1)
                                : '0'}
                            </Typography>
                            <Divider
                              sx={{ mx: 1 }}
                              orientation="vertical"
                              flexItem
                            />
                            <Box sx={{ display: 'flex' }}>
                              <Rating
                                size="small"
                                precision={0.5}
                                readOnly
                                value={item.questionAvgRating}
                              />
                            </Box>
                          </Box>
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {isArrayNotEmpty(item?.userWiseResponses) && (
                        <CustomDataTable
                          columns={[...columns]}
                          data={[...item.userWiseResponses].slice(
                            pagination.page * pagination.rowsPerPage,
                            (pagination.page + 1) * pagination.rowsPerPage
                          )}
                          onPageChange={val => {
                            setPagination({ ...pagination, page: val });
                          }}
                          onRowsPerPageChange={val => {
                            setPagination({ ...pagination, rowsPerPage: val });
                          }}
                          defaultOrderBy={'responseDate'}
                          defaultPage={pagination.page}
                          defaultRowsPerPage={pagination.rowsPerPage}
                          totalCount={pagination.totalEvents}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <Divider sx={{ mx: 1 }} orientation="horizontal" flexItem />
                </Box>
              );
            })
          : 'No Responses Found'}
      </Box>
    </Grid>
  );
};

export default RubricWiseResponseCard;
