import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Fade,
  Grid,
  Typography
} from '@mui/material';
import { IMG_SPLASH_LOGO } from '../utils/imageUrls';
import {
  AddIcon,
  ArrowLeft,
  ArrowLeftIcon,
  MinusIcon,
  PlusIcon
} from '../utils/iconSvg';
import { CustomTextLink } from '../containers/login/LoginForm';
import { useState } from 'react';
import {
  colorBorderGrey,
  colorLinkGrey,
  colorPrimary,
  colorPureWhite,
  colorSecondary,
  colorTextBlack
} from '../config/theme';
import { Link, useNavigate } from 'react-router-dom';

const FAQ = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleredirect = () => {
    window.location.href = 'mailto:contactus@pehlayakshar.org';
  };

  const FAQData = [
    {
      id: 1,
      question:
        'How do I log in for the first time or re-login when logged out?',
      answer: (
        <Grid>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 1:
            </Typography>
            &nbsp; Enter your username in the placeholder/textbox that says
            ‘Enter your username’.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 2:
            </Typography>
            &nbsp; Enter your password in the placeholder/textbox that says
            ‘Enter your password’.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 3:
            </Typography>
            &nbsp; Click <b>Log In</b> to continue.
          </Typography>
        </Grid>
      )
    },
    {
      id: 2,
      question: 'What to do when you forget your password?',
      answer: (
        <Grid>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 1:
            </Typography>
            &nbsp; Click <b>Forgot Password</b> on the login page.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 2:
            </Typography>
            &nbsp; You will be redirected to the Password Recovery page.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 3:
            </Typography>
            &nbsp; Enter username in the placeholder/textbox that says ‘Enter
            your Username’.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 4:
            </Typography>
            &nbsp; Press <b>Continue</b>.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 5:
            </Typography>
            &nbsp; You will receive a reset password link on your registered
            email id. Click the link and it will redirect to the{' '}
            <b>Reset Password</b>
            &nbsp;page.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 6:
            </Typography>
            &nbsp; Enter your new password in the Password placeholder/textbox
            that says ‘Enter your Password’.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 7:
            </Typography>
            &nbsp; Re-type the password in the <b>Confirm Password</b>
            &nbsp;placeholder/textbox and ensure that both the passwords are the
            same.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 8:
            </Typography>
            &nbsp; Press <b>Continue</b>.
          </Typography>
        </Grid>
      )
    },
    {
      id: 3,
      question:
        'Why does the web browser show a white screen or fail to load the page?',
      answer: (
        <Grid>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            A white screen or loading issue appears due to slow Internet speed
            or no Internet.
          </Typography>

          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Note :
            </Typography>
            &nbsp;Try refreshing the page with the keyboard buttons{' '}
            <b>Ctrl + Shift + R</b>.
          </Typography>
        </Grid>
      )
    },
    {
      id: 4,
      question: 'How to change your password on the Dashboard page?',
      answer: (
        <Grid>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 1:
            </Typography>
            &nbsp; Click the <b>Profile</b> drop-down arrow.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 2:
            </Typography>
            &nbsp; Click the <b>Change password</b> option.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 3:
            </Typography>
            &nbsp; Enter and confirm your new password.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 4:
            </Typography>
            &nbsp; Click <b>Continue</b>.
          </Typography>
        </Grid>
      )
    },
    {
      id: 5,
      question: 'What’s on the Dashboard screen?',
      answer: (
        <Grid>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            The Dashboard has two sections i.e. the left navigation menu and the
            main section.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            The left navigation menu has the following options:
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            ● &nbsp; User Management with sub-menus i.e. Users, Users Groups,
            Roles & App Access.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            ● &nbsp; Location Management with sub menus i.e State, District,
            Area & Schools.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            ● &nbsp; Teacher Evaluation with sub menus i.e Evaluation Flows &
            Evaluation Rubrics.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            ● &nbsp; Analytics with sub-menus i.e. Usage Analytics.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            ● &nbsp; Product Analytics with sub-menus i.e. Classroom Resources,
            Learning Management System, Teacher Evaluation and Forms.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            ● &nbsp; Calendar with sub-menus i.e. Create New Events & Share
            Calendar.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            ● &nbsp; Icon to download the Pehlay Akshar App.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            The main section will have the sections from the left navigation
            menu.
          </Typography>
        </Grid>
      )
    },
    {
      id: 6,
      question: 'How to download the PehlayAkshar App?',
      answer: (
        <Grid>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 1:
            </Typography>
            &nbsp; Navigate to the left menu bar at the bottom you will find the
            logo to download thePehlay Akshar App.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 2:
            </Typography>
            &nbsp; Click the logo to download the Pehaly Akshar App from Play
            Store for Android users and App Store for iOS users.
          </Typography>
        </Grid>
      )
    },
    {
      id: 7,
      question: 'How to upload a photo in the My Account section after login?',
      answer: (
        <Grid>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 1:
            </Typography>
            &nbsp; Click the profile drop-down arrow on the right corner of the
            &nbsp;<b>Dashboard</b> screen.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 2:
            </Typography>
            &nbsp; Click the <b>My Account</b> option. You will see an upload
            button on the <b>My Account</b> screen.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 3:
            </Typography>
            &nbsp; Click the <b>Upload</b> button and choose a picture of your
            choice from the computer.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 4:
            </Typography>
            &nbsp;A dialog box screen for choosing a picture will appear. Choose
            a picture and click the Open button.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 5:
            </Typography>
            &nbsp; Crop the selected picture and click the <b>Upload</b> button.
          </Typography>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            <Typography
              color={colorTextBlack}
              fontWeight={'700'}
              variant="span"
            >
              Step 6:
            </Typography>
            &nbsp; Click the <b>Save</b> button to set your profile picture in
            your login.
          </Typography>
        </Grid>
      )
    },
    {
      id: 8,
      question: 'How to Logout from your account?',
      answer: (
        <Grid>
          <Typography fontSize={'14px'} color={colorLinkGrey}>
            Click the profile drop-down arrow and click <b>Logout</b>.
          </Typography>
        </Grid>
      )
    }
  ];

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid
      sx={{
        p: 2,
        height: '100vh',

        display: 'flex',
        justifyContent: 'space-between',
        gap: 10,
        backgroundColor: colorPureWhite
      }}
    >
      <Grid sx={{ width: '70vw' }}>
        <Grid>
          <img
            src={IMG_SPLASH_LOGO}
            alt={IMG_SPLASH_LOGO}
            style={{ width: '80px', height: '75.88px' }}
          />
        </Grid>
        <Grid sx={{ p: 3 }}>
          <Grid sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Box>
              <Link
                to="/login"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <ArrowLeftIcon
                  color={isHovered ? colorPrimary : colorTextBlack}
                />
              </Link>
            </Box>

            <Box>
              <Typography fontSize={'32px'} fontWeight={'700'}>
                Frequently Asked Questions
              </Typography>
            </Box>
          </Grid>

          <Box
            mt={3}
            mb={1}
            sx={{
              // width: '100%',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 255px)',
              '&::-webkit-scrollbar': {
                width: '4px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#E4E7EB',
                border: '4px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: colorLinkGrey,
                borderRadius: '7px'
              }
            }}
          >
            {FAQData.map((item, key) => {
              return (
                <Box key={key} mb={2} mr={2}>
                  <Accordion
                    expanded={expanded === key}
                    onChange={handleChange(key)}
                    slots={{ transition: Fade }}
                    slotProps={{ transition: { timeout: 100 } }}
                    elevation={0}
                    sx={{
                      '& .MuiAccordion-region': {
                        height: expanded === key ? 'auto' : 0
                      },
                      // '& .MuiAccordionDetails-root': {
                      //   display: expanded === key ? 'block' : 'none'
                      // },
                      // padding: '12px 16px',
                      border:
                        expanded === key
                          ? `1px solid ${colorPrimary}`
                          : `1px solid ${colorBorderGrey}`
                    }}
                    style={{ borderRadius: '10px' }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === key ? <MinusIcon /> : <PlusIcon />
                      }
                      id={key}
                      sx={{
                        backgroundColor:
                          expanded === key ? colorSecondary : null
                      }}
                      style={{ borderRadius: '10px 10px 0 0' }}
                    >
                      <Typography fontSize={'16px'} fontWeight={'700'}>
                        {item.question || ''}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        color={colorLinkGrey}
                        fontSize={'14px'}
                        sx={{
                          // width: '100%',
                          overflowY: 'auto',
                          maxHeight: '198px',
                          '&::-webkit-scrollbar': {
                            width: '4px'
                          },
                          '&::-webkit-scrollbar-track': {
                            background: '#E4E7EB',
                            border: '4px'
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: colorLinkGrey,
                            borderRadius: '7px'
                          }
                        }}
                        // style={{ borderRadius:"5px"}}
                      >
                        {item.answer || ''}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
          </Box>

          <Divider />
          <Grid display={'flex'} mt={2}>
            <Typography
              fontSize={'16px'}
              fontWeight={'600'}
              color={colorLinkGrey}
              mr={1}
            >
              For any other questions regarding the web login please write send
              an email at
            </Typography>
            <CustomTextLink
              title="contactus@pehlayakshar.org"
              fontWeight="700"
              fontSize={'14px'}
              onClick={handleredirect}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          backgroundColor: colorSecondary,
          width: '20vw',
          borderRadius: '5px'
        }}
      ></Grid>
    </Grid>
  );
};

export default FAQ;
