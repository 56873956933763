import { useContext, useEffect, useRef, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
  Avatar,
  FormHelperText,
  Skeleton,
  SvgIcon,
  Divider,
  IconButton,
  Breadcrumbs,
  Link
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPageData,
  getRoleDataById,
  getRoles,
  revertRoleFormData,
  revertRolePageData
} from '../../redux/reducers/roleSlice';
import {
  createUser,
  getUserDetails,
  profilePicDelete,
  profilePicUpload,
  revertAllUsersData,
  updateUserDetails
} from '../../redux/reducers/usersSlice';
import {
  getArea,
  getDistrict,
  getModules,
  getSchools,
  getState
} from '../../redux/reducers/mastersSlice';
import {
  ageValidation,
  enterOnlyNumbers,
  generateImageSrc,
  handleError,
  isArrayNotEmpty,
  isImageExtensionAllowed,
  isValidEmail,
  mapArrayToTypography
} from '../../utils/utils';
import {
  ADMIN_ROLE_TYPE,
  ROLE_LEVEL,
  SELECT_IMAGE_MESSAGE,
  imageAllowedTypes,
  userTypesList
} from '../../utils/constant';
import SnackBarBox from '../../components/SnackBarBox';
import ImageCropper from '../../components/ImageCropper';
import { Add, Delete, Edit, Upload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { resetPassword } from '../../redux/reducers/authSlice';
import CustomBreadcrumb from '../../components/Breadcrumb';
import _ from 'lodash';
import { CaseContext } from '../../App';
import { getBasicDetails } from '../../utils/service';
import {
  Calendar01Icon,
  ChevronRightIcon,
  CircleCheckIcon,
  DeleteIcon,
  EditIcon,
  UploadIcon
} from '../../utils/iconSvg';
import { BootstrapDialog } from '../../utils/styledLayout';
import GenericTextField from '../../components/GenericTextField';
import { CalendarIcon } from '@mui/x-date-pickers';
import { CustomTextLink } from '../login/LoginForm';

const AddUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Formik validation schema
  const fileInputRef = useRef(null);
  const formControl = {
    paddingTop: '10px'
  };
  const [loading, setLoading] = useState(true);
  const [uploadedImage, setUploadedImage] = useState('');
  const [tempUploadImage, setTempUploadImage] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });
  const [showUserCreationModal, setShowUserCreationModal] = useState(false);
  const { areaData, stateData, districtData, modules, schoolsData } =
    useSelector(state => state.masters);

  const { isRolesFailed } = useSelector(state => state.roles);
  const { isStateFailed, isDistrictFailed, isAreaFailed, isSchoolsFailed } =
    useSelector(state => state.masters);
  const {
    userDetails,
    isUploadProfilePicLoading,
    isUploadProfilePicFailed,
    uploadProfilePicErrorContainer,
    isDeleteProfilePicLoading,
    isDeleteProfilePicFailed,
    DeleteProfilePicErrorContainer,
    isCreateUserSuccess,
    isCreateUserLoading,
    isUpadteUserDataSuccess,
    isUpadteUserDataLoading,
    isCreateUserFailed,
    isUpadteUserDataFailed,
    UpadteUserDataErrorContainer,
    isResetPasswordLoading,
    isResetPasswordSuccess,
    isResetPasswordFailed,
    resetPasswordErrorContainer,
    createUserErrorContainer,
    isGetUserSuccess
  } = useSelector(state => state.users);
  const { authData } = useSelector(state => state.auth);
  const { rolesData, pageData, roleByIdData, isRoleByIdSuccess } = useSelector(
    state => state.roles
  );
  const { setBasicDetailsContext, basicDetailsContext } =
    useContext(CaseContext);
  const [showGrade, setShowGrade] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    let mastersPayload = {
      type: 'all',
      page: 0,
      size: 0,
      search: ''
    };

    dispatch(getArea(mastersPayload));
    dispatch(getState(mastersPayload));
    dispatch(getDistrict(mastersPayload));
    dispatch(getRoles(mastersPayload));
    dispatch(getModules());
    dispatch(getPageData());
    dispatch(getSchools(mastersPayload));

    if (location.pathname === '/users/edit-user' && location.state) {
      dispatch(getUserDetails(location.state?.userId));
    }

    return () => {
      dispatch(revertAllUsersData());
      dispatch(revertRoleFormData());
    };
  }, []);

  // Formik form control
  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNo: '',
      email: '',
      role: '',
      state: '',
      district: '',
      area: '',
      school: '',
      dob: null,
      userType: '',
      grade: '',
      moduleId: [],
      profilePic: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
      phoneNo: yup
        .string()
        .matches(/^\d{10}$/, 'Phone number must be a 10-digit number')
        .required('Phone number is required'),
      email: yup
        .string()
        .test(
          'is-valid-email',
          'Please provide a valid email address.',
          value => isValidEmail(value)
        )
        .required('Email is required'),
      role: yup.string().required('Role is required'),
      state: yup.string().required('State is required'),
      district: yup.string().required('District is required'),
      area: yup.string().required('Area is required'),
      school: yup.string().required('School is required'),
      dob: yup
        .date()
        .max(
          ageValidation({ year: 4, month: 0 }),
          'Minimum age required is 4.0 years'
        )
        .required('Date of birth is required'),
      grade: yup.string().nullable(),
      userType: yup.string().nullable(),
      moduleId: yup.array().nullable()
      // profilePic: yup.object().nullable()
    }),
    onSubmit: values => {
      setLoading(true);

      let submitPayload = {
        name: values.name,
        contactNumber: values.phoneNo,
        email: values.email,
        dateOfBirth: dayjs(values.dob).format('YYYY-MM-DD'),
        grade: values.grade,
        area: values.area,
        userType: values.userType,
        citizenship: 'Indian',
        status: true,
        roleId: values.role,
        stateId: values.state,
        districtId: values.district,
        // moduleId: 46,
        schoolId: values.school,
        isAdmin: values.userType === 'PAF' ? true : false,
        loggedInUserName: authData?.userName
      };
      // Handle form submission

      if (userDetails?.userId) {
        // if (formik.values.profilePic) {
        //   const formData = new FormData();
        //   formData.append('profilePhoto', values.profilePic);
        //   formData.append('loggedInUserName', authData?.userName);
        //   dispatch(
        //     profilePicUpload({
        //       data: formData,
        //       userId: userDetails?.userId
        //     })
        //   );
        // } else if (formik.values.profilePic === null) {
        //   dispatch(
        //     profilePicDelete({
        //       userId: userDetails?.userId,
        //       loggedinUserId: authData?.userName
        //     })
        //   );
        // }

        dispatch(
          updateUserDetails({
            updatePayload: submitPayload,
            userId: userDetails?.userId,
            profilePic: formik.values.profilePic,
            isMyAccount: false
          })
        );
      } else {
        const createFormData = new FormData();
        createFormData.append(
          'profilePic',
          formik.values.profilePic ? formik.values.profilePic : null
        );
        createFormData.append('user', JSON.stringify(submitPayload));
        dispatch(createUser(createFormData));
      }
    }
  });

  const areAllRequiredFieldsFilled = () => {
    return (
      formik.values.name &&
      formik.values.phoneNo &&
      formik.values.email &&
      formik.values.role &&
      formik.values.state &&
      formik.values.district &&
      formik.values.area &&
      formik.values.school &&
      formik.values.dob
    );
  };

  const isSaveDisabled = !formik.isValid || !areAllRequiredFieldsFilled();

  useEffect(() => {
    setTimeout(() => {
      if (userDetails) {
        formik.setValues({
          name: userDetails?.name || '',
          phoneNo: userDetails?.contactNumber || '',
          email: userDetails?.email || '',
          role: userDetails?.roleId || null,
          state: userDetails?.stateId || null,
          district: userDetails?.districtId || null,
          area: userDetails?.areaId || null,
          school: userDetails?.schoolId || null,
          dob: userDetails?.dateOfBirth,
          grade: userDetails?.grade,
          // moduleId: userDetails?.moduleId || null,
          username: userDetails?.userName,
          userType: userDetails?.userType || null
        });
        dispatch(getRoleDataById(userDetails?.roleId));
        if (userDetails?.grade) {
          setShowGrade(true);
        }
        if (userDetails?.userImageUrl) {
          setUploadedImage(userDetails?.userImageUrl);
        }
        // else if (
        //   userDetails?.userImage &&
        //   !String(userDetails?.userImage).startsWith('/image')
        // ) {
        //   setUploadedImage(userDetails?.userImage);
        // }

        setLoading(false);
      } else {
        setLoading(false);
      }
    }, 1200);
  }, [isGetUserSuccess]);

  async function fetchBasicResponse() {
    const getBasicDetailsResponse = await getBasicDetails(authData?.userId);
    setBasicDetailsContext({
      userImage: getBasicDetailsResponse?.userImage,
      name: getBasicDetailsResponse?.name
    });
  }

  useEffect(() => {
    if (isUpadteUserDataSuccess || isCreateUserSuccess) {
      setTimeout(() => {
        if (userDetails?.userId == authData?.userId) {
          fetchBasicResponse();
        }
        dispatch(revertAllUsersData());
        navigate('/users');
      }, 2000);
    }
    if (!isCreateUserLoading && isCreateUserSuccess) {
      setShowUserCreationModal(true);
    }

    if (isUpadteUserDataFailed || isCreateUserFailed) {
      setLoading(false);
    }
  }, [
    isUpadteUserDataSuccess,
    isCreateUserSuccess,
    isUpadteUserDataFailed,
    isCreateUserFailed
  ]);

  useEffect(() => {
    if (isRoleByIdSuccess && roleByIdData) {
      let tempPageData = [...pageData];
      let accessbileData =
        (isArrayNotEmpty(tempPageData) &&
          tempPageData
            .filter(cc => _.includes(roleByIdData.pageIds, cc.pageId))
            .map(cc => cc.pageName)) ||
        [];
      // console.log(accessbileData, 'accessbileData');
      formik.setFieldValue('moduleId', accessbileData);
    }
  }, [isRoleByIdSuccess]);

  const handleRemoveImage = () => {
    // if (userDetails?.userId) {
    //   dispatch(
    //     profilePicDelete({
    //       userId: userDetails?.userId,
    //       loggedinUserId: authData?.userName
    //     })
    //   );
    // }
    if (userDetails?.userId == authData?.userId) {
      setBasicDetailsContext({
        userImage: null,
        name: basicDetailsContext?.name
      });
    }
    // dispatch(
    //   profilePicDelete({
    //     userId: userDetails?.userId,
    //     loggedinUserId: authData?.userName
    //   })
    // );
    handleDeleteImage();
    setTempUploadImage(null);
    setUploadedImage(null);

    formik.setFieldValue('profilePic', null);
  };

  // const handleReset = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     if (userDetails) {
  //       formik.setValues({
  //         name: userDetails?.name || '',
  //         phoneNo: userDetails?.contactNumber || '',
  //         email: userDetails?.email || '',
  //         role: userDetails?.roleId || null,
  //         state: userDetails?.stateId || null,
  //         district: userDetails?.districtId || null,
  //         area: userDetails?.areaId || null,
  //         school: userDetails?.schoolId || null,
  //         dob: userDetails?.dateOfBirth,
  //         grade: userDetails?.grade,
  //         moduleId: userDetails?.moduleId || null,
  //         username: userDetails?.userName,
  //         userType: userDetails?.userType || null
  //       });

  //       if (!String(userDetails?.userImage).startsWith('/image')) {
  //         setUploadedImage(userDetails?.userImage);
  //       }
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   }, 1200);
  // };

  const handleDeleteImage = () => {
    setTempUploadImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileUpload = file => {
    setUploadedImage(null);
    formik.setFieldValue('profilePic', file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      setUploadedImage(
        e.target.result?.replace(/^data:image\/jpeg;base64,/, '')
      );
    };

    // if (userDetails?.userId) {
    //   const formData = new FormData();
    //   formData.append('profilePhoto', file);
    //   formData.append('loggedInUserName', authData?.userName);
    //   dispatch(
    //     profilePicUpload({
    //       data: formData,
    //       userId: userDetails?.userId
    //     })
    //   );
    // }

    setTempUploadImage(null);
  };

  const handleImageUpload = event => {
    fileInputRef.current.click();
    const file = event.target.files[0];
    if (file) {
      fileInputRef.current.value = '';
      const allowedTypes = imageAllowedTypes;
      const maxSizeMB = 1;
      const fileName = file ? file?.name : 'NA';

      if (allowedTypes.includes(file.type)) {
        if (isImageExtensionAllowed(fileName)) {
          if (file.size / 1024 / 1024 <= maxSizeMB) {
            setUploadedFileName(fileName);
            const reader = new FileReader();
            reader.onload = e => {
              setTempUploadImage(e.target.result);
            };

            reader.readAsDataURL(file);
          } else {
            setImageUploadError({
              error: true,
              errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
            });
          }
        } else {
          setImageUploadError({
            error: true,
            errorMessage: SELECT_IMAGE_MESSAGE
          });
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: SELECT_IMAGE_MESSAGE
        });
      }
    }
  };

  return (
    <Box>
      {/* {location.pathname === '/users/edit-user' && (
        <Box display={'flex'}>
          <a style={{ cursor: 'pointer' }} onClick={() => {}}>
            <Typography
              sx={{ display: 'flex', alignItems: 'center' }}
              color={'#4E565F'}
              fontWeight={'700'}
              fontSize={'24px'}
            >
              Users
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={'#ABB4BD'} />
              </SvgIcon>
            </Typography>
          </a>

          <Typography
            sx={{ display: 'flex', alignItems: 'center' }}
            fontWeight={'&00'}
            fontSize={'32px'}
          >
            Edit User
          </Typography>
        </Box>
      )}
      {location.pathname !== '/users/edit-user' && (
        <Box display={'flex'}>
          <a style={{ cursor: 'pointer' }} onClick={() => {}}>
            <Typography
              sx={{ display: 'flex', alignItems: 'center' }}
              color={'#4E565F'}
              fontWeight={'700'}
              fontSize={'24px'}
            >
              Users
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={'#ABB4BD'} />
              </SvgIcon>
            </Typography>
          </a>
          <Typography
            sx={{ display: 'flex', alignItems: 'center' }}
            fontWeight={'&00'}
            fontSize={'32px'}
          >
            New User
          </Typography>
        </Box>
      )} */}
      <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <SvgIcon sx={{ height: 24, width: 24 }}>
              <ChevronRightIcon color={colorTextGrey} />
            </SvgIcon>
          }
          sx={{ marginBottom: 2 }}
        >
          <Link
            color="inherit"
            href={'school'}
            onClick={event => {
              event.preventDefault();
              navigate('/users');
            }}
            sx={{ textDecoration: 'none' }}
          >
            <Typography
              fontWeight={'700'}
              color={colorLinkGrey}
              fontSize={'24px'}
            >
              Users
            </Typography>
          </Link>
          <Typography
            sx={{ fontSize: '32px', fontWeight: '700', color: colorTextBlack }}
          >
            {`${location.pathname === '/users/edit-user' ? 'Edit' : 'New'} User`}
          </Typography>
        </Breadcrumbs>
      </Typography>

      {/* <CustomBreadcrumb path={`${location.pathname}`} /> */}
      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}

      {!loading && (
        <>
          <Box bgcolor={'white'} padding={2} borderRadius={2}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid container spacing={3} marginBottom={1.5}>
                <Grid item sm={12} lg={4} sx={formControl} alignSelf={'center'}>
                  <InputLabel
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    required
                  >
                    Select user role
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      value={formik.values.role}
                      disabled={authData.roleType !== ADMIN_ROLE_TYPE}
                      onChange={e => {
                        let trolesData = rolesData.roles.find(
                          cc => cc.roleId === e.target.value
                        );

                        setShowGrade(trolesData?.parentRole === 'Student');
                        dispatch(getRoleDataById(trolesData.roleId));
                        formik.handleChange(e);
                        formik.setFieldValue(
                          'userType',
                          _.includes(ROLE_LEVEL, trolesData?.roleLevel)
                            ? 'PAF'
                            : 'Registered'
                        );
                        formik.setFieldValue('grade', '');
                      }}
                      name="role"
                    >
                      {isArrayNotEmpty(rolesData.roles) &&
                        rolesData.roles.map((row, key) => {
                          return (
                            <MenuItem
                              key={key}
                              value={row?.roleId}
                              sx={{
                                ':hover': {
                                  backgroundColor: colorSecondary
                                }
                              }}
                            >
                              {row.roleName}
                            </MenuItem>
                          );
                        })}
                      {!isArrayNotEmpty(rolesData.roles) && (
                        <MenuItem
                          key={-99}
                          value={null}
                          sx={{
                            ':hover': {
                              backgroundColor: colorSecondary
                            }
                          }}
                        >
                          None
                        </MenuItem>
                      )}
                    </Select>
                    {formik.touched.role && formik.errors.role && (
                      <FormHelperText>{formik.errors.role}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Divider orientation="horizontal" flexItem sx={{ my: 1.5 }} />

              {
                <Typography
                  fontWeight={'700'}
                  fontSize={'20px'}
                  lineHeight={'26px'}
                  sx={{ my: 1.5 }}
                >
                  Add user details
                </Typography>
              }
              <Grid container spacing={3}>
                <Grid item sm={12} lg={4}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Box sx={{ marginRight: 2 }}>
                      {tempUploadImage && (
                        <ImageCropper
                          key="profilePicture"
                          uploadedFileName={uploadedFileName}
                          incomingFile={tempUploadImage}
                          handleFileUpload={handleFileUpload}
                          handleFileCancel={handleDeleteImage}
                        />
                      )}

                      {isUploadProfilePicLoading ||
                      isDeleteProfilePicLoading ? (
                        <>
                          <Skeleton
                            variant="circular"
                            width={80}
                            height={80}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '50%',
                              backgroundColor: '#c9c9c9',
                              color: 'white',
                              fontSize: '22px',
                              margin: 'auto'
                            }}
                          />
                        </>
                      ) : uploadedImage ? (
                        <Avatar
                          src={
                            !/^[A-Za-z0-9+/=]+$/.test(uploadedImage)
                              ? uploadedImage
                              : generateImageSrc(uploadedImage)
                          }
                          alt={'profile pic'}
                          sx={{ width: 80, height: 80, margin: 'auto' }}
                        ></Avatar>
                      ) : (
                        <Avatar
                          sx={{
                            width: 80,
                            height: 80,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%',
                            backgroundColor: '#c9c9c9',
                            color: 'white',
                            fontSize: '22px',
                            margin: 'auto'
                          }}
                        >
                          {userDetails
                            ? userDetails.name
                                .split(' ')
                                .map(word => word[0])
                                .join('')
                                .toUpperCase()
                            : ''}
                        </Avatar>
                      )}
                    </Box>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                    >
                      {uploadedImage ? (
                        <>
                          <Button
                            className="custom_menu"
                            component="label"
                            startIcon={<EditIcon />}
                            sx={{
                              height: 30,
                              color: colorTextBlack,
                              borderBottom: '1px solid black',
                              borderRadius: 0,
                              padding: '0px 3px'
                            }}
                            variant="text"
                          >
                            <input
                              ref={fileInputRef}
                              key={'uploadProfile'}
                              type="file"
                              style={{ display: 'none' }}
                              accept={imageAllowedTypes.join(',')}
                              onChange={handleImageUpload}
                            />
                            <Typography variant="button" color={'black'}>
                              Edit photo
                            </Typography>
                          </Button>
                          <Button
                            sx={{
                              color: '#F3413B',
                              borderBottom: '1px solid #F3413B',
                              borderRadius: '0px',
                              padding: '0px 3px'
                            }}
                            className="custom_menu"
                            onClick={handleRemoveImage}
                            startIcon={<DeleteIcon color="red" />}
                          >
                            Delete photo
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            className="custom_menu"
                            component="label"
                            startIcon={<Add />}
                            sx={{
                              height: 30,
                              color: colorTextBlack,
                              borderBottom: '1px solid black',
                              borderRadius: 0,
                              padding: '0px 3px',
                              ':hover': {
                                // color: 'red',
                                backgroundColor: 'transparent'
                              }
                            }}
                            variant="text"
                          >
                            <input
                              ref={fileInputRef}
                              key={'uploadProfile'}
                              type="file"
                              style={{ display: 'none', marginLeft: 1 }}
                              accept={imageAllowedTypes.join(',')}
                              onChange={handleImageUpload}
                            />{' '}
                            <Typography variant="button" color={'black'}>
                              Add Photo
                            </Typography>
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={12} lg={4} sx={formControl}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Name
                  </InputLabel>
                  <TextField
                    name="name"
                    size="small"
                    fullWidth
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item sm={12} lg={4} sx={formControl}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Phone No
                  </InputLabel>
                  <TextField
                    name="phoneNo"
                    size="small"
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                    value={formik.values.phoneNo}
                    onChange={e => {
                      formik.setFieldValue(
                        'phoneNo',
                        enterOnlyNumbers(e.target.value)
                      );
                    }}
                    error={
                      formik.touched.phoneNo && Boolean(formik.errors.phoneNo)
                    }
                    helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                  />
                </Grid>
                {/* </Grid>
              <Grid container spacing={3}> */}
                <Grid item sm={12} lg={4} sx={formControl}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Email
                  </InputLabel>
                  <TextField
                    name="email"
                    size="small"
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    // helperText={formik.touched.email && formik.errors.email}
                  />
                  <FormHelperText
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {formik.touched.email && formik.errors.email}

                    {userDetails?.userName && authData.isAdmin === true && (
                      <a
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          textDecoration: 'underline',
                          textUnderlineOffset: 2,
                          color: isHovered ? colorPrimary : colorTextBlack,
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          if (!isResetPasswordLoading)
                            dispatch(resetPassword(userDetails?.userName));
                        }}
                      >
                        <Typography
                          color={isHovered ? colorPrimary : colorTextBlack}
                        >
                          {!isResetPasswordLoading
                            ? 'Send reset password link'
                            : 'Sending reset password link'}
                        </Typography>
                      </a>
                    )}
                  </FormHelperText>
                </Grid>
                <Grid item sm={12} lg={4} sx={formControl}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Date of Birth
                  </InputLabel>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture={dayjs(new Date())}
                        name="dob"
                        value={
                          formik.values.dob ? dayjs(formik.values.dob) : null
                        }
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            size: 'small',
                            InputProps: {
                              sx: { color: colorTextBlack }
                            },
                            sx: {
                              borderRadius: '8px',
                              border: `1px solid ${formik.touched.dob && formik.errors.dob ? 'red' : 'inherit'}`
                            }
                          }
                        }}
                        onChange={date => {
                          formik.setFieldValue('dob', date);
                          formik.setFieldTouched('dob', true);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.dob && Boolean(formik.errors.dob)
                            }
                          />
                        )}
                        components={{
                          OpenPickerIcon: props => (
                            <IconButton size="small" {...props}>
                              <Calendar01Icon />
                            </IconButton>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    {formik.touched.dob && formik.errors.dob && (
                      <FormHelperText>{formik.errors.dob}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {showGrade && (
                  <Grid item md={12} lg={4} alignSelf={'center'} width={'100%'}>
                    <InputLabel
                      required
                      sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    >
                      Grade
                    </InputLabel>
                    <TextField
                      name="grade"
                      size="small"
                      fullWidth
                      value={formik.values.grade}
                      // onChange={formik.handleChange}
                      onChange={event => {
                        const value = event.target.value;
                        if (value.length <= 1) {
                          formik.handleChange(event);
                        }
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              <Divider orientation="horizontal" flexItem sx={{ my: 2 }} />
              <Grid container spacing={3} marginBottom={4}>
                <Grid item sm={12} lg={4} sx={formControl}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    State
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      value={formik.values.state}
                      onChange={e => {
                        formik.handleChange(e);
                        formik.setFieldValue('district', null);
                        formik.setFieldValue('area', null);
                        formik.setFieldValue('school', null);
                      }}
                      name="state"
                    >
                      {isArrayNotEmpty(stateData.state) &&
                        stateData.state.map((row, key) => {
                          return (
                            <MenuItem
                              key={key}
                              value={row.stateId}
                              sx={{
                                ':hover': {
                                  backgroundColor: colorSecondary
                                }
                              }}
                            >
                              {row?.stateName}
                            </MenuItem>
                          );
                        })}

                      {!isArrayNotEmpty(stateData.state) && (
                        <MenuItem
                          key={-99}
                          value={null}
                          sx={{
                            ':hover': {
                              backgroundColor: colorSecondary
                            }
                          }}
                        >
                          None
                        </MenuItem>
                      )}
                    </Select>
                    {formik.touched.state && formik.errors.state && (
                      <FormHelperText>{formik.errors.state}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={12} lg={4} sx={formControl}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    District
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      error={
                        formik.touched.district &&
                        Boolean(formik.errors.district)
                      }
                      value={formik.values.district}
                      onChange={e => {
                        formik.handleChange(e);
                        formik.setFieldValue('area', null);
                        formik.setFieldValue('school', null);
                      }}
                      name="district"
                    >
                      {isArrayNotEmpty(districtData.district) &&
                        districtData.district
                          .filter(cc => cc.stateId === formik.values.state)
                          .map((row, key) => {
                            return (
                              <MenuItem
                                key={key}
                                value={row?.districtId}
                                sx={{
                                  ':hover': {
                                    backgroundColor: colorSecondary
                                  }
                                }}
                              >
                                {row?.districtName}
                              </MenuItem>
                            );
                          })}
                      {!isArrayNotEmpty(
                        districtData.district.filter(
                          cc => cc.stateId === formik.values.state
                        )
                      ) && (
                        <MenuItem
                          key={-99}
                          value={null}
                          sx={{
                            ':hover': {
                              backgroundColor: colorSecondary
                            }
                          }}
                        >
                          None
                        </MenuItem>
                      )}
                    </Select>
                    {formik.touched.district &&
                      formik.errors.district && ( // Add error message display
                        <FormHelperText>
                          {formik.errors.district}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item sm={12} lg={4} sx={formControl}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Area
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      error={formik.touched.area && Boolean(formik.errors.area)}
                      value={formik.values.area}
                      onChange={e => {
                        formik.handleChange(e);
                        formik.setFieldValue('school', null);
                      }}
                      name="area"
                    >
                      {isArrayNotEmpty(areaData.area) &&
                        areaData.area
                          .filter(
                            cc =>
                              cc.stateId === formik.values.state &&
                              cc.districtId === formik.values.district
                          )
                          .map((row, key) => {
                            return (
                              <MenuItem
                                key={key}
                                value={row?.pinId}
                                sx={{
                                  ':hover': {
                                    backgroundColor: colorSecondary
                                  }
                                }}
                              >
                                {row?.area}
                              </MenuItem>
                            );
                          })}

                      {!isArrayNotEmpty(
                        areaData.area.filter(
                          cc =>
                            cc.stateId === formik.values.state &&
                            cc.districtId === formik.values.district
                        )
                      ) && (
                        <MenuItem
                          key={-99}
                          value={null}
                          sx={{
                            ':hover': {
                              backgroundColor: colorSecondary
                            }
                          }}
                        >
                          None
                        </MenuItem>
                      )}
                    </Select>
                    {formik.touched.area && formik.errors.area && (
                      <FormHelperText>{formik.errors.area}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={12} lg={4} sx={formControl}>
                  <InputLabel
                    required
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    School
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      value={formik.values.school}
                      onChange={formik.handleChange}
                      name="school"
                      error={
                        formik.touched.school && Boolean(formik.errors.school)
                      }
                    >
                      {isArrayNotEmpty(schoolsData.schools) &&
                        schoolsData.schools
                          .filter(
                            cc =>
                              cc.stateId === formik.values.state &&
                              cc.districtId === formik.values.district &&
                              cc.pinId === formik.values.area
                          )
                          .map((row, key) => {
                            return (
                              <MenuItem
                                key={key}
                                value={row?.schoolId}
                                sx={{
                                  ':hover': {
                                    backgroundColor: colorSecondary
                                  }
                                }}
                              >
                                {row?.schoolName}
                              </MenuItem>
                            );
                          })}

                      {!isArrayNotEmpty(
                        schoolsData.schools.filter(
                          cc =>
                            cc.stateId === formik.values.state &&
                            cc.districtId === formik.values.district &&
                            cc.pinId === formik.values.area
                        )
                      ) && (
                        <MenuItem
                          key={-99}
                          value={null}
                          sx={{
                            ':hover': {
                              backgroundColor: colorSecondary
                            }
                          }}
                        >
                          None
                        </MenuItem>
                      )}
                    </Select>
                    {formik.touched.school && formik.errors.school && (
                      <FormHelperText>{formik.errors.school}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={12} lg={4} sx={formControl}>
                  {' '}
                  <InputLabel
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    User Type
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <TextField
                      name="userType"
                      size="small"
                      fullWidth
                      disabled
                      value={formik.values.userType}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12} lg={4} sx={formControl}>
                  {' '}
                  <InputLabel
                    sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                  >
                    Modules
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth size="small">
                    <TextField
                      name="moduleId"
                      size="small"
                      fullWidth
                      disabled
                      multiline={
                        isArrayNotEmpty(formik.values.moduleId) ? true : false
                      }
                      rows={1}
                      value={
                        isArrayNotEmpty(formik.values.moduleId)
                          ? formik.values.moduleId.map(cc => ` ${cc}`)
                          : ''
                      }
                      inputProps={{
                        style: { overflow: 'auto' }
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Box>
          {/* {console.log(formik.,"sadasl")} */}
          <Box bgcolor={'white'} padding={2} borderRadius={2} marginTop={2}>
            <Grid display={'flex'} gap={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(revertAllUsersData());
                  navigate('/users');
                }}
                sx={{
                  borderColor: colorPrimary,
                  borderRadius: '10px',
                  color: colorTextBlack,
                  borderWidth: '2px',
                  height: '42px',
                  width: '106px',
                  '&:hover': {
                    borderColor: colorPrimary,
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    borderWidth: '2px'
                  }
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                onClick={() => formik.handleSubmit()}
                loading={loading}
                disabled={isSaveDisabled}
                type="submit"
                variant="contained"
                style={{
                  // fontWeight:"700",
                  // fontSize:"14px",
                  // marginRight: 24,
                  height: '42px',
                  width:
                    location.pathname !== '/users/edit-user' ? '176px' : '94px'
                }}
                // sx={{ px: 6, boxShadow: 'none' }}
              >
                {location.pathname !== '/users/edit-user'
                  ? 'Save & invite user'
                  : 'Save'}
              </LoadingButton>
            </Grid>

            {/* {userDetails?.userName && authData.isAdmin === true && (
              <LoadingButton
                loading={isResetPasswordLoading}
                variant="outlined"
                sx={{
                  borderColor: colorPrimary,
                  borderRadius: '10px',
                  color: colorTextBlack,
                  borderWidth: '2px',
                  marginRight: 2,
                  // width: 130,
                  '&:hover': {
                    borderColor: colorPrimary,
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    borderWidth: '2px'
                  }
                }}
                onClick={() => dispatch(resetPassword(userDetails?.userName))}
              >
                Send reset password email
              </LoadingButton>
            )} */}
          </Box>
        </>
      )}
      {imageUploadError.error && (
        <SnackBarBox
          type="error"
          message={handleError(imageUploadError.errorMessage)}
        />
      )}
      {(isRolesFailed ||
        isDistrictFailed ||
        isAreaFailed ||
        isStateFailed ||
        isSchoolsFailed) && (
        <SnackBarBox
          type={'error'}
          message={`Failed to fetch data for:- ${
            isRolesFailed
              ? 'Role'
              : isAreaFailed
                ? 'Area'
                : isStateFailed
                  ? 'State'
                  : isSchoolsFailed
                    ? 'School'
                    : ''
          } Master `}
        />
      )}

      {!isUploadProfilePicLoading && isUploadProfilePicFailed && (
        <SnackBarBox
          type="error"
          message={handleError(uploadProfilePicErrorContainer?.errorMessage)}
        />
      )}
      {!isDeleteProfilePicLoading && isDeleteProfilePicFailed && (
        <SnackBarBox
          type="error"
          message={handleError(DeleteProfilePicErrorContainer?.errorMessage)}
        />
      )}

      {!isUpadteUserDataLoading && isUpadteUserDataFailed && (
        <SnackBarBox
          type="error"
          message={
            UpadteUserDataErrorContainer?.errorMessage?.split(';').length > 1
              ? mapArrayToTypography(
                  UpadteUserDataErrorContainer?.errorMessage?.split(';')
                )
              : handleError(UpadteUserDataErrorContainer?.errorMessage)
          }
        />
      )}

      {!isResetPasswordLoading && isResetPasswordFailed && (
        <SnackBarBox
          type="error"
          message={handleError(resetPasswordErrorContainer?.errorMessage)}
        />
      )}
      {!isResetPasswordLoading && isResetPasswordSuccess && (
        <SnackBarBox
          type="success"
          message="Reset password email sent successful."
        />
      )}

      {!isUpadteUserDataLoading && isUpadteUserDataSuccess && (
        <SnackBarBox type="success" message="User updated successfully.." />
      )}

      {!isCreateUserLoading && isCreateUserFailed && (
        <SnackBarBox
          type="error"
          // message={mapArrayToTypography(
          //   createUserErrorContainer?.errorMessage?.split(';')
          // )}
          message={
            createUserErrorContainer?.errorMessage?.split(';').length > 1
              ? mapArrayToTypography(
                  createUserErrorContainer?.errorMessage?.split(';')
                )
              : handleError(createUserErrorContainer?.errorMessage)
          }
        />
      )}

      <BootstrapDialog
        onClose={() => setShowUserCreationModal(!showUserCreationModal)}
        open={showUserCreationModal}
        maxWidth={'sm'}
        fullWidth
      >
        <Box sx={{ padding: '40px', display: 'block', textAlign: 'center' }}>
          <CircleCheckIcon color={colorPrimary} />

          <Typography variant="h4" fontWeight={'500'} mt={3}>
            Great
          </Typography>
          <Typography variant="subtitle2" color={colorLinkGrey} mt={1}>
            User is sussessfully created and your invitation is successfully
            sent.
          </Typography>
        </Box>
      </BootstrapDialog>
    </Box>
  );
};

export default AddUser;
