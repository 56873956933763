import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle, handleError } from '../utils/utils';
import { clearLoginState, passwordRecovery } from '../redux/reducers/authSlice';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import CenteredLogo from '../components/CenteredLogo';
import {
  colorLinkGrey,
  colorPureWhite,
  colorSecondary,
  colorTextBlack
} from '../config/theme';
import PasswordRecoveryForm from '../containers/passwordrecovery/PasswordRecoveryForm';
import SnackBarBox from '../components/SnackBarBox';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { IMG_SPLASH_LOGO } from '../utils/imageUrls';
import { useFormik } from 'formik';
import * as yup from 'yup';

const successStyle = {
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: '100%',
  maxHeight: '100%',
  marginTop: '2rem'
};

const successArchStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: '#F3FCF7',
  height: '130px',
  borderRadius: '0 0 50% 50%'
};

const ModalDialog = ({ fullwidth, children }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth={fullwidth}
      maxWidth="sm"
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      disableEscapeKeyDown
      scroll="body"
      className="custom-dialog"
    >
      <DialogContent className="MuiDialogContentRootc">
        {children}
      </DialogContent>
    </Dialog>
  );
};

const PasswordRecovery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isPasswordRecoveryLoading,
    isPasswordRecoverySuccess,
    isPasswordRecoveryError,
    passwordRecoveryErrorContainer
  } = useSelector(state => state.auth);

  useEffect(() => {
    document.title = createTitle('Password-Recovery');
    return () => {
      dispatch(clearLoginState());
    };
  }, []);

  // form style
  const formControl = {
    margin: '0.5rem auto'
  };

  // form control
  const formik = useFormik({
    initialValues: {
      username: ''
    },
    validationSchema: yup.object({
      username: yup.string().required('Username is required')
    }),
    onSubmit: value => {
      dispatch(passwordRecovery(value.username));
    }
  });

  const handleFormValue = (e, field) => {
    formik.setFieldValue(field, e.target.value);
  };

  return (
    <>
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100vh'}
        borderRadius={'10px'}
      >
        <Grid
          item
          padding={3}
          md={4}
          bgcolor={colorSecondary}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {/* <CenteredLogo />
          <Typography mt={3} fontSize={24} fontWeight={'600'}>
            Password Recovery
          </Typography> */}
          <PasswordRecoveryForm />
        </Grid>
      </Grid>

      {!isPasswordRecoveryLoading && isPasswordRecoveryError && (
        <SnackBarBox
          type="error"
          message={handleError(passwordRecoveryErrorContainer?.errorMessage)}
        />
      )}

      {!isPasswordRecoveryLoading && isPasswordRecoverySuccess && (
        <ModalDialog fullwidth={true}>
          <Box>
            <div style={successArchStyle}></div>
            <img src="/images/success.png" alt="success" style={successStyle} />
            <Typography
              align="center"
              variant="body1"
              fontWeight={600}
              sx={{
                fontSize: '1rem',
                color: '#5EAC1B',
                marginTop: '150px'
              }}
            >
              Forgot password email sent successful.
            </Typography>
            <LoadingButton
              fullWidth
              style={{
                // textTransform: 'uppercase',
                marginTop: '3.5rem',
                textAlign: 'center',
                justifyContent: 'center'
              }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => navigate('/')}
            >
              Click to visit Login
            </LoadingButton>
          </Box>
        </ModalDialog>
      )}
    </>
  );
};

export default PasswordRecovery;
