import { darken, Dialog, Grid, lighten, styled, Switch } from '@mui/material';
import { colorPrimary } from '../config/theme';

export const FormLayout = styled('div')(({ theme }) => ({
  // height: 'calc(100vh - 20vh)', //Removing for sometime
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  justifyItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    width: '42%',
    margin: '1rem auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '10vh',
    boxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    WebkitBoxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    MozBoxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    padding: '0.5rem 0rem 1.5rem 0rem',
    height: 'max-content',
    borderRadius: '10px'
  }
}));

export const FooterLayout = styled('div')(({ theme }) => ({
  justifyItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-between',
  padding: '1rem 2rem 1rem 2rem',
  [theme.breakpoints.up('md')]: {
    padding: '1rem 2rem 1rem 2rem',
    width: '40%',
    margin: '1rem auto',
    justifyContent: 'center',
    height: 'max-content'
  }
}));
export const FormButtonBottom = styled('div')(() => ({
  margin: '2rem auto'
}));

export const FormContent = styled('div')(() => ({
  paddingTop: '2rem'
}));

export const StepperLayout = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '40%',
    margin: '0rem auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 'auto 1rem'
  }
}));

export const BoxShadowMdUp = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    boxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    WebkitBoxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    MozBoxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    padding: '1.5rem',
    height: 'max-content',
    borderRadius: '10px'
  }
}));

export const Container = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'block'
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  marginLeft: 10,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: colorPrimary,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}));

export const GroupItems = styled('ul')({
  padding: 0
});
