import { useState, useEffect } from 'react';
import { styled, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme, open }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: open ? '20ch' : '0px',
    '&:focus': {
      width: '20ch'
    }
  }
}));

const SearchInput = ({ onSearchChange, searchTerm }) => {
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    setSearchOpen(searchTerm.length > 0);
  }, [searchTerm]);

  return (
    <Search className="search-container">
      <SearchIconWrapper>
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 17.5L14.375 13.875M16.3333 9.16667C16.3333 12.8486 13.3486 15.8333 9.66667 15.8333C5.98477 15.8333 3 12.8486 3 9.16667C3 5.48477 5.98477 2.5 9.66667 2.5C13.3486 2.5 16.3333 5.48477 16.3333 9.16667Z"
            stroke="#ABB4BD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {/* <SearchIcon /> */}
      </SearchIconWrapper>
      <StyledInputBase
        open={searchOpen}
        onChange={onSearchChange}
        placeholder="Search here…"
        value={searchTerm}
      />
    </Search>
  );
};

export default SearchInput;
