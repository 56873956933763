import { useEffect, useState } from 'react';
import {
  Skeleton,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  Tab,
  Tabs,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  Button,
  Avatar,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  FormControl,
  Autocomplete,
  TextField,
  Dialog
} from '@mui/material';
import {
  colorPrimary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import {
  CheckCircleOutline,
  Close,
  EditOutlined,
  MoreVert,
  NotInterested,
  Search
} from '@mui/icons-material';
import { BootstrapDialog, Container } from '../../utils/styledLayout';
import { useNavigate } from 'react-router-dom';
import {
  createTitle,
  getOptionLabel,
  handleError,
  isArrayNotEmpty,
  mapArrayToTypography
} from '../../utils/utils';
import { setActiveModule } from '../../redux/reducers/layoutSlice';
import { useDispatch, useSelector } from 'react-redux';
import CustomDataTable from '../../components/CustomDataTable';
import moment from 'moment';
import {
  activateFlows,
  deactivateFlows,
  deleteFlows,
  getFlows,
  getFlowsCount,
  revertFlowsPageData
} from '../../redux/reducers/flowsSlice';
import ConfirmBox from '../../components/ConfirmBox';
import SnackBarBox from '../../components/SnackBarBox';
import {
  Edit05Icon,
  EditIcon,
  File02Icon,
  FileCheck02Icon,
  FileDownload02Icon,
  FileX02Icon,
  TrashIcon
} from '../../utils/iconSvg';
import CustomTabLabel from '../../custom/CustomTabLabel';

const Flows = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [initialValues, setInitialValues] = useState({
    tabValue: 0,
    size: 10,
    page: 0
  });
  const [forceUpdate, setForceUpdate] = useState('');
  const [forceDelete, setForceDelete] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [confirmation, setConfimation] = useState(null);
  const [showError, setShowError] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const {
    isFlowsLoading,
    isFlowsFailed,
    flowsData,
    flowsErrorContainer,
    activateFlowsLoading,
    activateFlowsSuccess,
    activateFlowsResponse,
    deactivateFlowsLoading,
    deactivateFlowsSuccess,
    deactivateFlowsFail,
    deactivateFlowsResponse,
    deactivateFlowsErrorContainer,
    deleteFlowsLoading,
    deleteFlowsSuccess,
    deleteFlowsFail,
    deleteFlowsResponse,
    deleteFlowsErrorContainer
  } = useSelector(state => state.flow);
  const { authData } = useSelector(state => state.auth);

  useEffect(() => {
    document.title = createTitle('Evaluation Forms');
    dispatch(setActiveModule('Evaluation Forms'));
    dispatch(getFlowsCount());
    return () => dispatch(revertFlowsPageData());
  }, []);

  useEffect(() => {
    dispatch(
      getFlows({
        type:
          initialValues.tabValue === 1
            ? true
            : initialValues.tabValue === 2
              ? false
              : 'all',
        page: initialValues.page,
        size: initialValues.size,
        search: ''
      })
    );
  }, [
    initialValues.page,
    initialValues.size,
    initialValues.tabValue,
    deleteFlowsSuccess === true,
    deactivateFlowsSuccess === true,
    activateFlowsSuccess === true
  ]);

  const columns = [
    {
      uinqueKey: 'flowId',
      id: 'flowName',
      label: 'Flow Name',
      sortable: true,
      minWidth: '100px',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.flowName}
          </Typography>
        );
      }
    },
    {
      id: 'createdBy',
      label: 'Created By'
    },

    {
      id: 'createdDate',
      label: 'Created On',
      minWidth: '150px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontSize={'14px'} fontWeight={'600'}>
                {row.createdDate
                  ? moment(row.createdDate).format('DD-MM-YYYY')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'responses',
      label: 'Responses'
    },
    {
      id: 'status',
      label: 'Status',
      width: '150px',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: row.status ? '#749E35' : '#D62828',
              width: row.status ? '62px' : '70px',
              height: '22px'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'edit',
      label: '',
      width: '50px',
      render: row => {
        return (
          <IconButton
            size="small"
            onClick={e => {
              navigate('edit-flow', {
                state: {
                  flowId: row.flowId,
                  flowName: row.flowName,
                  status: row.status,
                  createdBy: row.createdBy,
                  createdDate: row.createdDate,
                  role: { id: row.roleId, label: row.roleName }
                }
              });
              e.stopPropagation();
            }}
          >
            <EditIcon />
          </IconButton>
        );
      }
    }
  ];

  useEffect(() => {
    if (deactivateFlowsFail && !deactivateFlowsLoading) {
      setForceUpdate('deactivate');
    }
  }, [deactivateFlowsFail]);

  useEffect(() => {
    if (deleteFlowsFail && !deleteFlowsLoading) {
      setConfimation(false);
      setForceDelete(true);
    }
  }, [deleteFlowsFail]);

  useEffect(() => {
    if (
      (deleteFlowsSuccess && !deleteFlowsLoading) ||
      (activateFlowsSuccess && !deactivateFlowsLoading) ||
      (deactivateFlowsSuccess && !deactivateFlowsLoading)
    ) {
      handleReset();
    }
  }, [deleteFlowsSuccess, deactivateFlowsSuccess, activateFlowsSuccess]);

  const handleMenuActions = action => {
    setShowError(null);
    if (action === 'Delete') {
      handleDelete();
    } else if (action === 'De-Activate') {
      handleActivateDeActivate(false);
    } else if (action === 'Activate') {
      handleActivateDeActivate(true);
    }
  };

  const handleDelete = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(flowsData?.flows)) {
      if (isArrayNotEmpty(selectedList)) {
        setConfimation(true);
      } else {
        errorMessage = 'Please select flows to delete.';
      }
    }
    setShowError(errorMessage);
  };

  const handleDeleteFlow = (forceDeleteFlag = false) => {
    let deletePayload = {
      flowIds: [...selectedList],
      forceDelete: forceDeleteFlag,
      loggedInUserName: authData?.userName
    };
    dispatch(deleteFlows(deletePayload));
  };

  const handleActivateDeActivate = (val, forceUpdate = false) => {
    let errorMessage = '';
    if (isArrayNotEmpty(flowsData?.flows)) {
      if (!isArrayNotEmpty(selectedList)) {
        errorMessage = `Please select flow[s] to ${val ? `activate.` : 'in-activate'}`;
      } else {
        const selectedUsersData = flowsData.flows.filter(flow =>
          selectedList.includes(flow.flowId)
        );
        const flow = selectedUsersData.every(flows =>
          val ? !flows.status : flows.status
        );
        if (!flow) {
          errorMessage = `Please select only ${!val ? 'active' : 'inactive'} flow[s] to ${val ? 'activate' : 'in-activate'}.`;
        } else {
          let payload = {
            isActivate: val,
            flowIds: [...selectedList],
            forceDeactivate: forceUpdate,
            loggedInUserName: authData?.userName
          };
          dispatch(!val ? deactivateFlows(payload) : activateFlows(payload));
        }
      }
    }
    setShowError(errorMessage);
    setTimeout(() => {
      setShowError('');
    }, 1200);
  };

  const handleReset = () => {
    setSelectedList([]);
    setForceUpdate('');
    setForceDelete(false);
    setAnchorEl(null);
    setConfimation(null);
    setShowError(null);
    dispatch(getFlowsCount());
  };

  const onFlowModalClose = () => {
    setSelectedFlow(null);
    setShowModal(false);
  };

  const handleOnRowClick = row => {
    return navigate(`flows-details`, {
      state: {
        flowId: row.flowId,
        flowName: row.flowName,
        status: row.status,
        createdBy: row.createdBy,
        createdDate: row.createdDate,
        role: { id: row.roleId, label: row.roleName }
      }
    });
  };

  return (
    <>
      <Container mb={1}>
        <Grid>
          <Typography fontSize={32} fontWeight="700" display={'flex'}>
            Evaluation Flows{' '}
            {/* <Typography
              ml={1}
              fontSize={20}
              fontWeight="700"
              alignSelf={'center'}
            >
              ({flowsData.totalCount})
            </Typography> */}
          </Typography>
        </Grid>
        <Grid display={'flex'} gap={2}>
          <Button
            onClick={() => {
              setShowModal(!showModal);
            }}
            variant="outlined"
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: 'black',
              borderWidth: '2px',
              backgroundColor: 'transparent',
              fontWeight: '700'
            }}
          >
            <SvgIcon
              fontSize="small"
              sx={{ marginRight: 0.8, height: '16px', width: '16px' }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.91732 12.0771C1.94795 11.8015 1.96326 11.6636 2.00497 11.5348C2.04197 11.4205 2.09425 11.3117 2.16038 11.2114C2.23493 11.0984 2.33299 11.0003 2.52911 10.8042L11.3333 2.00004C12.0697 1.26366 13.2636 1.26366 14 2.00004C14.7364 2.73642 14.7364 3.93033 14 4.66671L5.19578 13.4709C4.99966 13.667 4.9016 13.7651 4.78855 13.8396C4.68826 13.9058 4.57949 13.958 4.46519 13.995C4.33636 14.0367 4.19853 14.0521 3.92287 14.0827L1.66663 14.3334L1.91732 12.0771Z"
                  stroke={colorTextBlack}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>{' '}
            Create new form
          </Button>
          <Button
            onClick={() => {
              navigate('create-new-flow');
            }}
            variant="contained"
            sx={{
              fontWeight: '700',
              // marginRight: 1,
              boxShadow: 'none'
            }}
          >
            <SvgIcon
              fontSize="small"
              sx={{ marginRight: 0.8, height: '16px', width: '16px' }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.91732 12.0771C1.94795 11.8015 1.96326 11.6636 2.00497 11.5348C2.04197 11.4205 2.09425 11.3117 2.16038 11.2114C2.23493 11.0984 2.33299 11.0003 2.52911 10.8042L11.3333 2.00004C12.0697 1.26366 13.2636 1.26366 14 2.00004C14.7364 2.73642 14.7364 3.93033 14 4.66671L5.19578 13.4709C4.99966 13.667 4.9016 13.7651 4.78855 13.8396C4.68826 13.9058 4.57949 13.958 4.46519 13.995C4.33636 14.0367 4.19853 14.0521 3.92287 14.0827L1.66663 14.3334L1.91732 12.0771Z"
                  stroke={colorTextBlack}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
            Create new flow
          </Button>
        </Grid>
      </Container>
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        borderRadius={'8px'}
        sx={{ paddingX: '14px', pt: 1 }}
      >
        <Container
          display={'flex'}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          justifyContent={'space-between'}
        >
          <Tabs
            value={initialValues.tabValue}
            onChange={(val, newValue) => {
              setInitialValues({
                ...initialValues,
                tabValue: newValue,
                page: 0
              });
            }}
            TabIndicatorProps={{
              sx: {
                height: '4px',
                borderRadius: '4px'
              }
            }}
          >
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 0 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 0
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              icon={
                <SvgIcon sx={{ height: 18, width: 18 }}>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 9H6M12 12.375H6M12 5.625H6M15 5.1V12.9C15 14.1601 15 14.7902 14.7548 15.2715C14.539 15.6948 14.1948 16.039 13.7715 16.2548C13.2902 16.5 12.6601 16.5 11.4 16.5H6.6C5.33988 16.5 4.70982 16.5 4.22852 16.2548C3.80516 16.039 3.46095 15.6948 3.24524 15.2715C3 14.7902 3 14.1601 3 12.9V5.1C3 3.83988 3 3.20982 3.24524 2.72852C3.46095 2.30516 3.80516 1.96095 4.22852 1.74524C4.70982 1.5 5.33988 1.5 6.6 1.5H11.4C12.6601 1.5 13.2902 1.5 13.7715 1.74524C14.1948 1.96095 14.539 2.30516 14.7548 2.72852C15 3.20982 15 3.83988 15 5.1Z"
                      stroke={
                        initialValues.tabValue === 0
                          ? colorTextBlack
                          : colorTextGrey
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
              }
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="All flows "
                  count={
                    flowsData?.allCount < 10
                      ? `${flowsData?.allCount}`
                      : flowsData?.allCount
                  }
                  selected={initialValues.tabValue === 0}
                />
              }
            />
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                fontSize: '16px',
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 1 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 1
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              icon={
                <SvgIcon sx={{ height: 18, width: 18 }}>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 9.375V5.1C15 3.83988 15 3.20982 14.7548 2.72852C14.539 2.30516 14.1948 1.96095 13.7715 1.74524C13.2902 1.5 12.6601 1.5 11.4 1.5H6.6C5.33988 1.5 4.70982 1.5 4.22852 1.74524C3.80516 1.96095 3.46095 2.30516 3.24524 2.72852C3 3.20982 3 3.83988 3 5.1V12.9C3 14.1601 3 14.7902 3.24524 15.2715C3.46095 15.6948 3.80516 16.039 4.22852 16.2548C4.70982 16.5 5.33985 16.5 6.59989 16.5H9.375M10.5 8.25H6M7.5 11.25H6M12 5.25H6M11.25 14.25L13.5 16.5M13.5 16.5L15.75 14.25M13.5 16.5V12"
                      stroke={
                        initialValues.tabValue === 1
                          ? colorTextBlack
                          : colorTextGrey
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
              }
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="Owned by me"
                  count={
                    flowsData?.activeCount < 10
                      ? `0${flowsData?.activeCount}`
                      : flowsData?.activeCount
                  }
                  selected={initialValues.tabValue === 1}
                />
              }
            />
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                fontSize: '16px',
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 2 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 2
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              icon={
                <SvgIcon sx={{ height: 18, width: 18 }}>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 8.25H6M7.5 11.25H6M12 5.25H6M15 9V5.1C15 3.83988 15 3.20982 14.7548 2.72852C14.539 2.30516 14.1948 1.96095 13.7715 1.74524C13.2902 1.5 12.6601 1.5 11.4 1.5H6.6C5.33988 1.5 4.70982 1.5 4.22852 1.74524C3.80516 1.96095 3.46095 2.30516 3.24524 2.72852C3 3.20982 3 3.83988 3 5.1V12.9C3 14.1601 3 14.7902 3.24524 15.2715C3.46095 15.6948 3.80516 16.039 4.22852 16.2548C4.70982 16.5 5.33988 16.5 6.6 16.5H9M12 12L15.75 15.75M15.75 12L12 15.75"
                      stroke={
                        initialValues.tabValue === 2
                          ? colorTextBlack
                          : colorTextGrey
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
              }
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="Not owned by me"
                  count={
                    flowsData?.inActiveCount < 10
                      ? `0${flowsData?.inActiveCount}`
                      : flowsData?.inActiveCount
                  }
                  selected={initialValues.tabValue === 2}
                />
              }
            />
          </Tabs>
          <div style={{ margin: 'auto 10px' }}>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => {
                setAnchorEl(null);
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: '20ch',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {['Activate', 'De-Activate', 'Delete'].map(option => (
                <MenuItem
                  key={option}
                  onClick={() => {
                    handleMenuActions(option);
                  }}
                >
                  <ListItemIcon>
                    {option === 'Delete' ? (
                      <>
                        <SvgIcon sx={{ height: 20, width: 20 }}>
                          <TrashIcon color="red" />
                        </SvgIcon>
                      </>
                    ) : option === 'De-Activate' ? (
                      <SvgIcon
                        sx={{ height: 20, width: 20, color: 'orangered' }}
                      >
                        <NotInterested />
                      </SvgIcon>
                    ) : option === 'Activate' ? (
                      <SvgIcon sx={{ height: 20, width: 20, color: 'green' }}>
                        <CheckCircleOutline />
                      </SvgIcon>
                    ) : (
                      ''
                    )}
                  </ListItemIcon>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Container>
        <Grid sx={{ padding: '18px 0px' }} width={'100%'}>
          {isFlowsLoading && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}
          {!isFlowsLoading && (
            <CustomDataTable
              columns={columns}
              allowSelection={true}
              data={flowsData?.flows?.length > 0 ? flowsData?.flows : []}
              onPageChange={val => {
                setInitialValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitialValues({ ...initialValues, size: val });
              }}
              selectedRows={[...selectedList]}
              onSelectionChange={val => {
                setSelectedList(val);
              }}
              defaultOrderBy={'createdDate'}
              defaultPage={initialValues.page}
              defaultRowsPerPage={initialValues.size}
              totalCount={flowsData?.totalCount}
              onRowClick={row => handleOnRowClick(row)}
            />
          )}
        </Grid>
      </Grid>

      <ConfirmBox
        isOpen={confirmation}
        title="Delete Flow!"
        subtitle={`${selectedList.length} flow[s] are selected, Are you sure you want to delete. `}
        handleClose={() => setConfimation(null)}
        handleSubmit={() => {
          handleDeleteFlow();
        }}
      />

      <ConfirmBox
        isOpen={forceUpdate === 'deactivate'}
        title="Force Update flow[s]!"
        subtitle={`Assigned forms will be removed from the flow[s],  Are you sure you want to force update. `}
        handleClose={() => {
          setForceUpdate(null);
        }}
        handleSubmit={() => {
          handleActivateDeActivate(forceUpdate === 'activate', true);
        }}
      />

      <ConfirmBox
        isOpen={forceDelete}
        title="Force Delete flow[s]!"
        subtitle={`Assigned forms will be removed from the flow[s],  Are you sure you want to force delete. `}
        handleClose={() => {
          setForceDelete(null);
        }}
        handleSubmit={() => {
          handleDeleteFlow(true);
        }}
      />

      {showError && <SnackBarBox type={'error'} message={showError} />}

      {!isFlowsLoading && isFlowsFailed && (
        <SnackBarBox
          type="error"
          message={handleError(flowsErrorContainer?.errorMessage)}
        />
      )}
      {!deactivateFlowsLoading && deactivateFlowsFail && (
        <SnackBarBox
          type="error"
          message={mapArrayToTypography(
            deactivateFlowsErrorContainer?.errorMessage
              ?.split('.,')
              .map(part => part.trim().replace(/^[{]|[}]$/g, ''))
          )}
        />
      )}

      {!deleteFlowsLoading && deleteFlowsFail && (
        <SnackBarBox
          type="error"
          message={mapArrayToTypography(
            deleteFlowsErrorContainer?.errorMessage
              ?.split('.,')
              .map(part => part.trim().replace(/^[{]|[}]$/g, ''))
          )}
        />
      )}

      {!activateFlowsLoading && activateFlowsSuccess && (
        <SnackBarBox type="success" message={activateFlowsResponse} />
      )}

      {!deactivateFlowsLoading && deactivateFlowsSuccess && (
        <SnackBarBox type="success" message={deactivateFlowsResponse} />
      )}

      {!deleteFlowsLoading && deleteFlowsSuccess && (
        <SnackBarBox type="success" message={deleteFlowsResponse} />
      )}

      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            // minWidth: '520px',
            // maxWidth: '520px',
            borderRadius: '8px'
            // padding: '6px'
          }
        }}
        // fullWidth
        open={showModal}
        onClose={() => {
          onFlowModalClose();
        }}
      >
        <Grid sx={{ padding: '24px' }}>
          {/* <DialogContent sx={{}}> */}
          <Typography fontSize={'20px'} fontWeight={'700'} mb={2}>
            Select the flow
          </Typography>
          <FormControl variant="outlined" fullWidth size="small" sx={{ mb: 2 }}>
            <Autocomplete
              size="small"
              value={selectedFlow}
              onChange={(event, newValue) => {
                setSelectedFlow(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                option.id === (value ? value.id : null)
              }
              getOptionLabel={getOptionLabel}
              options={
                isArrayNotEmpty(flowsData.flows)
                  ? flowsData.flows.map(c => {
                      return {
                        label: c.flowName,
                        id: c.flowId
                      };
                    })
                  : []
              }
              // sx={{ width: '100%' }}
              sx={{ width: '440px' }}
              renderInput={params => <TextField name={`flowId`} {...params} />}
            />
          </FormControl>
          <Grid mt={2} item display={'flex'} justifyContent={'start'} gap={2}>
            <Button
              variant="outlined"
              onClick={() => onFlowModalClose()}
              sx={{
                px: 4,

                borderColor: colorPrimary,
                borderRadius: '10px',
                color: 'black',
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!selectedFlow}
              onClick={() => {
                let tempFlow = flowsData.flows.find(
                  cc => cc.flowId === selectedFlow?.id
                );

                navigate('/flows/flows-details/create-new-form', {
                  state: tempFlow
                });
              }}
              variant="contained"
              sx={{ mr: 2, px: 4, borderWidth: 2 }}
            >
              Proceed
            </Button>
          </Grid>
          {/* </DialogContent> */}
        </Grid>
      </Dialog>
    </>
  );
};

export default Flows;
