import React, { useEffect, useState } from 'react';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Skeleton,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../components/Breadcrumb';
import {
  createTitle,
  getOptionLabel,
  handleError,
  isArrayNotEmpty
} from '../../utils/utils';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getRoles, revertRolePageData } from '../../redux/reducers/roleSlice';
import {
  createUpdateFlows,
  revertFlowsFormData,
  revertFlowsPageData
} from '../../redux/reducers/flowsSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { FLOWS_STATUS } from '../../utils/constant';
import SnackBarBox from '../../components/SnackBarBox';
import { ChevronRightIcon } from '../../utils/iconSvg';

const AddEditFlow = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { authData } = useSelector(state => state.auth);
  const { rolesData, isRolesSuccess } = useSelector(state => state.roles);
  const {
    isCreateUpdateFlowsSuccess,
    isCreateUpdateFlowsFail,
    isCreateUpdateFlowsLoading,
    createUpdateFlowsResponse,
    createUpdateFlowsErrorContainer
  } = useSelector(state => state.flow);

  useEffect(() => {
    document.title = createTitle(
      `${location.pathname === '/flows/edit-flow' ? 'Edit' : 'Add'} Flow`
    );

    dispatch(
      getRoles({
        type: 'all',
        page: 0,
        size: 0,
        search: ''
      })
    );

    return () => {
      dispatch(revertRolePageData());
      dispatch(revertFlowsFormData());
    };
  }, []);

  const formik = useFormik({
    initialValues: { flowName: '', role: null, status: null },
    validationSchema: Yup.object().shape({
      flowName: Yup.string().required('Flow Name is required'),
      role: Yup.object()
        .shape({
          id: Yup.string().required()
        })
        .required('Role is required'),
      status: Yup.boolean().required('Status is required')
    }),
    onSubmit: values => {
      setLoading(true);

      let submitPayload = {
        flowName: values.flowName,
        roleId: values.role.id,
        status: values.status,
        loggedInUserName: authData.userName
      };

      dispatch(
        createUpdateFlows({
          payload: submitPayload,
          flowId: location.state?.flowId || null
        })
      );
    }
  });

  useEffect(() => {
    if (isCreateUpdateFlowsSuccess) {
      setTimeout(() => {
        navigate('/flows');
        dispatch(revertFlowsPageData());
      }, 2000);
    }

    if (isCreateUpdateFlowsFail || isCreateUpdateFlowsFail) {
      setLoading(false);
    }
  }, [isCreateUpdateFlowsFail, isCreateUpdateFlowsSuccess]);

  useEffect(() => {
    if (location.pathname === '/flows/edit-flow' && location.state) {
      formik.setValues({
        flowName: location.state.flowName,
        role: location.state?.role || null,
        status: location.state.status
      });
      setLoading(false);
    }
  }, [isRolesSuccess]);

  const areAllRequiredFieldsFilled = () => {
    return formik.values.flowName && formik.values.role && formik.values.status;
  };

  const isSaveDisabled = !formik.isValid || !areAllRequiredFieldsFilled();

  return (
    <>
      <Box>
        <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={colorTextGrey} />
              </SvgIcon>
            }
            sx={{ marginBottom: 2 }}
          >
            <Link
              color="inherit"
              href={'flow-craete'}
              onClick={event => {
                event.preventDefault();
                navigate(
                  location.pathname === '/flows/edit-flow'
                    ? '/flows'
                    : '/flows',
                  { state: { ...location.state } }
                );
              }}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                fontWeight={'700'}
                color={colorLinkGrey}
                fontSize={'24px'}
              >
                {location.state?.flowName || 'Flows'}
              </Typography>
            </Link>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
                color: colorTextBlack
              }}
            >
              {`${location.pathname === '/flows/edit-flow' ? 'Edit' : 'Add new'} flow`}
            </Typography>
          </Breadcrumbs>{' '}
        </Typography>

        {loading && (
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={400}
            animation="wave"
          />
        )}
        {!loading && (
          <Box bgcolor={'white'} padding={2} borderRadius={2} marginBottom={2}>
            {location.pathname !== '/flows/edit-flow' && (
              <Typography
                mb={1.5}
                fontWeight={'700'}
                fontSize={'20px'}
                lineHeight={'26px'}
              >
                Create new flow
              </Typography>
            )}
            <Grid container spacing={3}>
              <Grid item sm={12} md={4}>
                <InputLabel
                  required
                  sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                >
                  Add flow name
                </InputLabel>
                <FormControl variant="outlined" fullWidth size="small">
                  <TextField
                    placeholder="Enter flow name"
                    name="flowName"
                    fullWidth
                    size="small"
                    inputProps={{ maxLength: 64 }}
                    value={formik.values.flowName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.flowName && Boolean(formik.errors.flowName)
                    }
                    InputLabelProps={{
                      shrink: formik.values.flowName !== ''
                    }}
                    helperText={
                      formik.touched.flowName && formik.errors.flowName
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={4}>
                <InputLabel
                  required
                  sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                >
                  Select role
                </InputLabel>
                <FormControl variant="outlined" fullWidth size="small">
                  <Autocomplete
                    size="small"
                    value={formik.values.role}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('role', newValue ? newValue : null);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === (value ? value.id : null)
                    }
                    getOptionLabel={getOptionLabel}
                    options={
                      isArrayNotEmpty(rolesData.roles)
                        ? rolesData.roles.map(c => {
                            return {
                              label: c.roleName,
                              id: c.roleId
                            };
                          })
                        : []
                    }
                    sx={{ width: '100%' }}
                    clearIcon={false}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: '2.5px 4px 2.5px 4px' // Adjust this padding as needed
                            },
                            tabIndex: -1,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ marginLeft: '4px' }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 14L11.6667 11.6667M13.3333 7.66667C13.3333 10.7963 10.7963 13.3333 7.66667 13.3333C4.53705 13.3333 2 10.7963 2 7.66667C2 4.53705 4.53705 2 7.66667 2C10.7963 2 13.3333 4.53705 13.3333 7.66667Z"
                                    stroke="#ABB4BD"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </InputAdornment>
                            )
                          }}
                          placeholder="Select role"
                          error={formik.touched.role && formik.errors.role}
                          helperText={formik.touched.role && formik.errors.role}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={4}>
                <InputLabel
                  required
                  sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                >
                  Select status
                </InputLabel>
                <FormControl variant="outlined" fullWidth size="small">
                  <Select
                    error={
                      formik.touched.status && Boolean(formik.errors.status)
                    }
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    name="status"
                  >
                    {FLOWS_STATUS.map((row, key) => (
                      <MenuItem
                        key={key}
                        value={row.value}
                        sx={{
                          ':hover': {
                            backgroundColor: colorSecondary
                          }
                        }}
                      >
                        {row.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.status && Boolean(formik.errors.status) && (
                    <FormHelperText>{formik.errors.status}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Box bgcolor={'white'} padding={2} borderRadius={2} marginTop={2}>
        <Grid display={'flex '} gap={2}>
          <Button
            variant="outlined"
            onClick={() => {
              if (location.state) {
                navigate(-1, { ...location.state });
              } else {
                navigate('/flows');
              }
            }}
            sx={{
              borderColor: colorPrimary,
              borderRadius: '10px',
              color: colorTextBlack,
              borderWidth: '2px',
              height: '42px',
              width: '106px',
              '&:hover': {
                borderColor: colorPrimary,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                borderWidth: '2px'
              }
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            disabled={isSaveDisabled}
            onClick={() => formik.handleSubmit()}
            variant="contained"
            sx={{
              width: '94px',
              height: '42px',
              boxShadow: 'none'
            }}
          >
            Save
          </LoadingButton>
        </Grid>
      </Box>

      {!isCreateUpdateFlowsLoading && isCreateUpdateFlowsFail && (
        <SnackBarBox
          type="error"
          message={handleError(createUpdateFlowsErrorContainer?.errorMessage)}
        />
      )}
      {!isCreateUpdateFlowsLoading && isCreateUpdateFlowsSuccess && (
        <SnackBarBox type="success" message={createUpdateFlowsResponse} />
      )}
    </>
  );
};

export default AddEditFlow;
